import React, { useState, useEffect } from "react";
import {
    Image,
    Pressable,
    View as ReactView,
    StyleSheet,
    Text,
    View
} from "react-native";
import { FontAwesome, Ionicons } from "@expo/vector-icons";
import { colors } from "../../styles/colors";
import CustomModal from "../CustomElements/CustomModal";
import CustomDivider from "../CustomDivider";
import Header from "../CustomElements/Header";
import StarRatingDisplay from "./StarRatingDisplay";
import getEnvVars from "../../../environment";
import Flex from "../CustomElements/Flex";
import { globalStyles } from "../../styles/styles";
const { cloudfrontURL } = getEnvVars();

const UserReview = ({ review }) => {
    const { reviewerAvatar, reviewerName, description, rating, images } = (review)
    const [displayAvatar, setDisplayAvatar] = useState(null);
    const [reviewDisplayImages, setReviewDisplayImages] = useState([]);

    useEffect(() => {
        !!reviewerAvatar && setDisplayAvatar(`${cloudfrontURL}${reviewerAvatar}`);
        !!images && setReviewDisplayImages(images);
    }, [])

    return (
        <View style={{ margin: 20 }}>
            <View style={styles.row}>
                {displayAvatar ? (
                    <Image
                        style={[styles.avatarImage, { width: 60, height: 60, }]}
                        source={{ uri: displayAvatar }}
                    />
                ) : (
                    <Ionicons name="person-circle" size={70} color="grey" />
                )}
                <View style={{ marginLeft: 10 }}>
                    <Header padding={0} level={.9} text={reviewerName} />
                    <View style={globalStyles.center}>
                        <Text style={{ fontSize: 17, marginTop: 10, marginRight: 5 }}>{Number(rating).toFixed(1)}</Text>
                        <StarRatingDisplay stars={rating} />
                    </View>
                </View>
            </View>
            <Text style={{ fontSize: 17, marginLeft: 20, marginTop: 5 }}>"{description}"</Text>
            {!!reviewDisplayImages.length && (
                <Image
                    style={[styles.reviewImage, { width: 300, height: 200, }]}
                    source={{ uri: `${cloudfrontURL}${reviewDisplayImages[0]}` }}
                />
            )}
        </View>
    )
}

const ReviewModalDisplay = ({ reviewModalOpen, setReviewModalOpen, averageRating, numberReviews, mobileView, reviews }) => {
    return (
        <CustomModal modalVisible={reviewModalOpen} setModalVisible={() => setReviewModalOpen(!reviewModalOpen)}>
            <View style={[styles.headerSection, { flexDirection: 'row' }]}>
                <View style={styles.row}>
                    <FontAwesome name="star" size={32} color={colors.orange50} />
                    <Text style={styles.modalHeaderText}>{averageRating}</Text>
                </View>
                <View style={styles.numberReviews}>
                    <Header textAlign="left" text={`${numberReviews} review${numberReviews > 1 ? 's' : ''}`} />
                </View>
            </View >
            <CustomDivider />
            <View style={[styles.container, { flexDirection: "column" }]}>
                {reviews.map((v, i) => (
                    <UserReview mobileView={mobileView} review={v} key={i} />
                ))}
            </View>
        </CustomModal>
    )
}

const ReviewDisplayFirstThree = ({ averageRating, numberReviews, mobileView, reviews, width, setReviewModalOpen }) => {
    const firstThree = reviews.slice(0, 3);
    return (
        <View style={{ flexDirection: "column", flex: 1, marginLeft: 10 }}>
            <View style={styles.row}>
                <FontAwesome name="star" size={20} color={colors.orange50} />
                <Text style={styles.modalHeaderText}>{averageRating}</Text>
                <Text style={styles.modalHeaderText}>&#x2022;</Text>
                <Pressable onPress={() => setReviewModalOpen(true)}>
                    <Text style={styles.textUnderline}>{numberReviews} review{numberReviews > 1 ? 's' : ''}</Text>
                </Pressable>
            </View>
            <View style={[styles.container, { flexDirection: "column" }]}>
                {firstThree.map((v, i) => (
                    <UserReview width={width} mobileView={mobileView} review={v} key={i} />
                ))}
            </View>
            <Pressable onPress={() => setReviewModalOpen(true)}>
                <View style={styles.row}>
                    <Text style={styles.textUnderline}>View {numberReviews > 1 ? "all " : ""}{numberReviews} review{numberReviews > 1 ? 's' : ''}</Text>
                </View>
            </Pressable>
        </View>
    )
}

export const ReviewView = ({ reviews, width, mobileView, showFirstThree = false }) => {
    const [averageRating, setAverageRating] = useState(0);
    const [loadingReview, setLoadingReview] = useState(true);
    const [numberReviews, setNumberReviews] = useState(0);
    const [reviewModalOpen, setReviewModalOpen] = useState(false);

    const getAverageRating = () => {
        let allReviewsAdded = 0;
        reviews.forEach(element => {
            allReviewsAdded = allReviewsAdded + element.rating;
        });
        setNumberReviews(reviews.length)
        setAverageRating(Number(allReviewsAdded / reviews.length).toFixed(1))
        setLoadingReview(false)
    };

    useEffect(() => {
        getAverageRating();
    }, []);

    return (
        <ReactView style={[styles.container, { marginBottom: showFirstThree ? 40 : 0 }]}>
            {!loadingReview && (
                <>
                    {showFirstThree ? (
                        <ReviewDisplayFirstThree setReviewModalOpen={() => setReviewModalOpen(true)} width={width} reviews={reviews} numberReviews={numberReviews} averageRating={averageRating} mobileView={mobileView} />
                    ) : (
                        <Pressable onPress={() => setReviewModalOpen(true)}>
                            <View style={styles.row}>
                                <FontAwesome name="star" size={20} color={colors.amplifyNeutral80} />
                                <Text style={[styles.text, { marginLeft: mobileView ? 2 : 5 }]}>{averageRating}</Text>
                                <Text style={[styles.text, { marginLeft: mobileView ? 0 : 5 }]}>&#x2022;</Text>
                                <Text style={[styles.textUnderline, { marginLeft: mobileView ? 0 : 5 }]}>{numberReviews} review{numberReviews > 1 ? 's' : ''}</Text>
                            </View>
                        </Pressable>
                    )}
                    <ReviewModalDisplay reviewModalOpen={reviewModalOpen} setReviewModalOpen={() => setReviewModalOpen(!reviewModalOpen)} reviews={reviews} numberReviews={numberReviews} averageRating={averageRating} />
                </>
            )}
        </ReactView>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        alignContent: "flex-start",
        alignSelf: "flex-start",
        marginTop: 5
    },
    row: {
        flexDirection: "row",
        alignContent: "flex-start",
        alignItems: "center",
        alignSelf: "flex-start",
    },
    text: {
        fontSize: 14,
        fontWeight: "bold"
    },
    modalHeaderText: {
        fontSize: 22,
        marginLeft: 6,
        fontWeight: "bold"
    },
    textUnderline: {
        fontSize: 14,
        marginTop: 2,
        textDecorationLine: "underline"
    },
    headerSection: {
        justifyContent: "space-between",
        alignItems: "space-between",
        alignContent: "space-between",
        flexDirection: "row"
    },
    numberReviews: {
        flexDirection: "row",
        marginLeft: 15,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center"
    },
    avatarImage: {
        borderRadius: 100,
        borderWidth: 1,
    },
    reviewImage: {
        borderRadius: 5,
        margin: 10,
        borderWidth: .5
    }
});
