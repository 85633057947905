import { updateUserMutation } from "../api/user";


export const addOrRemoveFavorite = async ({ v, user, propertyID }) => {
    const { favorites } = user.userData.userData;
    const setUserData = user.setUserData;
    const updateUserData = (data) => setUserData(data);
    let favsArray = favorites !== null ? [...favorites] : [];

    if (!!v) {
        favsArray.push(propertyID);
    } else {
        favsArray = favsArray.filter(x => x !== propertyID);
    }
    let data = {
        id: user.userData.sub,
        favorites: favsArray,
    }
    let updatedUser = await updateUserMutation({ currentUserData: user.userData, data })
    if (updatedUser) {
        return updateUserData(updatedUser)
    } else {
        return;
    }
};