import React, { useContext, useEffect, useState } from "react";
import { StyleSheet, Text as ReactText, Pressable, View, useWindowDimensions } from "react-native";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { FontAwesome } from '@expo/vector-icons';
import { UserDataContext } from "../../utils/UserContextProvider";
import Flex from "../CustomElements/Flex"
import Header from "../CustomElements/Header"
import Card from "../CustomElements/Card"
import { createRenterReview as createRenterReviewMutation } from "../../graphql/mutations";
import CustomButton from "../CustomElements/CustomButton";
import { colors } from "../../styles/colors";
import CustomTextInput from "../CustomElements/CustomTextInput";
import { UUID } from "../../utils/UUID";
import CustomModal from "../CustomElements/CustomModal";
import { ErrorDisplay } from "../CustomElements/ErrorDisplay";
import CloseButtonUnderlined from "../CustomElements/CloseButtonUnderlined";
import { formatMonthDay } from "../../utils/formatDate";
import { getUserDetails } from "../../api/user";
import { sendEmail } from "../../lambdaFunctions/sendEmail/sendEmail";

const ReviewStarSelect = ({ type, starVal, setStarVal, width }) => {
    return (
        <Flex justifyContent="space-between" alignSelf="space-between" width={width}>
            <ReactText>{type}</ReactText>
            <View style={{ flexDirection: "row" }}>
                <Pressable onPress={() => setStarVal(1)} >
                    {starVal >= 1 ? <FontAwesome name="star" margin={.5} padding={.5} size={24} color={colors.orange50} /> : <FontAwesome name="star-o" size={24} color="black" />}
                </Pressable>
                <Pressable onPress={() => setStarVal(2)} >
                    {starVal >= 2 ? <FontAwesome name="star" margin={.5} padding={.5} size={24} color={colors.orange50} /> : <FontAwesome name="star-o" size={24} color="black" />}
                </Pressable>
                <Pressable onPress={() => setStarVal(3)} >
                    {starVal >= 3 ? <FontAwesome name="star" margin={.5} padding={.5} size={24} color={colors.orange50} /> : <FontAwesome name="star-o" size={24} color="black" />}
                </Pressable>
                <Pressable onPress={() => setStarVal(4)} >
                    {starVal >= 4 ? <FontAwesome name="star" margin={.5} padding={.5} size={24} color={colors.orange50} /> : <FontAwesome name="star-o" size={24} color="black" />}
                </Pressable>
                <Pressable onPress={() => setStarVal(5)} >
                    {starVal >= 5 ? <FontAwesome name="star" margin={.5} padding={.5} size={24} color={colors.orange50} /> : <FontAwesome name="star-o" size={24} color="black" />}
                </Pressable>
            </View>
        </Flex>
    )
}

const ReviewCreateCard = ({
    bookingToDisplay,
    width,
    mobileView,
    setCleanlinessStars,
    setCommunicationStars,
    setDescription,
    setOverallStars,
    cleanlinessStars,
    communicationStars,
    description,
    overallstars
}) => {

    return (
        <Card
            backgroundColor="#fad8be"
            color="#000"
            alignItems="center"
            border={colors.amplifyNeutral60}
            width={width / 1.75}
            padding={30}
            marginBottom={20}
            marginTop={10}
        >
            <ReviewStarSelect width={width / 1.8} type="Communication" starVal={communicationStars} setStarVal={(v) => { setCommunicationStars(v) }} />
            <ReviewStarSelect width={width / 1.8} type="Cleanliness" starVal={cleanlinessStars} setStarVal={(v) => { setCleanlinessStars(v) }} />
            <ReviewStarSelect width={width / 1.8} type="Overall" starVal={overallstars} setStarVal={(v) => { setOverallStars(v) }} />
            <ReactText style={{ alignSelf: "flex-start", marginTop: 20 }}>Comments</ReactText>
            <CustomTextInput
                textAlign="left"
                height={100}
                width={width / 1.85}
                multiline={true}
                onChangeText={(e) => setDescription(e)}
                value={description}
                backgroundColor="#fff"
            ></CustomTextInput>
        </Card>
    );
};

const ReviewModalRenter = ({
    setAlreadyReview,
    modalVisible,
    setModalVisible,
    bookingDetails,
    imTheHost
}) => {
    const {
        id,
        cancelled,
        bookingRequest,
        renterID,
        check_in,
        check_out,
        renterEmail
    } = bookingDetails;
    const {
        spaceCity,
        spaceState,
        spaceTitle,
        rentalType,
        propertyID,
        propertyTitle
    } = bookingRequest;
    const { user } = useContext(UserDataContext);
    const { userData } = user;
    const { mobileView } = useSelector(state => state.currentUser);
    const { width } = useWindowDimensions();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [description, setDescription] = useState('');
    const [cleanlinessStars, setCleanlinessStars] = useState(0);
    const [overallstars, setOverallStars] = useState(0);
    const [communicationStars, setCommunicationStars] = useState(0);
    const [checkIn, setCheckIn] = useState(null)
    const [checkOut, setCheckOut] = useState(null)
    const [revieweesName, setRevieweesName] = useState('')

    const closeThis = () => {
        setLoading(false);
        setError(false);
        setModalVisible(!modalVisible);
    };

    const getRevieweesName = async () => {
        const renter = await getUserDetails(renterID);
        setRevieweesName(renter.name)
    }

    const createReview = async () => {
        setLoading(true);
        const averageRating = Number((overallstars + communicationStars + cleanlinessStars) / 3).toFixed(2);
        const reviewData = {
            id: UUID(),
            overallRating: overallstars,
            communicationRating: communicationStars,
            cleanlinessRating: cleanlinessStars,
            rating: averageRating,
            description: description,
            reviewerName: userData.userName,
            reviewerAvatar: userData.avatarImage,
            reviewerID: userData.sub,
            bookingID: id,
            renterID: renterID,
            propertyID: propertyID
        };
        await API.graphql({
            query: createRenterReviewMutation,
            variables: {
                input: reviewData,
            },
            authMode: "AMAZON_COGNITO_USER_POOLS",
        })
            .then((d) => {
                console.log(">>  REVIEW created  >>>>  ", d.data.createRenterReview)
                setAlreadyReview(d.data.createRenterReview.rating)
                const { reviewerName } = reviewData;
                const emailBody = `You just got a new review on Hookhub! Your host just reviewed you for your stay at: '${spaceTitle}'. If you haven't left a review for ${reviewerName} yet, please logon to Hookhub and leave a review.`
                const emailSubject = `You just received a new Review`;
                const emailAddress = renterEmail;
                sendEmail({ emailAddress, emailBody, emailSubject });
            })
            .catch((e) => {
                console.log("ERROR  > ", e)
                setError(true);
            })
        closeThis();
        setLoading(false);
    };

    useEffect(() => {
        getRevieweesName()
        setCheckIn(formatMonthDay(check_in))
        setCheckOut(formatMonthDay(check_out))
    }, [])

    return (
        <CustomModal modalWidth={width / 1.5} modalVisible={modalVisible} setModalVisible={() => setModalVisible(!modalVisible)}>
            <Header level={1.3} textAlign="left" text={revieweesName} />
            <ReactText>{checkIn} to {checkOut}</ReactText>
            <ReactText>{propertyTitle}</ReactText>
            <ReviewCreateCard
                mobileView={mobileView}
                cancelled={cancelled}
                bookingToDisplay={bookingDetails}
                width={width}
                setCleanlinessStars={(v) => setCleanlinessStars(v)}
                setCommunicationStars={(v) => setCommunicationStars(v)}
                setDescription={(v) => setDescription(v)}
                setOverallStars={(v) => setOverallStars(v)}
                cleanlinessStars={cleanlinessStars}
                communicationStars={communicationStars}
                description={description}
                overallstars={overallstars}
            />

            {!loading && (
                <>
                    {error ? (<ErrorDisplay customWidth={width / 1.6} error="Error saving review. Please contact Hookhub support if this problem persists." />
                    ) : (
                        <Flex
                            wrap="wrap"
                            direction={mobileView ? "row" : "column"}
                            justifyContent="center"
                            alignItems="center"
                            alignContent="center"
                            marginBottom={10}
                        >
                            <CustomButton
                                width={100}
                                backgroundColor={!description.length ? colors.amplifyNeutral60 : colors.orange10}
                                color="#fff"
                                disabled={!description.length}
                                border={colors.amplifyNeutral80}
                                onPress={() => createReview()}
                            >
                                <ReactText style={{ fontSize: 17 }}>save</ReactText>
                            </CustomButton>
                        </Flex>
                    )}
                </>
            )}
            <CloseButtonUnderlined onPress={() => closeThis()} />
        </CustomModal>
    );
};

export default ReviewModalRenter;
