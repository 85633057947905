import React from "react";
import { View, Text } from "react-native";
import CustomButton from "./CustomButton";
import { colors } from "../../styles/colors";


export const CustomIconToggleButton = ({ amenityType, updateAmenityType, icon, largeSize = true, selectedColor = colors.vibrantBlue, selectedColorBorder = colors.medBlue, amenityTitle, customHeight = null, key=null }) => {

    return (
        <CustomButton
            width={largeSize ? 130 : 83}
            height={customHeight ? customHeight : largeSize ? 110 : 83}
            margin={6}
            marginBottom={12}
            marginLeft={6}
            marginRight={6}
            backgroundColor={amenityType ? selectedColor : colors.amplifyNeutral20}
            borderColor={amenityType ? selectedColorBorder : colors.white}
            onPress={() => updateAmenityType()}
            key={key}
        >
            <View style={{
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
                textAlign: "center",
                alignContent: "center"
            }}>
                {icon}
                {amenityTitle ? (
                    <Text style={{ textAlign: "center", flexWrap: "wrap", fontSize: 14, marginTop: largeSize ? 4 : 0, color: amenityType ? colors.black : colors.amplifyNeutral90, width: largeSize ? 95 : 75 }}>
                        {amenityTitle}
                    </Text>
                ) : <></>}
            </View>
        </CustomButton>
    )
};