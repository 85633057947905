import React, { useState } from "react";
import { Text, View, Pressable } from "react-native";
import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import { colors } from "../../styles/colors";
import PaymentDatesDisplay from "./PaymentDatesDisplay";
import { globalStyles } from "../../styles/styles";
import CustomDivider from "../CustomDivider";
import CustomButton from "../CustomElements/CustomButton";

const BookingRequestView = ({
    width,
    mobileView,
    monthlyPrice,
    baseCost,
    monthOptionDisabled,
    yearOptionDisabled,
    monthlyPayout,
    setMonthlyPayout,
    renterFee,
    rentalCost,
    currentDateSpan,
    currentMonthSpan,
    spaceTypes,
    activeTabIndex,
    numberOfMonths,
    allPaymentsSchedule,
    nextPaymentsSchedule
}) => {
    const [showMonthlyPayoutBreakdown, setShowMonthlyPayoutBreakdown] = useState(true);

    return (
        <View style={[globalStyles.center, { width: width / 1.2, marginTop: 10, marginBottom: 15 }]}>
            {!!baseCost && (monthOptionDisabled || yearOptionDisabled) ? (
                <View style={globalStyles.centerColumn}>
                    <AntDesign name="exclamationcircle" size={40} color="grey" />
                    <Text style={{ textAlign: "center", width: width / 1.23 }}>{monthOptionDisabled ? 'Stay must be longer than 28 days to reserve this option' : 'Stay must be at least 1 year to reserve this option'}</Text>
                </View>
            ) : (
                <View style={globalStyles.centerColumn}>
                    {!monthlyPayout && (
                        <View>
                            <View style={[globalStyles.spaceBetween, { marginTop: 5 }]}>
                                <Text style={globalStyles.underlineText}>$ {spaceTypes[activeTabIndex]?.price} X {currentDateSpan.length - 1} {currentDateSpan.length > 1 ? `nights` : `night`}</Text>
                                <Text style={globalStyles.flexEnd}>${Number(baseCost).toLocaleString("en-US", { maximumFractionDigits: 2 })}</Text>
                            </View>
                            <View style={[globalStyles.spaceBetween, { marginTop: 5 }]}>
                                <Text style={globalStyles.underlineText}>services fee:</Text>
                                <Text style={globalStyles.flexEnd}>${Number(renterFee).toLocaleString("en-US", { maximumFractionDigits: 2 })}</Text>
                            </View>
                        </View>
                    )}
                    {currentMonthSpan >= 1 && (
                        <View style={[globalStyles.centerColumn, { width: mobileView ? width / 1.3 : width / 1.6 }]}>
                            <View style={[globalStyles.center, { marginVertical: 10 }]}>
                                <Pressable onPress={() => setMonthlyPayout(!monthlyPayout)}>
                                    {!monthlyPayout ? (
                                        <MaterialIcons name="check-box-outline-blank" size={30} color="black" />
                                    ) : (
                                        <MaterialIcons name="check-box" size={30} color="black" />
                                    )}
                                </Pressable>
                                <Text style={{ marginLeft: 8, fontSize: 17 }}>I'd prefer to make my payments monthly</Text>
                            </View>
                            <Text style={{ textAlign: "center" }}>10% service fee to run our platform and offer support and stripe fee of 2.9% + .30 to secure your payment.</Text>
                            <CustomDivider width={mobileView ? width / 1.2 : width / 2.5} marginVertical={10} />
                            <PaymentDatesDisplay
                                width={width}
                                mobileView={mobileView}
                                numberOfMonths={numberOfMonths}
                                monthlyPrice={monthlyPrice}
                                hostView={false}
                                stillPaymentsComingBoolean={!!nextPaymentsSchedule.length}
                                paymentDates={nextPaymentsSchedule}
                                formatted={true}
                            />
                            {monthlyPayout && (
                                <>
                                    {showMonthlyPayoutBreakdown ? (
                                        <View>
                                            <View style={[globalStyles.spaceBetween, { marginTop: 5 }]}>
                                                <Text style={globalStyles.underlineText}>$ {spaceTypes[activeTabIndex]?.price} X {currentDateSpan.length - 1} {currentDateSpan.length > 1 ? `nights` : `night`}</Text>
                                                <Text style={globalStyles.flexEnd}>${Number(baseCost).toLocaleString("en-US", { maximumFractionDigits: 2 })}</Text>
                                            </View>
                                            <View style={[globalStyles.spaceBetween, { marginTop: 5 }]}>
                                                <Text style={globalStyles.underlineText}>services fee:</Text>
                                                <Text style={globalStyles.flexEnd}>${Number(renterFee).toLocaleString("en-US", { maximumFractionDigits: 2 })}</Text>
                                            </View>
                                            <View style={[globalStyles.spaceBetween, { marginTop: 5 }]}>
                                                <View></View>
                                                <Text style={globalStyles.flexEnd}>split over {numberOfMonths} months</Text>
                                            </View>
                                        </View>
                                    ) : (
                                        <CustomButton
                                            width={mobileView ? width / 1.05 : 300}
                                            height={40}
                                            marginTop={10}
                                            backgroundColor={colors.orange10}
                                            borderRadius={8}
                                            border={colors.orange80}
                                            hideBorder={true}
                                            onPress={e => setShowMonthlyPayoutBreakdown(true)}
                                        >
                                            <Text style={globalStyles.underlineText}>{"show monthly cost breakdown"}</Text>
                                        </CustomButton>
                                    )}
                                </>
                            )}
                        </View>
                    )}
                    <View style={{ flexDirection: "column", alignContent: "center", justifyContent: "center", alignItems: "center", marginTop: 20 }}>
                        <CustomDivider width={mobileView ? width / 1.2 : width / 2.5} marginVertical={15} />
                        <View style={globalStyles.centerColumn}>
                            <Text style={[globalStyles.boldText, { fontSize: 17 }]}>{!!monthlyPayout ? "Monthly Total" : "Total"}</Text>
                            {monthlyPayout ? (
                                <Text><Text style={[globalStyles.boldText, { fontSize: 23 }]}>{!!monthlyPrice ? `$ ${Number(monthlyPrice).toLocaleString("en-US", { maximumFractionDigits: 2 })}` : '--'}</Text> per month</Text>
                            ) : (
                                <Text style={globalStyles.boldText}>{!!rentalCost ? `$ ${Number(rentalCost).toLocaleString("en-US", { maximumFractionDigits: 2 })}` : '--'}</Text>
                            )}
                        </View>
                    </View>
                </View>
            )}
        </View>
    );
};

export default BookingRequestView;
