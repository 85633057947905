import React from "react";
import { StyleSheet, View, useWindowDimensions, ScrollView } from "react-native";
import Modal from "react-native-modal";
import CloseButtonIcon from "./CloseButtonIcon";

const CustomModal = ({ modalVisible, setModalVisible, children, backgroundColor = "white", modalWidth = null, nestedScrollView = true }) => {
    const { width, height } = useWindowDimensions();

    return (
        <View>
            <Modal
                isVisible={modalVisible}
                animationIn='slideInUp'
                animationOut='slideOutDown'
                deviceHeight={height}
                onBackdropPress={() => setModalVisible()}
            >
                {nestedScrollView ? (
                    <ScrollView>
                        <View style={[styles.modalView, {
                            width: modalWidth || width / 1.2,
                            backgroundColor: backgroundColor
                        }]}>
                            <CloseButtonIcon onPress={() => setModalVisible(false)} />
                            {children}
                        </View>
                    </ScrollView>
                ) : (

                    <View style={[styles.modalView, {
                        width: modalWidth || width / 1.2,
                        backgroundColor: backgroundColor
                    }]}>
                        <CloseButtonIcon onPress={() => setModalVisible(false)} />
                        {children}
                    </View>
                )}
            </Modal>
        </View>
    );
};

const styles = StyleSheet.create({
    feeButton: {
        textDecorationLine: "underline"
    },
    modalView: {
        marginTop: 10,
        marginBottom: 15,
        backgroundColor: "#c4c4c4",
        justifyContent: 'center',
        alignContent: "center",
        alignSelf: "center",
        borderRadius: 10,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.35,
        shadowRadius: 4,
        elevation: 5,
        paddingBottom: 20
    },
    approvebutton: {
        borderRadius: 12,
        padding: 15,
        elevation: 5,
        backgroundColor: "#599cde",
        shadowColor: "grey",
        shadowOffset: {
            width: 1,
            height: 3,
        },
        shadowOpacity: 0.45,
        shadowRadius: 4,
    },
    closeButton: {
        padding: 10,
        marginBottom: 15
    },
    textStyle: {
        color: "white",
        fontWeight: "bold",
        textAlign: "center"
    },
    denyTextStyle: {
        color: "#292929",
        textAlign: "center",
        fontWeight: "bold",
    },
    closeText: {
        fontWeight: "bold",
        textDecorationLine: "underline",
        fontSize: 17
    },
    modalText: {
        marginBottom: 15,
        textAlign: "center"
    },
    title: {
        textAlign: "left",
        width: '100%',
        backgroundColor: "#2196F3"
    },
    note: {
        textAlign: "center",
        backgroundColor: "#d6d6d6",
        borderRadius: 20,
        padding: 7
    },
});

export default CustomModal;
