import { Pressable, StyleSheet, View, Image, Text } from "react-native";
import { colors } from "../../styles/colors"
import { globalStyles } from "../../styles/styles";


const MobileOpenReserveCardButton = ({ setMobileReserveCardOpen }) => {

    return (
        <Pressable onPress={() => setMobileReserveCardOpen()}>
            <View>
                <View style={styles.reserveButton} />
                <View style={[globalStyles.centerColumn,{ position: "absolute", width: 80 }]}>
                    <Image
                        style={{ width: 35, height: 35, marginTop: 8 }}
                        source={require("../../../assets/icons/reservation.svg")}
                    />
                    <Text style={styles.bookText}>Book Now</Text>
                </View>
            </View>
        </Pressable>
    )
}

export default MobileOpenReserveCardButton;


const styles = StyleSheet.create({
    reserveButton: {
        height: 65,
        width: 80,
        borderRadius: 10,
        backgroundColor: colors.orange10,
        shadowColor: colors.amplifyNeutral80,
        shadowOffset: { width: 1, height: 2 },
        shadowOpacity: .9,
        shadowRadius: 3,
    },
    bookText: {
        color: colors.orange60
    }
})