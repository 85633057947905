import React from "react";
import { View, TextInput, StyleSheet } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { globalStyles } from "../../styles/styles";


export const CustomNumberInput = ({ value, setValue }) => {

    const handleKeyDown = (v) => {
        if (!isNaN(v)) { setValue(v) }
    };

    const reduceNumber = () => {
        if (value > 1) {
            setValue((Number(value) - 1))
        }
    }

    return (
        <View style={globalStyles.center}>
            <AntDesign
                name="minuscircleo"
                size={28}
                color="black"
                style={{ marginRight: 10 }}
                onPress={() => reduceNumber()}
            />
            <TextInput
                style={styles.textInput}
                keyboardType='numeric'
                onChangeText={(v) => handleKeyDown(v)}
                value={value.toString()}
            />
            <AntDesign
                name="pluscircleo"
                size={28}
                color="black"
                style={{ marginLeft: 10 }}
                onPress={() => setValue(Number(value) + 1)}
            />
        </View>
    )
};


const styles = StyleSheet.create({
    textInput: {
        borderRadius: 4,
        width: 60,
        height: 30,
        alignItems: "center",
        textAlign: "center",
        borderWidth: 1
    }
});