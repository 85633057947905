import React from "react";
import {
  View
} from "react-native";
import { Ionicons } from '@expo/vector-icons';
import Header from "../CustomElements/Header";
import { colors } from "../../styles/colors"
import { globalStyles } from "../../styles/styles";
import TextBody from "../CustomElements/TextBody";

const DimensionsView = ({ width, length }) => {

  return (
    <>
      <View style={[globalStyles.center, { marginBottom: 5 }]}>
        <Header level={1} textAlign="center" padding={0} color={colors.black} text="Dimensions"></Header>
        <TextBody> (feet)</TextBody>
      </View>
      <View style={[globalStyles.center, { alignItems: "center" }]}>
        <View style={[globalStyles.center, { marginHorizontal: 5 }]}>
          <Header marginRight={5} padding={0} color={colors.amplifyNeutral90} level={.85} text={width}></Header>
          <TextBody>wide</TextBody>
        </View>
        <View style={[globalStyles.center, { marginHorizontal: 5 }]}>
          <Ionicons name="close-sharp" size={22} color={colors.amplifyNeutral90} />
        </View>
        <View style={[globalStyles.center, { marginHorizontal: 5 }]}>
          <Header marginRight={5} padding={0} color={colors.amplifyNeutral90} level={.85} text={length}></Header>
          <TextBody>long</TextBody>
        </View>
      </View>
    </>
  );
};

export default DimensionsView;
