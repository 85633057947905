import { useNavigation } from "@react-navigation/native";
import { AntDesign, Entypo, FontAwesome5 } from "@expo/vector-icons";
import { Image, Linking, Platform, Pressable, Text, useWindowDimensions, View } from "react-native";
import { colors } from "../../styles/colors"
import { useSelector } from "react-redux";
import { globalStyles } from "../../styles/styles";
import CustomDivider from "../CustomDivider";
import PrivacyTermsSitemapFooter from "./PrivacyTermsSitemapFooter";
import CustomButton from "../CustomElements/CustomButton";


const FooterView = () => {
    const navigation = useNavigation();
    const { width } = useWindowDimensions();
    const { mobileView } = useSelector(state => state.currentUser);

    return (
        <>
            {mobileView ? (
                <View style={[globalStyles.flexEndColumn, { width: 240 }]}>
                    <View style={globalStyles.centerColumn}>
                        <View style={[globalStyles.center, { marginBottom: 10 }]}>
                            <Pressable onPress={() => Linking.openURL("https://www.facebook.com/profile.php?id=100093356511676")}>
                                <FontAwesome5 name="facebook" size={30} color="#3b5998" />
                            </Pressable>
                            <Pressable onPress={() => Linking.openURL("https://www.youtube.com/@rvhookhub/videos")}>
                                <Entypo name="youtube-with-circle" size={30} color="#CD201F" />
                            </Pressable>
                        </View>
                    </View>
                    <View style={globalStyles.flexEndColumn}>
                        <Text style={{ color: colors.amplifyNeutral90}}> &copy; {"2024 Hookhub, Inc."}</Text>
                        <PrivacyTermsSitemapFooter mobileView={true} />
                        <View style={[globalStyles.flexEnd]}>
                            <Image
                                style={{ width: 23, height: 23 }}
                                source={require("../../../assets/icons/privacy-security.svg")}
                            />
                            <a href="https://www.hookhub.co/security" target="_blank" style={{ marginLeft: 5 }}>
                                <Text style={{fontSize: 12}}>Your Privacy and Security</Text>
                            </a>
                        </View>
                    </View>
                </View >
            ) : (
                <View style={[globalStyles.centerColumn, { backgroundColor: colors.white, paddingBottom: 10, borderTopWidth: .5, borderColor: colors.orange50, paddingTop: 30, paddingBottom: 20, width }]}>
                    <View style={globalStyles.centerColumn}>
                        <Image
                            style={{ width: 150, height: (150) / 3.86, marginBottom: 5 }}
                            source={require("../../../assets/images/hookhub-logo-orange-mini.png")}
                        />
                        <View style={[globalStyles.center, { margin: 5, flexWrap: "wrap" }]}>
                            <View style={[globalStyles.center, { marginHorizontal: 10 }]}>
                                <Pressable onPress={() => navigation.reset({ routes: [{ name: 'help' }] })}><Text>Help Center</Text></Pressable>
                            </View>
                            <View style={[globalStyles.center, { marginHorizontal: 10 }]}>
                                <Pressable onPress={() => navigation.reset({ routes: [{ name: 'host' }] })}><Text>Hosting Resources</Text></Pressable>
                            </View>
                            <View style={[globalStyles.center, { marginHorizontal: 10 }]}>
                                <Pressable onPress={() => navigation.reset({ routes: [{ name: 'about' }] })}><Text>About</Text></Pressable>
                                {/* <Pressable onPress={() => navigation.reset({ routes: [{ name: 'help' }] })}><Text>Investors</Text></Pressable> */}
                            </View>
                        </View>
                        <View style={[globalStyles.center, { marginTop: 5 }]}>
                            <a href="https://www.facebook.com/profile.php?id=100093356511676" target="_blank" style={{ textDecoration: "none", padding: 5 }}>
                                <FontAwesome5 name="facebook" size={30} color="#3b5998" />
                            </a>
                            <a href="https://www.youtube.com/@rvhookhub/videos" target="_blank" style={{ textDecoration: "none", padding: 5 }}>
                                <Entypo name="youtube-with-circle" size={30} color="#CD201F" />
                            </a>
                        </View>
                    </View>
                    <View style={[mobileView ? globalStyles.centerColumn : globalStyles.center]}>
                        <Text style={{ color: colors.amplifyNeutral90, marginLeft: 15 }}> &copy; {"2024 Hookhub, Inc."}</Text>
                        <PrivacyTermsSitemapFooter />
                        <View style={[globalStyles.center]}>
                            <a href="https://www.hookhub.co/security" target="_blank" style={{ marginLeft: 20, paddingRight: 10 }}>
                                <Text style={{fontSize: 12}}>Your Privacy and Security</Text>
                            </a>
                            <FontAwesome5 name="user-shield" size={18} color="royalblue" />
                        </View>
                    </View>
                </View >
            )}
        </>
    )
}

export default FooterView;
