import { Dimensions, StyleSheet, useWindowDimensions, View, Text, Pressable } from 'react-native';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import Animated, {
    useSharedValue,
    withTiming,
    useAnimatedStyle,
    runOnJS,
} from 'react-native-reanimated';
import { colors } from '../../styles/colors';
import { globalStyles } from '../../styles/styles';
import { AntDesign } from '@expo/vector-icons';
import { useState } from 'react';

export default function SwipeableView({ children }) {
    const { height } = useWindowDimensions()
    const END_POSITION = -(height/25);
    const TOP_POSITION = - (height / 1.78);
    const onTop = useSharedValue(true);
    const position = useSharedValue(TOP_POSITION);
    const [mapShowing, setMapShowing] = useState(true);

    const panGesture = Gesture.Pan()
        .onUpdate((e) => {
            if (onTop.value) {
                position.value = TOP_POSITION + e.translationY;
            } else {
                position.value = END_POSITION + e.translationY;
            }
        })
        .onEnd((e) => {
            if (position.value > END_POSITION / 2) {
                position.value = withTiming(END_POSITION, { duration: 100 });
                onTop.value = false;
                runOnJS(setMapShowing)(false)
            } else {
                position.value = withTiming(TOP_POSITION, { duration: 100 });
                onTop.value = true;
                runOnJS(setMapShowing)(true)
            }
        });

    const showMap = () => {
        if (position.value === TOP_POSITION) {
            position.value = withTiming(END_POSITION, { duration: 100 });
            onTop.value = false;
            setMapShowing(false);
        } else {
            position.value = withTiming(TOP_POSITION, { duration: 100 });
            onTop.value = true;
            setMapShowing(true);
        }
    }

    const animatedStyle = useAnimatedStyle(() => ({
        transform: [{ translateY: position.value }],
    }));

    return (
        <View>
            <GestureDetector gesture={panGesture}>
                <Animated.View style={[styles.box, animatedStyle]} >
                    <View style={[globalStyles.center, styles.line]} />
                    <Pressable onPress={() => showMap()}>
                        <View style={[globalStyles.center, styles.button]}>
                            <AntDesign name={mapShowing ? "downcircleo" : "upcircleo"} size={24} color="black" style={{ marginRight: 5 }} />
                            <Text>{mapShowing ? "Show map" : "Hide map"}</Text>
                        </View>
                    </Pressable>
                    {children}
                </Animated.View>
            </GestureDetector>
        </View>
    );
}

const styles = StyleSheet.create({
    box: {
        height: Dimensions.get('window').height /1.35,
        paddingTop: 30,
        width: Dimensions.get('window').width,
        backgroundColor: '#fff',
        borderRadius: 30,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center"
    },
    line: {
        borderBottomWidth: 3,
        borderColor: colors.amplifyNeutral60,
        width: 65,
        marginBottom: 10,
        marginTop: 15
    },
    button: {
        marginBottom: 10
    }
});
