import React, { useState, useEffect, useContext } from "react";
import { StyleSheet, Text, View, useWindowDimensions, Pressable } from "react-native";
import HostOnboarding from "../../components/host/HostOnboarding";
import Flex from "../../components/CustomElements/Flex"
import Header from "../../components/CustomElements/Header"
import Card from "../../components/CustomElements/Card"
import { AntDesign, MaterialIcons } from "@expo/vector-icons";
import { useDispatch, useSelector } from "react-redux";
import { ImageGrid } from "../../components/space/ImageGrid";
import AmenitiesView from "../../components/space/AmenitiesView";
import { SuccessCreatingPropertyModal } from "../../components/space/SuccessCreatingPropertyModal";
import { UserDataContext } from "../../utils/UserContextProvider";
import { BookingsContext } from "../../utils/BookingsContextProvider";
import ThingsToKnow from "../../components/space/ThingsToKnowView";
import { setIdentityVerificationNotComplete, setPayoutStatus } from "../../redux/actions/CurrentUserActions";
import { colors } from "../../styles/colors";
import { UUID } from "../../utils/UUID";
import { LoadingComponent } from "../../components/LoadingComponent";
import MultipleSpacesDisplay from "../../components/space/MultipleSpacesDisplay";
import { notifyHookhub } from "../../lambdaFunctions/notifyHookhub";
import { ErrorDisplay } from "../../components/CustomElements/ErrorDisplay";
import { createPropertyMutation } from "../../api/property";
import { createAddressMutation } from "../../api/address";
import { createSpaceMutation, updateSpaceMutation } from "../../api/space";
import Badge from "../../components/CustomElements/Badge";
import { globalStyles } from "../../styles/styles";

const SpaceDescription = ({ description }) => {

  return (
    <View >
      <View
        style={{
          borderBottomColor: "#bdbdbd",
          borderBottomWidth: StyleSheet.hairlineWidth,
          width: "100%",
          marginTop: 12,
          marginBottom: 30
        }}
      />
      <Text style={styles.longDescription}>
        {description}
      </Text>
    </View>
  );
};

const SpacesAdded = ({ additionalSpaces, mobileView, width, error, errorText, propertyTitle, water_hookup, electric_hookup, sewage_hookup, propertyImage, originalSpaceUpdatedTitle = null }) => {
  const [displaySpace, setDisplaySpace] = useState(Object.values(additionalSpaces)[0]);
  const amenitiesPresent = (water_hookup || electric_hookup || sewage_hookup);

  return (
    <>
      {error && <ErrorDisplay error={errorText} />}
      <View>
        <MultipleSpacesDisplay
          width={width}
          preview={true}
          mobileView={mobileView}
          displaySpace={displaySpace}
          setDisplaySpace={(v) => setDisplaySpace(v)}
          spaces={Object.values(additionalSpaces)}
          amenitiesPresent={amenitiesPresent}
          propertyImage={propertyImage}
          electric_hookup={electric_hookup}
          water_hookup={water_hookup}
          sewage_hookup={sewage_hookup}
          originalSpaceUpdatedTitle={originalSpaceUpdatedTitle}
          propertyTitle={propertyTitle}
        />
      </View>
    </>

  )
}

const ImagesStillLoading = ({ showLoadingIcon = true }) => {
  return (
    <Flex justifyContent="center" alignContent="center" alignItems="center" direction="column" marginTop={20} marginBottom={20}>
      <Header textAlign="center" text="If you uploaded your images, they are still loading, please hold... If you havent uploaded any images, navigate back to the Photo Upload Screen." color={colors.red70} />
      {showLoadingIcon && <LoadingComponent />}
    </Flex>
  )
}

const ImageUploadError = () => {
  return (
    <Flex justifyContent="center" alignContent="center" alignItems="center" direction="column" marginTop={25} marginBottom={25}>
      <AntDesign name="exclamationcircle" size={40} color={colors.red70} />
      <Header textAlign="center" text="ERROR uploading your images. Please navigate back to Photo Upload Screen. If the issue persists, contact Hookhub on the Help Screen." color={colors.red70} />
    </Flex>
  )
}

const ConfirmationDescription = ({ propertyState = null, userName, errorText, error, imagesPresent, newProperty }) => {
  const { mobileView } = useSelector(state => state.currentUser);
  const location = !!propertyState?.canadianCivicAddress?.length ? `${propertyState.cityName}, ${propertyState.canadianCivicAddress},  ${propertyState.countryName}` : `${propertyState.cityName}, ${propertyState.stateName}, ${propertyState.countryName}`;
  const anyRulesPresent = (
    propertyState?.pets_allowed ||
    !propertyState?.smoking_allowed ||
    propertyState?.adults_only ||
    propertyState?.outdoor_fires ||
    propertyState.check_in_time ||
    propertyState.quiet_hours_start ||
    propertyState.full_refund_date ||
    propertyState.half_refund_date
  );

  return (
    <View style={[globalStyles.centerColumn]}>
      {error && <ErrorDisplay error={errorText} />}
      {propertyState.imageUploadError ? (
        <ImageUploadError />
      ) : imagesPresent.length ? (
        <ImageGrid images={imagesPresent} />
      ) : (
        <ImagesStillLoading />
      )}
      <View style={{ width: "90%" }}>
        <Header padding={2} level={1.3} text={propertyState.title} />
        <Header padding={2} color={colors.amplifyNeutral80} level={1} text={location} />
        {propertyState?.instant_book &&
          <View style={{ flexDirection: 'row' }}>
            <Badge width={100} marginTop={8} shadow={false}><Text>instant book</Text></Badge>
          </View>
        }
        <Header level={.9} color={colors.amplifyNeutral80} text={`Hosted by ${userName}`} padding={0} marginTop={10} textAlign="left"></Header>
        <SpaceDescription mobileView={mobileView} description={propertyState.description} />
        <AmenitiesView space={propertyState} />
        {anyRulesPresent && <ThingsToKnow space={propertyState} />}
      </View>
    </View>
  );
};

function ConfirmationScreen() {
  const dispatch = useDispatch();
  const windowWidth = useWindowDimensions().width;
  const { mobileView, identityVerificationNotComplete } = useSelector(state => state.currentUser);
  const propertyState = useSelector((state) => state.hostOnboarding);
  const { user } = useContext(UserDataContext);
  const { userData } = user;
  const { sub, name, userName, email } = userData;
  const { myHosting, availabilityMissing } = useContext(BookingsContext);
  const { availabilityNeeded, setAvailabilityNeeded } = availabilityMissing;
  const { mySpaces, setMySpaces } = myHosting;
  const [error, setError] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [liability, setLiability] = useState(false);
  const [regulationsCheck, setRegulationsCheck] = useState(false);
  const [offPlatformPolicy, setOffPlatformPolicy] = useState(false);
  const [signature, setSignature] = useState(false);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const {
    existingSpaceTitleToUpdate,
    coordinates,
    title,
    type_storage,
    type_longterm,
    type_shortterm,
    type_yearly,
    price_longterm,
    price_yearly,
    price_shortterm,
    price_storage,
    description,
    width,
    length,
    electric_hookup,
    water_hookup,
    sewage_hookup,
    wifi_available,
    wifi_network_name,
    wifi_password,
    shower_available,
    restroom_available,
    food_nearby,
    restaurant_nearby,
    water_accessible,
    easy_entry,
    laundry_available,
    hottub_available,
    outdoor_area_available,
    indoor_area_available,
    thingsInArea,
    checkInInstructions,
    trash_available,
    pool_available,
    pre_booking_message_required,
    distance_electric_hookup,
    distance_water_hookup,
    distance_sewage_hookup,
    imageIDs,
    imageUploadError,
    check_in_time,
    check_out_time,
    space_rules,
    pets_allowed,
    smoking_allowed,
    adults_only,
    instant_book,
    quiet_hours_start,
    quiet_hours_end,
    outdoor_fires,
    full_refund_date,
    half_refund_date,
    additionalSpacesBoolean,
    additionalSpacesAlteredData,
    newProperty,
    propertyID,
    existingSpaceID,
    imagesFinishedUploading,
    numberAdditionalSpaces
  } = propertyState;
  const updateMySpaces = v => setMySpaces(v);
  const updateNextSteps = v => setAvailabilityNeeded(v);
  const imagesPresent = Object.values(imageIDs);
  let errorText = "Error creating your space. HookHub has been notified and we will reach out soon.";
  const updateOriginalSpaceTitle = additionalSpacesBoolean && existingSpaceTitleToUpdate !== title && existingSpaceTitleToUpdate;

  async function createSpace(propertyID, imgArr, id, spaceData) {
    let distanceElectric = !!additionalSpacesBoolean ? spaceData['distance_electric_hookup'] || distance_electric_hookup : distance_electric_hookup;
    let distanceWater = !!additionalSpacesBoolean ? spaceData['distance_water_hookup'] || distance_water_hookup : distance_water_hookup
    let distanceSewage = !!additionalSpacesBoolean ? spaceData['distance_sewage_hookup'] || distance_sewage_hookup : distance_sewage_hookup
    let spaceTitle = !!additionalSpacesBoolean ? spaceData['title'] || title : title
    let spaceWidth = !!additionalSpacesBoolean ? spaceData['width'] || width : width
    let spaceLength = !!additionalSpacesBoolean ? spaceData['length'] || length : length
    const spaceImage = [!!additionalSpacesBoolean && spaceData.images ? spaceData.images[0] : imgArr[0]];

    const data = {
      id: id,
      ownerID: sub,
      propertyID: propertyID,
      distance_electric_hookup: Number(distanceElectric),
      distance_water_hookup: Number(distanceWater),
      distance_sewage_hookup: Number(distanceSewage),
      title: spaceTitle,
      width: Number(spaceWidth),
      length: Number(spaceLength),
      space_approved: false,
      active: true,
      pre_booking_message_required: false,
      profile_photo_required: false,
      instant_book,
      set_availability: false,
      price_storage,
      price_shortterm,
      price_longterm,
      price_yearly,
      description,
      things_to_do: thingsInArea,
      check_in_instructions: checkInInstructions,
      laundry_available,
      pool_available,
      hottub_available,
      outdoor_area_available,
      indoor_area_available,
      trash_available,
      electric_hookup,
      water_hookup,
      sewage_hookup,
      wifi_available,
      wifi_network_name,
      wifi_password,
      shower_available,
      restroom_available,
      food_nearby,
      restaurant_nearby,
      water_accessible,
      easy_entry,
      pre_booking_message_required,
      host_acknowledge_liability: true,
      host_acknowledge_tax_responsibility: true,
      images: spaceImage,
      check_in_time,
      check_out_time,
      space_rules,
      instant_book,
      pets_allowed,
      smoking_allowed,
      adults_only,
      quiet_hours_start,
      quiet_hours_end,
      outdoor_fires,
      full_refund_date,
      half_refund_date,
      type: "space"
    };

    const newSpace = await createSpaceMutation({ data, userEmail: email });
    if (!!newSpace) {
      return newSpace
    } else {
      setLoading(false)
      setError(true)
      return false
    }
  };

  const finish = ({ propertyData, newSpaces }) => {
    const { stripePayoutsEnabled } = userData?.userData;
    !stripePayoutsEnabled && dispatch(setPayoutStatus({ payoutNeeded: true }))
    if (!!propertyData && newProperty) {
      const updatedPropertyWithSpaces = { ...propertyData }
      updatedPropertyWithSpaces['spaces']['items'] = newSpaces;
      updateMySpaces([...mySpaces, updatedPropertyWithSpaces]);
    }
    if (!!updateOriginalSpaceTitle) {
      updateOriginalSpace();
    }
    setModalVisible(true);
  }

  async function updateOriginalSpace() {
    const data = {
      id: existingSpaceID,
      title: existingSpaceTitleToUpdate
    };

    const updatedSpace = await updateSpaceMutation({ data });
    if (!!updatedSpace) {
      return updatedSpace
    } else {
      console.log("ERROR     >>>>>  ")
      setLoading(false)
      setError(true)
    }
  };

  async function createSpaces(propertyID, imgArr, propertyData = null) {
    const newSpaces = [];
    if (additionalSpacesBoolean) {
      for (const key of Object.keys(additionalSpacesAlteredData)) {
        let spaceData = additionalSpacesAlteredData[key];
        let newSpace = await createSpace(propertyID, imgArr, spaceData['id'], spaceData);
        if (!newSpace) {
          setError(true)
          setLoading(false)
          return;
        }
        newSpaces.push(newSpace)
        if (numberAdditionalSpaces === newSpaces.length) {
          finish({ propertyData, newSpaces })
        }
      }
    } else {
      let newSpace = await createSpace(propertyID, imgArr, propertyID);
      if (newSpace) {
        newSpaces.push(newSpace)
        finish({ propertyData, newSpaces })
      } else {
        setError(true)
        setLoading(false)
      }
    }
  };

  async function createProperty({ addressId }) {
    const propertyID = UUID();
    const imgArr = Object.values(imageIDs);
    const data = {
      id: propertyID,
      active: true,
      approved: !identityVerificationNotComplete,
      ownerID: sub,
      title,
      images: imgArr,
      description,
      propertyAddressId: addressId,
      coordinates_lat: String(coordinates.lat),
      coordinates_lng: String(coordinates.lng),
      type_storage,
      type_longterm,
      type_shortterm,
      type_yearly,
      price_storage,
      price_longterm,
      price_shortterm,
      price_yearly,
      type: "property",
      internalRating: 20
    }

    const newProperty = await createPropertyMutation({ data, userEmail: email });
    if (!!newProperty) {
      createSpaces(propertyID, imgArr, newProperty)
    } else {
      setLoading(false)
      setError(true)
    }
  };

  async function createAddress() {
    const newAddress = await createAddressMutation({ data: propertyState.fullAddress, userID: sub, userEmail: email });
    if (!!newAddress) {
      createProperty({ addressId: newAddress.id });
    } else {
      setLoading(false)
      setError(true)
    }
  };

  const createOrUpdateProperty = () => {
    setLoading(true);
    if (newProperty) {
      createAddress()
    } else {
      createSpaces(propertyID, imageIDs)
    }
  }

  useEffect(() => {
    if (!imagesPresent.length) {
      setLoading(true)
    } else if (imageUploadError) {
      setError(true)
      let subject = `ERROR uploading IMAGES`
      let body = `Image upload error.  ERROR: ${imageUploadError}`
      notifyHookhub({ subject, body, userID: sub, userEmail: email })
      setLoading(false)
    } else {
      setLoading(false)
      setError(false)
    }
  }, [imagesPresent.length]);

  useEffect(() => {
    if (imagesFinishedUploading && !loading && liability && signature && regulationsCheck && offPlatformPolicy) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [imagesFinishedUploading, loading, liability, signature, type_longterm, type_storage, regulationsCheck, offPlatformPolicy])

  return (
    <>
      <HostOnboarding
        heading={newProperty ? "Preview of my space" : "The following spaces will be added"}
        loading={loading}
        error={error && errorText}
        navigateB={newProperty ? "SetThingsKnow" : "SetDimensions"}
        nextButtonText="Create my Space"
        onClickNext={() => createOrUpdateProperty()}
        disabledNextButton={disabled}
      >
        {newProperty ? (
          <ConfirmationDescription
            newProperty={newProperty}
            propertyState={propertyState}
            userName={userName}
            error={error}
            errorText={errorText}
            imagesPresent={imagesPresent}
          />
        ) : (
          <SpacesAdded
            additionalSpaces={additionalSpacesAlteredData}
            water_hookup={water_hookup}
            sewage_hookup={sewage_hookup}
            electric_hookup={electric_hookup}
            propertyTitle={title}
            originalSpaceUpdatedTitle={updateOriginalSpaceTitle}
            propertyImage={imageIDs[0]}
            width={windowWidth}
            error={error}
            errorText={errorText}
            mobileView={mobileView}
          />
        )}
        <Flex
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          padding={15}
        >
          <Card marginTop={15} width={windowWidth / 1.1} backgroundColor={colors.amplifyNeutral20}>
            <Header level={1.2} text="Terms and Conditions" marginBottom={20} marginTop={20} />
            <Text style={{ fontSize: 17 }}>Terms & Conditions can be found at the bottom of the screen.</Text>
            <Text style={{ fontSize: 17, marginBottom: 30 }}>* For security, never transfer/receive money or communicate outside of Hookhub.</Text>
            <Flex width={windowWidth / 1.25}>
              <Pressable onPress={() => setLiability(!liability)}>
                {!liability ? (
                  <MaterialIcons name="check-box-outline-blank" size={33} color={colors.amplifyBlue20} />
                ) : (
                  <MaterialIcons name="check-box" size={33} color={colors.amplifyBlue20} />
                )}
              </Pressable>
              <Text style={styles.disclaimerText}>Hookhub is not liable for any damages or personal injury that may occur during stay.</Text>
            </Flex>
            <Flex width={windowWidth / 1.25}>
              <Pressable onPress={() => setRegulationsCheck(!regulationsCheck)}>
                {!regulationsCheck ? (
                  <MaterialIcons name="check-box-outline-blank" size={33} color={colors.amplifyBlue20} />
                ) : (
                  <MaterialIcons name="check-box" size={33} color={colors.amplifyBlue20} />
                )}
              </Pressable>
              <Text style={styles.disclaimerText}>
                As the Host, I am responsible for verifying any local laws or restrictions. With income made, I am responsible for lodging, sales or income tax for my state. A 1099 will be provided to me with my earnings at year-end.
              </Text>
            </Flex>
            <Flex width={windowWidth / 1.25}>
              <Pressable onPress={() => setOffPlatformPolicy(!offPlatformPolicy)}>
                {!offPlatformPolicy ? (
                  <MaterialIcons name="check-box-outline-blank" size={33} color={colors.amplifyBlue20} />
                ) : (
                  <MaterialIcons name="check-box" size={33} color={colors.amplifyBlue20} />
                )}
              </Pressable>
              <Text style={styles.disclaimerText}>
                I understand the following actions are strictly prohibited: Taking transactions off the Hookhub platform for any current or future bookings.
              </Text>
            </Flex>
            <Flex width={windowWidth / 1.25}>
              <Pressable onPress={() => setSignature(!signature)}>
                {!signature ? (
                  <MaterialIcons name="check-box-outline-blank" size={33} color={colors.amplifyBlue20} />
                ) : (
                  <MaterialIcons name="check-box" size={33} color={colors.amplifyBlue20} />
                )}
              </Pressable>
              <Text style={styles.disclaimerText}>By checking the above boxes I, {name}, acknowledge that I am aware and agree to all of Hookhub's Terms & Conditions.</Text>
            </Flex>
          </Card>
        </Flex>
        {!imagesFinishedUploading && !modalVisible && !imageUploadError && <ImagesStillLoading showLoadingIcon={false} />}
        {!!imageUploadError && <ImageUploadError />}
      </HostOnboarding>
      <SuccessCreatingPropertyModal modalVisible={modalVisible} setModalVisible={() => setModalVisible(!modalVisible)} userVerified={!identityVerificationNotComplete} />
    </>
  )
};

const styles = StyleSheet.create({
  longDescription: {
    fontSize: 17,
    textAlign: "center",
    marginBottom: 30,
    padding: 10
  },
  disclaimerText: {
    fontSize: 17,
    marginBottom: 15,
    marginLeft: 10,
    width:window.innerWidth / 1.4
  },
});

export default ConfirmationScreen;
