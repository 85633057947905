import {
  StyleSheet,
  View,
  Text,
  Image
} from "react-native";
import ContactView from "./ContactView";
import CustomDivider from "../CustomDivider";
import { ReviewView } from "../review/ReviewView";
import Badge from "../CustomElements/Badge";
import { globalStyles } from "../../styles/styles";
import TextBody from "../CustomElements/TextBody";
import { colors } from "../../styles/colors";
import Header from "../CustomElements/Header";
import { AntDesign, FontAwesome, FontAwesome5, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import CustomButton from "../CustomElements/CustomButton";
import { useEffect, useState } from "react";
import { ShareModal } from "../other/ShareModal";
import CustomDropdown from "../CustomElements/CustomDropdown";
import SpaceAmenities from "./AmenitiesView";
import getEnvVars from "../../../environment";
const { REACT_APP_ENV } = getEnvVars();


const HeaderButtons = ({ favorited, updateFavorite, setShareModalOpen, mobileView = false }) => {
  return (
    <View style={globalStyles.center}>
      <CustomButton
        width={mobileView ? 30 : 90}
        marginRight={10}
        hideBorder
        backgroundColor={"#fff"}
        onPress={() => setShareModalOpen(true)}
      >
        <View style={globalStyles.center}>
          <FontAwesome name="share-square-o" size={18} color={colors.amplifyNeutral90} />
          <Text style={{ marginLeft: 4 }}>Share</Text>
        </View>
      </CustomButton>
      <CustomButton
        width={45}
        hideBorder
        marginLeft={25}
        backgroundColor={"#fff"}
        onPress={() => updateFavorite()}
      >
        <View style={globalStyles.center}>
          <AntDesign name={favorited ? "heart" : "hearto"} size={18} color={favorited ? colors.orange50 : colors.amplifyNeutral80} />
          <Text style={{ marginLeft: 4 }}>{favorited ? "Saved" : "Save"}</Text>
        </View>
      </CustomButton>
    </View>
  )
}

const Section = ({ header, body }) => {
  return (
    <View style={{ marginBottom: 20 }}>
      <Header padding={0} text={header} color={colors.amplifyNeutral90} />
      <TextBody
        style={styles.longDescription}
      >
        {body}
      </TextBody>
    </View>
  )
}

const SpaceDescription = ({
  userData = null,
  space,
  ownerID,
  hostUser,
  property,
  mobileView,
  width,
  address,
  allReviews,
  numOfSpaces,
  displaySpace,
  favorited,
  updateFavorite,
  imLoggedIn
}) => {
  const { description } = property;
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [cancellationPolicy, setcancellationPolicy] = useState([]);
  const [thingsToKnow, setThingsToKnow] = useState([]);
  const [perksList, setperksList] = useState([]);

  const setCancellationPolicyText = () => {
    let value = [];
    space.half_refund_date == 0 ? (
      value.push({ text: "No 50% refund available once booked.", icon: <MaterialCommunityIcons name="cash-refund" size={24} color="black" /> })
    ) : (
      value.push({ text: `Cancel ${space.half_refund_date} week${space.half_refund_date > 1 && "s"} prior to reservation start date for 50% refund.`, icon: <MaterialCommunityIcons name="cash-refund" size={24} color="black" margin={10} /> })
    )

    space.full_refund_date == 0 ? (
      value.push({ text: "No refund available once booked.", icon: <MaterialCommunityIcons name="cash-refund" size={24} color="black" margin={10} /> })
    ) : (
      value.push({ text: `Cancel ${space.full_refund_date} week${space.full_refund_date > 1 && "s"} week${space.half_refund_date > 1 && "s"} prior to reservation start date for FULL refund.`, icon: <MaterialCommunityIcons name="cash-refund" size={24} color="black" margin={10} /> })
    )
    setcancellationPolicy(value)
  }

  const setThingsToKnowText = () => {
    console.log("<<<<<<<<<<<<<<<<<   ")
    let value = [];

    space.pets_allowed && (
      value.push({
        text: "Pets Allowed",
        icon:
          <MaterialIcons
            name="pets"
            size={25}
            color={colors.orange30}
            marginLeft={10}
          />
      })
    )
    !space?.smoking_allowed && (
      value.push({
        text: "NO Smoking",
        icon:
          <FontAwesome5
            name="smoking-ban"
            size={25}
            color={colors.orange30}
            marginLeft={10}
          />
      })
    )
    space?.adults_only && (
      value.push({
        text: "NO Children permitted to stay on property",
        icon: <MaterialCommunityIcons name="baby-carriage-off" size={25} color={colors.orange30} marginLeft={10} />
      })
    )
    space.outdoor_fires && (
      value.push({
        text: "Outdoor Fires Allowed",
        icon: <MaterialCommunityIcons name="campfire" size={27} color={colors.orange30} marginLeft={8} />,
        subText: "Campfires: allowed only if there are no fire restrictions, per the Dept. of Forestry"
      })
    )
    if (space?.check_in_time && space?.check_out_time) {
      value.push({ text: `CHECK IN after: ${space.check_in_time}` })
      value.push({ text: `CHECK OUT before: ${space.check_out_time}` })
    }
    if (space?.quiet_hours_start && space?.quiet_hours_end) {
      value.push({
        text: `QUIET HOURS: ${space.quiet_hours_start} to ${space.quiet_hours_end}}`
      })
    }console.log("THINGS TO KNOW  >>>>>>>>>>>>>     ", value)
    setThingsToKnow(value)
  }

  useEffect(() => {
    setThingsToKnowText()
  }, [space])

  useEffect(() => {
    setCancellationPolicyText()
  }, [])

  return (
    <View style={[globalStyles.flexStartColumn, { width: mobileView ? width / 1.1 : width / 2.2 }]}>

      {/* ////// SECTION:   Header  */}
      <View style={[globalStyles.spaceBetween, { marginTop: 5 }]}>
        <Header level={1.1} text={property.title} padding={0} color={colors.amplifyNeutral90} />
        <HeaderButtons mobileView={true} favorited={favorited} updateFavorite={() => updateFavorite()} setShareModalOpen={() => setShareModalOpen(true)} imLoggedIn={imLoggedIn} />
      </View>
      {!!address && <Text style={{ fontSize: 15, marginTop: mobileView ? 0 : 30, marginBottom: 5 }}>{`${address.cityName}, ${address.canadianCivicAddress ? address.canadianCivicAddress : address.stateName}`}</Text>}
      <Text style={{ fontSize: 15 }}><Text style={{ fontSize: mobileView ? 19 : 22, fontWeight: "bold" }}>{numOfSpaces}</Text> RV Space{numOfSpaces > 1 ? "s" : ""} on Property</Text>
      {!!allReviews?.length && <ReviewView reviews={allReviews} />}
      <View style={globalStyles.flexStart}>
        {space.instant_book && <Badge width={125} marginTop={12} color={colors.amplifyNeutral20} shadow={false}><TextBody>instant book</TextBody></Badge>}
      </View>
      <CustomDivider marginVertical={20} />


      {/* ////// SECTION:   Descriptions  */}
      <Section header={`ABOUT RV SPACE${numOfSpaces > 1 ? "S" : ""}:`} body={description} />
      {/* <Section header="PERKS:" body={perksList} />
      <Section header={`ABOUT ${property.title.toUpperCase()}: `} body="" /> */}


      {/* ////// SECTION:   Amenities  */}
      <SpaceAmenities space={space} />


      {/* ////// SECTION:   Things to know dropdowns  */}
      <View style={{ marginVertical: 15 }}>
        {thingsToKnow.length && <CustomDropdown
          header="Things to Know"
          icon={
            <Image
              style={{ width: 30, height: 30 }}
              source={require("../../../assets/icons/lightbulb-on.svg")}
            />
          }
          bodyItems={thingsToKnow}
          space={space}
        />}
        {space?.things_to_do && (
          <CustomDropdown
            header="Things To Do in The Area"
            icon={
              <Image
                style={{ width: 30, height: 30 }}
                source={require("../../../assets/icons/things-to-do.svg")}
              />
            }
            bodyItems={[
              {
                text: space.things_to_do
              }
            ]}
          />)
        }
        <CustomDropdown
          header="Host's Rules and Policies"
          icon={
            <Image
              style={{ width: 30, height: 30 }}
              source={require("../../../assets/icons/host-rules.svg")}
            />
          }
          subHeader="Rules"
          bodyItems={[
            {
              text: space.space_rules
            }
          ]}
        />
        {cancellationPolicy && <CustomDropdown
          header="Cancellation Policy"
          icon={
            <Image
              style={{ width: 30, height: 30 }}
              source={require("../../../assets/icons/cancellation-policy.svg")}
            />
          }
          bodyItems={cancellationPolicy}
        />}
        {!!userData && !!Object.keys(userData).length && userData.sub !== ownerID &&
          <View style={[globalStyles.flexStart]}>
            <ContactView
              viewWidth={mobileView ? width / 1.2 : 300}
              centerView={mobileView}
              userDataPresent={hostUser}
              ownerID={ownerID}
              hosteeID={userData.sub}
              contactToHost={true}
              spaceID={space.id}
              spaceTitle={space.title}
              propertyTitle={property.title}
              propertyID={property.id}
              spaceState={property.address.stateName}
              spaceCity={property.address.cityName}
            />
          </View>
        }
      </View>


      <ShareModal
        modalVisible={shareModalOpen}
        setModalVisible={() => setShareModalOpen(!shareModalOpen)}
        mobileView={mobileView}
        width={width}
        shareLink={__DEV__ ? `http://localhost:19006/space/${property.id}` : REACT_APP_ENV === "development" ? `https://staging.d18w937drvm0l.amplifyapp.com/space/${property.id}` : `www.hookhub.co/space/${property.id}`}
      />
    </View >
  );
};

const styles = StyleSheet.create({
  longDescription: {
    lineHeight: 24
  },
});

export default SpaceDescription;
