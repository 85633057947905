import React, { useEffect, useState } from "react";
import {
  Pressable,
  StyleSheet,
  Text,
  View,
  TextInput
} from "react-native";
import Flex from "../CustomElements/Flex"
import Header from "../CustomElements/Header"

const EditDetailsPersonal = ({
  userState,
  setEditPersonal,
  setPhoneValue,
  setDialCodeValue,
}) => {
  const [nameValue, setNameValue] = useState(userState.name);

  const setPhoneNumber = (event) => {
    event.preventDefault();
    setPhoneValue(event.target.value);
  };

  const setDialCode = (event) => {
    event.preventDefault();
    setDialCodeValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <Flex as="form" direction="column" onSubmit={handleSubmit}>
      <Header marginTop={5} level={2} text="Personal Details">
      </Header>
      <TextInput
        label="Legal Name"
        errorMessage="There is an error"
        onChange={(e) => setNameValue(e.currentTarget.value)}
        value={nameValue}
      />
      <View style={styles.buttonSection}>
        <Pressable onPress={() => setEditPersonal()} style={styles.backButton}>
          <Text style={styles.backButtonText}>Cancel</Text>
        </Pressable>
        <Pressable
          onPress={(e) => handleSubmit(e)}
          style={styles.forwardButton}
        >
          <Text style={styles.forwardButtonText}>Save</Text>
        </Pressable>
      </View>
    </Flex>
  );
};

export const DetailsPersonal = ({ userState }) => {
  const [formattedPhoneNum, setFormattedPhoneNum] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [dialCodeValue, setDialCodeValue] = useState("");
  const [editPersonal, setEditPersonal] = useState(false);

  useEffect(() => {
    if (!!userState.phone_number) {
      const number = userState.phone_number.slice(-10);
      const numberCode = userState.phone_number.slice(0, -10);
      setPhoneValue(number);
      setDialCodeValue(numberCode);
    }
  }, []);

  useEffect(() => {
    if (!!phoneValue) {
      const last4 = phoneValue.slice(-4);
      const mid3 = phoneValue.slice(-7, -4);
      const firstDigits = phoneValue.slice(0, -7);
      setFormattedPhoneNum(
        `${dialCodeValue} (${firstDigits})-${mid3}-${last4}`
      );
    }
  }, [phoneValue]);

  return (
    <>
      {editPersonal ? (
        <EditDetailsPersonal
          phoneValue={phoneValue}
          dialCodeValue={dialCodeValue}
          setDialCodeValue={(v) => setDialCodeValue(v)}
          setPhoneValue={(v) => setPhoneValue(v)}
          userState={userState}
          setEditPersonal={() => setEditPersonal(false)}
        />
      ) : (
        <Flex marginTop={5} direction="column" alignContent="flex-start" justifyContent="flex-start" textAlign="left" alignItems="flex-start">
          <Header text="Personal Details" marginBottom={10} padding={0}></Header>
          <Flex marginBottom={8}>
            <Text style={{ fontWeight: "bold", marginRight: 20 }}>Legal Name</Text>
            <Text>{userState.name}</Text>
          </Flex>
          <Flex>
            <Text style={{ fontWeight: "bold", marginRight: 20 }}>Phone Number</Text>
            <Text>{formattedPhoneNum}</Text>
          </Flex>
        </Flex>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  cancelbutton: {
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 1,
    paddingHorizontal: 32,
    borderRadius: 4,
    elevation: 3,
    backgroundColor: "white",
  },
  canceltext: {
    fontSize: 16,
    lineHeight: 21,
    fontWeight: "bold",
    letterSpacing: 0.25,
    color: "black",
  },
  buttonSection: {
    flexDirection: "row",
    justifyContent: "center",
    marginVertical: 4,
  },
  backButtonText: {
    color: "#484a4a",
    fontSize: 20,
    fontWeight: "bold",
  },
  backButton: {
    alignItems: "center",
    borderBottomWidth: 2,
    justifyContent: "center",
    alignSelf: "center",
    height: 40,
  },
  forwardButtonText: {
    color: "#fff",
    fontSize: 20,
  },
  forwardButton: {
    borderColor: "#616A6B",
    borderRadius: 10,
    width: 100,
    borderWidth: 1,
    backgroundColor: "#065d8c",
    alignItems: "center",
    borderBottomWidth: 2,
    justifyContent: "center",
    alignSelf: "center",
    height: 40,
    marginLeft: 40,
  },
  logoImage: {
    height: 120,
    display: "block",
    width: 120,
    borderWidth: 1,
  },
});
