import React from "react";
import {
  StyleSheet,
  Text,
  Pressable,
  Image,
  View,
} from "react-native";
import { useSelector } from "react-redux";
import CustomModal from "../CustomElements/CustomModal";
import { colors } from "../../styles/colors";

export const SignUpModal = ({
  modalVisible,
  setModalVisible,
  width,
  authenticate
}) => {
  const { mobileView } = useSelector(state => state.currentUser);

  const closeAndNav = () => {
    setModalVisible()
    authenticate('signUp')
  }

  return (
    <CustomModal
      modalVisible={modalVisible}
      setModalVisible={() => setModalVisible(!modalVisible)}
      width={mobileView ? width / 1.1 : width / 1.7}
    >
      <View style={styles.flex}>
        <Image
          style={[
            styles.miniLogoImage,
            {
              margin: 10,
              marginTop: 15,
              width: mobileView ? 154 : 200,
              height: mobileView ? 40 : 52
            }]}
          source={require("../../../assets/images/hookhub-logo_mini.png")}
        />
        <Text style={{ fontSize: 17, textAlign: "center", margin: 20 }}>
          Sign up for your free app to discover new frontiers and affordable spaces around the U.S. for your RV.
        </Text>
        <Pressable onPress={() => closeAndNav()} style={styles.forwardButton}>
          <Text style={styles.forwardButtonText}>Continue</Text>
        </Pressable>
      </View>
    </CustomModal>

  );
};

const styles = StyleSheet.create({
  modalView: {
    margin: 13,
    backgroundColor: "white",
    borderRadius: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 90
  },
  forwardButtonText: {
    color: "#fff",
    fontSize: 20,
  },
  forwardButton: {
    borderColor: "grey",
    borderRadius: 10,
    marginTop: 10,
    marginBottom: 20,
    width: 150,
    borderWidth: 1,
    backgroundColor: colors.orange50,
    alignItems: "center",
    borderBottomWidth: 2,
    justifyContent: "center",
    alignSelf: "center",
    height: 55,
    shadowColor: "#252625",
    shadowOffset: { width: -3, height: 5 },
    shadowOpacity: 0.5,
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
  flex: {
    direction: "column",
    textAlign: "center",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
  },
  flexEnd: {
    justifyContent: "flex-end",
    margin: 13,
    textAlign: "right",
    alignContent: "flex-end",
    alignItems: "flex-end",
    alignSelf: "flex-end"
  },
  logoImage: {
    justifyContent: "center",
  },
  miniLogoImage: {
    justifyContent: "flex-start",
  },
});
