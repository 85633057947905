import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, useWindowDimensions } from "react-native";
import { Ionicons, MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';
import HostOnboarding from "../../components/host/HostOnboarding";
import { useNavigation } from "@react-navigation/native";
import { useDispatch, useSelector } from 'react-redux';
import { setAdditionalSpacesAlteredData, setSpaceDistances } from "../../redux/actions/HostOnboardingActions";
import SetDistances from "../../components/space/SetDistances";
import Card from "../../components/CustomElements/Card";
import Flex from "../../components/CustomElements/Flex";
import CustomButton from "../../components/CustomElements/CustomButton";
import { colors } from "../../styles/colors";
import Badge from "../../components/CustomElements/Badge";
import { globalStyles } from "../../styles/styles";

const SetAllValues = ({
  electric_hookup,
  water_hookup,
  sewage_hookup,
  distanceelectric,
  setdistanceelectric,
  distancewater,
  setdistancewater,
  distancesewage,
  setdistancesewage,
  mobileView,
  compact = false
}) => {
  const { width } = useWindowDimensions();

  return (
    <View>
      {electric_hookup && (
        <SetDistances
          compact={compact}
          val={distanceelectric}
          setVal={(v) => setdistanceelectric(v)}
          title="Distance to ELECTRIC hookup"
          subtext="(ft)"
          icon={
            <MaterialIcons
              name="electrical-services"
              size={45}
              color={colors.orange50}
            />
          }
        />
      )}
      {water_hookup && (
        <SetDistances
          compact={compact}
          val={distancewater}
          setVal={(v) => setdistancewater(v)}
          title="Distance to WATER hookup"
          subtext="(ft)"
          icon={<Ionicons
            name="water-sharp"
            size={40}
            color={colors.orange50}
          />}
        />
      )}
      {sewage_hookup && (
        <SetDistances
          compact={compact}
          val={distancesewage}
          setVal={(v) => setdistancesewage(v)}
          title="Distance to SEWAGE hookup"
          subtext="(ft)"
          icon={
            <MaterialCommunityIcons
              name="pipe"
              size={40}
              color={colors.orange50}
            />
          }
        />
      )}
    </View>
  )
}

function SetDistancesScreen() {
  const dispatch = useDispatch();
  const {
    distance_electric_hookup,
    distance_water_hookup,
    distance_sewage_hookup,
    water_hookup,
    electric_hookup,
    sewage_hookup,
    additionalSpacesBoolean,
    numberAdditionalSpaces,
    additionalSpacesAlteredData,
    newProperty
  } = useSelector(state => state.hostOnboarding);
  const { mobileView } = useSelector(state => state.currentUser);
  const { width } = useWindowDimensions();
  const [distanceelectric, setdistanceelectric] = useState(distance_electric_hookup || 20);
  const [distancewater, setdistancewater] = useState(distance_water_hookup || 20);
  const [distancesewage, setdistancesewage] = useState(distance_sewage_hookup || 10);
  const [additionalSpacesDistances, setAdditionalSpacesDistances] = useState({});
  const [distancesDiffer, setdistancesDiffer] = useState(false);
  const navigation = useNavigation();

  const setDistances = () => {
    if (additionalSpacesBoolean) {
      if (distancesDiffer) {
        dispatch(setAdditionalSpacesAlteredData({
          additionalSpacesAlteredData: additionalSpacesDistances
        }))
      } else {
        let setMyValues = { ...additionalSpacesDistances };
        Object.keys(setMyValues).map((key) => {
          setMyValues[key]["distance_sewage_hookup"] = distancesewage
          setMyValues[key]["distance_water_hookup"] = distancewater
          setMyValues[key]["distance_electric_hookup"] = distanceelectric
        })
        dispatch(setAdditionalSpacesAlteredData({
          additionalSpacesAlteredData: setMyValues
        }))
      }
    } else {
      dispatch(setSpaceDistances({
        distance_electric_hookup: distanceelectric,
        distance_water_hookup: distancewater,
        distance_sewage_hookup: distancesewage,
      }));
    }
  };

  const moveForward = () => {
    navigation.navigate("SpaceLocation");
  };

  const updateDistance = (i, type, val) => {
    let spacesObj = { ...additionalSpacesDistances };
    spacesObj[i][type] = val;
    setAdditionalSpacesDistances(spacesObj)
  }

  useEffect(() => {
    if (!water_hookup && !electric_hookup && !sewage_hookup) {
      moveForward();
    }
  }, []);

  useEffect(() => {
    numberAdditionalSpaces && (
      setAdditionalSpacesDistances(additionalSpacesAlteredData)
    )
  }, []);

  return (
    <HostOnboarding
      heading="How far are your amenities?"
      navigateF={newProperty ? "SpaceLocation" : "PhotoUpload"}
      nextButtonText="Next"
      navigateB={newProperty ? "SpaceDetails" : "AddTitle"}
      onClickNext={() => setDistances()}
    >
      {additionalSpacesBoolean ? (
        <>
          <View>
            <CustomButton
              height={mobileView ? 110 : 70}
              textAlign="flex-start"
              alignItems="flex-start"
              width={mobileView ? width / 1.1 : 500}
              marginBottom={10}
              backgroundColor={!distancesDiffer ? colors.vibrantBlue : colors.amplifyNeutral20}
              border={!distancesDiffer ? colors.amplifyNeutral80 : "#4c4e52"}
              onPress={() => setdistancesDiffer(false)}
            >
              <View style={{ flexDirection: "row", padding: 10 }}>
                <Ionicons name={!distancesDiffer ? "radio-button-on-sharp" : "radio-button-off-sharp"} size={24} color={colors.amplifyNeutral80} />
                <Text style={{ fontSize: 17, textAlign: "left", marginHorizontal: 4 }}>Distance to Amenities is relatively the same for all my spaces</Text>
              </View>
            </CustomButton>
            <CustomButton
              height={mobileView ? 60 : 45}
              textAlign="flex-start"
              alignItems="flex-start"
              width={mobileView ? width / 1.1 : 500}
              marginBottom={10}
              backgroundColor={distancesDiffer ? colors.vibrantBlue : colors.amplifyNeutral20}
              border={distancesDiffer ? colors.amplifyNeutral80 : "#4c4e52"}
              onPress={() => setdistancesDiffer(true)}
            >
              <View style={{ flexDirection: "row", paddingLeft: 10 }}>
                <Ionicons name={distancesDiffer ? "radio-button-on-sharp" : "radio-button-off-sharp"} size={24} color={colors.amplifyNeutral80} />
                <Text style={{ fontSize: 17, textAlign: "left", marginLeft: 4 }}>Nope, distances vary</Text>
              </View>
            </CustomButton>
          </View>
          <>
            {
              distancesDiffer ? (
                <View style={[mobileView ? globalStyles.centerColumn : globalStyles.center, { marginTop: 15 }]}>
                  {
                    Object.keys(additionalSpacesDistances).map((key) => (
                      <View style={[globalStyles.centerColumn, { marginBottom: 10 }]}>
                        <Badge margin={0} width={"90%"} color={colors.white}>
                          <Text>Space: {additionalSpacesDistances[key].title}</Text>
                        </Badge>
                        <SetAllValues
                          compact={true}
                          mobileView={mobileView}
                          electric_hookup={electric_hookup}
                          water_hookup={water_hookup}
                          sewage_hookup={sewage_hookup}
                          distanceelectric={additionalSpacesDistances[key].distance_electric_hookup || distanceelectric}
                          setdistanceelectric={(v) => updateDistance(key, 'distance_electric_hookup', v)}
                          distancewater={additionalSpacesDistances[key].distance_water_hookup || distancewater}
                          setdistancewater={(v) => updateDistance(key, 'distance_water_hookup', v)}
                          distancesewage={additionalSpacesDistances[key].distance_sewage_hookup || distancesewage}
                          setdistancesewage={(v) => updateDistance(key, 'distance_sewage_hookup', v)}
                        />
                      </View>
                    ))
                  }
                </View>
              ) : (
                <SetAllValues
                  compact={true}
                  mobileView={mobileView}
                  electric_hookup={electric_hookup}
                  water_hookup={water_hookup}
                  sewage_hookup={sewage_hookup}
                  distanceelectric={distanceelectric}
                  setdistanceelectric={(v) => setdistanceelectric(v)}
                  distancewater={distancewater}
                  setdistancewater={(v) => setdistancewater(v)}
                  distancesewage={distancesewage}
                  setdistancesewage={(v) => setdistancesewage(v)}
                />
              )}
          </>
        </>
      ) : (
        <>
          <SetAllValues
            mobileView={mobileView}
            electric_hookup={electric_hookup}
            water_hookup={water_hookup}
            sewage_hookup={sewage_hookup}
            distanceelectric={distanceelectric}
            setdistanceelectric={(v) => setdistanceelectric(v)}
            distancewater={distancewater}
            setdistancewater={(v) => setdistancewater(v)}
            distancesewage={distancesewage}
            setdistancesewage={(v) => setdistancesewage(v)}
          />
        </>
      )}
    </HostOnboarding>
  )
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: "#808080",
    padding: 70
  },
  textBox: {
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 50
  },
  text: {
    color: "white",
    fontSize: 33,
    fontWeight: "bold",
    textAlign: "center",
  },
  buttonSection: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  backButton: {
    alignItems: 'center',
    borderBottomWidth: 2,
    justifyContent: 'center',
    alignSelf: 'center',
    height: 40,
    marginTop: 25,
    marginLeft: 25,
  },
  forwardButtonText: {
    color: "#616A6B",
    fontSize: 20,
  },
  forwardButton: {
    borderColor: "#616A6B",
    borderRadius: 10,
    width: 100,
    borderWidth: 1,
    backgroundColor: "#fff",
    alignItems: 'center',
    borderBottomWidth: 2,
    justifyContent: 'center',
    alignSelf: 'center',
    height: 40,
    marginTop: 25,
    marginLeft: 25,
  },
  disabledButtonText: {
    color: "#99A3A4",
    fontSize: 20,
  },
  disabledButton: {
    borderColor: "#616A6B",
    borderRadius: 10,
    width: 100,
    borderWidth: 1,
    backgroundColor: "#616A6B",
    alignItems: 'center',
    borderBottomWidth: 2,
    justifyContent: 'center',
    alignSelf: 'center',
    height: 40,
    marginTop: 25,
    marginLeft: 25,
  },
});

export default SetDistancesScreen;
