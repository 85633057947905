import React, { useState, useEffect, useContext } from "react";
import {
    View as ReactView,
    StyleSheet,
    useWindowDimensions,
    Pressable,
    Text,
    View
} from "react-native";
import Flex from "../CustomElements/Flex"
import Header from "../CustomElements/Header"
import Card from "../CustomElements/Card"
import { Entypo, FontAwesome5, Ionicons } from "@expo/vector-icons";
import { Calendar } from "react-native-calendars";
import { useNavigation } from "@react-navigation/native";
import { useSelector } from "react-redux";
import { BookingsContext } from "../../utils/BookingsContextProvider";
import { getCalendarDisplayDates } from "../../utils/getCalendarDisplayDates";
import { colors } from "../../styles/colors"
import CustomButton from "../CustomElements/CustomButton";
import { globalStyles } from "../../styles/styles";

const SpacesSchedule = ({ displayTimes }) => {
    const { width } = useWindowDimensions();
    const { mobileView } = useSelector(state => state.currentUser);

    return (
        <View style={globalStyles.center}>
        <Card
            backgroundColor={colors.orange10}
            border="#000"
            width={!!mobileView ? width / 1.1 : width / 1.2}
            alignItems="center"
            padding={20}
            textAlign="center"
            marginTop={4}
        >
            <Calendar
                markingType={"period"}
                markedDates={displayTimes}
                renderArrow={(direction) => {
                    return (
                        <>
                            {direction == "right" ? (
                                <Entypo
                                    name={"arrow-with-circle-right"}
                                    size={24}
                                    color={colors.amplifyNeutral80}
                                />
                            ) : (
                                <Entypo
                                    name={"arrow-with-circle-left"}
                                    size={24}
                                    color={colors.amplifyNeutral80}
                                />
                            )}
                        </>
                    );
                }}
                firstDay={1}
                enableSwipeMonths={true}
                monthFormat={mobileView ? 'MMM' : 'MMMM'}
                theme={{
                    backgroundColor: "tan",
                    calendarBackground: "#ffffff",
                    textSectionTitleColor: "#b6c1cd",
                    selectedDayBackgroundColor: "gray",
                    todayTextColor: "#00adf5",
                    dayTextColor: "#2d4150",
                    arrowColor: "blue",
                    monthTextColor: "gray",
                    indicatorColor: "blue",
                    textDayFontWeight: 300,
                    textMonthFontWeight: "bold",
                    textDayHeaderFontWeight: 300,
                    textDayFontSize: !!mobileView ? 15 : 20,
                    textMonthFontSize: !!mobileView ? 12 : 20,
                    textDayHeaderFontSize: !!mobileView ? 10 : 17,
                }}
                style={{
                    borderWidth: 1,
                    borderColor: "gray",
                    margin: 10,
                    width: mobileView ? width / 1.15 : width / 1.4
                }}
            />
            <Flex
                marginTop={10}
                textAlign="center"
                justifyContent="center"
                width={width / 1.2}
            >
                <Text style={{ fontSize: 14, textAlign: "center" }}>* Note: you may need to reload the app to see any changes or updates.</Text>
            </Flex>
        </Card>
        </View>
    )
};

const PropertySpacesAccordian = ({ property, selectedProperty, setSelectedProperty, mobileView, setSpace, space }) => {
    const { width } = useWindowDimensions();
    const selected = selectedProperty?.id === property?.id;
    const spaces = property?.spaces?.items;

    return (
        <View>
            {selected ? (
                <Card
                    backgroundColor={colors.amplifyNeutral20}
                    border="#4c4e52"
                    boxShadow="#737272"
                    padding={!mobileView ? 8 : 0}
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    alignContent="flex-start"
                    width={mobileView ? width / 1.2 : width / 1.8}
                >
                    {spaces?.length > 1 && <Header text={property.title} level={.9} />}
                    {spaces.map((v, i) => (
                        <View key={i}>
                            <Pressable onPress={() => setSpace(v)}>
                                <View style={{ width: mobileView ? width / 1.2 : width / 1.8, flexDirection: "row", alignContent: "flex-start", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                    <Ionicons name={(space?.id && space.id === v.id) ? "radio-button-on-sharp" : "radio-button-off-sharp"} size={20} color={colors.amplifyNeutral80} />
                                    <Text style={{ width: mobileView ? width / 1.2 : width / 1.8 }}>
                                        {v.title}
                                    </Text>
                                </View>
                            </Pressable>
                        </View>
                    ))}
                </Card>
            ) : (
                <Card
                    backgroundColor={colors.amplifyNeutral20}
                    border="#4c4e52"
                    boxShadow="#737272"
                    padding={4}
                    marginTop={1}
                    marginBottom={1}
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    alignContent="flex-start"
                    width={mobileView ? width / 1.2 : 400}
                >
                    <Pressable onPress={() => setSelectedProperty(property)}>
                        <View style={globalStyles.center}>
                            <Ionicons name={"radio-button-off-sharp"} size={20} color={colors.amplifyNeutral80} />
                            <Text style={{marginLeft: 5}}>{property.title.length > 25 ? `${property.title.slice(0, 25)}...` : property.title}</Text>
                        </View>
                    </Pressable>
                </Card>
            )}
        </View>
    )
};

export const MyHostCalendar = ({ bookingRequests, bookings }) => {
    const { mobileView } = useSelector(state => state.currentUser);
    const { myHosting } = useContext(BookingsContext);
    const { mySpaces } = myHosting;
    const { width } = useWindowDimensions();
    const navigation = useNavigation();
    const [property, setSelectedProperty] = useState();
    const [space, setSpace] = useState({})
    const [displayTimes, setDisplayTimes] = useState({});

    useEffect(() => {
        property?.id && setSpace(property.spaces?.items[0])
    }, [property]);

    useEffect(() => {
        space?.id && setDisplayTimes(getCalendarDisplayDates(space, bookings, bookingRequests))
    }, [space]);

    const editAvailability = () => {
        setSelectedProperty({})
        navigation.navigate("availability", {
            spaceID: space.id,
            propertyName: property.title,
            spaceName: space.title,
        });
    };

    const EditAvailabilityButton = ({ mobileView }) => {
        return (
            <CustomButton
                width={mobileView ? 150 : 300}
                height={mobileView ? 30 : 35}
                marginBottom={5}
                backgroundColor={colors.orange50}
                textAlign="center"
                marginTop={10}
                onPress={() => editAvailability()}
                disabled={!space?.id}
            >
                <View style={globalStyles.center}>
                    <FontAwesome5 name="edit" size={18} color="black" />
                    <Text style={{ marginLeft: 10, fontSize: 14 }}>{!!mobileView ? "Edit Availability" : "Edit my Space Availability"}</Text>
                </View>
            </CustomButton>
        )
    };

    return (
        <View style={[globalStyles.flexStartColumn, { marginBottom: 40, marginTop: 20, width: width/1.1 }]}>
            <Header
                color="#4c4e52"
                level={1.1}
                text="My Calendar"
                marginBottom={10}
            />
            {mySpaces.map((v, i) => (
                <View key={i} style={globalStyles.flexEnd}>
                    <PropertySpacesAccordian setSpace={(v) => setSpace(v)} space={space} mobileView={mobileView} property={v} selectedProperty={property} setSelectedProperty={(v) => setSelectedProperty(v)} />
                </View>
            ))}
            <View style={[globalStyles.flexEndColumn, { marginRight: 30 }]}>
                <EditAvailabilityButton mobileView={mobileView} />
                <Flex marginRight={mobileView && 10} justifyContent="flex-end" textAlign="right"><Text style={{ fontSize: 15, marginRight: 10 }}>{!!mobileView ? "unavailable" : "unavailable days"}</Text><ReactView style={styles.unavailableKey}></ReactView></Flex>
                <Flex marginRight={mobileView && 10} justifyContent="flex-end" textAlign="right"><Text style={{ fontSize: 15, marginRight: 10 }}>{!!mobileView ? "requests" : "booking requests"}</Text><ReactView style={styles.requestKey}></ReactView></Flex>
                <Flex marginRight={mobileView && 10} justifyContent="flex-end" textAlign="right"><Text style={{ fontSize: 15, marginRight: 10 }}>{!!mobileView ? "bookings" : "scheduled bookings"}</Text><ReactView style={styles.bookingKey}></ReactView></Flex>
            </View>
            <SpacesSchedule displayTimes={displayTimes} />
        </View >
    );
};

const styles = StyleSheet.create({
    unavailableKey: {
        width: 13,
        height: 13,
        backgroundColor: "#bdbbbb",
        borderRadius: 20,
        alignItems: "center",
        elevation: 5,
    },
    requestKey: {
        width: 13,
        height: 13,
        backgroundColor: colors.orange50,
        borderRadius: 20,
        alignItems: "center",
        elevation: 5,
    },
    bookingKey: {
        width: 13,
        height: 13,
        backgroundColor: "#42b0f5",
        borderRadius: 20,
        alignItems: "center",
        elevation: 5,
    }
});
