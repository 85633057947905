import React from "react";
import { Provider } from "react-redux";
import { StyleSheet, Platform, View } from "react-native";
import Router from "./src/navigation/Router";
import { PersistGate } from "redux-persist/integration/react";
import LoadingScreen from "./src/screens/LoadingScreen";
import { store, persistor } from "./src/redux/store";
import { Amplify } from "aws-amplify";
import UseScriptTag from "./src/utils/UseScriptTag";
import getEnvVars from './environment';
const { googleAPI } = getEnvVars();
import awsconfig from "./src/aws-exports";
import { SafeAreaProvider } from 'react-native-safe-area-context';
// import * as dotenv from "dotenv"; // see https://github.com/motdotla/dotenv#how-do-i-use-dotenv-with-import
// dotenv.config();
Amplify.configure(awsconfig);

const App = () => {
  Platform.OS === 'web' && UseScriptTag({ url: googleAPI });

  return (
    <SafeAreaProvider>
      <Provider store={store}>
        <View style={{ flex: 1 }}>
          <PersistGate loading={<LoadingScreen />} persistor={persistor}>
            <Router style={styles.container} />
          </PersistGate>
        </View>
      </Provider>
    </SafeAreaProvider>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default App;