const INITIAL_STATE = {
    imageIDs: {},
    imageUploadError: false,
    imagesFinishedUploading: false,
    type_shortterm: false,
    type_longterm: false,
    type_storage: false,
    type_yearly: false,
    electric_hookup: false,
    water_hookup: false,
    sewage_hookup: false,
    wifi_available: false,
    wifi_network_name: false,
    wifi_password: false,
    shower_available: false,
    restroom_available: false,
    food_nearby: false,
    restaurant_nearby: false,
    water_accessible: false,
    easy_entry: false,
    distance_electric_hookup: 10,
    distance_water_hookup: 10,
    distance_sewage_hookup: 10,
    address: '',
    cityName: '',
    stateName: '',
    countryName: 'United States of America',
    zipcode: null,
    canadianCivicAddress: '',
    fullAddress: {},
    coordinates: {
        lat: 0,
        lng: 0
    },
    title: '',
    description: '',
    thingsInArea: '',
    checkInInstructions: '',
    width: 15,
    length: 30,
    price_storage: 2,
    price_shortterm: 40,
    price_longterm: 25,
    price_yearly: 12,
    laundry_available: false,
    pool_available: false,
    hottub_available: false,
    outdoor_area_available: false,
    indoor_area_available: false,
    trash_available: false,
    pets_allowed: false,
    smoking_allowed: false,
    adults_only: false,
    outdoor_fires: false,
    full_refund_date: '',
    half_refund_date: '',
    check_in_time: null,
    check_out_time: null,
    space_rules: '',
    instant_book: false,
    quiet_hours_start: null,
    quiet_hours_end: null,
    additionalSpacesBoolean: false,
    newProperty: true,
    propertyID: null,
    numberAdditionalSpaces: 1,
    additionalSpacesTitleType: 'custom',
    additionalSpacesAlteredData: {},
    existingSpaceTitles: [],
    existingSpaceID: '',
    existingSpaceTitleToUpdate: ''
};

const hostOnboardingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_SPACE_TYPE':
            return {
                ...state,
                type_shortterm: action.short,
                type_longterm: action.long,
                type_storage: action.storage,
                type_yearly: action.yearly
            }
        case 'SET_SPACE_DETAILS':
            return {
                ...state,
                electric_hookup: action.electric_hookup,
                water_hookup: action.water_hookup,
                sewage_hookup: action.sewage_hookup,
                wifi_available: action.wifi_available,
                shower_available: action.shower_available,
                restroom_available: action.restroom_available,
                food_nearby: action.food_nearby,
                restaurant_nearby: action.restaurant_nearby,
                water_accessible: action.water_accessible,
                easy_entry: action.easy_entry,
                laundry_available: action.laundry_available,
                pool_available: action.pool_available,
                hottub_available: action.hottub_available,
                outdoor_area_available: action.outdoor_area_available,
                indoor_area_available: action.indoor_area_available,
                trash_available: action.trash_available,
                pets_allowed: action.pets_allowed,
                smoking_allowed: action.smoking_allowed,
                adults_only: action.adults_only,
                outdoor_fires: action.outdoor_fires,
            }
        case 'SET_SPACE_LOCATION':
            return {
                ...state,
                address: action.address,
                cityName: action.cityName,
                stateName: action.stateName,
                countryName: action.countryName,
                canadianCivicAddress: action.canadianCivicAddress,
                zipcode: Number(action.zipcode),
                fullAddress: action.fullAddress
            }
        case 'SET_SPACE_COORDINATES':
            return {
                ...state,
                coordinates: action.coordinates
            }
        case 'SET_TITLE':
            return {
                ...state,
                title: action.title,
                existingSpaceTitleToUpdate: action.existingSpaceTitleToUpdate
            }
        case 'SET_DESCRIPTION':
            return {
                ...state,
                description: action.description,
                thingsInArea: action.thingsInArea
            }
        case 'SET_DIMENSIONS':
            return {
                ...state,
                length: action.length,
                width: action.width
            }
        case 'SET_SPACE_DISTANCES':
            return {
                ...state,
                distance_electric_hookup: action.distance_electric_hookup,
                distance_water_hookup: action.distance_water_hookup,
                distance_sewage_hookup: action.distance_sewage_hookup,
            }
        case 'SET_PRICES':
            return {
                ...state,
                price_storage: action.price_storage,
                price_shortterm: action.price_shortterm,
                price_longterm: action.price_longterm,
                price_yearly: action.price_yearly
            }
        case 'SET_THINGS_TO_KNOW':
            return {
                ...state,
                check_in_time: action.check_in_time,
                check_out_time: action.check_out_time,
                space_rules: action.space_rules,
                instant_book: action.instant_book,
                quiet_hours_start: action.quiet_hours_start,
                quiet_hours_end: action.quiet_hours_end,
                full_refund_date: action.full_refund_date,
                half_refund_date: action.half_refund_date,
                checkInInstructions: action.checkInInstructions,
                wifi_network_name: action.wifi_network_name,
                wifi_password: action.wifi_password
            }
        case 'CLEAR_SPACE_DETAILS':
            return INITIAL_STATE
        case 'SET_IMAGE_STATUS':
            return {
                ...state,
                imageIDs: action.imageIDs,
                imageUploadError: action.imageUploadError,
                imagesFinishedUploading: action.imagesFinishedUploading
            }
        case 'SET_ADDITIONAL_SPACES_OPTION':
            return {
                ...state,
                additionalSpacesBoolean: action.additionalSpacesBoolean,
                numberAdditionalSpaces: action.numberAdditionalSpaces,
            }
        case 'SET_ADDITIONAL_SPACES_ALTERED_DATA':
            return {
                ...state,
                additionalSpacesAlteredData: action.additionalSpacesAlteredData,
                additionalSpacesTitleType: action?.additionalSpacesTitleType || state.additionalSpacesTitleType
            }
        case 'SET_NEW_PROPERTY_BOOLEAN':
            return {
                ...state,
                newProperty: action.newProperty,
                additionalSpacesBoolean: action.additionalSpacesBoolean
            }
        case 'SET_ADDITIONAL_SPACE_DETAILS':
            return {
                ...state,
                propertyID: action.propertyID,
                title: action.title,
                imageIDs: action.imageIDs,
                additionalSpacesBoolean: action.additionalSpacesBoolean,
                newProperty: action.newProperty,
                length: action.length,
                width: action.width,
                cityName: action.cityName,
                stateName: action.stateName,
                countryName: action.countryName,
                description: action.description,
                thingsInArea: action.thingsInArea,
                price_storage: action.price_storage,
                price_shortterm: action.price_shortterm,
                price_longterm: action.price_longterm,
                price_yearly: action.price_yearly,
                distance_electric_hookup: action.distance_electric_hookup,
                distance_water_hookup: action.distance_water_hookup,
                distance_sewage_hookup: action.distance_sewage_hookup,
                type_shortterm: action.short,
                type_longterm: action.long,
                type_storage: action.storage,
                type_yearly: action.yearly,
                electric_hookup: action.electric_hookup,
                water_hookup: action.water_hookup,
                sewage_hookup: action.sewage_hookup,
                wifi_available: action.wifi_available,
                shower_available: action.shower_available,
                restroom_available: action.restroom_available,
                food_nearby: action.food_nearby,
                water_accessible: action.water_accessible,
                restaurant_nearby: action.restaurant_nearby,
                easy_entry: action.easy_entry,
                laundry_available: action.laundry_available,
                pool_available: action.pool_available,
                hottub_available: action.hottub_available,
                outdoor_area_available: action.outdoor_area_available,
                indoor_area_available: action.indoor_area_available,
                trash_available: action.trash_available,
                pets_allowed: action.pets_allowed,
                smoking_allowed: action.smoking_allowed,
                adults_only: action.adults_only,
                outdoor_fires: action.outdoor_fires,
                check_in_time: action.check_in_time,
                check_out_time: action.check_out_time,
                space_rules: action.space_rules,
                instant_book: action.instant_book,
                quiet_hours_start: action.quiet_hours_start,
                quiet_hours_end: action.quiet_hours_end,
                full_refund_date: action.full_refund_date,
                half_refund_date: action.half_refund_date,
                checkInInstructions: action.checkInInstructions,
                wifi_network_name: action.wifi_network_name,
                wifi_password: action.wifi_password,
                existingSpaceID: action.existingSpaceID,
                existingSpaceTitleToUpdate: action.existingSpaceTitleToUpdate,
                existingSpaceTitles: action.existingSpaceTitles
            }
        default:
            return state
    }
};

export default hostOnboardingReducer;