import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, useWindowDimensions, Pressable } from "react-native";
import Flex from "../CustomElements/Flex"
import Header from "../CustomElements/Header"
import Card from "../CustomElements/Card"
import { colors } from "../../styles/colors"
import CustomButton from "../CustomElements/CustomButton";
import CustomModal from "../CustomElements/CustomModal";
import { Ionicons } from "@expo/vector-icons";
import SelectEditSpaceOrProperty from "./SelectEditSpaceOrProperty";
import { useNavigation } from "@react-navigation/native";
import { deactivatePropertyMutation, deletePropertyMutation } from "../../api/property";
import { deactivateSpaceMutation, deleteSpaceMutation } from "../../api/space";

const DeactivateOrDeletePropertyModal = ({
    property,
    spaces,
    modalVisible,
    setModalVisible,
    mobileView,
    updateMySpaces,
    setActiveProperty,
    mySpaceData,
    allActiveSpaces,
    setAllActiveSpaces,
    inactiveSpaces,
    setInactiveSpaces
}) => {
    const navigation = useNavigation();
    const [deactivate, setdeactivate] = useState(true);
    const { width } = useWindowDimensions();
    const [displaySpace, setDisplaySpace] = useState(allActiveSpaces[0]);
    const [updatePropertyBoolean, setUpdatePropertyBoolean] = useState(true);
    const [error, setError] = useState(false);
    const [title, setTitle] = useState('');

    useEffect(() => {
        let showTitle = updatePropertyBoolean ? property.title : displaySpace.title
        setTitle(showTitle)
    }, [displaySpace, updatePropertyBoolean]);

    const deactivateOrDelete = () => {
        if (deactivate) {
            updatePropertyBoolean ? deactivateProperty() : deactivateSpace();
        } else {
            updatePropertyBoolean ? deleteProperty() : deleteSpace();
        }
    };

    const deleteProperty = async () => {
        let successfulDelete = await deletePropertyMutation(property.id)
        if (successfulDelete) {
            if (!!mySpaceData) {
                const updatedSpaces = mySpaceData.mySpaces.filter((v) => v.id !== property.id)
                updateMySpaces(updatedSpaces);
            }
            setModalVisible(false)
            navigation.goBack()
        } else { setError(true) }
    };

    async function deactivateProperty() {
        let successfulDeactivate = await deactivatePropertyMutation(property.id)
        if (!!successfulDeactivate) {
            setModalVisible(false);
            setActiveProperty(false)
        } else { setError(true) }
    };

    const deleteSpace = async () => {
        let successfulDelete = await deleteSpaceMutation(displaySpace.id)
        if (successfulDelete) {
            const updatedActiveSpaces = allActiveSpaces.filter((v) => v.id !== displaySpace.id);
            const updatedInactiveSpaces = inactiveSpaces.filter((v) => v.id !== successfulDelete.id);
            if (updatedActiveSpaces.length < 2) {
                setInactiveSpaces(updatedInactiveSpaces);
                setAllActiveSpaces(updatedActiveSpaces);
                deactivateProperty();
            } else {
                setInactiveSpaces(updatedInactiveSpaces);
                setAllActiveSpaces(updatedActiveSpaces);
                setModalVisible(false);
            }
        } else { setError(true) }
    };

    async function deactivateSpace() {
        let successfulDeactivate = await deactivateSpaceMutation(displaySpace.id)
        if (successfulDeactivate) {
            const updatedActiveSpaces = allActiveSpaces.filter((v) => v.id !== displaySpace.id);
            const updatedInactiveSpaces = [...inactiveSpaces, successfulDeactivate]
            if (updatedActiveSpaces.length < 1) {
                setInactiveSpaces(updatedInactiveSpaces);
                setAllActiveSpaces(updatedActiveSpaces);
                deactivateProperty();
            } else {
                setInactiveSpaces(updatedInactiveSpaces);
                setAllActiveSpaces(updatedActiveSpaces);
                setModalVisible(false);
            }
        } else { setError(true) }
    };

    return (
        <CustomModal modalVisible={modalVisible} setModalVisible={() => setModalVisible(!modalVisible)} modalWidth={mobileView ? width/1.1 : width/1.2}>
            <SelectEditSpaceOrProperty
                spaces={allActiveSpaces}
                width={width}
                mobileView={mobileView}
                setTitle={(v) => setTitle(v)}
                displaySpace={displaySpace}
                setDisplaySpace={(v) => setDisplaySpace(v)}
                updateProperty={updatePropertyBoolean}
                setUpdateProperty={(v) => setUpdatePropertyBoolean(v)}
                deactiveOrDelete={true}
            />
            <Flex direction="column" padding={5} justifyContent="center" alignContent="center" alignItems="center">
                <Flex direction="column" justifyContent="center" alignContent="center" alignItems="center" marginTop={15} marginBottom={10}>
                    <Pressable onPress={() => setdeactivate(true)}>
                        <Card
                            backgroundColor={deactivate ? colors.orange80 : colors.grey20}
                            border="#4c4e52"
                            padding={8}
                            alignItems="center"
                            textAlign="left"
                            width={width / 1.4}
                        >
                            <View style={{ alignContent: "center", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
                                <Ionicons name={deactivate ? "radio-button-on-sharp" : "radio-button-off-sharp"} size={24} color={deactivate ? "#000" : colors.amplifyNeutral90} />
                                <Header color={deactivate ? "#000" : colors.amplifyNeutral80} text="Deactivate"></Header>
                            </View>
                            <Text style={{ textAlign: "center" }}>Set your {updatePropertyBoolean ? "property" : "space"} offline, you can reactivate later.</Text>
                        </Card>
                    </Pressable>
                    <Pressable onPress={() => setdeactivate(false)}>
                        <Card
                            backgroundColor={!deactivate ? colors.orange80 : colors.grey20}
                            border="#4c4e52"
                            padding={8}
                            alignItems="center"
                            textAlign="left"
                            width={width / 1.4}
                        >
                            <View style={{ alignContent: "center", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
                                <Ionicons name={!deactivate ? "radio-button-on-sharp" : "radio-button-off-sharp"} size={24} color={!deactivate ? "#000" : colors.amplifyNeutral90} />
                                <Header color={!deactivate ? "#000" : colors.amplifyNeutral80} text="Delete"></Header>
                            </View>
                            <Text style={{ textAlign: "center" }}>This will permanently remove your {updatePropertyBoolean ? "property" : "space"}!</Text>
                        </Card>
                    </Pressable>
                </Flex>
                <Flex direction="column" justifyContent="center" alignContent="center" alignItems="center">
                    <Header level={.8} marginTop={10} textAlign="center" text="** note! you may need to reload the app to see your updates"></Header>
                    <CustomButton
                        width={170}
                        backgroundColor={colors.orange80}
                        marginTop={10}
                        marginBottom={5}
                        onPress={() => deactivateOrDelete()}
                    >
                        <Text style={styles.updatebuttontext}>{deactivate ? `Deactivate ${updatePropertyBoolean ? "property" : "space"}` : `Delete ${updatePropertyBoolean ? "property" : "space"}`}</Text>
                    </CustomButton>

                    <CustomButton
                        width={150}
                        backgroundColor={colors.amplifyNeutral20}
                        onPress={() => setModalVisible()}
                        marginBottom={5}
                    >
                        <Text style={styles.updatebuttontext}>Cancel</Text>
                    </CustomButton>
                </Flex>
            </Flex>
        </CustomModal>
    );
};

const styles = StyleSheet.create({
    cancelbutton: {
        borderColor: "grey",
        borderRadius: 10,
        width: 150,
        borderWidth: 1,
        backgroundColor: colors.orange50,
        alignItems: "center",
        borderBottomWidth: 2,
        justifyContent: "center",
        alignSelf: "center",
        height: 30,
        padding: 20,
        shadowColor: "#252625",
        shadowOffset: { width: -3, height: 5 },
        shadowOpacity: 0.5,
    },
    modalView: {
        margin: 15,
        backgroundColor: "white",
        borderRadius: 20,
        padding: 10,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    updatebuttontext: {
        color: "#000",
        fontSize: 17,
    },
    updatebutton: {
        borderColor: "grey",
        borderRadius: 10,
        width: 150,
        margin: 20,
        borderWidth: 1,
        backgroundColor: colors.orange50,
        alignItems: "center",
        borderBottomWidth: 2,
        justifyContent: "center",
        alignSelf: "center",
        height: 30,
        padding: 20,
        shadowColor: "#252625",
        shadowOffset: { width: -3, height: 5 },
        shadowOpacity: 0.5,
    },
});

export default DeactivateOrDeletePropertyModal;