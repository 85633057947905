import React, { useContext } from "react";
import Property from "./Property";
import { UserDataContext } from "../../utils/UserContextProvider";
import { globalStyles } from "../../styles/styles";
import { useWindowDimensions, View } from "react-native";


function PropertiesGrid({ properties, showCardBackground = false, mobileView }) {
  const data = useContext(UserDataContext);
  const { width } = useWindowDimensions();

  return (
    <View style={[globalStyles.center, { width, flexWrap: "wrap", marginBottom: 150 }]}>
      {properties.map((v, i) => <Property showCardBackground={showCardBackground} property={v} key={i} userData={data?.user?.userData} />)}
    </View>

  );
}

export default PropertiesGrid;
