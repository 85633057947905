import React, { useState, useEffect } from "react";
import { StyleSheet, useWindowDimensions, ScrollView, Text, Image, View, ImageBackground } from "react-native";
import Header from "../../components/CustomElements/Header"
import { useDispatch, useSelector } from "react-redux";
import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from "@react-navigation/native";
import CustomButton from "../../components/CustomElements/CustomButton";
import { colors } from "../../styles/colors";
import CustomSection from "../../components/CustomElements/CustomSection";
import HelpForm from "../../components/other/HelpForm";
import { Auth } from "aws-amplify";
import getEnvVars from "../../../environment";
import { globalStyles } from "../../styles/styles";
import CustomSlider from "../../components/other/CustomSlider";
import TextBody from "../../components/CustomElements/TextBody";
import SelectAmenities from "../../components/space/SelectAmenities";
import CustomDivider from "../../components/CustomDivider";
import FooterView from "../../components/other/FooterView";
import ComparisonToCompetitorsChart from "../../components/other/ComparisonToCompetitorsChart";
import { SignUpModal } from "../../components/user/SignUpModal";
import { setAuthed } from "../../redux/actions/CurrentUserActions";
import SelectStayType from "../../components/space/SelectStayType";
import LearnMore from "../../components/LearnMore";
import { NumberDisplayOne, NumberDisplayThree, NumberDisplayTwo } from "../../components/CustomElements/NumberDisplays";
import { HeroHeader } from "../../components/CustomElements/HeroHeaderDisplay";
import { ReviewDisplay } from "../../components/other/ReviewDisplay";


const HostSpaceButton = ({ authed, navigate, setSignUpModalOpen, mobileView, width }) => {
    return (
        <CustomButton
            width={mobileView ? width / 2.25 : 200}
            backgroundColor={colors.orange40}
            color="#fff"
            textAlign="center"
            height={50}
            onPress={() => authed ? navigate() : setSignUpModalOpen(true)}
        >
            <View style={globalStyles.center}>
                <AntDesign name="pluscircleo" size={24} color="white" />
                <Text style={{ marginLeft: 10, color: "white", fontWeight: 700, fontSize: 16 }}>Host Space</Text>
            </View>
        </CustomButton>
    )
}
function HostSplashScreen() {
    const { cloudfrontURLStaticImages } = getEnvVars();
    const navigation = useNavigation();
    const dispatch = useDispatch();
    const { width, height } = useWindowDimensions();
    const { mobileView } = useSelector(state => state.currentUser);
    const [authenticated, setAuthenticated] = useState(false)
    const [stayType, setStayType] = useState('monthly');
    const [water, setWater] = useState(true)
    const [electric, setElectric] = useState(true)
    const [sewage, setSewage] = useState(true)
    const [deluxe, setDeluxe] = useState(false)
    const [nightlyCost, setNightlyCost] = useState(40)
    const [numNights, setNumNights] = useState(30)
    const [showExplanation, setShowExplanation] = useState(false)
    const [signUpModalOpen, setSignUpModalOpen] = useState(false)
    const customWidth = mobileView ? width / 1.1 : width / 1.4

    const authCheck = async () => {
        await Auth.currentUserInfo()
            .then((userInfo) => {
                if (userInfo && userInfo.attributes) {
                    setAuthenticated(true)
                } else { setAuthenticated(false) }
            })
    }

    useEffect(() => {
        authCheck();
    }, [])

    const authenticate = () => {
        dispatch(setAuthed({ setAuthed: true, authMethod: 'signUp' }))
    }

    useEffect(() => {
        const optionsSelected = stayType + (electric ? "_electric" : "") + (water ? "_water" : "") + (sewage ? "_sewage" : "") + (deluxe ? "_deluxe" : "")
        const price = {
            vacation: "20",
            vacation_water: "30",
            vacation_sewage: "30",
            vacation_electric: "30",
            vacation_electric_water: "40",
            vacation_water_sewage: "35",
            vacation_electric_sewage: "35",
            vacation_electric_water_sewage: "70",
            vacation_electric_water_sewage_deluxe: "200",

            monthly: "12",
            monthly_water: "15",
            monthly_electric: "15",
            monthly_sewage: "15",
            monthly_electric_water: "20",
            monthly_water_sewage: "20",
            monthly_electric_sewage: "20",
            monthly_electric_water_sewage: "45",
            monthly_electric_water_sewage_deluxe: "60",

            type_yearly: "10",
            type_yearly_water: "15",
            type_yearly_electric: "15",
            type_yearly_sewage: "15",
            type_yearly_electric_water: "18",
            type_yearly_water_sewage: "18",
            type_yearly_electric_sewage: "18",
            type_yearly_electric_water_sewage: "20",
            type_yearly_electric_water_sewage_deluxe: "45",

            storage: "2",
            storage_water: "3",
            storage_electric: "3",
            storage_sewage: "3",
            storage_electric_water: "5",
            storage_electric_sewage: "5",
            storage_water_sewage: "5",
            storage_electric_water_sewage: "8",
            storage_electric_water_sewage_deluxe: "10",
        }
        setNightlyCost(price[optionsSelected]);
    }, [water, electric, sewage, deluxe, stayType]);


    return (
        <ScrollView>
            <View style={[globalStyles.centerColumn, { backgroundColor: "#fff", paddingBottom: 50 }]}>


                {!mobileView && (
                    <>
                        <ImageBackground
                            source={require("../../../assets/icons/Banner-IMG2.svg")}
                            resizeMode="cover"
                            style={styles.image}
                        >
                            <View style={[mobileView ? globalStyles.flexStartColumn : globalStyles.center, { height: 200, width: width }]}>
                                <View style={[globalStyles.flexStartColumn, { width: width / 1.5 }]}>
                                    <View style={[globalStyles.flexStartColumn]}>
                                        <Text style={[globalStyles.shadowedText, { fontSize: 30, fontWeight: "bold", color: "white" }]}>
                                            RENT
                                        </Text>
                                        <Text style={[globalStyles.shadowedText, { fontSize: 25, fontStyle: "italic", color: colors.orange40 }]}>
                                            your
                                        </Text>
                                        <Text style={[globalStyles.shadowedText, { fontSize: 30, fontWeight: "bold", color: "white" }]}>
                                            UNUSED LAND SPACE
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </ImageBackground>
                    </>
                )}
                <HeroHeader
                    headerText={mobileView ?  <><Text style={{ color: colors.orange30, fontSize: 30, fontWeight: 700 }}>Rent</Text> your Unused Land Space</> : ""}
                    bodyText="Free to list your space and it takes less than 5 minutes. Whether you want to offer nightly, longterm or just storage parking- you set your nightly rate and you set the days or seasons that are available. Start hosting and earning today."
                    button={<HostSpaceButton authed={authenticated} navigate={() => navigation.navigate("HostCreateSpaceNavigator")} mobileView={mobileView} width={width} setSignUpModalOpen={() => setSignUpModalOpen(true)}  />}
                />


                {/* Price Calculator */}
                <Header fontWeight="400" marginTop={35} marginBottom={-15} padding={0} textAlign="center" level={!!mobileView ? 1.7 : 2} text={`You Could Earn`} />
                <Header textAlign="center" color={colors.orange40} level={!!mobileView ? 3.5 : 4} text={`$ ${Number(numNights * nightlyCost).toLocaleString("en-US", { maximumFractionDigits: 0 })}`} />
                <Header padding={0} marginTop={-10} textAlign="center" level={!!mobileView ? 1 : 1.3} color={colors.amplifyNeutral60} text={`with your unused land space`} />
                <View style={[globalStyles.centerColumn, { marginTop: 10, width: width / 1.1, marginBottom: 60 }]}>
                    <TextBody style={{ marginTop: mobileView ? 20 : 50, fontSize: 18, textAlign: "center" }}><TextBody style={{ fontWeight: "bold", fontSize: 20, color: colors.orange40 }}>{`${numNights}`}</TextBody> {`night${numNights > 1 ? "s" : ""} at estimated `}<TextBody style={{ fontWeight: "bold", fontSize: 20, color: colors.orange40 }}>$ {nightlyCost} </TextBody>{`a night`}</TextBody>
                    <CustomSlider
                        width={mobileView ? width / 1.1 : width / 2}
                        value={numNights}
                        setValue={(v) => setNumNights(v)}
                    />
                    <View style={[globalStyles.centerColumn, { marginTop: 20 }]}>
                        <SelectStayType
                            setSelectedType={(v) => setStayType(v)}
                            selectedType={stayType}
                            largeVersion={true}
                        />
                        <SelectAmenities
                            width={width}
                            water={water}
                            setWater={(v) => {
                                if (!!deluxe && water) { setDeluxe(false) }
                                setWater(!water)
                            }}
                            electric={electric}
                            setElectric={(v) => {
                                if (!!deluxe && electric) { setDeluxe(false) }
                                setElectric(!electric)
                            }}
                            sewage={sewage}
                            setSewage={(v) => {
                                if (!!deluxe && sewage) { setDeluxe(false) }
                                setSewage(!sewage)
                            }}
                            deluxe={deluxe}
                            setDeluxe={(v) => {
                                if (!deluxe) {
                                    setSewage(true)
                                    setElectric(true)
                                    setWater(true)
                                }
                                setDeluxe(!deluxe)
                            }}
                        />
                        <View>
                            {showExplanation ? (
                                <View style={[globalStyles.centerColumn, { width: width / 1.1 }]}>
                                    <TextBody style={{ fontWeight: "bold", marginBottom: 15 }}>Please note, the above amenity options are not everything you will be able to add to your space. This is a general price calculator. We recommend you still check prices in your local area as you may be able to get more for your property. </TextBody>
                                    <TextBody>Amenities and hook-ups are optional, you can charge more with amenities.</TextBody>
                                    <TextBody>These rates are based on Arizona. We will soon have the option to check your local area's current rates.</TextBody>
                                    <CustomDivider marginVertical={30} width={mobileView ? width / 1.5 : width / 2.5} />
                                    <TextBody style={{ marginBottom: 10 }}><TextBody style={{ fontWeight: "bold" }}>NIGHTLY rate:</TextBody> Basic RV campsites (with basic amenities including water, electric and sometimes sewage) typically cost between $30–$70 per night, while more luxurious ones can cost $80–$200 per night.</TextBody>
                                    <TextBody style={{ marginBottom: 10 }}><TextBody style={{ fontWeight: "bold" }}>LONG TERM rate:</TextBody> Basic RV campsites (with basic amenities including water, electric and sewage) typically cost between $13–$30 per night, while more luxurious ones can cost $35–$55 per night.</TextBody>
                                    <TextBody style={{ marginBottom: 10 }}><TextBody style={{ fontWeight: "bold" }}>* Deluxe:</TextBody> This option refers to a more luxurious space, including extra features and amenities that aren't typical. These can include but are not limited to amenities such as: pool, hottub, gated community, cover for RV/trailer, or bathroom/shower facilities.</TextBody>
                                    <TextBody style={{ marginBottom: 10 }}>Generally, the longer the stay, the lower the nightly rate. For example, minimum month long stays and year long stays. But this is up to your discretion.</TextBody>
                                    <TextBody>This price calculator does not include service fees for platform that will be reduced from your payout. The Hookhub service fee per booking is 10%. Adjust your nightly price so you can make the exact amount you'd like.</TextBody>

                                    <CustomButton hideBorder={true} width={mobileView ? width / 1.5 : width / 2.5} onPress={() => setShowExplanation(false)}><TextBody style={{ textDecorationLine: "underline" }}>hide explanation</TextBody></CustomButton>
                                </View>
                            ) : (<></>)}
                        </View>
                        <View style={[globalStyles.center, { marginBottom: 60, marginTop: 10 }]}>
                            <CustomButton
                                width={mobileView ? width / 2.25 : 200}
                                backgroundColor="#fff"
                                borderColor={colors.orange40}
                                color="#fff"
                                textAlign="center"
                                marginRight={10}
                                height={50}
                                onPress={() => setShowExplanation(true)}
                            >
                                <View style={globalStyles.center}>
                                    <AntDesign name="infocirlceo" size={24} color={colors.orange40} />
                                    <Text style={{ marginLeft: 10, color: colors.orange40, fontWeight: 700, fontSize: 16 }}>View Explanation</Text>
                                </View>
                            </CustomButton>

                            <HostSpaceButton authed={authenticated} navigate={() => navigation.navigate("HostCreateSpaceNavigator")} mobileView={mobileView} width={width} setSignUpModalOpen={() => setSignUpModalOpen(true)}  />
                        </View>

                    </View>
                </View>


                {/* Image: 'Rent out your unused driveway or land space' */}
                {/* <View>
                    {width < 500 ? (
                        <>
                            <Image
                                style={{ width: width / 1.2, height: (width / 1.2) * .8, marginTop: 20 }}
                                source={{ uri: `${cloudfrontURLStaticImages}hoststatement.png` }}
                            />
                        </>
                    ) : (
                        <ImageBackground
                            source={{ uri: `${cloudfrontURLStaticImages}splashScreen2.jpg` }}
                            resizeMode="cover"
                            style={[styles.image, { marginVertical: 25 }]}
                        >
                            <View style={[globalStyles.spaceBetween, { width: width }]}>
                                <Image
                                    style={{ width: width / 3, height: (width / 3), marginLeft: "15%" }}
                                    source={{ uri: `${cloudfrontURLStaticImages}hostlist.png` }}
                                />
                                <Image
                                    style={{ width: width / 3, height: (width / 3), marginRight: "10%" }}
                                    source={{ uri: `${cloudfrontURLStaticImages}hoststatement.png` }}
                                />
                            </View>
                        </ImageBackground>
                    )}
                </View> */}


                {/* Your Security Breakdown */}
                <View>
                    <Header fontWeight={300} level={mobileView ? 3 : 2} text="YOUR" color="#000" padding={0} />
                    <Header marginTop={-10} level={mobileView ? 3 : 2} text="SECURITY" color={colors.orange40} padding={0} />
                    <CustomSection customWidth={customWidth}>
                        <View style={[globalStyles.flexStart, { width: customWidth, padding: 10, paddingTop: 15 }]}>
                            <NumberDisplayOne />
                            <View style={[globalStyles.flexStartColumn, { width: customWidth / 1.35, padding: 15 }]}>
                                <Text style={[globalStyles.boldText, { textAlign: "left", marginBottom: 10 }]}>ID Verify</Text>
                                <Text style={{ fontSize: 17, textAlign: "left" }}>
                                    Identity Verification is required for a renter to make a booking request. We also provide criminal background checks upon request. You will have the chance to review your renters details and speak to them prior to approving or denying to their reservation request.
                                </Text>
                            </View>
                        </View>
                    </CustomSection>
                    <CustomSection customWidth={customWidth}>
                        <View style={[globalStyles.flexStart, { width: customWidth, padding: 10, paddingTop: 15 }]}>
                            <NumberDisplayTwo />
                            <View style={[globalStyles.flexStartColumn, { width: customWidth / 1.35, padding: 15 }]}>
                                <Text style={[globalStyles.boldText, { textAlign: "left", marginBottom: 10 }]}>Secure Payout System</Text>
                                <Text style={{ fontSize: 17, textAlign: "left" }}>
                                    We partner with Stripe to provide a secure payment and payout system. Stripe's infrastructure is designed to ensure the integrity and confidentiality of your data.
                                </Text>
                            </View>
                        </View>
                    </CustomSection>
                    <CustomSection customWidth={customWidth}>
                        <View style={[globalStyles.flexStart, { width: customWidth, padding: 10, paddingTop: 15 }]}>
                            <NumberDisplayThree />
                            <View style={[globalStyles.flexStartColumn, { width: customWidth / 1.35, padding: 15 }]}>
                                <Text style={[globalStyles.boldText, { textAlign: "left", marginBottom: 10 }]}>Your Rules Enforced</Text>
                                <Text style={{ fontSize: 17, textAlign: "left" }}>
                                    Along with Hookhubs' Terms and Conditions, you will set your Rules and Regulations. Any potential renter will be required to agree and electronically provide their signature to make a booking request. If any of your rules are not followed and the renter does not comply after a timely period, the renter will be towed at the renter's expense.
                                </Text>
                            </View>
                        </View>
                    </CustomSection>
                </View>


                {/* Review Display */}
                <View style={{ marginTop: 100, marginBottom: 50 }}>
                    <ReviewDisplay
                        width={width}
                        mobileView={mobileView}
                        reviewerAvatar={"trish.jpg"}
                        reviewerText={`Easy as can be and the support team is always available and ready to help with anything. I've already made over $ 3,000 in less than a year with one space.`}
                        reviewerSignature={"Trish, Host since 2023"}
                    />
                </View>

                {/* CompetitorsChart */}
                <View style={{ marginVertical: 100 }}>
                    <ComparisonToCompetitorsChart />
                </View>


                {/* Image */}
                {width < 500 && (
                    <View style={{ marginBottom: 150, marginTop: 50 }}>
                        <ImageBackground
                            source={{ uri: `${cloudfrontURLStaticImages}splashScreen2.jpg` }}
                            resizeMode="cover"
                            style={[{ width: width, height: "auto" }]}
                        >
                            <View style={[globalStyles.centerColumn, { width: width }]}>
                                <Image
                                    style={{ width: width / 1.2, height: (250), }}
                                    source={{ uri: `${cloudfrontURLStaticImages}hostlist.png` }}
                                />
                            </View>
                        </ImageBackground>
                    </View>
                )}


                {/* Learn More Display */}
                <LearnMore hostViewOnly={true} />


                <View style={{ marginVertical: 100 }}>
                    <HelpForm authed={authenticated} />
                </View>


                <SignUpModal
                    authenticate={(v) => authenticate(v)}
                    modalVisible={signUpModalOpen}
                    setModalVisible={() => setSignUpModalOpen(false)}
                    mobileView={mobileView}
                    width={width}
                />
            </View>
            {!mobileView && <FooterView />}
        </ScrollView >
    );
}

const styles = StyleSheet.create({
    container: {
        margin: 20,
    },
    centeredRow: {
        alignItems: "center",
        justifyContent: "space-between",
        alignSelf: "center",
        flexDirection: "row",
        width: 170
    },
    image: {
        flex: 1,
        justifyContent: "center"
    },
    buttonText: {
        color: colors.amplifyNeutral90,
        fontWeight: "500",
        fontSize: 17,
    },
    helpButton: {
        borderColor: "grey",
        borderRadius: 10,
        width: 100,
        borderWidth: 1,
        backgroundColor: "grey",
        alignItems: "center",
        borderBottomWidth: 2,
        justifyContent: "center",
        alignSelf: "center",
        height: 40,
    },
    view: {
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "center"
    },
});

export default HostSplashScreen;
