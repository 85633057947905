import { View } from 'react-native';
import { colors } from '../../styles/colors';
import Header from './Header';

export const NumberDisplayOne = () => {
    return (
        <View>
            <View style={{ height: 70, width: 70, borderRadius: 100, borderColor: colors.orange40, borderWidth: 2 }} />
            <View style={{ position: "absolute", bottom: -8, right: 13 }}>
                <Header textAlign="center" color={colors.orange40} fontWeight={300} level={3.5} text="1" />
            </View>
        </View>
    );
};

export const NumberDisplayTwo = () => {
    return (
        <View>
            <View style={{ height: 70, width: 70, borderRadius: 100, borderColor: colors.orange40, borderWidth: 2 }} />
            <View style={{ position: "absolute", bottom: -8, right: 7 }}>
                <Header textAlign="center" color={colors.orange40} fontWeight={300} level={3.5} text="2" />
            </View>
        </View>
    );
};

export const NumberDisplayThree = () => {
    return (
        <View>
            <View style={{ height: 70, width: 70, borderRadius: 100, borderColor: colors.orange40, borderWidth: 2 }} />
            <View style={{ position: "absolute", bottom: -8, right: 7 }}>
                <Header textAlign="center" color={colors.orange40} fontWeight={300} level={3.5} text="3" />
            </View>
        </View>
    );
};