import React, { useState, useEffect } from "react";
import { StyleSheet, Text, Pressable, View } from "react-native";
import ContactView from "../space/ContactView";
import { AntDesign, Fontisto } from '@expo/vector-icons';
import { formatMonthDay, formatYear } from "../../utils/formatDate";
import Flex from "../CustomElements/Flex"
import Card from "../CustomElements/Card"
import Header from "../CustomElements/Header"
import { colors } from "../../styles/colors";
import PaymentDatesDisplay from "./PaymentDatesDisplay";
import { getUser } from "../../graphql/queries";
import { API } from "aws-amplify";
import LoadingBar from "../CustomElements/LoadingBar";
import { globalStyles } from "../../styles/styles";
import Badge from "../CustomElements/Badge";

const BookingSummary = ({
    error,
    showBookingRequest = true,
    width,
    cancelled = false,
    cancelledDate = null,
    requestDetails,
    renter = null,
    closeModal,
    mobileView,
    loading,
    loadingPercentage,
    conflictingBooking = false,
    spaceTitle,
    propertyTitle
}) => {
    const {
        hostFeeCost,
        hostPayout,
        spaceCity,
        spaceState,
        spaceID,
        propertyID,
        hostID,
        renterID,
        rentalType,
        check_in,
        check_out,
        payMonthly,
        numberOfMonths,
        nextPaymentsSchedule,
        monthlyHostPayout
    } = requestDetails;
    const [checkIn, setCheckIn] = useState(check_in);
    const [checkOut, setCheckOut] = useState(check_out);
    const [checkInYear, setCheckInYear] = useState(null);
    const [checkOutYear, setCheckOutYear] = useState(null);
    const [feesModalOpen, setFeesModalOpen] = useState(false);
    const [localRenter, setLocalRenter] = useState(renter)
    const [propertySpacesMultipleBoolean, setpropertySpacesMultipleBoolean] = useState(false)
    const stayType = rentalType === 'shortterm' ? 'Vacation' : rentalType === 'longterm' || rentalType === 'yearly' ? 'Long Term' : 'Storage'

    const formatDates = () => {
        const start = formatMonthDay(check_in);
        const startYear = formatYear(check_in);
        const end = cancelled ? formatMonthDay(cancelledDate) : formatMonthDay(check_out);
        const endYear = cancelled ? formatYear(cancelledDate) : formatYear(check_out);
        setCheckIn(start);
        setCheckInYear(startYear);
        setCheckOut(end);
        setCheckOutYear(endYear);
    };

    const getRenter = async () => {
        const user = await API.graphql({
            query: getUser,
            variables: { id: renterID },
            authMode: "API_KEY",
        });
        const data = user.data && user.data.getUser;
        if (data) {
            setLocalRenter(data);
        }
    };

    useEffect(() => {
        formatDates();
    }, []);

    useEffect(() => {
        setpropertySpacesMultipleBoolean(propertyTitle !== spaceTitle)
    }, [])

    useEffect(() => {
        renter === null && getRenter()
    }, []);

    return (
        <View style={globalStyles.centerColumn}>
            <Flex
                justifyContent="flex-start"
                alignItems="flex-start"
                alignContent="flex-start"
                width={!!mobileView ? width / 1.35 : width / 1.55}
            >
                <Badge margin={2} color={colors.grey10} width={180}>
                    <Text style={{ fontSize: 16, lineHeight: 22, color: "black" }}>
                        {showBookingRequest ? 'rental request' : 'scheduled booking'}
                    </Text>
                </Badge>
            </Flex>
            <View style={[globalStyles.centerColumn, { width: !!mobileView ? width / 1.35 : width / 1.55, marginTop: 30 }]}>
                {propertySpacesMultipleBoolean && (<Header textAlign="center" level={1.4} padding={0} text={propertyTitle} />)}
                <Header level={1.2} padding={2} text={`${spaceTitle}`} />
                <View style={[globalStyles.center, {marginTop: 10}]}>
                    <Text style={{ fontSize: 17, marginRight:4 }}>stay type:</Text>
                    <Header padding={0} textAlign="center" level={1} color={colors.amplifyNeutral80} text={stayType} />
                </View>
            </View>
            <Flex
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                width={!!mobileView ? width / 1.35 : width / 1.5}
                marginTop={25}
            >
                <Card
                    backgroundColor={error ? colors.grey20 : colors.orange10}
                    alignItems="center"
                    justifyContent="center"
                    alignContent="center"
                    width={mobileView ? width / 3.2 : width / 4.5}
                    textAlign="center"
                >
                    <Text>{!mobileView && 'CHECK '}IN</Text>
                    <Flex
                        alignItems="center"
                        textAlign="center"
                        justifyContent="center"
                        width={mobileView ? width / 3.2 : width / 4.5}
                        alignContent="center"
                    >
                        <Header textAlign="center" color={colors.amplifyNeutral80} level={1.4} text={checkIn} />
                    </Flex>
                    <Text>{checkInYear}</Text>
                </Card>
                <Card
                    backgroundColor={error ? colors.grey20 : colors.orange10}
                    alignItems="center"
                    width={mobileView ? width / 3.2 : width / 4.5}
                    textAlign="center"
                    border="#4c4e52"
                >
                    <Text>{!mobileView && 'CHECK '}OUT</Text>
                    <Flex
                        alignItems="center"
                        width={mobileView ? width / 3.2 : width / 4.5}
                        textAlign="center"
                        justifyContent="center"
                        alignContent="center"
                    >
                        <Header textAlign="center" color={colors.amplifyNeutral80} level={1.4} text={checkOut} />
                    </Flex>
                    <Text>{checkOutYear}</Text>
                </Card>
            </Flex>
            <View style={[globalStyles.centerColumn, { marginTop: 30, marginBottom: 10 }]}>
                <Text>TOTAL INCOME</Text>
                <View style={globalStyles.center}>
                    <Fontisto name="dollar" size={24} color={colors.amplifyNeutral80} />
                    <Header level={1.75} text={Number(hostPayout).toLocaleString("en-US", { maximumFractionDigits: 2 })} />
                </View>
            </View>
            <View>
                {payMonthly && (
                    <PaymentDatesDisplay
                        width={width}
                        mobileView={mobileView}
                        numberOfMonths={numberOfMonths}
                        monthlyPrice={monthlyHostPayout}
                        hostView={true}
                        stillPaymentsComingBoolean={!!nextPaymentsSchedule.length}
                        paymentDates={nextPaymentsSchedule}
                        checkIn={check_in}
                        formatted={true}
                    />
                )}
            </View>
            {
                (!error && !loading &&
                    <>
                        <Flex alignContent="center" alignItems="center" justifyContent="center">
                            {!loading &&
                                <ContactView
                                    viewWidth={150}
                                    closeModal={() => closeModal()}
                                    userDataPresent={localRenter}
                                    ownerID={hostID}
                                    propertyTitle={propertyTitle}
                                    propertyID={propertyID}
                                    hosteeID={renterID}
                                    spaceID={spaceID}
                                    spaceTitle={spaceTitle}
                                    spaceCity={spaceCity}
                                    spaceState={spaceState}
                                    contactToHost={false}
                                    centerView={true}
                                />
                            }
                        </Flex>
                        {!conflictingBooking && (
                            <>
                                <Flex
                                    justifyContent="center"
                                    alignItems="center"
                                    alignContent="center"
                                    marginTop={25}
                                    marginBottom={40}
                                >
                                    <Pressable onPress={() => setFeesModalOpen(!feesModalOpen)}>
                                        {feesModalOpen ? (
                                            <Flex
                                                justifyContent="center"
                                                alignItems="center"
                                                alignContent="center"
                                                direction="column"
                                                marginBottom={4}
                                                width={mobileView ? width / 1.4 : 400}
                                                wrap="wrap"
                                            >
                                                <AntDesign name="infocirlce" size={24} color="grey" />
                                                <Flex
                                                    direction="column"
                                                    wrap="wrap"
                                                    width={mobileView ? width / 1.4 : 400}
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    alignContent="center"
                                                >
                                                    <Text>hookhub service fee (10%): $ {Number(hostFeeCost).toLocaleString("en-US", { maximumFractionDigits: 2 })}</Text>
                                                    <Header text={`your total income: $ ${Number(hostPayout).toLocaleString("en-US", { maximumFractionDigits: 2 })}`} />
                                                </Flex>
                                            </Flex>
                                        ) : (
                                            <Flex
                                                justifyContent="center"
                                                alignItems="center"
                                                alignContent="center"
                                                width={mobileView ? width / 1.4 : 400}
                                            >
                                                <AntDesign name="infocirlce" size={22} color="grey" />
                                                <Text style={[styles.feeButton, { fontSize: 16 }]}>fees breakdown</Text>
                                            </Flex>
                                        )}
                                    </Pressable>
                                </Flex>
                            </>

                        )}
                    </>
                )
            }
            {
                !!loading && (
                    <LoadingBar loadingStatus={loading} loadingPercentage={loadingPercentage} />
                )
            }
        </View >
    );
};

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    feeButton: {
        textDecorationLine: "underline",
        marginLeft: 5
    },
    progressBar: {
        height: 20,
        marginTop: 10,
        flexDirection: "row",
        width: '100%',
        backgroundColor: 'white',
        borderColor: '#000',
        borderWidth: 2,
        borderRadius: 5
    },
    modalView: {
        marginTop: 10,
        marginBottom: 15,
        backgroundColor: "#c4c4c4",
        borderRadius: 10,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.35,
        shadowRadius: 4,
        elevation: 5,
    },
    textStyle: {
        color: "white",
        fontWeight: "bold",
        textAlign: "center"
    },
    denyTextStyle: {
        color: "#292929",
        textAlign: "center",
        fontWeight: "bold",
    },
    modalText: {
        marginBottom: 15,
        textAlign: "center"
    },
    title: {
        textAlign: "left",
        width: '100%',
        backgroundColor: "#2196F3"
    },
    note: {
        textAlign: "center",
        backgroundColor: "#d6d6d6",
        borderRadius: 200,
        padding: 7
    },
});

export default BookingSummary;