import React from "react";
import { Text, View } from "react-native";
import Badge from "../CustomElements/Badge";
import { Ionicons } from "@expo/vector-icons";
import { colors } from "../../styles/colors";
import { globalStyles } from "../../styles/styles";

const SelectEditSpaceOrProperty = ({
    spaces,
    width,
    mobileView,
    deactiveOrDelete = false,
    setDisplaySpace,
    displaySpace,
    updateProperty,
    setUpdateProperty
}) => {

    return (
        <View style={[globalStyles.centerColumn, { marginTop: 20, padding: 10 }]}>
            <Text style={{ textAlign: "center", fontSize: 16, marginBottom: 10 }}>{deactiveOrDelete ? "Which are you deactiviating or deleting?" : "What needs updating?"}</Text>
            <View>
                <Badge justifyContent="flex-start" onPress={() => setUpdateProperty(true)} width={mobileView ? width / 1.5 : width / 3} margin={2} color={updateProperty ? colors.orange40 : colors.grey10}>
                    <View style={[globalStyles.flexStart, { alignItems: "center" }]}>
                        <Ionicons name={updateProperty ? "radio-button-on-sharp" : "radio-button-off-sharp"} size={24} color={updateProperty ? colors.amplifyNeutral90 : colors.amplifyNeutral80} />
                        <Text style={{ marginLeft: 10 }}>The entire property</Text>
                    </View>
                </Badge>
                {
                    updateProperty ? (
                        <Badge justifyContent="flex-start" onPress={() => setUpdateProperty(false)} width={mobileView ? width / 1.5 : width / 3} margin={2} color={!updateProperty ? colors.orange40 : colors.grey10}>
                            <View style={[globalStyles.flexStart, { alignItems: "center" }]}>
                                <Ionicons name={!updateProperty ? "radio-button-on-sharp" : "radio-button-off-sharp"} size={24} color={!updateProperty ? colors.amplifyNeutral90 : colors.amplifyNeutral80} />
                                <Text style={{ marginLeft: 10 }}>A specific space</Text>
                            </View>
                        </Badge>
                    ) : (
                        <View style={{ marginTop: 15 }}>
                            {(spaces).map((v, i) => (
                                <Badge justifyContent="flex-start" key={i} onPress={() => setDisplaySpace(v)} width={mobileView ? width / 1.4 : width / 3} margin={2} color={displaySpace.id === v.id ? colors.orange40 : colors.grey10}>
                                    <View style={[globalStyles.flexStart, { alignItems: "center" }]}>
                                        <Ionicons name={displaySpace.id === v.id ? "radio-button-on-sharp" : "radio-button-off-sharp"} size={24} color={displaySpace.id === v.id ? colors.amplifyNeutral90 : colors.amplifyNeutral80} />
                                        <Text style={{ marginLeft: 10 }}>
                                            {v.title}
                                        </Text>
                                    </View>
                                </Badge>
                            ))}
                        </View>
                    )
                }
            </View>
        </View>
    );
};

export default SelectEditSpaceOrProperty;
