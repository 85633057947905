import React, { useEffect, useState } from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
  Platform,
  ImageBackground,
  Image
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import PropertiesGrid from "../components/space/PropertiesGrid";
import { Ionicons } from "@expo/vector-icons";
import Geocode from "react-geocode";
import getEnvVars from '../../environment';
const { geocodeAPIkey } = getEnvVars();
import { GestureHandlerRootView, ScrollView } from 'react-native-gesture-handler';
Geocode.setApiKey(geocodeAPIkey);
import { setNotificationViewed, setSpaceStayType } from "../redux/actions/CurrentUserActions";
import Badge from "../components/CustomElements/Badge";
import AllSpacesMapView from "../components/map/AllSpacesMapView";
import { useNavigation } from "@react-navigation/native";
import LocationSearch from "../components/search/LocationSearch";
import FooterView from "../components/other/FooterView";
import { globalStyles } from "../styles/styles";
import { LoadingComponent } from '../components/LoadingComponent';
import WebPullToRefresh from '../components/other/WebPullToRefresh';
import FilterOptions from '../components/search/FilterOptions';
import { listPropertiesByInternalRatingQuery } from '../api/property';
import SwipeableView from '../components/other/SwipeableView';
import { colors } from '../styles/colors';
import { HomeScreenHeroCard } from '../components/other/HomeScreenHeroCard';
import CustomButton from '../components/CustomElements/CustomButton';
const { cloudfrontURLStaticImages } = getEnvVars();

const HomeScreenBody = ({ loading, setLoading, refresh = true, setRefresh = null }) => {
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const mobilePlatform = Platform.OS !== 'web';
  const lakesideLatLng = { lat: 34.1425438, lng: -109.9603843 };
  const { stayType, mobileView, notifyOfNationwide } = useSelector(state => state.currentUser);
  const [coordinates, setCoordinates] = useState({});
  const [properties, setProperties] = useState([]);
  const [showMap, setShowMap] = useState(mobilePlatform);
  const [selectedPlace, setSelectedPlace] = useState({});
  const [newLocationSearched, setnewLocationSearched] = useState('');
  const [searchInput, setSearchInput] = useState(false);
  const [loadingDisplay, setLoadingDisplay] = useState(true);
  const [amazingView, setAmazingView] = useState(false)
  const [electricAndWater, setElectricAndWater] = useState(false)
  const [shadedSite, setShadedSite] = useState(false)
  const [petsAllowed, setPetsAllowed] = useState(false)

  const listProperties = async () => {
    setLoadingDisplay(true)
    setSelectedPlace({})
    let filterData = {
      active: { eq: true },
      approved: { eq: true }
    };
    let spaceFilterData = {
      active: { eq: true }
    };
    if (!!coordinates && !!coordinates.lat) {
      const { lat, lng } = coordinates;
      const latRange = lat < 0 ? [lat + 1, lat - 1] : [lat - 1, lat + 1];
      const lngRange = lng < 0 ? [lng + 1, lng - 1] : [lng - 1, lng + 1];
      filterData['coordinates_lat'] = { between: latRange };
      filterData['coordinates_lng'] = { between: lngRange };
    }
    if (petsAllowed) {
      spaceFilterData['pets_allowed'] = { eq: true };
    }
    if (electricAndWater) {
      spaceFilterData['water_hookup'] = { eq: true };
      spaceFilterData['electric_hookup'] = { eq: true };
    }
    if (shadedSite) {
      spaceFilterData['shade_cover'] = { eq: true };
    }
    if (amazingView) {
      spaceFilterData['amazing_view'] = { eq: true };
    }
    if (stayType === 'vacation') {
      filterData['type_shortterm'] = { eq: true };
    } else if (stayType === 'monthly') {
      filterData['type_longterm'] = { eq: true };
    } else if (stayType === 'type_yearly') {
      filterData['type_yearly'] = { eq: true };
    } else if (stayType === 'storage') {
      filterData['type_storage'] = { eq: true };
    }

    const propertiesArr = await listPropertiesByInternalRatingQuery({ filterData, spaceFilterData });
    if (propertiesArr) {
      setProperties(propertiesArr);
    } else {
      //   if (!!coordinates && !!coordinates.lat) {
      //     const { lat, lng } = coordinates;
      //     const latRange = lat < 0 ? [lat - 1, lat + 1] : [lat - 1, lat + 1];
      //     const lngRange = lng < 0 ? [lng + 1, lng - 1] : [lng + 1, lng - 1];
      //     filterData['coordinates_lat'] = { between: latRange };
      //     filterData['coordinates_lng'] = { between: lngRange };
      //     await API.graphql({
      //       query: listPropertiesQuery,
      //       variables: {
      //         filter: filterData
      //       },
      //       authMode: "API_KEY",
      //     })
      //   }
    }
    setLoading(false);
    if (!mobilePlatform) {
      setRefresh(false);
      setTimeout(() => setLoadingDisplay(false), 500)
    } else {
      setLoadingDisplay(false)
    }
  };

  const newCenter = (v) => {
    setCoordinates(v);
  };

  const clearFilterOrLocation = () => {
    dispatch(setSpaceStayType({ stayType: undefined }));
    setSelectedPlace({})
    setPetsAllowed(false)
    setSearchInput(false)
    setCoordinates(lakesideLatLng)
    listProperties(lakesideLatLng)
  };

  const goToPropertyPage = (property) => {
    navigation.navigate("space", {
      propertyID: property.id
    })
  };

  const setStayType = (v) => {
    dispatch(setSpaceStayType({ stayType: v }));
  };

  const closeNotification = () => {
    dispatch(setNotificationViewed({ notifyOfNationwide: false }));
  };

  const goToHostSplash = () => {
    closeNotification();
    navigation.navigate("host")
  }

  useEffect(() => {
    listProperties();
  }, [stayType, coordinates, refresh, petsAllowed, electricAndWater, shadedSite, amazingView]);

  return (
    <>
      {loading ? (<View style={{ marginTop: 200 }}><LoadingComponent /></View>) : (
        <>
          {mobilePlatform ? (
            <GestureHandlerRootView style={{ flex: 1, backgroundColor: colors.grey10 }}>
              <View style={[globalStyles.center, styles.backgroundblock, { paddingTop: 10, marginBottom: 0, flexWrap: "wrap", position: "absolute", top: 0, width: width, }]}>
                <LocationSearch mobileView={mobileView} searchInput={searchInput} setSearchInput={() => setSearchInput(!searchInput)} clearLocation={() => clearFilterOrLocation()} setCoordinates={(v) => setCoordinates(v)} setnewLocationSearched={(v) => setnewLocationSearched(v)} />
              </View>
              <AllSpacesMapView
                coordinates={coordinates}
                properties={properties}
                selectedPlace={selectedPlace}
                setSelectedPlace={(v) => setSelectedPlace(v)}
                updateSpacesShowing={(v) => newCenter(v)}
                goToSpacePage={(v) => goToPropertyPage(v)}
                newLocationSearched={newLocationSearched}
              />
              <SwipeableView>
                <View style={[globalStyles.center, { marginBottom: -20, marginTop: 0 }]}>
                  <ScrollView>
                    {!!properties.length ? (
                      <PropertiesGrid properties={properties} mobileView={mobileView} />
                    ) : (
                      <View style={[globalStyles.centerColumn, { marginBottom: 250 }]}>
                        <Text style={{ marginVertical: 25, marginHorizontal: 10, fontSize: 17, textAlign: "center" }}>Oh no! No spaces here yet, but more are popping up everyday.</Text>
                        <Pressable style={{ width: 150, height: 35, margin: 3 }}>
                          <Badge margin={0} onPress={() => clearFilterOrLocation()}>
                            <Text style={styles.fontSize}>clear search</Text>
                          </Badge>
                        </Pressable>
                      </View>
                    )}
                    <FooterView />
                  </ScrollView>
                </View>
              </SwipeableView>
            </GestureHandlerRootView>
          ) : (
            <ScrollView>
              <View style={[globalStyles.centerColumn, { backgroundColor: colors.grey10 }]}>
                {!mobileView ? (
                  <View>
                    <ImageBackground
                      source={require("../../assets/images/Banner-IMG.png")}
                      resizeMode="cover"
                      style={[styles.image, { height: height / 1.15, width: width }]}
                    >
                      <HomeScreenHeroCard
                        width={width}
                        goToHostSplash={() => goToHostSplash()}
                        height={height}
                        searchInput={searchInput}
                        clearLocation={() => clearFilterOrLocation()}
                        setCoordinates={(v) => setCoordinates(v)}
                        setnewLocationSearched={(v) => setnewLocationSearched(v)}
                        loading={loading}
                        properties={properties}
                        stayType={stayType}
                        setStayType={(v) => setStayType(v)}
                        mobileView={mobileView}
                        showMap={showMap}
                        setShowMap={() => setShowMap(!showMap)}
                        amazingView={amazingView}
                        setAmazingView={() => setAmazingView(!amazingView)}
                        electricAndWater={electricAndWater}
                        setElectricAndWater={() => setElectricAndWater(!electricAndWater)}
                        shadedSite={shadedSite}
                        setShadedSite={() => setShadedSite(!shadedSite)}
                        petsAllowed={petsAllowed}
                        setPetsAllowed={() => setPetsAllowed(!petsAllowed)}
                      />
                    </ImageBackground>
                  </View>
                ) : (
                  <View style={globalStyles.center}>
                    <FilterOptions
                      width={width / 1.05}
                      stayType={stayType}
                      setStayType={(v) => setStayType(v)}
                      mobileView={mobileView}
                      showMap={showMap}
                      setShowMap={() => setShowMap(!showMap)}
                      amazingView={amazingView}
                      setAmazingView={() => setAmazingView(!amazingView)}
                      electricAndWater={electricAndWater}
                      setElectricAndWater={() => setElectricAndWater(!electricAndWater)}
                      shadedSite={shadedSite}
                      setShadedSite={() => setShadedSite(!shadedSite)}
                      petsAllowed={petsAllowed}
                      setPetsAllowed={() => setPetsAllowed(!petsAllowed)}
                      clearLocation={() => clearFilterOrLocation()}
                      loading={loading}
                      goToHostSplash={() => goToHostSplash()}
                      height={height}
                      searchInput={searchInput}
                      setCoordinates={(v) => setCoordinates(v)}
                      setnewLocationSearched={(v) => setnewLocationSearched(v)}
                      properties={properties}
                    />
                  </View>
                )}
                {showMap &&
                  <View style={globalStyles.center} >
                    <AllSpacesMapView
                      coordinates={coordinates}
                      properties={properties}
                      selectedPlace={selectedPlace}
                      setSelectedPlace={(v) => setSelectedPlace(v)}
                      updateSpacesShowing={(v) => newCenter(v)}
                      width={width}
                      height={height}
                      goToSpacePage={(v) => goToPropertyPage(v)}
                      newLocationSearched={newLocationSearched}
                    />
                  </View>
                }
                {!loading && (
                  <View style={[globalStyles.center, { marginBottom: 20, marginTop: -10 }]}>
                    {!!properties.length ? (
                      <PropertiesGrid properties={properties} mobileView={mobileView} showCardBackground={true} />
                    ) : (
                      <View style={[globalStyles.centerColumn, { width: width / 1.1, marginBottom: 150 }]}>
                        <Text style={{ marginTop: 150, marginBottom: 50, fontSize: 17, textAlign: "center" }}>Oh no! No spaces here yet, but more are popping up everyday.</Text>
                        <CustomButton onPress={() => clearFilterOrLocation()} width={200}>
                          <Text style={styles.fontSize}>clear search</Text>
                        </CustomButton>
                      </View>
                    )}
                  </View>
                )}
                {! mobileView ? <FooterView /> : <></> }
              </View>
            </ScrollView>
          )}
        </>
      )}
    </>
  )
}

function HomeScreen() {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);

  return Platform.OS === 'web' ? (
    <View style={{ flex: 1, alignItems: 'center' }}>
      <WebPullToRefresh setRefresh={(v) => setRefresh(v)}>
        <HomeScreenBody loading={loading} setLoading={(v) => setLoading(v)} refresh={refresh} setRefresh={(v) => setRefresh(v)} />
      </WebPullToRefresh>
    </View>
  ) : (<HomeScreenBody loading={loading} setLoading={(v) => setLoading(v)} />)
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  image: {
    flex: 1,
  },
  backgroundblock: {
    backgroundColor: colors.white,
    height: 80,
    top: 0,
    zIndex: 1
  },
  fontSize: {
    fontSize: 15,
    paddingBottom: 2
  }
});

export default HomeScreen;
