import React, { useState } from 'react';
import {
    Dimensions,
    Text,
    Pressable
} from 'react-native';

const CustomButtonUnderlined = ({
    children,
    onPress,
    disabled = false,
    width = Dimensions.get('window').width - 100,
    height = 40,
    padding = 0,
    marginBottom = 1,
    marginRight = 1,
    marginLeft = 1,
    marginTop = 1,
    textAlign = "center",
    alignItems = "center"
}) => {
    const [onHover, setOnHover] = useState(false)

    return (
        <Pressable
            onPress={() => onPress()}
            onHoverIn={() => setOnHover(true)}
            onHoverOut={() => setOnHover(false)}
            disabled={disabled}
            style={{
                marginLeft: marginLeft,
                marginRight: marginRight,
                marginBottom: marginBottom,
                marginTop: marginTop,
                padding: padding,
                textAlign: textAlign,
                alignItems: alignItems,
                width: width,
                height: height,
                alignItems: alignItems,
                justifyContent: "center",
                alignContent: "center",
                textAlign: "center",
            }}
        >
            <Text>

                {children}
            </Text>
        </Pressable>
    )

}

export default CustomButtonUnderlined;