import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigation } from "@react-navigation/native";
import { UserDataContext } from "../../utils/UserContextProvider";
import { Auth } from "aws-amplify";
import { DrawerContentScrollView, DrawerItem, DrawerItemList } from "@react-navigation/drawer";
import { RESETAPP } from "../../redux/reducers/reducers";
import CustomButton from "./CustomButton";
import { Image, Text, useWindowDimensions } from "react-native";
import FooterView from "../other/FooterView";
import { globalStyles } from "../../styles/styles";
import { View } from "react-native";


function UnauthedDrawer(props) {
  const navigation = useNavigation();

  return (
    <DrawerContentScrollView {...props}>
      <DrawerItemList {...props} />
      <DrawerItem label={() => {
        return (
          <View style={[globalStyles.center, { marginBottom: -10, marginTop: -14 }]}>
            <CustomButton onPress={() => props.authenticate('login')} width={"115%"} alignItems="flex-start">
              <View style={globalStyles.center}>
                <Image
                  style={{ width: 27, height: 27, marginHorizontal: 10 }}
                  source={require("../../../assets/icons/login.svg")}
                />
                <Text>Login</Text>
              </View>
            </CustomButton>
          </View>
        )
      }} />
      <DrawerItem label={() => {
        return (
          <View style={[globalStyles.center, { marginTop: -15 }]}>
            <CustomButton onPress={() => props.authenticate('signUp')} width={"115%"} alignItems="flex-start">
              <View style={globalStyles.center}>
                <Image
                  style={{ width: 27, height: 27, marginHorizontal: 10 }}
                  source={require("../../../assets/icons/sign-up.svg")}
                />
                <Text>Sign Up</Text>
              </View>
            </CustomButton>
          </View>
        )
      }} />
      <DrawerItem
        label="Host Your Space"
        onPress={() => navigation.navigate("host")}
        style={{ marginTop: 0 }}
      />
      <DrawerItem
        label="Learn More"
        onPress={() => navigation.navigate("about")}
        style={{ marginTop: -10 }}
      />
      <DrawerItem
        label="Help"
        onPress={() => navigation.navigate("help")}
        style={{ marginTop: -10 }}
      />
      <DrawerItem label={() => {
        return (
          <View style={{ position: "fixed", bottom: 30 }}>
            <FooterView />
          </View>
        )
      }}
      >
      </DrawerItem>
    </DrawerContentScrollView>
  );
};

function AuthedDrawer(props) {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { user } = useContext(UserDataContext);
  const { setUserData } = user;
  const { conversations } = useSelector((state) => state.conversations);
  const clearUserData = () => setUserData({});
  const { width } = useWindowDimensions();
  const mobileView = width < 650;

  function logout() {
    clearUserData();
    dispatch(RESETAPP());
    Auth.signOut();
  }

  return (
    <DrawerContentScrollView {...props}>
      <DrawerItemList {...props} />
      <DrawerItem
        label="Host Your Space"
        onPress={() => navigation.navigate("host")}
        style={{ marginTop: -30 }}
      />
      <DrawerItem
        label="Learn More"
        onPress={() => navigation.navigate("about")}
        style={{ marginTop: -10 }}
      />
      <DrawerItem
        label="Help"
        onPress={() => navigation.navigate("help")}
        style={{ marginTop: -10, marginBottom: -30 }}
      />
      <DrawerItem label={() => {
        return (
          <View style={{ position: "fixed", bottom: 60 }}>
            {mobileView ? <FooterView /> : <></>}
          </View>
        )
      }}
      >
      </DrawerItem>
      <DrawerItem label={() => {
        return (
          <View style={[globalStyles.center, { position: "fixed", bottom: 10 }]}>
            <CustomButton onPress={logout} width={200} marginLeft={20}>
              <Text>Sign Out</Text>
            </CustomButton>
          </View>
        )
      }}>
      </DrawerItem>

    </DrawerContentScrollView>
  );
};

export function CustomDrawerContent(props) {
  return (
    <>
      {props.isAuthed ? <AuthedDrawer {...props} /> : <UnauthedDrawer {...props} authenticate={(v) => props.authenticate(v)} />}
    </>
  );
};