import {
  StyleSheet,
  Text,
  useWindowDimensions,
  View
} from "react-native";
import { Feather } from '@expo/vector-icons';
import { useNavigation } from "@react-navigation/native";
import Header from "../CustomElements/Header"
import { useDispatch, useSelector } from "react-redux";
import { colors } from "../../styles/colors";
import { clearSpaceDetails } from "../../redux/actions/HostOnboardingActions";
import CustomButton from "../CustomElements/CustomButton";
import { globalStyles } from "../../styles/styles";
import CustomModal from "../CustomElements/CustomModal";
import VerifyNeededForPropertyApprovalCard from "../host/VerifyNeededForPropertyApprovalCard";

export const SuccessCreatingPropertyModal = ({ modalVisible, setModalVisible, userVerified }) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { mobileView } = useSelector(state => state.currentUser);

  const goToNextSteps = () => {
    navigation.navigate("hostaccount", {
      screen: "My Hosting"
    });
    dispatch(clearSpaceDetails());
    setModalVisible();
  }

  return (
    <CustomModal modalVisible={modalVisible} setModalVisible={() => setModalVisible()} modalWidth={width / 1.15}>
      <Header level={mobileView ? 1.4 : 2} text="Success!"></Header>
      <Text>Your space has been created.</Text>
      <View style={[mobileView ? globalStyles.center : globalStyles.centerColumn, { marginTop: 25 }]}>
        {!userVerified ? (<VerifyNeededForPropertyApprovalCard action={<Text style={{textAlign: "center"}}>Complete this by going to Next Steps below.</Text>}/>) : <></>}
      </View>
      <CustomButton
        width={160}
        backgroundColor={colors.orange50}
        marginTop={50}
        marginBottom={40}
        border={colors.amplifyNeutral90}
        onPress={() => goToNextSteps()}
      >
        <Text style={styles.forwardButtonText}>Next Steps</Text>
      </CustomButton>
      <View style={[globalStyles.center, { width: width / 1.25 }]}>
        <Feather name="info" size={24} color="#696969" />
        <Text style={styles.infoText}>
          IMPORTANT: Hoster obligations: You are responsible for being
          aware of your property laws and limitations and responsible
          for any income or other taxes. Taking transactions off the Hookhub platform
          for any current or future bookings is strictly prohibited.
          Terms & Conditions can be found at the bottom of the screen or
          the bottom of the Learn More screen on mobile, labelled "Terms".
        </Text>
      </View>
    </CustomModal >
  );
};

const styles = StyleSheet.create({
  infoText: {
    color: "#696969",
    marginLeft: 10
  }
});
