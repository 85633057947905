import { useEffect, useState } from "react";
import { ScrollView, View } from "react-native";
import LearnMore from "../../components/LearnMore";
import BackButton from "../../components/other/BackButton";
import HelpForm from "../../components/other/HelpForm";
import FooterView from "../../components/other/FooterView";
import { Auth } from "aws-amplify";
import { useSelector } from "react-redux";

export default function HelpScreen() {
  const [authed, setAuthed] = useState(false)
  const { mobileView } = useSelector(state => state.currentUser);

  const authCheck = async () => {
    await Auth.currentUserInfo()
      .then((userInfo) => {
        if (userInfo && userInfo.attributes) {
          setAuthed(true)
        } else { setAuthed(false) }
      })
  }

  useEffect(() => {
    authCheck();
  }, [])

  return (
    <ScrollView>
      <BackButton goBack={false} />
      <LearnMore />
      <View style={{ marginTop: 200 }}>
        <HelpForm authed={authed} />
      </View>
      {!mobileView && <FooterView />}
    </ScrollView>
  );
}
