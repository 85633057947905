import {
    View,
    Image,
    Text,
} from 'react-native';
import TextBody from '../CustomElements/TextBody';
import { globalStyles } from '../../styles/styles';
import getEnvVars from '../../../environment';
import Card from '../CustomElements/Card';
import { colors } from '../../styles/colors';
import { useState } from 'react';
import OrangeUnderlineHeader from './OrangeUnderlineHeader';
import CustomCarouselDisplay from '../CustomElements/CustomCarouselDisplay';

export const ReviewDisplay = ({
    reviewerAvatar,
    reviewerText,
    reviewerSignature,
    width,
    mobileView
}) => {
    const { cloudfrontURLStaticImages } = getEnvVars();
    const cardWidth = mobileView ? width / 1.35 : width / 4;

    return (
        <View style={[globalStyles.centerColumn]}>
            <Card backgroundColor={colors.orange10} width={cardWidth} borderRadius={20}>
                <View style={globalStyles.spaceBetweenColumn}>
                    <View style={{ marginLeft: 15 }}>
                        <Image style={{ width: 20, height: 20 }} source={require("../../../assets/icons/quote.svg")} />
                        <TextBody style={{ width: cardWidth - 70, fontStyle: "italic", fontSize: 19 }}>{reviewerText}</TextBody>
                    </View>
                    <View style={[globalStyles.flexEnd, {}]}>
                        <TextBody>-{reviewerSignature}</TextBody>
                        {reviewerAvatar && (
                            <Image
                                style={{ width: 65, height: (65), margin: mobileView ? 2 : 5, borderRadius: 100 }}
                                source={{ uri: `${cloudfrontURLStaticImages}${reviewerAvatar}` }}
                            />
                        )}
                    </View>
                </View>
            </Card>
        </View>
    )
}


export const ReviewDisplaySection = ({ width, mobileView }) => {
    const allReviews = [
        {
            reviewerText: "Finding unique places to park our RV has never been easier. HookHub offers such incredible locations that we would have never found on our own.",
            reviewerSignature: "Sarah, Renter"
        }, {

            reviewerText: "I like the location due to its proximity to Show Low and Pinetop-Lakeside.  The park is quiet  and I love all the trees. Trish has been great and has made our stay here wonderful. We are so happy to have found this park!",
            reviewerSignature: "Adria, Renter",
            reviewerAvatar: "adriaAvatar.jpg"
        }, {
            reviewerText: "Easy as can be and the support team is always available and ready to help with anything. I've already made over $ 3,000 in less than a year with one space.",
            reviewerSignature: "Trish, Host",
            reviewerAvatar: "trish.jpg"
        }, {
            reviewerText: "My husband and I recently went on a two-week vacation across several states with our RV. Initially, we had difficulty finding a site, but then we discovered the Hookup app, which made it easy to find several great places to stay. One site, in particular, stood out—thanks to the amazing camp host and the beautiful, serene environment. It was so peaceful and relaxing that we ended up extending our stay for a couple of extra days just to soak in the serenity. Highly recommend!",
            reviewerSignature: "Jen, Renter",
        }, {
            reviewerText: "Hookhub is the best app we’ve used! My friends and I have been traveling quite a bit this summer and due to age restrictions it’s hard to find somewhere to park for the night being in our early 20’s. As soon as we downloaded the app we felt so much relief seeing how many places we could stay! All of our hosts were wonderful and they definitely made it such more of an enjoyable experience for us! I will recommend Hook Hub to anyone I know.",
            reviewerSignature: "Kylie, Renter",
        }, {
            reviewerText: "Absolutely love this app made me finding a spot to camp and settle down so much easier I’ve never been so happy with an app 👍",
            reviewerSignature: "Alex, Renter"
        }
    ]
    const [displayReview, setDisplayReview] = useState(mobileView ? 2 : 0);

    return (
        <View style={[globalStyles.centerColumn, { width: width / 1.1 }]}>
            <Text style={[globalStyles.boldText, { textAlign: "left", marginBottom: 10, }]}>What Our Renters & Hosts Say About Us</Text>
            <OrangeUnderlineHeader />
            <View style={[globalStyles.flexStart, { marginTop: 20, flexWrap: "wrap", width: width }]}>
                {mobileView ? (
                    <CustomCarouselDisplay width={width} navLeft={() => setDisplayReview(displayReview === 0 ? allReviews.length-1 : displayReview - 1)} navRight={() => setDisplayReview(displayReview === allReviews.length-1 ? 0 : displayReview + 1)}>
                        <ReviewDisplay
                            mobileView={mobileView}
                            width={width}
                            reviewerAvatar={allReviews[displayReview]?.reviewerAvatar}
                            reviewerText={allReviews[displayReview]?.reviewerText}
                            reviewerSignature={allReviews[displayReview]?.reviewerSignature}
                        />
                    </CustomCarouselDisplay>
                ) : (
                    <>
                        <CustomCarouselDisplay width={width} navLeft={() => setDisplayReview(displayReview === 0 ? allReviews.length + 1 : displayReview - 1)} navRight={() => setDisplayReview(displayReview === allReviews.length - 3 ? 0 : displayReview + 1)}>
                            <View style={globalStyles.flexStart}>
                                <ReviewDisplay
                                    mobileView={mobileView}
                                    width={width}
                                    reviewerAvatar={allReviews[displayReview]?.reviewerAvatar}
                                    reviewerText={allReviews[displayReview]?.reviewerText}
                                    reviewerSignature={allReviews[displayReview]?.reviewerSignature}
                                />
                                <ReviewDisplay
                                    mobileView={mobileView}
                                    width={width}
                                    reviewerAvatar={allReviews[displayReview + 1]?.reviewerAvatar}
                                    reviewerText={allReviews[displayReview + 1]?.reviewerText}
                                    reviewerSignature={allReviews[displayReview + 1]?.reviewerSignature}
                                />
                                <ReviewDisplay
                                    mobileView={mobileView}
                                    width={width}
                                    reviewerAvatar={allReviews[displayReview + 2]?.reviewerAvatar}
                                    reviewerText={allReviews[displayReview + 2]?.reviewerText}
                                    reviewerSignature={allReviews[displayReview + 2]?.reviewerSignature}
                                />
                            </View>
                        </CustomCarouselDisplay>
                    </>
                )}
            </View>
        </View>
    )
}
