import { NavigationContainer, useNavigation } from "@react-navigation/native";
import {
  View,
  StyleSheet,
  Image,
  Pressable,
  useWindowDimensions,
  Text,
} from "react-native";
import {
  AntDesign
} from "@expo/vector-icons";
import * as Linking from "expo-linking";
import HelpScreen from "../screens/info/HelpScreen";
import AboutScreen from "../screens/info/AboutScreen";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { colors } from "../styles/colors";
import { CustomDrawerContent } from "../components/CustomElements/CustomDrawerContent";
import HostSplashScreen from "../screens/info/HostSplashScreen";
import SecurityScreen from "../screens/info/SecurityScreen";
import { globalStyles } from "../styles/styles";
import { createStackNavigator } from "@react-navigation/stack";
import CustomButton from "../components/CustomElements/CustomButton";
import HomeScreen from "../screens/HomeScreen";
import PropertyScreen from "../screens/space/PropertyScreen";

const headerRight = ({ authenticate }) => {
  return (
    <View style={styles.headerRight}>
      <Pressable onPress={() => authenticate()}>
        <Image
          style={{ width: 38, height: 38 }}
          source={require("../../assets/icons/user-account.svg")}
        />
      </Pressable>
    </View>
  )
};

const UnauthNavigatorMobileView = ({ authenticate }) => {
  const Drawer = createDrawerNavigator();

  return (
    <Drawer.Navigator
      useLegacyImplementation={false}
      backBehavior="history"
      defaultStatus="closed"
      drawerContent={(props) => <CustomDrawerContent {...props} isAuthed={false} authenticate={(v) => authenticate(v)} />}
      screenOptions={({ navigation }) => ({
        swipeEdgeWidth: 0,
        swipeEnabled: false,
        title: "",
        drawerStyle: { backgroundColor: colors.grey10, marginTop: 15, borderTopRightRadius: 10 },
        drawerActiveTintColor: colors.orange80,
        headerLeft: ({ focused, size }) => (
          <View style={styles.headerRight}>
            <Pressable
              style={styles.drawerButton}
              onPress={() => navigation.openDrawer()}
            >
              <Image
                style={{ width: 30, height: 30, marginLeft: 10 }}
                source={require("../../assets/icons/burger-icon.svg")}
              />
            </Pressable>
            <Pressable
              onPress={() => navigation.reset({ routes: [{ name: 'explore' }] })}
            >
              <Image
                style={{ width: 163.35, height: 42.35, marginLeft: 10 }}
                source={require("../../assets/images/hookhub-logo-orange-mini.png")}
              />
            </Pressable>
          </View>
        ),
        headerRight: ({ focused }) => headerRight({ authenticate: () => authenticate() })
      })}
    >
      <Drawer.Screen
        name="explore"
        headerShown={false}
        component={HomeScreen}
        options={{
          drawerIcon: ({ focused, size }) => (
            <View style={[globalStyles.center, { borderBottomColor: colors.orange30 }]}>
              <AntDesign name="close" size={35} color={colors.amplifyNeutral60} style={{ marginRight: 170 }} />
              <Image
                style={styles.miniLogoImage}
                source={require("../../assets/images/favicon.png")}
              />
            </View>
          ),
          drawerActiveBackgroundColor: colors.grey10,
          drawerInactiveBackgroundColor: colors.white,
        }}
      />
      <Drawer.Screen
        component={PropertyScreen}
        name="space"
        options={{ drawerItemStyle: { height: 0 } }}
      />
      <Drawer.Screen
        name="about"
        component={AboutScreen}
        options={{ drawerItemStyle: { height: 0 } }}
      />
      <Drawer.Screen
        name="help"
        component={HelpScreen}
        options={{ drawerItemStyle: { height: 0 } }}
      />
      <Drawer.Screen
        name="host"
        component={HostSplashScreen}
        options={{ drawerItemStyle: { height: 0 } }}
      />
      <Drawer.Screen
        name="security"
        component={SecurityScreen}
        options={{ drawerItemStyle: { height: 0 } }}
      />
    </Drawer.Navigator>
  )
}

const UnauthNavigator = ({ authenticate }) => {
  const Stack = createStackNavigator();
  const navigation = useNavigation();

  return (
    <Stack.Navigator
      screenOptions={{
        title: "",
        headerRight: () => (
          <View style={globalStyles.center}>
            <CustomButton onPress={() => navigation.navigate("about")} hideBorder={true} marginLeft={15} marginRight={10} width={100}>
              <Text>LEARN MORE</Text>
            </CustomButton>
            <CustomButton onPress={() => navigation.navigate("host")} hideBorder={true} marginLeft={15} marginRight={10} width={120}>
              <Text>HOST MY SPACE</Text>
            </CustomButton>
            <CustomButton onPress={() => authenticate('signUp')} hideBorder={true} marginLeft={15} marginRight={10} width={70}>
              <Text>SIGN-UP</Text>
            </CustomButton>
            <CustomButton onPress={() => authenticate('login')} hideBorder={true} marginLeft={15} marginRight={20} width={60}>
              <Text>LOGIN</Text>
            </CustomButton>
          </View>
        ),
        headerLeft: () => (
          <Pressable onPress={() => navigation.navigate("explore")}>
            <Image
              style={{ width: 150, height: 38.86, marginLeft: 15 }}
              source={require("../../assets/images/hookhub-logo-orange-mini.png")}
            />
          </Pressable>
        ),
      }}

    >
      <Stack.Screen
        name="explore"
        headerShown={false}
        component={HomeScreen}
        options={{
          drawerIcon: ({ focused, size }) => (
            <View style={[globalStyles.center, { borderBottomColor: colors.orange30 }]}>
              <AntDesign name="close" size={35} color={colors.amplifyNeutral60} style={{ marginRight: 170 }} />
              <Image
                style={styles.miniLogoImage}
                source={require("../../assets/images/favicon.png")}
              />
            </View>
          ),
          drawerActiveBackgroundColor: colors.grey10,
          drawerInactiveBackgroundColor: colors.white,
        }}
      />
      <Stack.Screen
        component={PropertyScreen}
        name="space"
        options={{
          headerShown: false
        }}
      />
      <Stack.Screen
        name="about"
        component={AboutScreen}
      />
      <Stack.Screen
        name="help"
        component={HelpScreen}
      />
      <Stack.Screen
        name="host"
        component={HostSplashScreen}
      />
      <Stack.Screen
        name="security"
        component={SecurityScreen}
      />
    </Stack.Navigator>
  )

}

const UnauthenticatedNavigator = ({ authenticate }) => {
  const { width } = useWindowDimensions();
  const prefix = [Linking.createURL('/'), 'https://hookhub.co', 'https://www.hookhub.co'];
  const mobileView = width < 650;
  const config = {
    screens: {
      explore: {
        path: ''
      },
      space: {
        path: 'space/:propertyID'
      },
      about: {
        path: "/about"
      },
      host: {
        path: "/host"
      },
      security: {
        path: "/security"
      },
      help: {
        path: "/help"
      }
    }
  };
  const linking = {
    enabled: true,
    prefixes: [prefix],
    config
  };

  return (
    <NavigationContainer
      linking={linking}
      documentTitle={{
        formatter: () => `Hookhub | RV Parking`,
      }}
    >
      {mobileView ? <UnauthNavigatorMobileView authenticate={(v) => authenticate(v)} /> : <UnauthNavigator authenticate={(v) => authenticate(v)} />}
    </NavigationContainer>

  )
};

const styles = StyleSheet.create({
  headerRight: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    marginRight: 10,
    justifyContent: "center",
    alignContent: "center",
  },
  drawerButton: {
    flexDirection: "row",
    textAlign: "center",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    marginBottom: 5,
    marginLeft: 5
  },
  miniLogoImage: {
    width: 40,
    height: 40,
    margingLeft: 200
  },
});

export default UnauthenticatedNavigator;
