import React, { useState, useEffect, useRef } from "react";
import { useWindowDimensions, View, Animated, Text, StyleSheet, Pressable, Image } from "react-native";
import { AntDesign, FontAwesome, FontAwesome5, Ionicons } from '@expo/vector-icons';
import CalendarView from "./CalendarView";
import getEnvVars from "../../../environment";
import Header from "../CustomElements/Header";
import MapView from "./MapView";
import ReserveCard from "../booking/ReserveCard";
import SpaceDescription from "./SpaceDescription";
import ThingsToDoInArea from "./ThingsToDoInAreaView";
import { useSelector } from "react-redux";
import { colors } from "../../styles/colors";
import { useNavigation } from "@react-navigation/native";
import Badge from "../CustomElements/Badge";
import MultipleSpacesDisplay from "./MultipleSpacesDisplay";
import { ReviewView } from "../review/ReviewView";
import { globalStyles } from "../../styles/styles";
import CustomDivider from "../CustomDivider";
import Card from "../CustomElements/Card";
import { addOrRemoveFavorite } from "../../utils/addOrRemoveFavorite";
import CustomButtonUnderlined from "../CustomElements/CustomButtonUnderlined";
import CustomButton from "../CustomElements/CustomButton";
import { ImageGrid } from "./ImageGrid";
import BackButton from "../other/BackButton";
import TextBody from "../CustomElements/TextBody";
import MobileOpenReserveCardButton from "./MobileOpenReserveCardButton";
import CustomModal from "../CustomElements/CustomModal";
const { REACT_APP_ENV } = getEnvVars();


function DetailedSpace({
  user = null,
  mySpace = null,
  property,
  images,
  imLoggedIn,
  activeSpaces,
  displaySpace,
  setDisplaySpace,
  initialDate,
  spaceTypes,
  hostUser,
  calendarError,
  setCalendarError,
  scrollAndSetHeight,
  setSignUpModalOpen,
  signUpModalOpen
}) {
  const navigation = useNavigation();
  const { mobileView } = useSelector(state => state.currentUser);
  const { width, height } = useWindowDimensions();
  const translation = useRef(new Animated.Value(-100)).current;
  const { title, ownerID, address, id, reviews } = property;
  const [mobileReserveCardOpen, setMobileReserveCardOpen] = useState(!mobileView);
  const [favorited, setFavorited] = useState(false);
  const allReviews = reviews?.items;
  const numOfSpaces = activeSpaces.length;


  const checkForFavorite = async () => {
    const { favorites } = user.userData.userData;
    if (!!favorites && !!favorites.length) {
      const favoritesCheck = favorites.findIndex((x => x === property.id));
      favoritesCheck !== -1 && setFavorited(true)
    }
  };

  const updateFavorite = async (v) => {
    setFavorited(v);
    addOrRemoveFavorite({ v, user, propertyID: id })
  };

  useEffect(() => {
    !!imLoggedIn && checkForFavorite();
  }, [imLoggedIn]);

  useEffect(() => {
    if (!activeSpaces && !activeSpaces[0]) {
      navigation.navigate("explore")
    }
    setDisplaySpace(activeSpaces[0])
  }, [activeSpaces]);

  useEffect(() => {
    Animated.timing(translation, {
      toValue: mobileReserveCardOpen ? 0 : width / 2.5,
      duration: 250,
      useNativeDriver: true,
    }).start();
  }, [mobileReserveCardOpen]);

  return (
    <View style={globalStyles.pageHorizontalMargins}>
      <View style={globalStyles.centerColumn}>
        <View style={[globalStyles.center, { width: width / 1.05 }]}>
          {images && <ImageGrid images={images} />}
          <View style={{ position: "absolute", top: 2, left: 0 }}>
            <BackButton goBack={false} />
          </View>
        </View>
        <View style={[globalStyles.center, { alignItems: "flex-start" }]}>

          {/* ////// SECTION:   Description  */}
          <View style={[globalStyles.flexStartColumn, { marginBottom: 20, marginRight: 20 }]}>
            <SpaceDescription
              hostUser={hostUser}
              userData={user?.userData}
              space={displaySpace}
              property={property}
              mobileView={mobileView}
              ownerID={ownerID}
              width={width}
              address={address}
              allReviews={allReviews}
              numOfSpaces={numOfSpaces}
              displaySpace={displaySpace}
              favorited={favorited}
              updateFavorite={() => updateFavorite(!favorited)}
              imLoggedIn={imLoggedIn}
            />
          </View>


          {/* ////// SECTION:   Reserve Card for WEB */}
          {!mobileView && (
            <>
              <Animated.View
                style={{
                  position: 'sticky',
                  top: 0,
                  transform: [
                    { translateX: translation },
                  ],
                }}
              >
                <ReserveCard
                  customWidth={width / 3.1}
                  mobileView={mobileView}
                  userData={user?.userData}
                  signUpModalOpen={signUpModalOpen}
                  setSignUpModalOpen={(v) => setSignUpModalOpen(v)}
                  mySpace={mySpace}
                  hostEmail={hostUser.email}
                  hostID={hostUser.id}
                  stripeConnectedAccountId={hostUser.stripeConnectedAccountId}
                  displaySpace={displaySpace}
                  setDisplaySpace={(v) => setDisplaySpace(v)}
                  displayProperty={property}
                  propertyID={id}
                  propertyTitle={title}
                  spaceTypes={spaceTypes}
                  scrollToTop={() => scrollAndSetHeight()}
                  initialDate={initialDate}
                  error={calendarError}
                  activeSpaces={activeSpaces}
                  calendarDisplayForDateInput={true}
                />
              </Animated.View>
              <View style={globalStyles.flexStartColumn}></View>
            </>
          )}
        </View>

        <View style={{ marginTop: 50 }} />
        {/* ////// SECTION:   Reviews  */}
        {!!allReviews?.length && <ReviewView reviews={allReviews} showFirstThree={true} />}


        {/* ////// SECTION:   Map  */}
        <MapView space={property} width={width} />
      </View>

      {/* ////// SECTION:   Reserve Card for MOBILE */}
      {mobileView && (
        <>
          {mobileReserveCardOpen ? (
            <CustomModal modalVisible={mobileReserveCardOpen} modalWidth={width / 1.05} backgroundColor={null} nestedScrollView={false} setModalVisible={() => setMobileReserveCardOpen(!mobileReserveCardOpen)}>
              <ReserveCard
                customWidth={width / 1.05}
                mobileView={mobileView}
                userData={user?.userData}
                signUpModalOpen={signUpModalOpen}
                setSignUpModalOpen={(v) => setSignUpModalOpen(v)}
                mySpace={mySpace}
                hostEmail={hostUser.email}
                hostID={hostUser.id}
                stripeConnectedAccountId={hostUser.stripeConnectedAccountId}
                displaySpace={displaySpace}
                setDisplaySpace={(v) => setDisplaySpace(v)}
                displayProperty={property}
                propertyID={id}
                propertyTitle={title}
                spaceTypes={spaceTypes}
                scrollToTop={() => scrollAndSetHeight()}
                initialDate={initialDate}
                error={calendarError}
                activeSpaces={activeSpaces}
                calendarDisplayForDateInput={true}
                setMobileReserveCardOpen={() => setMobileReserveCardOpen(!mobileReserveCardOpen)}
                mobileReserveCardOpen={mobileReserveCardOpen}
              />
            </CustomModal>
          ) : (
            <Animated.View
              style={{
                position: 'sticky',
                bottom: 0,
                width: 70,
                height: 140,
                transform: [
                  { translateX: translation },
                ],
              }}
            >
              <View style={{ paddingBottom: 15 }}>
                <MobileOpenReserveCardButton setMobileReserveCardOpen={() => setMobileReserveCardOpen(!mobileReserveCardOpen)} />
              </View>
            </Animated.View>
          )}
        </>
      )}
    </View>
  );
}

export default DetailedSpace;
