import React from "react";
import {
    useWindowDimensions
} from "react-native";
import CustomModal from "../CustomElements/CustomModal";
import { IDVerificationRequiredComponent } from "./IDVerificationRequiredComponent";

export const IDVerificationRequiredModal = ({
    modalVisible,
    setModalVisible,
}) => {
    const { width } = useWindowDimensions();

    return (
        <CustomModal
            modalVisible={modalVisible}
            setModalVisible={() => setModalVisible(!modalVisible)}
            width={width / 1.05}
        >
            <IDVerificationRequiredComponent />
        </CustomModal >
    );
};


