import React, { useState } from 'react';
import { Text, StyleSheet, View, Pressable } from 'react-native';
import { AntDesign } from "@expo/vector-icons";
import CustomModal from './CustomModal';
import { colors } from '../../styles/colors';

const SelectField = ({ options, label, onChange, value, width = 170, displayOnly = false }) => {
    const [visible, setVisible] = useState(false);
    const onItemPress = (item) => {
        onChange(item);
        setVisible(false);
    };

    const renderDropdown = () => {
        return (
            <CustomModal
                modalVisible={visible}
                setModalVisible={() => setVisible(!visible)}
                backgroundColor={colors.amplifyNeutral20}
                modalWidth={width}
            >
                <View style={{ flexDirection: 'column', justifyContent: "center", alignContent: "center", alignItems: "center" }}>
                    <Text style={{ marginBottom: 15, fontSize: 25, textAlign: "center", marginTop: 10, fontWeight: "600" }}>{label}</Text>
                    <View style={[styles.dropdown]}>
                        {options.map((v, i) => (
                            <View key={i}>
                                {displayOnly ? (
                                    <View style={[styles.item, {width: width}]} key={i}>
                                        <Text style={{ fontSize: 17 }}>{v}</Text>
                                    </View>
                                ) : (
                                    <Pressable style={[styles.item, {width: width}]} onPress={() => onItemPress(v)} key={i}>
                                        <Text style={{ fontSize: 17 }}>{v}</Text>
                                    </Pressable>
                                )}
                            </View>
                        ))}
                    </View>
                    <Pressable onPress={() => setVisible(false)} style={{ margin: 25 }}>
                        <AntDesign name="closecircleo" size={28} color="#000" />
                    </Pressable>
                </View>
            </CustomModal>
        );
    };


    return (
        <Pressable
            style={[styles.button, {width:width}]}
            onPress={() => setVisible(!visible)}
        >
            {renderDropdown()}
            <Text style={styles.buttonText}>
                {(!!value) && value || label}
            </Text>
            <AntDesign
                name="caretdown"
                size={24}
                color={colors.amplifyNeutral80}
            />
        </Pressable>
    );
};


const styles = StyleSheet.create({
    button: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#efefef',
        height: 50,
        paddingHorizontal: 10,
        borderRadius: 7,
        borderWidth: .9,
        zIndex: 1,
        margin: 4,
        marginBottom:8
    },
    item: {
        padding: 18,
        borderWidth: .9,
        width: "100%",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
    },
    buttonText: {
        flex: 1,
        textAlign: 'center',
        fontSize: 17,
    },
    dropdown: {
        width: '100%',
        shadowColor: '#000000',
        shadowRadius: 4,
        shadowOffset: { height: 4, width: 0 },
        shadowOpacity: 0.5,
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
    },
});

export default SelectField;