import { AntDesign } from '@expo/vector-icons';
import React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { colors } from '../../styles/colors';

const CloseButtonIcon = ({ onPress, margin = 13 }) => {

    return (
        <View style={[styles.flexEnd, { margin: margin }]}>
            <Pressable onPress={() => onPress()}>

                <View style={styles.reserveButton} />
                <View style={[{ position: "absolute", top:0, left:0 }]}>
                    <AntDesign name="close" size={25} color={colors.amplifyNeutral80} />
                </View>
            </Pressable>
        </View>
    );
};

export default CloseButtonIcon

const styles = StyleSheet.create({
    flexEnd: {
        justifyContent: "flex-end",
        textAlign: "right",
        alignContent: "flex-end",
        alignItems: "flex-end",
        alignSelf: "flex-end"
    },
    reserveButton: {
        height: 26,
        width: 26,
        borderRadius: 100,
        backgroundColor: colors.white,
        opacity: .5
    },
});

