import React, { useState, useEffect } from "react";
import { useWindowDimensions, View } from "react-native";
import { ReserveModal } from "./ReserveModal";
import getEnvVars from '../../../environment';
import moment from "moment";
import { MyRigNeededModal } from "../user/MyRigNeededModal";
const { REACT_APP_ENV } = getEnvVars();
import { UUID } from "../../utils/UUID";
import ReserveCardBody from "./ReserveCardBody";
import { IDVerificationRequiredModal } from "../user/IDVerificationRequiredModal";


const ReserveCard = ({
  mobileView,
  userData = null,
  mySpace = null,
  hostEmail,
  hostID,
  stripeConnectedAccountId,
  displaySpace,
  setDisplaySpace,
  displayProperty,
  propertyID,
  propertyTitle,
  spaceTypes,
  scrollToTop,
  initialDate,
  error,
  calendarDisplayForDateInput = false,
  activeSpaces,
  paddingBottom = 10,
  signUpModalOpen,
  setSignUpModalOpen,
  customWidth,
  setMobileReserveCardOpen
}) => {
  const { width } = useWindowDimensions();
  const { id, instant_book, full_refund_date, half_refund_date, space_rules, smoking_allowed, adults_only } = displaySpace;
  const [checkinDate, setCheckinDate] = useState("");
  const [checkoutDate, setCheckoutDate] = useState("");
  const [checkoutDateError, setCheckoutDateError] = useState(null);
  const [checkinDateError, setCheckinDateError] = useState(null);
  const [showReserveModal, setShowReserveModal] = useState(false);
  const [dateSpanSet, setDateSpanSet] = useState(false);
  const [rentalCost, setRentalCost] = useState(0);
  const [hostFee, setHostFee] = useState(0);
  const [currentMonthSpan, setCurrentMonthSpan] = useState(0);
  const [monthlyPayout, setMonthlyPayout] = useState(false);
  const [allPaymentsSchedule, setallPaymentsSchedule] = useState([]);
  const [upcomingPaymentsSchedule, setupcomingPaymentsSchedule] = useState([]);
  const [monthlyPrice, setMonthlyPrice] = useState(0);
  const [monthlyPayoutAmount, setMonthlyPayoutAmount] = useState(0);
  const [renterFee, setRenterFee] = useState(0);
  const [stripeFee, setStripeFee] = useState(0);
  const [hostPayout, setHostPayout] = useState(0);
  const [hookhubPayout, setHookhubPayout] = useState(0);
  const [baseCost, setBaseCost] = useState(0);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [clientSecret, setClientSecret] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rentalType, setRentalType] = useState(null);
  const [monthOptionDisabled, setMonthOptionDisabled] = useState(false);
  const [yearOptionDisabled, setYearOptionDisabled] = useState(false);
  const [halfRefundDate, sethalfRefundDate] = useState(null);
  const [halfRefundEnabled, sethalfRefundEnabled] = useState(false);
  const [fullRefundEnabled, setfullRefundEnabled] = useState(false);
  const [fullRefundDate, setfullRefundDate] = useState(null);
  const [noRefundEnabled, setnoRefundEnabled] = useState(false);
  const [myRigNeededModalOpen, setMyRigNeededModalOpen] = useState(false);
  const [ephemeralKey, setEphemeralKey] = useState('');
  const [monthlyHookhubPayout, setMonthlyHookhubPayout] = useState(0);
  const [datesSelectedError, setDatesSelectedError] = useState(false);
  const [stripeCustomerID, setStripeCustomerID] = useState('');
  const [bookingRequestID, setBookingRequestID] = useState(null);
  const [stripePaymentIntentID, setStripePaymentIntentID] = useState(null);
  const [IDVerificationNoticeOpen, setIDVerificationNoticeOpen] = useState(false);
  const [currentDateSpan, setCurrentDateSpan] = useState([]);

  const updatePriceShowing = () => {
    setRentalType(spaceTypes[activeTabIndex].type);
    if (!!currentDateSpan.length) {
      let stayLength = currentDateSpan.length - 1

      // RenterTotalBookingCost:
      //   baseBookingCost
      //   + renterFeeCost = baseBookingCost * 10%
      //   + renterStripeFee = (baseBookingCost + renterFeeCost) * 2.9% + .30

      // hostPayout:
      //    baseBookingCost
      //    - hostFeeCost = baseBookingCost * 6 %

      // hookhubPayout
      //   + renterFeeCost
      //   + hostFeeCost

      // renter portion:
      const base = Number(spaceTypes[activeTabIndex].price * (stayLength)).toFixed(2);
      const hookhubRenterFeeCost = Number(base * .1).toFixed(2);
      const totalCostBeforeStripeFee = Number(base) + Number(hookhubRenterFeeCost);
      const renterStripeFee = ((totalCostBeforeStripeFee * 0.02986) + .3).toFixed(2);
      const renterFeeCost = Number(renterStripeFee) + Number(hookhubRenterFeeCost);
      const rentalTotalBookingCost = (Number(base) + Number(renterFeeCost)).toFixed(2);

      // host portion:
      const hostFeeCost = (base * .1).toFixed(2);
      const hostPortion = (base - hostFeeCost).toFixed(2);

      // hookhub portion
      const hookhubPortion = (Number(hookhubRenterFeeCost) + Number(hostFeeCost)).toFixed(2);

      //monthly breakdown, if enough days:
      let lastDate = (currentDateSpan[stayLength - 1])
      let firstDate = currentDateSpan[0];
      let total = 0;
      const monthlyAmount = moment(lastDate).diff(moment(firstDate), 'months', true);
      total = Math.round(monthlyAmount)

      if (monthlyAmount > .9) {
        let datesArray = [];
        for (let i = 0; i < total - 1; i++) {
          const newDate = i == 0 ? currentDateSpan[0] : moment(datesArray[i - 1]).format("YYYY-MM-DD");
          const paymentDate = moment(moment(newDate).add(28, 'days')).format("YYYY-MM-DD");
          const formattedDate = `${paymentDate.slice(5, 7)}/${paymentDate.slice(8, 10)}/${paymentDate.slice(0, 4)}`;
          datesArray.push(paymentDate);
        }

        const monthCost = (Number(rentalTotalBookingCost) / Number(total)).toFixed(2);
        const monthPayout = (Number(hostPortion) / Number(total)).toFixed(2);
        const monthlyHHPayout = (Number(hookhubPortion) / Number(total)).toFixed(2);
        setMonthlyPrice(monthCost);
        setMonthlyHookhubPayout(monthlyHHPayout);
        setMonthlyPayoutAmount(monthPayout);
        setallPaymentsSchedule([firstDate, ...datesArray]);
        setupcomingPaymentsSchedule(datesArray);
        setCurrentMonthSpan(total);
        setMonthlyPayout(true);
      } else {
        setCurrentMonthSpan(0)
        setMonthlyPrice(0)
        setMonthlyPayoutAmount(0)
        setallPaymentsSchedule([]);
        setupcomingPaymentsSchedule(null);
        setMonthlyPayout(false);
      };

      setBaseCost(base);
      setHookhubPayout(hookhubPortion);
      setHostPayout(hostPortion);
      setRentalCost(rentalTotalBookingCost);
      setHostFee(hostFeeCost);
      setRenterFee(renterFeeCost);
      setStripeFee(renterStripeFee);

      if (spaceTypes[activeTabIndex].increment === 'month' && monthlyAmount < .9) {
        setMonthOptionDisabled(true);
      } else { setMonthOptionDisabled(false) }

      if (spaceTypes[activeTabIndex].increment === 'year' && monthlyAmount < 11.7) {
        setYearOptionDisabled(true);
      } else { setYearOptionDisabled(false) }
    }
  };

  const timeValueCheck = (date1, date2) => {
    const start = new Date(date1).getTime();
    const end = new Date(date2).getTime();
    return start <= end;
  };

  const getDaysArray = (start, end) => {
    let obj = {};
    for (
      const dt = new Date(`${start}T00:00:00`);
      dt <= new Date(`${end}T00:00:00`);
      dt.setDate(dt.getDate() + 1)
    ) {
      const year = dt.toLocaleString("default", { year: "numeric" });
      const month = dt.toLocaleString("default", { month: "2-digit" });
      const day = dt.toLocaleString("default", { day: "2-digit" });
      const theDate = year + "-" + month + "-" + day;
      obj = { ...obj, [theDate]: { color: "#42b0f5" } };
    }
    return obj;
  };

  const tryUpdateDateSpan = (v) => {
    setCheckoutDateError(null)
    if (!!checkinDate && timeValueCheck(checkinDate, v)) {
      setCheckoutDate(v);
      const updatedArray = getDaysArray(checkinDate, v);
      setCurrentDateSpan(Object.keys(updatedArray));
      updatePriceShowing();
    } else {
      setCheckoutDateError("checkin must be prior to checkout");
    }
  };

  const prepareToReserve = async (event) => {
    const rigDataPresent = !!userData?.myRigImage;
    setBookingRequestID(UUID());
    if (!!Object.keys(userData).length) {
      // if (!!rigDataPresent) {
      setLoading(true);
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
          id: userData?.sub,
          email: userData?.email,
          name: userData?.name,
          enviro: REACT_APP_ENV
        })
      };
      fetch('https://eo5kuq7j25bdllq2wpuku3t6be0swmjv.lambda-url.us-west-2.on.aws/', requestOptions)
        .then(response => response.json())
        .then(data => {
          const { client_secret, customer, id, ephemeralKey } = data.paymentIntent;
          setClientSecret(client_secret);
          setEphemeralKey(ephemeralKey);
          setStripeCustomerID(customer);
          setStripePaymentIntentID(id);
          setLoading(false);
          scrollToTop();
          setShowReserveModal(true);
        }).catch((err) => { console.log("ERROR, ", err) });
      // } 
      // else {
      //   setMyRigNeededModalOpen(true);
      // }
    } else {
      setSignUpModalOpen(true)
    }
  };

  const resetStripeReserve = () => {
    setClientSecret(null);
    setEphemeralKey('');
    setShowReserveModal(false)
  };

  useEffect(() => {
    if (currentDateSpan.length) {
      setCheckinDate(currentDateSpan[0]);
      setCheckoutDate(currentDateSpan[currentDateSpan.length - 1]);

      if (!!half_refund_date) {
        const startDate = moment(String(currentDateSpan[0]));
        let date = startDate.subtract(half_refund_date * 7, 'days')
        const formatteddate = (date.format("YYYY-MM-DD"))
        sethalfRefundDate(formatteddate);
        sethalfRefundEnabled(true);
      }
      if (!!full_refund_date) {
        const startDate = moment(String(currentDateSpan[0]));
        const formatteddate = startDate.subtract(full_refund_date * 7, 'days').format("YYYY-MM-DD")
        setfullRefundDate((formatteddate));
        setfullRefundEnabled(true);
      }
      if (!half_refund_date && !full_refund_date) {
        setnoRefundEnabled(true);
      }
    } else {
      setCheckinDate("")
      setCheckoutDate("")
    }
  }, [currentDateSpan]);

  useEffect(() => {
    if (!!checkinDate && !!checkoutDate && timeValueCheck(checkinDate, checkoutDate)) {
      updatePriceShowing();
      setDateSpanSet(true);
    }
  }, [checkoutDate]);

  useEffect(() => {
    updatePriceShowing();
  }, [activeTabIndex]);

  return (
    <>
      <ReserveCardBody
        mobileView={mobileView}
        setMobileReserveCardOpen={() => setMobileReserveCardOpen()}
        initialDate={initialDate}
        userData={userData}
        mySpace={mySpace}
        space={displaySpace}
        currentDateSpan={currentDateSpan}
        spaceTypes={spaceTypes}
        width={width}
        customWidth={customWidth}
        error={error}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={(v) => setActiveTabIndex(v)}
        rentalType={rentalType}
        monthlyPayout={monthlyPayout}
        setMonthlyPayout={(v) => setMonthlyPayout(!monthlyPayout)}
        setSignUpModalOpen={() => setSignUpModalOpen(!signUpModalOpen)}
        monthOptionDisabled={monthOptionDisabled}
        baseCost={baseCost}
        renterFee={renterFee}
        rentalCost={rentalCost}
        currentMonthSpan={currentMonthSpan}
        monthlyPrice={monthlyPrice}
        checkinDate={checkinDate}
        setCheckinDate={(v) => setCheckinDate(v)}
        checkoutDate={checkoutDate}
        tryUpdateDateSpan={(v) => tryUpdateDateSpan(v)}
        checkinDateError={checkinDateError}
        checkoutDateError={checkoutDateError}
        prepareToReserve={() => prepareToReserve()}
        loading={loading}
        dateSpanSet={dateSpanSet}
        allPaymentsSchedule={allPaymentsSchedule}
        yearOptionDisabled={yearOptionDisabled}
        calendarDisplayForDateInput={calendarDisplayForDateInput}
        activeSpaces={activeSpaces}
        displaySpace={displaySpace}
        setDisplaySpace={(v) => setDisplaySpace(v)}
        image={displayProperty?.images[0]}
        property={displayProperty}
        numberOfMonths={currentMonthSpan}
        datesSelectedError={datesSelectedError}
        setDatesSelectedError={(v) => setDatesSelectedError(v)}
        setCurrentDateSpan={(v) => setCurrentDateSpan(v)}
      />
      <MyRigNeededModal
        modalVisible={myRigNeededModalOpen}
        setModalVisible={() => setMyRigNeededModalOpen(!myRigNeededModalOpen)}
      />
      <IDVerificationRequiredModal
        modalVisible={IDVerificationNoticeOpen}
        setModalVisible={() => setIDVerificationNoticeOpen(!IDVerificationNoticeOpen)}
      />
      {!!userData && (
        <ReserveModal
          modalVisible={showReserveModal}
          setShowReserveModal={() => resetStripeReserve()}
          bookedDays={currentDateSpan}
          userData={userData}
          hostEmail={hostEmail}
          payMonthly={monthlyPayout}
          hostPayout={hostPayout}
          hookhubPayout={hookhubPayout}
          hostFeeCost={hostFee}
          monthlyPaymentAmount={monthlyPrice}
          monthlyHookhubPayout={monthlyHookhubPayout}
          monthlyHostPayout={monthlyPayoutAmount}
          setMonthlyPayout={(v) => setMonthlyPayout(v)}
          numberOfMonths={currentMonthSpan}
          initialDate={initialDate}
          currentDateSpan={currentDateSpan}
          currentMonthSpan={currentMonthSpan}
          checkinDate={checkinDate}
          setCheckinDate={(v) => setCheckinDate(v)}
          checkoutDate={checkoutDate}
          tryUpdateDateSpan={(v) => tryUpdateDateSpan(v)}
          checkinDateError={checkinDateError}
          checkoutDateError={checkoutDateError}
          displayProperty={displayProperty}
          displaySpace={displaySpace}
          setDisplaySpace={(v) => setDisplaySpace(v)}
          spaceTypes={spaceTypes}
          activeSpaces={activeSpaces}
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={(v) => setActiveTabIndex(v)}
          setCurrentDateSpan={(v) => setCurrentDateSpan(v)}
          datesSelectedError={datesSelectedError}
          setDatesSelectedError={(v) => setDatesSelectedError(!!v)}
          stripeConnectedAccountId={stripeConnectedAccountId}
          renterFeeCost={renterFee}
          renterStripeFee={stripeFee}
          baseCost={baseCost}
          stripeCustomerID={stripeCustomerID}
          stripePaymentIntentID={stripePaymentIntentID}
          bookingRequestID={bookingRequestID}
          nightlyPrice={spaceTypes[activeTabIndex].price}
          hostID={hostID}
          monthOptionDisabled={monthOptionDisabled}
          yearOptionDisabled={yearOptionDisabled}
          propertyTitle={propertyTitle}
          propertyID={propertyID}
          instantBook={instant_book}
          rentalCost={rentalCost}
          clientSecret={clientSecret}
          full_refund_date={fullRefundDate}
          half_refund_date={halfRefundDate}
          full_refund_enabled={fullRefundEnabled}
          half_refund_enabled={halfRefundEnabled}
          no_refund_enabled={noRefundEnabled}
          space_rules={space_rules}
          noSmoking={!smoking_allowed}
          adultsOnly={adults_only}
          spaceID={id}
          paymentSchedule={allPaymentsSchedule}
          nextPaymentsSchedule={upcomingPaymentsSchedule}
          rentalType={rentalType}
          ephemeralKey={ephemeralKey}
          setIDVerificationNoticeOpen={() => setIDVerificationNoticeOpen(true)}
        />
      )}
    </>
  );
};

export default ReserveCard;
