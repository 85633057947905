import {
    StyleSheet,
    Text,
    useWindowDimensions,
    View
} from "react-native";
import { MaterialCommunityIcons } from '@expo/vector-icons';
import Header from "../CustomElements/Header"
import { useDispatch, useSelector } from "react-redux";
import { colors } from "../../styles/colors";
import { globalStyles } from "../../styles/styles";
import CustomModal from "../CustomElements/CustomModal";
import { VerifyIdentityIcon } from "./VerifyIdentityIcon";
import CloseButtonUnderlined from "../CustomElements/CloseButtonUnderlined";
import { alertNoticeOfSuccess } from "../../redux/actions/CurrentUserActions";


export const SuccessVerifyingIdentityModal = () => {
    const dispatch = useDispatch();
    const {width} = useWindowDimensions();
    const { mobileView, verifyNoticeOfSuccess, actionCompletedAfterIDVerified } = useSelector(state => state.currentUser);

    const setModalVisible = () => {
        dispatch(alertNoticeOfSuccess({ success: false, actionCompletedAfterIDVerified: ""}))
    }

    return (
        <CustomModal modalVisible={verifyNoticeOfSuccess} setModalVisible={() => setModalVisible()}>
            <View style={[globalStyles.centerColumn, {width: width/1.4}]}>
                <Header level={mobileView ? 1.4 : 2} text="Success!"></Header>
                <View style={[globalStyles.centerColumn, { marginTop: 25 }]}>
                    <VerifyIdentityIcon largeVersion={true} />
                    <View style={[globalStyles.flexStart, { margin: 10 }]} >
                        <MaterialCommunityIcons name="police-badge-outline" size={24} color={colors.amplifyNeutral80} />
                        <Text style={{ marginLeft: 5, fontWeight: "500", fontSize: 15, textAlign: "center" }}>Your Identity has been successfully verified.</Text>
                    </View>
                    {actionCompletedAfterIDVerified ? (<Text style={[styles.nextStepsIDVerifyText, { marginTop: 5, marginBottom: 10 }]}>{actionCompletedAfterIDVerified}</Text>) : <></>}
                </View>
                <CloseButtonUnderlined onPress={() => setModalVisible()} />
            </View>
        </CustomModal>
    );
};

const styles = StyleSheet.create({
    nextStepsIDVerifyText: {
        textAlign: "center",
        marginHorizontal: 5
    }
});
