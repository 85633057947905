import React, { useContext, useState, useEffect } from "react";
import { StyleSheet, Text, View, useWindowDimensions } from "react-native";
import { Entypo } from '@expo/vector-icons';
import { BookingsContext } from "../../utils/BookingsContextProvider";
import { useSelector } from "react-redux";
import BookingHostView from "./BookingHostView";
import Flex from "../CustomElements/Flex"
import CustomButton from "../CustomElements/CustomButton";
import { colors } from "../../styles/colors";
import CustomModal from "../CustomElements/CustomModal";
import { UUID } from "../../utils/UUID";
import Card from "../CustomElements/Card";
import { notifyHookhub } from "../../lambdaFunctions/notifyHookhub";
import { sendEmail } from "../../lambdaFunctions/sendEmail/sendEmail";
import { sendRequestApprovedEmail } from "../../lambdaFunctions/sendEmail/sendRequestDecisionEmail";
import CloseButtonUnderlined from "../CustomElements/CloseButtonUnderlined";
import { formatDate } from "../../utils/formatDate";
import Header from "../CustomElements/Header";
import { createBookingMutation, listSpaceBookings } from "../../api/bookings";
import moment from "moment";
import { ErrorDisplay } from "../CustomElements/ErrorDisplay";
import { updateBookingRequestMutation } from "../../api/bookingRequests";
import { getUserDetails } from "../../api/user";
import { globalStyles } from "../../styles/styles";

const ConflictingBookingView = ({
    renterNotifiedOfConflict,
    notifyOfConflictingDate,
    width,
    mobileView
}) => {
    return (
        <>
            {renterNotifiedOfConflict ? (
                <Card
                    backgroundColor={colors.orange80}
                    alignItems="center"
                    textAlign="center"
                    border={colors.amplifyNeutral60}
                    width={width / 1.5}
                    marginTop={25}
                >
                    <Header level={1.1} color={colors.amplifyNeutral90} textAlign="center" text="Renter has been notified, no further action needed." />
                </Card>
            ) : (
                <View style={globalStyles.centerColumn}>
                    <Card
                        backgroundColor={colors.orange50}
                        alignItems="center"
                        textAlign="center"
                        border={colors.amplifyNeutral60}
                        width={width / 1.25}
                        marginTop={25}
                    >
                        <Header level={1.1} color={colors.amplifyNeutral90} textAlign="center" text="Oh no! You have a scheduled Booking that conflicts with this Request." />
                    </Card>
                    <CustomButton
                        width={mobileView ? width / 1.3 : 300}
                        height={mobileView ? 60 : 45}
                        backgroundColor={colors.orange80}
                        marginBottom={55}
                        padding={2}
                        onPress={() => notifyOfConflictingDate()}
                    >
                        <View style={{ flexDirection: "row", justifyContent: "center", alignContent: "center", alignItems: "center", }}>
                            <Text style={styles.textStyle}>Notify Renter and Deny Request</Text>
                        </View>
                    </CustomButton>
                </View>
            )}
        </>
    )
};

const ReviewRequestModal = ({ modalVisible, setModalVisible, requestDetails, myEmail, stripeHostConnectedAccountId, myID }) => {
    const {
        id,
        members,
        renterID,
        hostID,
        spaceID,
        propertyTitle,
        spaceTitle,
        propertyID,
        spaceCity,
        spaceState,
        bookedDays,
        baseBookingCost,
        hookhubPayout,
        hostPayout,
        renterFeeCost,
        renterTotalBookingCost,
        hostFeeCost,
        renterStripeFee,
        full_refund_date,
        half_refund_date,
        full_refund_enabled,
        half_refund_enabled,
        no_refund_enabled,
        payMonthly,
        paymentSchedule,
        nextPaymentsSchedule,
        monthlyPaymentAmount,
        monthlyHostPayout,
        numberOfMonths,
        renterEmail,
        check_in,
        check_out,
        errorCode,
        stripeCustomerID,
        stripePaymentMethodID,
        stripePaymentIntentID
    } = requestDetails;
    const { mobileView } = useSelector(state => state.currentUser);
    const { bookingRequests, bookings } = useContext(BookingsContext);
    const { bookingRequestsArray, setBookingRequestsArray } = bookingRequests;
    const { bookingsArray, setBookingsArray } = bookings;
    const [renter, setRenter] = useState(null);
    const { width } = useWindowDimensions();
    const updateBookingRequests = v => setBookingRequestsArray(v);
    const updateBookings = v => setBookingsArray(v);
    const bookingID = UUID();
    const [loadingStatus, setLoadingStatus] = useState("");
    const [loadingPercentage, setLoadingPercentage] = useState("0%");
    const [error, setError] = useState(null);
    const [conflictingBooking, setConflictingBooking] = useState(false);
    const [renterNotifiedOfConflict, setrenterNotifiedOfConflict] = useState(false)
    const dateNow = new Date().toISOString().split('T')[0];

    const handleError = async ({ emailBody, errorSubject, errorText }) => {
        let data = {
            id,
            errorCode: emailBody
        }
        setError(errorText);
        notifyHookhub({ subject: errorSubject, body: emailBody, userID: renterID, userEmail: renterEmail });
        setLoadingStatus("")
        setLoadingPercentage("0%")
        await updateBookingRequestMutation({ data });
        const newArr = bookingRequestsArray.filter(function (el) { return el.id !== id; });
        let updatedRequest = { ...requestDetails, errorCode: emailBody }
        newArr.push(updatedRequest)
        updateBookingRequests(newArr)
    };

    const updateBookingRequest = async (decision, newBooking) => {
        decision ? setLoadingPercentage("75%") : setLoadingStatus('Denying this request...')
        const data = {
            id: id,
            confirmed: decision,
            cancelled: !decision,
            reviewed: true,
            _version: 1
        };

        const updateBookingRequest = await updateBookingRequestMutation({ data });
        if (!!updateBookingRequest) {
            setLoadingPercentage("90%")
            const newArr = bookingRequestsArray.filter(function (el) { return el.id !== id; });
            updateBookingRequests(newArr);
            if (!!decision) {
                sendRequestApprovedEmail({ bookingData: newBooking })
            } else {
                const emailSubject = `Booking Request Denied`;
                const emailBody = `Booking Request for: '${propertyTitle}' starting on ${formatDate(check_in)} has been Denied. ${conflictingBooking ? "The host has an already scheduled booking that overlaps with your request days." : ""} Please logon to Hookhub to check out other stays or days.`
                sendEmail({ emailAddress: renterEmail, emailBody, emailSubject })
            }
            conflictingBooking ? setrenterNotifiedOfConflict(true) : setModalVisible(false);
            setLoadingStatus("");
            setLoadingPercentage("0%")
            setError(null);
        } else {
            const emailBody = `ERRORCODE on booking request when host attempted to approve. ERROR at step "updateBookingRequest". Payment and createBooking are complete. Booking REQUEST ID: ${id}`
            const errorSubject = "ERROR UPDATING BOOKING REQUEST"
            handleError({ emailBody, errorSubject, errorText: "Error updating your approval / deny for this booking request. No worries, we are contacting Hookhub now. Hookhub will reach out to you soon." })
        }
    };

    const createBooking = async (paymentIntentID, paymentMethodId) => {
        setLoadingStatus("Creating your booking...")
        setLoadingPercentage("40%")
        const data = {
            id: bookingID,
            full_refund_date,
            half_refund_date,
            full_refund_enabled,
            half_refund_enabled,
            no_refund_enabled,
            hostEmail: myEmail,
            renterEmail: renter.email,
            bookingRequestId: id,
            members: members,
            hostID: hostID,
            renterID: renterID,
            spaceID: spaceID,
            check_in: check_in,
            check_out: check_out,
            bookedDays: bookedDays,
            cancelled: false,
            createdAt: dateNow,
            updatedAt: dateNow,
            baseBookingCost: Number(baseBookingCost).toFixed(2),
            hookhubPayout: Number(hookhubPayout).toFixed(2),
            hostPayout: Number(hostPayout).toFixed(2),
            renterFeeCost: Number(renterFeeCost).toFixed(2),
            renterTotalBookingCost: Number(renterTotalBookingCost).toFixed(2),
            hostFeeCost: Number(hostFeeCost).toFixed(2),
            renterStripeFee: Number(renterStripeFee).toFixed(2),
            paymentRequiresAction: false,
            payMonthly: payMonthly,
            numberOfMonths: numberOfMonths,
            monthlyPaymentAmount: monthlyPaymentAmount,
            paymentSchedule: paymentSchedule,
            nextPaymentsSchedule: nextPaymentsSchedule,
            monthlyHostPayout: monthlyHostPayout,
            stripeHostConnectedAccountId: stripeHostConnectedAccountId,
            stripeCustomerID: stripeCustomerID,
            stripePaymentIntentID: stripePaymentIntentID,
            stripePaymentMethodID: stripePaymentMethodID
        };

        const newBooking = await createBookingMutation({ data });
        newBooking['bookingRequest'] = {
            propertyTitle,
            spaceTitle,
            spaceID,
            propertyID,
            spaceCity,
            spaceState,
            bookedDays
        }
        if (newBooking) {
            setLoadingPercentage("60%")
            updateBookings([...bookingsArray, newBooking]);
            updateBookingRequest(true, newBooking)
        } else {
            const errorSubject = "ERROR CREATING BOOKING"
            const emailBody = `ERRORCODE on booking request when host attempted to approve. ERROR at step "createBooking". Payment is complete, update booking request not attempted.  Booking REQUEST ID: ${id}`
            handleError({ emailBody, errorSubject, errorText: "Error creating new Booking. No worries, we are contacting Hookhub now. Hookhub will reach out to you soon." })
        }
    };

    const handleResponse = async (approved) => {
        approved ? createBooking() : updateBookingRequest(false);
    };

    const getRenter = async () => {
        const user = await getUserDetails(renterID);
        if (user) {
            setRenter(user);
        }
    };

    const closeModal = () => {
        setLoadingStatus("")
        setError(null)
        setrenterNotifiedOfConflict(false)
        setConflictingBooking(false)
        setModalVisible(false)
    };

    const notifyOfConflictingDate = () => {
        updateBookingRequest(false)
    };

    const checkIfConflictingBookingAlreadyScheduled = async () => {
        const currentSpacesBookings = await listSpaceBookings({ myID: myID, spaceID: spaceID });
        const data = currentSpacesBookings?.data?.listBookings?.items;

        if (data && !!data?.length) {
            let foundConflict = false;
            data.forEach(booking => {
                const checkThisBooking = booking.bookedDays;
                const firstDayOfBooking = checkThisBooking[0];
                const lastDayOfBooking = checkThisBooking[checkThisBooking.length - 1];
                if (check_in === firstDayOfBooking) {
                    foundConflict = true
                    return
                }
                if (check_out === lastDayOfBooking) {
                    foundConflict = true
                    return
                }
                const requestStartsBeforeBookingCheck = moment(check_in, "YYYY-MM-DD").isBefore(firstDayOfBooking)

                if (!!requestStartsBeforeBookingCheck) {
                    //   check  >>>   RequestCheck_In < BookingCheck_In < RequestCheck_Out

                    const requestStartsBeforeBooking = moment(check_in, "YYYY-MM-DD").isBefore(firstDayOfBooking)
                    const bookingCheckInIsBeforeRequestCheckout = moment(firstDayOfBooking, "YYYY-MM-DD").isBefore(check_out)
                    if (requestStartsBeforeBooking && bookingCheckInIsBeforeRequestCheckout) {
                        foundConflict = true
                        return;
                    }
                } else {
                    //   check  >>>   BookingCheck_In < RequestCheck_In < BookingCheck_Out

                    const bookingStartsBeforeRequest = moment(firstDayOfBooking, "YYYY-MM-DD").isBefore(check_in)
                    const requestCheckInIsBeforeBookingCheckout = moment(check_in, "YYYY-MM-DD").isBefore(lastDayOfBooking)

                    if (bookingStartsBeforeRequest && requestCheckInIsBeforeBookingCheckout) {
                        foundConflict = true;
                        return;
                    }
                }
            })
            foundConflict && setConflictingBooking(true)
        } else {
            setConflictingBooking(false)
        }
    };

    useEffect(() => {
        getRenter()
    }, []);

    useEffect(() => {
        checkIfConflictingBookingAlreadyScheduled()
    }, [id]);

    useEffect(() => {
        !!errorCode && setError("Error processing this request. Hookhub has been notified, we will reach out soon.")
    }, [errorCode]);

    return (
        <CustomModal modalVisible={modalVisible} setModalVisible={() => setModalVisible(!modalVisible)} >
            <Flex justifyContent="center" alignItems="center" alignContent="center" direction="column" marginBottom={15}>
                {conflictingBooking && (<ConflictingBookingView closeModal={() => closeModal()} width={width} renterNotifiedOfConflict={renterNotifiedOfConflict} setModalVisible={() => closeModal(false)} notifyOfConflictingDate={() => notifyOfConflictingDate()} />)}
                <BookingHostView
                    loadingPercentage={loadingPercentage}
                    conflictingBooking={conflictingBooking}
                    error={error}
                    loading={loadingStatus}
                    width={width}
                    mobileView={mobileView}
                    requestDetails={requestDetails}
                    renter={renter}
                    closeModal={() => setModalVisible(false)}
                    showBookingRequest={true}
                    spaceTitle={spaceTitle}
                    propertyTitle={propertyTitle}
                />
                {!loadingStatus && (
                    <>
                        {!!conflictingBooking ? (
                            <ConflictingBookingView mobileView={mobileView} width={width} renterNotifiedOfConflict={renterNotifiedOfConflict} setModalVisible={() => closeModal(false)} notifyOfConflictingDate={() => notifyOfConflictingDate()} />
                        ) : !!error ? (
                            <ErrorDisplay error={error} />
                        ) : (
                            <Flex
                                wrap="wrap"
                                justifyContent="center"
                                alignItems="center"
                                alignContent="center"
                                marginTop={10}
                                marginBottom={10}
                            >
                                <CustomButton
                                    width={150}
                                    backgroundColor={colors.grey20}
                                    color="#fff"
                                    margin={2}
                                    border={colors.amplifyNeutral80}
                                    onPress={() => handleResponse(false)}
                                >
                                    <Text style={styles.textStyle}>Deny</Text>
                                </CustomButton>
                                <CustomButton
                                    width={150}
                                    backgroundColor={colors.brightBlue}
                                    borderRadius={9}
                                    margin={2}
                                    padding={2}
                                    onPress={() => handleResponse(true)}
                                >
                                    <View style={{ flexDirection: "row", justifyContent: "center", alignContent: "center", alignItems: "center", }}>
                                        <Entypo name="check" size={24} color="black" />
                                        <Text style={styles.textStyle}>Approve</Text>
                                    </View>
                                </CustomButton>
                            </Flex>
                        )}
                        <CloseButtonUnderlined onPress={() => closeModal()} />
                    </>
                )}
            </Flex >
        </CustomModal >
    );
};

const styles = StyleSheet.create({
    textStyle: {
        textAlign: "center",
        fontSize: 17
    },
});

export default ReviewRequestModal;
