import React, { useEffect, useState, useContext } from "react";
import {
  ScrollView,
  StyleSheet,
  View,
  Text
} from "react-native";
import Flex from "../../components/CustomElements/Flex"
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import PropertiesGrid from '../../components/space/PropertiesGrid';
import { listProperties } from "../../graphql/queries";
import { UserDataContext } from "../../utils/UserContextProvider";
import { LoadingComponent } from "../../components/LoadingComponent";
import Header from "../../components/CustomElements/Header";
import { colors } from "../../styles/colors";
import { globalStyles } from "../../styles/styles";

function FavoritesScreen() {
  const { mobileView } = useSelector(state => state.currentUser);
  const { user } = useContext(UserDataContext);
  const { userData } = user;
  const favorites = userData.userData?.favorites;
  const [loading, setLoading] = useState(true);
  const [myFavorites, setMyFavorites] = useState([])

  const checkForFavorites = async () => {
    setLoading(true)
    if (!!favorites && !!favorites.length) {
      let favoritesFilter = [];
      favorites.forEach(x => favoritesFilter.push({ id: { eq: x } }))
      const favoriteSpaces = await API.graphql({
        query: listProperties,
        variables: {
          filter: {
            or: favoritesFilter
          }
        },
        authMode: "API_KEY",
      });
      const favoritesArr = favoriteSpaces.data.listProperties.items;
      setMyFavorites(favoritesArr)
    }
    setLoading(false)
  };

  useEffect(() => {
    checkForFavorites();
  }, []);

  return (
    <ScrollView style={styles.scrollView}>
      {
        loading ?
          (
            <Flex
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              alignSelf="center"
              marginTop={100}
            >
              <LoadingComponent />
            </Flex>
          ) : (
            <>
              {!!myFavorites.length ? (
                <View>
                  <Flex
                    marginTop={mobileView ? 10 : 40}
                    marginLeft={mobileView ? 10 : 20}
                    direction="column"
                  >
                    <Header level={1} color={colors.amplifyNeutral80} text="My Favorites"></Header>
                  </Flex>
                  <View style={[globalStyles.center, { marginTop: 5 }]}>
                    <PropertiesGrid properties={myFavorites} showCardBackground={true} />
                  </View>
                </View>
              ) : (
                <>
                  <Flex
                    justifyContent="center"
                    alignContent="center"
                    alignItems="center"
                    alignSelf="center"
                    marginTop={100}
                  >
                    <Text>No favorites saved</Text>
                  </Flex>
                </>
              )}
            </>
          )
      }
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    width: "100%",
  },
});

export default FavoritesScreen;
