import React from "react";
import { useWindowDimensions, Text, StyleSheet, View } from "react-native";
import {
    MaterialIcons,
    MaterialCommunityIcons,
    FontAwesome5,
} from "@expo/vector-icons";
import Flex from "../CustomElements/Flex"
import Header from "../CustomElements/Header"
import Card from "../CustomElements/Card"
import { useSelector } from "react-redux";
import { colors } from "../../styles/colors"
import { globalStyles } from "../../styles/styles";

const Amenity = ({ amenity, icon, subtext = null, width, mobileView, compact }) => {
    return (
        <Flex
            direction="column"
            alignItems="flex-start"
            alignContent="flex-start"
            justifyContent="flex-start"
            marginTop={10}
            width={mobileView ? width / 1.25 : compact ? width / 2 : width / 3}
        >
            <View style={globalStyles.center}>
                {icon}
                <Text style={{ fontSize: 16, marginLeft: 5 }}>{amenity}</Text>
            </View>
            {!!subtext &&
                <Text style={{ color: "#4c4e52", fontStyle: "italic", fontSize: 15, width: width / 3 }}>* {subtext}</Text>
            }
        </Flex>
    );
};

const ThingsToKnow = ({ space, compact = false }) => {
    const { width } = useWindowDimensions();
    const allowedRulesPresnt = space.pets_allowed || !space.smoking_allowed || space.outdoor_fires;
    const timeRulesPresent = space.check_in_time || space.quiet_hours_start;
    const { mobileView } = useSelector(state => state.currentUser);

    return (
        <>
            <View style={[globalStyles.flexStartColumn, {width: mobileView ? width / 1.15 : width / 2.2, marginTop: 25}]}>
                <Header level={1.05} marginBottom={5} padding={0} textAlign="left" color={colors.amplifyNeutral90} text="Things to know"></Header>
                {allowedRulesPresnt && (
                    <>
                        <Header padding={0} color={colors.amplifyNeutral80} level={1} marginTop={10} text="Rules"></Header>
                        <View style={{ marginLeft: 5, marginBottom: 30 }}>
                            {timeRulesPresent && (
                                <View style={{ marginBottom: 20 }}>
                                    {!!space.check_in_time && (<Text style={styles.fontSize}>CHECK IN after: {space.check_in_time}</Text>)}
                                    {!!space.check_out_time && (<Text style={styles.fontSize}>CHECK OUT before: {space.check_out_time}</Text>)}
                                    {!!space.quiet_hours_start && !!space.quiet_hours_end && (<Text style={[styles.fontSize, { marginBottom: 5 }]}>QUIET HOURS: {space.quiet_hours_start} to {space.quiet_hours_end}</Text>)}
                                </View>
                            )}
                            {space.pets_allowed && (
                                <Amenity
                                    width={width}
                                    compact={compact}
                                    mobileView={mobileView}
                                    amenity="Pets allowed"
                                    icon={
                                        <MaterialIcons
                                            name="pets"
                                            size={25}
                                            color={colors.orange30}
                                            marginLeft={10}
                                        />
                                    }
                                />
                            )}
                            {!space?.smoking_allowed && (
                                <Amenity
                                    width={width}
                                    compact={compact}
                                    mobileView={mobileView}
                                    amenity="NO Smoking"
                                    icon={
                                        <FontAwesome5
                                            name="smoking-ban"
                                            size={25}
                                            color={colors.orange30}
                                            marginLeft={10}
                                        />
                                    }
                                />
                            )}
                            {space?.adults_only && (
                                <Amenity
                                    width={width}
                                    compact={compact}
                                    mobileView={mobileView}
                                    amenity="NO Children permitted to stay on property"
                                    icon={
                                        <MaterialCommunityIcons name="baby-carriage-off" size={25} color={colors.orange30} marginLeft={10} />
                                    }
                                />
                            )}
                            {space.outdoor_fires && (
                                <Amenity
                                    width={width}
                                    compact={compact}
                                    mobileView={mobileView}
                                    amenity="Outdoor fires allowed"
                                    icon={
                                        <MaterialCommunityIcons name="campfire" size={28} color={colors.orange30} marginLeft={8} />
                                    }
                                    subtext="Campfires: allowed only if there are no fire restrictions, per the Dept. of Forestry"
                                />
                            )}
                        </View>
                    </>
                )}
                {!!space.space_rules && (
                    <>
                        <Header padding={0} level={1} color={colors.amplifyNeutral80} text="Host's Rules and Policies" ></Header>
                        <View style={{ marginLeft: 5, marginBottom: 30 }}>
                            <Text style={{ fontSize: 16, marginTop: 10, width: mobileView ? width / 1.25 : compact ? width / 2.4 : width / 1.5 }}>{space.space_rules}</Text>
                        </View>
                    </>
                )}
                <>
                    <Header padding={0} level={1} color={colors.amplifyNeutral80} text="Cancellation Policy" ></Header>
                    <View style={{ marginLeft: 5, marginBottom: 5, width: "90%" }}>
                        <View style={globalStyles.column}>
                            {space.full_refund_date == 0 ? (
                                <View style={globalStyles.centerWithPadding}>
                                    <MaterialCommunityIcons name="cash-refund" size={24} color="black" margin={10} />
                                    <Text style={styles.fontSize}><Text style={{ fontWeight: "bold" }}>No </Text>100% refund available once booked.</Text>
                                </View>
                            ) : (
                                <View style={globalStyles.centerWithPadding}>
                                    <MaterialCommunityIcons name="cash-refund" size={24} color="black" margin={10} />
                                    <Text style={styles.fontSize}>
                                        Cancel
                                        <Text style={{ fontWeight: "bold" }}> {space.full_refund_date} week{space.full_refund_date > 1 && "s"} </Text>
                                        prior to reservation start date for
                                        <Text style={{ fontWeight: "bold" }}> FULL REFUND.</Text>
                                    </Text>
                                </View>
                            )}
                        </View>
                        <View>
                            {space.half_refund_date == 0 ? (
                                <View style={globalStyles.centerWithPadding}>
                                    <MaterialCommunityIcons name="cash-refund" size={24} color="black" margin={10} />
                                    <Text style={styles.fontSize}> <Text style={{ fontWeight: "bold" }}>No </Text>50% refund available once booked.</Text>
                                </View>
                            ) : (
                                <View style={globalStyles.centerWithPadding}>
                                    <MaterialCommunityIcons name="cash-refund" size={24} color="black" margin={10} />
                                    <Text style={styles.fontSize}>
                                        Cancel
                                        <Text style={{ fontWeight: "bold" }}> {space.half_refund_date} week{space.half_refund_date > 1 && "s"} </Text>
                                        prior to reservation start date for
                                        <Text style={{ fontWeight: "bold" }}> 50% REFUND.</Text>
                                    </Text>
                                </View>
                            )}
                        </View>
                    </View>
                </>
            </View>

        </>
    );
};

export default ThingsToKnow;

const styles = StyleSheet.create({
    fontSize: {
        fontSize: 15,
        marginLeft: 5
    },
});

