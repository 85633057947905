import React, { useState, useEffect } from "react";
import { Text, useWindowDimensions, View } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import Flex from "../CustomElements/Flex"
import Header from "../CustomElements/Header"
import Card from "../CustomElements/Card"
import CustomTextInput from "../CustomElements/CustomTextInput";
import { useSelector } from "react-redux";
import { CustomNumberInput } from "../CustomElements/CustomNumberInput";
import { globalStyles } from "../../styles/styles";

const SetPrice = ({ title = null, subtext = null, val, setVal, showMonthlyPrice = false, showYearPrice = false }) => {
  const [monthlyBreakdown, setMonthlyBreakdown] = useState(0);
  const [yearBreakdown, setYearBreakdown] = useState(0);
  const { width } = useWindowDimensions();
  const { mobileView } = useSelector(state => state.currentUser);

  useEffect(() => {
    showMonthlyPrice && setMonthlyBreakdown(Number(val * 30).toFixed(2))
    showYearPrice && setYearBreakdown(Number(val * 365).toFixed(2))
  }, [val]);

  return (
    <Card
      backgroundColor="#fff"
      width={!!mobileView ? width / 1.2 : 300}
      padding={9}
      alignItems="center"
      textAlign="center"
    >
      <View style={[globalStyles.centerColumn, { marginBottom: 20, marginTop: 10 }]}>
        {!!title && <Header padding={0} level={1.1} text={title}></Header>}
        {!!subtext && <Text style={{ fontSize: 14, textAlign: "center", marginTop: 5, fontStyle: "italic" }}>{subtext}</Text>}
      </View>
      <CustomNumberInput
        value={val}
        setValue={(v) => setVal(v)}
      />
      <Text style={{ fontSize: 16 }}>$ per night</Text>
      {!!showMonthlyPrice && <Text style={{ fontSize: 17, marginTop: 20 }}>$ {Number(monthlyBreakdown).toLocaleString("en-US", { maximumFractionDigits: 2 })} / month</Text>}
      {!!showYearPrice && <Text style={{ fontSize: 17, marginTop: 10 }}>$ {Number(yearBreakdown).toLocaleString("en-US", { maximumFractionDigits: 2 })} / year</Text>}

    </Card>
  );
};

export default SetPrice;
