import React from 'react';
import {
    Text,
    Dimensions,
    StyleSheet,
    TextInput
} from 'react-native';
import { colors } from '../../styles/colors';

const CustomTextInput = ({
    onChangeText,
    value,
    showTextCount=false,
    disabled=false,
    placeholder='',
    border = colors.amplifyNeutral90,
    width = Dimensions.get('window').width - 50,
    height = 40,
    alignContent = "flex-start",
    alignItems = "flex-start",
    justifyContent = "flex-start",
    marginBottom=0,
    marginTop=0,
    keyboardType='default',
    textAlign="left",
    type="off",
    multiline=false,
    numberOfLines=14,
    maxLength=500,
    autoCapitalize="sentences",
    showBorder=true
}) => {

    return (
        <>
            <TextInput
                onChangeText={(v) => onChangeText(v)}
                value={value}
                disabled={disabled}
                placeholder={placeholder}
                placeholderTextColor={colors.amplifyNeutral60}
                multiline={multiline}
                numberOfLines={numberOfLines}
                autoCapitalize={autoCapitalize}
                keyboardType={keyboardType}
                autoComplete={type}
                style={[{
                    verticalAlign: 'top',
                    fontSize:16,
                    alignItems: alignItems,
                    alignContent: alignContent,
                    justifyContent: justifyContent,
                    width: width,
                    height: height,
                    backgroundColor: colors.amplifyNeutral20,
                    marginBottom: marginBottom,
                    marginTop: marginTop,
                    textAlign:textAlign,
                    margin: 2,
                    padding:5,
                    borderRadius: 7,
                }, showBorder && {
                    borderColor: border,
                    borderWidth: .9,
                }]}
            />
            {showTextCount && <Text style={styles.text}>{value.length}/500</Text>}
        </>
    )

}

const styles = StyleSheet.create({
    text: {
        color: colors.amplifyNeutral80, 
        marginLeft:8
    }
})
export default CustomTextInput;