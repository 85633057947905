import React, { useCallback, useEffect, useState } from "react";
import {
    StyleSheet,
    Text,
    Image,
    View,
} from "react-native";
import { GoogleMap, Marker, useJsApiLoader, InfoWindow } from '@react-google-maps/api';
import Geocode from "react-geocode";
import getEnvVars from '../../../environment';
import { useSelector } from "react-redux";
import FixedHeightCard from "../CustomElements/FixedHeightCard";
import Badge from "../CustomElements/Badge";
import { globalStyles } from "../../styles/styles";
import { colors } from "../../styles/colors";
const { geocodeAPIkey, googleMapsApiKey, cloudfrontURL } = getEnvVars();
Geocode.setApiKey(geocodeAPIkey);
const { REACT_APP_ENV } = getEnvVars();


const AllSpacesMapView = ({
    coordinates,
    properties,
    selectedPlace,
    setSelectedPlace,
    updateSpacesShowing,
    width,
    height,
}) => {
    const { mobileView, stayType } = useSelector(state => state.currentUser);
    const [mapRef, setMapRef] = useState(null);
    const [coords, setCoords] = useState({ lat: Number(34.1425438), lng: Number(-109.9603843) });
    Geocode.setLocationType("ROOFTOP");

    const containerStyle = {
        width: width / 1.25,
        height: height / 1.5
    };

    const onLoad = useCallback(function callback(map) {
        setMapRef(map)
    }, []);

    const onUnmount = useCallback(function callback(map) {
        setMapRef(null)
    }, []);

    const handleMapMoved = () => {
        if (mapRef) {
            const currentCenter = mapRef.getCenter();
            updateSpacesShowing({ lat: currentCenter.lat(), lng: currentCenter.lng() })
        }
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: googleMapsApiKey
    });

    useEffect(() => {
        Object.keys(coordinates).length && setCoords(coordinates);
    }, [coordinates])

    return isLoaded ? (
        <FixedHeightCard
            width={mobileView ? width / 1.1 : width / 1.2}
            height={!!mobileView ? height / 1.5 : height / 2 + 40}
            padding={10}
            backgroundColor={"#fff"}
            marginTop={mobileView ? 0 : 20}
        >
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={coords}
                zoom={13}
                onLoad={onLoad}
                onUnmount={onUnmount}
                onDragEnd={handleMapMoved}
                onZoomChanged={handleMapMoved}
            >
                {!!properties && properties.map((property, i) => {
                    return (
                        <>
                            {
                                property.spaces?.items.length ? (
                                    <Marker
                                        key={i}
                                        position={{ lat: Number(property.coordinates_lat), lng: Number(property.coordinates_lng) }}
                                        onClick={() => setSelectedPlace(property)}
                                    />
                                ) : <></>
                            }
                        </>
                    )
                })}
                {
                    selectedPlace?.coordinates_lat &&
                    (
                        <InfoWindow
                            position={{ lat: Number(selectedPlace.coordinates_lat), lng: Number(selectedPlace.coordinates_lng) }}
                            clickable={true}
                            onCloseClick={() => setSelectedPlace({})}
                        >
                            <a href={__DEV__ ? `http://localhost:19006/space/${selectedPlace.id}` : REACT_APP_ENV === "development" ? `https://staging.d18w937drvm0l.amplifyapp.com/space/${selectedPlace.id} ` : `https://www.hookhub.co/space/${selectedPlace.id}`} target="_blank" style={styles.hyperLink}>
                                <View style={[globalStyles.centerColumn]}>
                                    <Image
                                        source={{ uri: `${cloudfrontURL}${selectedPlace.images[0]}` }}
                                        style={[{ width: 250, height: 160 }, styles.image]}
                                    />
                                    <Text style={{ fontSize: 16, textAlign: "center", marginBottom: 10 }}>{selectedPlace.title}</Text>
                                    <View style={[globalStyles.center, { flexWrap: "wrap" }]}>
                                        {selectedPlace.type_shortterm && (
                                            <Badge height={25} width={110} margin={2} size={"small"} color={stayType === 'vacation' ? colors.orange40 : colors.grey10}><Text>Nightly $ {selectedPlace.price_shortterm}</Text></Badge>
                                        )}
                                        {selectedPlace.type_longterm && (
                                            <Badge height={25} width={110} margin={2} size={"small"} color={stayType === 'monthly' ? colors.orange40 : colors.grey10}><Text>Monthly $ {selectedPlace.price_longterm}</Text></Badge>
                                        )}
                                        {selectedPlace.type_yearly && (
                                            <Badge height={25} width={110} margin={2} size={"small"} color={stayType === 'type_yearly' ? colors.orange40 : colors.grey10}><Text>Yearly $ {selectedPlace.price_yearly}</Text></Badge>
                                        )}
                                        {selectedPlace.type_storage && (
                                            <Badge height={25} width={110} margin={2} size={"small"} color={stayType === 'storage' ? colors.orange40 : colors.grey10}><Text>Storage $ {selectedPlace.price_storage}</Text></Badge>
                                        )}
                                    </View>
                                </View>
                            </a>
                        </InfoWindow>
                    )
                }
            </GoogleMap >
        </FixedHeightCard>
    ) : <></>
}

const styles = StyleSheet.create({
    image: {
        borderRadius: 8
    },
    hyperLink: {
        textDecorationLine: "none"
    },
});

export default AllSpacesMapView;
