import React, { useState, useContext } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  Text,
  useWindowDimensions,
  Pressable,
  Platform,
} from "react-native";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import { UserDataContext } from "../../utils/UserContextProvider";
import FixedHeightCard from "../CustomElements/FixedHeightCard";
import Badge from "../CustomElements/Badge";
import { globalStyles } from "../../styles/styles";
import { colors } from "../../styles/colors";
import CloseButtonIcon from "../CustomElements/CloseButtonIcon";
import CustomTextInput from "../CustomElements/CustomTextInput";
import { CustomToggleButton } from "../CustomElements/CustomToggleButton";
import { notifyHookhub } from "../../lambdaFunctions/notifyHookhub";

const HelpChatMessageInput = ({
  width,
  height,
  setShowChatBox,
  setEmailSentSuccess,
  mobileView,
  emailSentSuccess
}) => {
  const { user } = useContext(UserDataContext);
  const { userData } = user;
  const { sub, email, phone_number, name } = userData;
  const [inputValue, setInputValue] = useState("");
  const [emailSelected, setEmailSelected] = useState(false);
  const [textSelected, setTextSelected] = useState(false);

  const sendHelpChatEmail = () => {
    const subject = `HELP CHAT message`
    const body = `MESSAGE: ${inputValue}. /// RESPONSE PREFERENCE: TEXT>> ${textSelected}  EMAIL>> ${emailSelected} ///. CONTACT INFO: email: ${email}  phone: ${phone_number}`
    notifyHookhub({ subject, body, userID: sub, userEmail: email, userName: name })
    setEmailSentSuccess(true)
  };

  const closeAndReset = () => {
    setTextSelected(false)
    setEmailSelected(false)
    setInputValue("")
    setEmailSentSuccess(false)
    setShowChatBox()
  }

  return (
    <>
      <CloseButtonIcon margin={3} onPress={() => closeAndReset()} />

      <View style={[emailSentSuccess ? globalStyles.center : globalStyles.flexStart, { flex: 1 }]}>
        {emailSentSuccess ? (
          <View style={[globalStyles.centerColumn, { width: mobileView ? width / 1.7 : width / 3.2 }]}>
            <Ionicons
              name="checkmark-circle-sharp"
              size={35}
              color={colors.brightBlue}
              marginLeft={10}
            />
            <Text style={{ textAlign: "center", fontSize: 15 }}>Success! Your message has been received, we will reach out in less than 12 hours.</Text>

          </View>
        ) : (
          <View style={globalStyles.centerColumn}>
            <ScrollView keyboardDismissMode="interactive">
              <CustomTextInput
                multiline={true}
                width={mobileView ? width / 1.5 : width / 3.5}
                height={height / 2.65}
                onChangeText={(e) => setInputValue(e)}
                label="Title"
                labelHidden
                value={inputValue}
                backgroundColor="#fff"
                placeholder={'I have a question…'}
                showBorder={false}
              />
            </ScrollView>
            <Text style={{ width: mobileView ? width / 2 : width / 3.5, marginTop: 15, marginBottom: mobileView ? 0 : 10, textAlign: "center" }}>How would you like us to get back to you?</Text>
            <View style={[globalStyles.center, { marginTop: 5, marginBottom: mobileView ? 20 : 0 }]}>
              <View>
                <CustomToggleButton
                  text={"respond via email"}
                  updateSelection={() => setEmailSelected(!emailSelected)}
                  selection={emailSelected}
                />
                <CustomToggleButton
                  text={"respond via text"}
                  updateSelection={() => setTextSelected(!textSelected)}
                  selection={textSelected}
                />
              </View>
              <Pressable onPress={() => sendHelpChatEmail()}>
                <MaterialIcons
                  style={[styles.send, { backgroundColor: (!!inputValue && (textSelected || emailSelected) ? colors.brightBlue : colors.amplifyNeutral60) }]}
                  name="send"
                  size={18}
                  color="white"
                />
              </Pressable>
            </View>
          </View>
        )}
      </View >
    </>
  )
}

const HelpChat = ({ }) => {
  const { width, height } = useWindowDimensions();
  const [showChatBox, setShowChatBox] = useState(false);
  const [emailSentSuccess, setEmailSentSuccess] = useState(false);
  const mobileApp = Platform.OS !== 'web';
  const mobileView = width < 600;

  return (
    <>
      <View style={[styles.bottomButton, { bottom: mobileApp ? 20 : 70, right: mobileApp ? -20 : 20 }]}>
        {!showChatBox ? (
          <Pressable onPress={() => setShowChatBox(!showChatBox)}>
            <Badge onPress={() => setShowChatBox(!showChatBox)} margin={0} color={colors.orange80} width={95} height={29}>
              <View style={globalStyles.center}>
                <Ionicons name="chatbox-ellipses" size={24} color={"#fff"
                } />
                <Text style={styles.chatText}>chat</Text>
              </View>
            </Badge>
          </Pressable>
        ) : (
          <FixedHeightCard height={emailSentSuccess ? height / 4 : mobileView ? height / 1.5 : height / 1.6} width={mobileView ? width / 1.3 : width / 3} margin={10} padding={5} justifyContent="center" alignContent="center" alignItems="center">
            <HelpChatMessageInput
              height={height}
              width={width}
              mobileView={mobileView}
              setShowChatBox={() => setShowChatBox(!showChatBox)}
              setEmailSentSuccess={(v) => setEmailSentSuccess(v)}
              emailSentSuccess={emailSentSuccess}
            />
          </FixedHeightCard>
        )}
      </View>
    </>
  );
};

export default HelpChat;

const styles = StyleSheet.create({
  accessoryView: {
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center"
  },
  accessoryContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  chatText: {
    marginHorizontal: 6,
    color: "#fff",
    fontSize: 16
  },
  bottomButton: {
    position: "absolute"
  },
  input: {
    borderRadius: 18,
    flex: 1,
    padding: 10,
    fontSize: 15,
    flexGrow: 1,
    color: "#262626",
    backgroundColor: "#F2F3F5",
  },
  send: {
    padding: 7,
    borderRadius: 15,
    marginLeft: 10
  },
});
