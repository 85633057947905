// Action Types
export const SET_STRIPE_PROMISE = 'SET_STRIPE_PROMISE';
export const SET_SPACE_TYPE_FILTER = 'SET_SPACE_TYPE_FILTER';
export const SET_SPACE_STAY_TYPE = 'SET_SPACE_STAY_TYPE';
export const SET_DEVICE_VIEW = 'SET_DEVICE_VIEW';
export const SET_RIG_UPLPOAD_STATUS = 'SET_RIG_UPLPOAD_STATUS';
export const SET_PAYOUT_STATUS = 'SET_PAYOUT_STATUS';
export const SET_ADDITIONAL_SPACES_OPTION = 'SET_ADDITIONAL_SPACES_OPTION';
export const SET_NOTIFICATION_VIEWED = 'SET_NOTIFICATION_VIEWED';
export const SET_HOST_STATUS = 'SET_HOST_STATUS';
export const SET_AUTHED = 'SET_AUTHED';
export const SET_IDENTITY_VERIFICATION_NOT_COMPLETE = 'SET_IDENTITY_VERIFICATION_NOT_COMPLETE';
export const HOLD_BOOKING_REQUEST_DATA = 'HOLD_BOOKING_REQUEST_DATA';
export const ALERT_NOTICE_OF_SUCCESS = 'ALERT_NOTICE_OF_SUCCESS';


export const setSpaceTypeFilter = value => (
  {
    type: SET_SPACE_TYPE_FILTER,
    spaceTypeFilter: value.spaceTypeFilter
  }
);


export const setStripePromise = value => (
  {
    type: SET_STRIPE_PROMISE,
    stripePromise: value.stripePromise
  }
);

export const setDeviceView = value => (
  {
    type: SET_DEVICE_VIEW,
    mobileView: value.mobileView
  }
);

export const setSpaceStayType = value => (
  {
    type: SET_SPACE_STAY_TYPE,
    stayType: value.stayType
  }
);

export const setAuthed = value => (
  {
    type: SET_AUTHED,
    setAuthed: value.setAuthed,
    authMethod: value.authMethod
  }
);


export const setRigUploadStatus = value => (
  {
    type: SET_RIG_UPLPOAD_STATUS,
    rigNeedsUploading: value.rigNeedsUploading
  }
);

export const setPayoutStatus = value => (
  {
    type: SET_PAYOUT_STATUS,
    payoutNeeded: value.payoutNeeded
  }
);

export const setIdentityVerificationNotComplete = value => (
  {
    type: SET_IDENTITY_VERIFICATION_NOT_COMPLETE,
    required: value.required
  }
);

export const temporarilyHoldBookingRequestData = value => (
  {
    type: HOLD_BOOKING_REQUEST_DATA,
    bookingRequestData: value.bookingRequestData
  }
);

export const alertNoticeOfSuccess = value => (
  {
    type: ALERT_NOTICE_OF_SUCCESS,
    success: value.success,
    actionCompletedAfterIDVerified: value.actionCompletedAfterIDVerified
  }
);

export const setAdditionalSpacesOption = value => (
  {
    type: SET_ADDITIONAL_SPACES_OPTION,
    additionalSpacesBoolean: value.additionalSpacesBoolean,
    numberAdditionalSpaces: value.numberAdditionalSpaces,
  }
);

export const setHostStatus = value => (
  {
    type: SET_HOST_STATUS,
    imAHost: value.imAHost
  }
);


export const setNotificationViewed = value => (
  {
    type: SET_NOTIFICATION_VIEWED,
    notifyOfNationwide: value.notifyOfNationwide
  }
);
