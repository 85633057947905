import React, { useState, useEffect } from "react";
import { StyleSheet, Platform, Pressable, Image, useWindowDimensions, View, Text } from "react-native";
import {
  Fontisto,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import * as ImagePicker from "expo-image-picker";
import Flex from "../CustomElements/Flex"
import Card from "../CustomElements/Card"
import { LoadingComponent } from "../../components/LoadingComponent";
import { useSelector } from "react-redux";
import getEnvVars from '../../../environment';
import FixedHeightCard from "../CustomElements/FixedHeightCard";
import { UUID } from "../../utils/UUID";
import { pickImage } from "../../utils/pickImage";
const { cloudfrontURL } = getEnvVars();


const ImagePreview = ({ mobileView, index, updateImage, icon, savedImage = null, savedImagePreview = null, setLoading }) => {
  const [image, setImage] = useState(null);
  const [error, setError] = useState(false);
  const { width } = useWindowDimensions();


  const selectImage = async () => {
    setLoading(true);
    const imageValue = `${index}-${UUID()}.jpg`;
    let formattedImage = await pickImage(false);
    if (formattedImage) {
      const response = await fetch(formattedImage.uri);
      const blob = await response.blob();
      updateImage(imageValue, formattedImage.uri, blob);
    } else {
      setLoading(false)
    }
  };

  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web') {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
          alert('Sorry, we need camera roll permissions to make this work!');
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (savedImagePreview !== null) {
      setImage(savedImagePreview);
      setError(false);
    } else if (savedImage !== null) {
      setImage(`${cloudfrontURL}${savedImage}`)
    }
  }, [savedImagePreview]);

  return (
    <Pressable onPress={selectImage} style={styles.multPhotoContainer}>
      {!!image && !error ? (
        <Card width={!!mobileView ? width / 1.6 : 240} marginBottom={13}>
          <Image
            source={{ uri: image }}
            padding={12}
            style={{ width: !!mobileView ? width / 1.77 : 240, height: !!mobileView ? width / 1.75 : 240 }}
            onError={() => setError(true)}
          />
        </Card>
      ) : (
        <FixedHeightCard
          padding={mobileView ? 11 : 70}
          marginBottom={13}
          marginLeft={5}
          marginRight={5}
          width={mobileView ? width / 1.6 : 240}
          height={mobileView ? width / 1.6 : 240}
          alignItems="center"
          justifyContent="center"
          alignContent="center"
          alignSelf="center"
        >
          <View
            height={mobileView ? width / 1.6 : 240}
            justifyContent="center"
            alignContent="center"
            alignSelf="center"
            alignItems="center"
          >
            {icon == "photograph" ? (
              <View
                justifyContent="center"
                alignContent="center"
                alignSelf="center"
                alignItems="center"
              >
                <Fontisto name="photograph" size={80} color="grey" />
              </View>
            ) : (
              <MaterialCommunityIcons
                name="image-plus"
                size={80}
                color="#BDC3C7"
              />
            )}</View>
        </FixedHeightCard>
      )}
    </Pressable>
  );
};

const AddMore = ({ setAddRow, mobileView }) => {
  const { width } = useWindowDimensions();
  return (
    <Pressable onPress={() => setAddRow()} style={styles.multPhotoContainer}>
      <Card
        padding={!!mobileView ? 9 : 19}
        margin={2}
        width={width / 1.8}
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          alignContent="center"
        >
          <MaterialCommunityIcons
            name="image-plus"
            size={80}
            color="#BDC3C7"
          />
        </Flex>
      </Card>
    </Pressable>
  )
};

const ImageRow = ({ image1, image2, imagePreview1, setLoading, imagePreview2, indexes, updateImage, mobileView, defaultOpen = true }) => {
  const [addRow, setAddRow] = useState(false);

  useEffect(() => {
    if (!!image1 && Object.keys(image1).length || !!imagePreview1 && Object.keys(imagePreview1).length) {
      setAddRow(true)
    }
  }, [image1, imagePreview1]);

  return (
    <Flex
      wrap="wrap"
      justifyContent="center"
      paddingTop={2}
      paddingBottom={2}
    >
      {addRow || defaultOpen ? (
        <>
          <ImagePreview
            savedImage={image1}
            savedImagePreview={imagePreview1}
            index={indexes[0]}
            mobileView={mobileView}
            updateImage={(x, y, z) => updateImage(indexes[0], x, y, z)}
            icon="photograph"
            setLoading={(v) => setLoading(v)}
          />
          <ImagePreview
            savedImage={image2}
            savedImagePreview={imagePreview2}
            index={indexes[1]}
            mobileView={mobileView}
            updateImage={(x, y, z) => updateImage(indexes[1], x, y, z)}
            icon="photograph"
            setLoading={(v) => setLoading(v)}
          />
        </>
      ) : (
        <AddMore mobileView={mobileView} setAddRow={() => setAddRow(true)} />
      )}
    </Flex>
  )
};

const SelectPhotos = ({ imageUploadError, loading, setLoading, setImages, images = null, imagePreviews = null }) => {
  const [showRow3, setShowRow3] = useState(false);
  const [showRow4, setShowRow4] = useState(false);
  const [showRow5, setShowRow5] = useState(false);
  const [showRow6, setShowRow6] = useState(false);
  const [showRow7, setShowRow7] = useState(false);
  const [showRow8, setShowRow8] = useState(false);
  const [showRow9, setShowRow9] = useState(false);
  const [showRow10, setShowRow10] = useState(false);
  const [showRow11, setShowRow11] = useState(false);
  const [showRow12, setShowRow12] = useState(false);
  const { mobileView } = useSelector(state => state.currentUser);

  useEffect(() => {
    if (Object.keys(images).length > 3 || Object.keys(imagePreviews).length > 3) {
      setShowRow3(true);
      if (Object.keys(images).length > 5 || Object.keys(imagePreviews).length > 5) {
        setShowRow4(true)
        if (Object.keys(images).length > 7 || Object.keys(imagePreviews).length > 7) {
          setShowRow5(true)
          if (Object.keys(images).length > 9 || Object.keys(imagePreviews).length > 9) {
            setShowRow6(true)
            if (Object.keys(images).length > 11 || Object.keys(imagePreviews).length > 11) {
              setShowRow7(true)
              if (Object.keys(images).length > 13 || Object.keys(imagePreviews).length > 13) {
                setShowRow8(true)
                if (Object.keys(images).length > 15 || Object.keys(imagePreviews).length > 15) {
                  setShowRow9(true)
                  if (Object.keys(images).length > 17 || Object.keys(imagePreviews).length > 17) {
                    setShowRow10(true)
                    if (Object.keys(images).length > 19 || Object.keys(imagePreviews).length > 19) {
                      setShowRow11(true)
                      if (Object.keys(images).length > 21 || Object.keys(imagePreviews).length > 21) {
                        setShowRow12(true)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }, [images, imagePreviews]);

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          {!!imageUploadError && <Text>{imageUploadError}</Text>}
          <ImageRow
            mobileView={mobileView}
            image1={images && images[0] ? images[0] : null}
            image2={images && images[1] ? images[1] : null}
            imagePreview1={imagePreviews && imagePreviews[0] ? imagePreviews[0] : null}
            imagePreview2={imagePreviews && imagePreviews[1] ? imagePreviews[1] : null}
            indexes={[0, 1]}
            updateImage={(w, x, y, z) => setImages(w, x, y, z)}
            loading={loading}
            setLoading={(v) => setLoading(v)}
          />
          <ImageRow
            mobileView={mobileView}
            image1={images && images[2] ? images[2] : null}
            image2={images && images[3] ? images[3] : null}
            imagePreview1={imagePreviews && imagePreviews[2] ? imagePreviews[2] : null}
            imagePreview2={imagePreviews && imagePreviews[3] ? imagePreviews[3] : null}
            indexes={[2, 3]}
            updateImage={(w, x, y, z) => setImages(w, x, y, z)}
            loading={loading}
            setLoading={(v) => setLoading(v)}
          />
          {showRow3 && (
            <ImageRow
              mobileView={mobileView}
              image1={images && images[4] ? images[4] : null}
              image2={images && images[5] ? images[5] : null}
              imagePreview1={imagePreviews && imagePreviews[4] ? imagePreviews[4] : null}
              imagePreview2={imagePreviews && imagePreviews[5] ? imagePreviews[5] : null}
              indexes={[4, 5]}
              updateImage={(w, x, y, z) => setImages(w, x, y, z)}
              defaultOpen={false}
              loading={loading}
              setLoading={(v) => setLoading(v)}
            />
          )}
          {showRow4 && (
            <ImageRow
              mobileView={mobileView}
              image1={images && images[6] ? images[6] : null}
              image2={images && images[7] ? images[7] : null}
              imagePreview1={imagePreviews && imagePreviews[6] ? imagePreviews[6] : null}
              imagePreview2={imagePreviews && imagePreviews[7] ? imagePreviews[7] : null}
              indexes={[6, 7]}
              updateImage={(w, x, y, z) => setImages(w, x, y, z)}
              defaultOpen={false}
              loading={loading}
              setLoading={(v) => setLoading(v)}
            />
          )}
          {showRow5 && (
            <ImageRow
              mobileView={mobileView}
              image1={images && images[8] ? images[8] : null}
              image2={images && images[9] ? images[9] : null}
              imagePreview1={imagePreviews && imagePreviews[8] ? imagePreviews[8] : null}
              imagePreview2={imagePreviews && imagePreviews[9] ? imagePreviews[9] : null}
              indexes={[8, 9]}
              updateImage={(w, x, y, z) => setImages(w, x, y, z)}
              defaultOpen={false}
              loading={loading}
              setLoading={(v) => setLoading(v)}
            />
          )}
          {showRow6 && (
            <ImageRow
              mobileView={mobileView}
              image1={images && images[10] ? images[10] : null}
              image2={images && images[11] ? images[11] : null}
              imagePreview1={imagePreviews && imagePreviews[10] ? imagePreviews[10] : null}
              imagePreview2={imagePreviews && imagePreviews[11] ? imagePreviews[11] : null}
              indexes={[10, 11]}
              updateImage={(w, x, y, z) => setImages(w, x, y, z)}
              defaultOpen={false}
              loading={loading}
              setLoading={(v) => setLoading(v)}
            />
          )}
          {showRow7 && (
            <ImageRow
              mobileView={mobileView}
              image1={images && images[12] ? images[12] : null}
              image2={images && images[13] ? images[13] : null}
              imagePreview1={imagePreviews && imagePreviews[12] ? imagePreviews[12] : null}
              imagePreview2={imagePreviews && imagePreviews[13] ? imagePreviews[13] : null}
              indexes={[12, 13]}
              updateImage={(w, x, y, z) => setImages(w, x, y, z)}
              defaultOpen={false}
              loading={loading}
              setLoading={(v) => setLoading(v)}
            />
          )}
          {showRow8 && (
            <ImageRow
              mobileView={mobileView}
              image1={images && images[14] ? images[14] : null}
              image2={images && images[15] ? images[15] : null}
              imagePreview1={imagePreviews && imagePreviews[14] ? imagePreviews[14] : null}
              imagePreview2={imagePreviews && imagePreviews[15] ? imagePreviews[15] : null}
              indexes={[14, 15]}
              updateImage={(w, x, y, z) => setImages(w, x, y, z)}
              defaultOpen={false}
              loading={loading}
              setLoading={(v) => setLoading(v)}
            />
          )}
          {showRow9 && (
            <ImageRow
              mobileView={mobileView}
              image1={images && images[16] ? images[16] : null}
              image2={images && images[17] ? images[17] : null}
              imagePreview1={imagePreviews && imagePreviews[16] ? imagePreviews[16] : null}
              imagePreview2={imagePreviews && imagePreviews[17] ? imagePreviews[17] : null}
              indexes={[16, 17]}
              updateImage={(w, x, y, z) => setImages(w, x, y, z)}
              defaultOpen={false}
              loading={loading}
              setLoading={(v) => setLoading(v)}
            />
          )}
          {showRow10 && (
            <ImageRow
              mobileView={mobileView}
              image1={images && images[18] ? images[18] : null}
              image2={images && images[19] ? images[19] : null}
              imagePreview1={imagePreviews && imagePreviews[18] ? imagePreviews[18] : null}
              imagePreview2={imagePreviews && imagePreviews[19] ? imagePreviews[19] : null}
              indexes={[18, 19]}
              updateImage={(w, x, y, z) => setImages(w, x, y, z)}
              defaultOpen={false}
              loading={loading}
              setLoading={(v) => setLoading(v)}
            />
          )}
          {showRow11 && (
            <ImageRow
              mobileView={mobileView}
              image1={images && images[20] ? images[20] : null}
              image2={images && images[21] ? images[21] : null}
              imagePreview1={imagePreviews && imagePreviews[20] ? imagePreviews[20] : null}
              imagePreview2={imagePreviews && imagePreviews[21] ? imagePreviews[21] : null}
              indexes={[20, 21]}
              updateImage={(w, x, y, z) => setImages(w, x, y, z)}
              defaultOpen={false}
              loading={loading}
              setLoading={(v) => setLoading(v)}
            />
          )}
          {showRow12 && (
            <ImageRow
              mobileView={mobileView}
              image1={images && images[22] ? images[22] : null}
              image2={images && images[23] ? images[23] : null}
              imagePreview1={imagePreviews && imagePreviews[22] ? imagePreviews[22] : null}
              imagePreview2={imagePreviews && imagePreviews[23] ? imagePreviews[23] : null}
              indexes={[22, 23]}
              updateImage={(w, x, y, z) => setImages(w, x, y, z)}
              defaultOpen={false}
              loading={loading}
              setLoading={(v) => setLoading(v)}
            />
          )}
        </>
      )
      }
    </ >
  );
};

export default SelectPhotos;

const styles = StyleSheet.create({
  multPhotoContainer: {
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
  },
});
