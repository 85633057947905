import { StyleSheet, Dimensions } from 'react-native';
import { colors } from './colors';
const windowWidth = Dimensions.get("window").width;

export const globalStyles = StyleSheet.create({
    textBody: {
        fontSize: 15
    },
    boldText: {
        fontWeight: "bold",
        textAlign: 'center',
        fontSize: 20
    },
    underlineText: {
        textDecorationLine: "underline",
        textAlign: "center",
    },
    buttonText: {
        fontSize: 17
    },
    shadowedText: {
        textShadowColor: colors.amplifyNeutral80,
        textShadowOffset: { width: -1, height: 1 },
        textShadowRadius: 9
    },
    shadowedTextDark: {
        textShadowColor: "#000",
        textShadowOffset: { width: -1, height: 1 },
        textShadowRadius: 6
    },
    center: {
        flexDirection: "row",
        textAlign: "center",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        textAlign: "center"
    },
    centerColumn: {
        flexDirection: "column",
        textAlign: "center",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        textAlign:"center",
        flexWrap: "wrap"
    },
    centerWithPadding: {
        flexDirection: "row",
        padding: 10,
        textAlign: "center",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },
    flexStart: {
        flexDirection: "row",
        textAlign: "flex-start",
        justifyContent: "flex-start",
        alignContent: "flex-start",
        alignItems: "flex-start",
    },
    flexStartColumn: {
        flexDirection: "column",
        textAlign: "flex-start",
        justifyContent: "flex-start",
        alignContent: "flex-start",
        alignItems: "flex-start"
    },
    pageHorizontalMargins: {
        width: windowWidth > 1200 ? "90%" : windowWidth > 700 ? "95%" : "100%",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        alignSelf: "center"
    },
    spaceBetween: {
        flexDirection: "row",
        textAlign: "space-between",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "space-between",
        width: "100%"
    },
    spaceBetweenColumn: {
        flexDirection: "column",
        textAlign: "space-between",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "space-between",
        width: "100%"
    },
    flexEnd: {
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        alignContent: "flex-end"
    },
    flexEndColumn: {
        flexDirection: "column",
        textAlign: "flex-end",
        justifyContent: "flex-end",
        alignContent: "flex-end",
        alignItems: "flex-end"
    },
});