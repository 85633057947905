import React, { useState, useEffect } from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  View,
  Image,
  Platform
} from "react-native";
import * as ImagePicker from "expo-image-picker";
import { colors } from '../../styles/colors';
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import CustomModal from '../CustomElements/CustomModal';
import Flex from '../CustomElements/Flex';
import { LoadingComponent } from '../LoadingComponent';
import { pickImage } from '../../utils/pickImage';
import CustomButton from '../CustomElements/CustomButton';
import { globalStyles } from '../../styles/styles';

const ImageDisplay = ({ editProfile, image }) => {
  return (
    <>
      {image ? <Image style={styles.logoImage} source={{ uri: image }} /> : <Ionicons name="person-circle" size={120} color="grey" />}
      {editProfile && (
        <View style={{ position: "absolute", top: image ? 15 : 0, right: image ? 5 : 0, backgroundColor: colors.lightBlue, borderRadius: 100, padding: 5 }}>
          <MaterialCommunityIcons
            name="file-image-plus"
            size={40}
            color={colors.white}
          />
        </View>
      )}
    </>
  )
};

export function AvatarImage({
  image = null,
  editProfile = false,
  setImage,
  uploadResourceS3
}) {
  const [modalVisible, setModalVisible] = useState(false);
  const [progressStatus, setprogressStatus] = useState(0);

  const formatImage = async () => {
    let formattedImage = await pickImage(true);
    setImage(formattedImage.uri)
  };

  const upload = () => {
    uploadResourceS3()
    setModalVisible(false)
  };

  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web') {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
          alert('Sorry, we need camera roll permissions to make this work!');
        }
      }
    })();
  }, []);

  useEffect(() => {
    modalVisible && formatImage()
  }, [modalVisible]);

  return (
    <>
      <CustomModal modalVisible={modalVisible} setModalVisible={() => setModalVisible(!modalVisible)}>
        <View style={globalStyles.centerColumn}>
          <Pressable onPress={formatImage} justifyContent="center">
            <>
              <ImageDisplay image={image} editProfile={editProfile} />
              {progressStatus === 0 ? (
                <CustomButton
                  width={150}
                  marginTop={20}
                  backgroundColor={colors.orange50}
                  onPress={() => upload()}
                  title="Edit profile"
                >
                  <Text style={{ color: "#fff" }}>LOOKS GOOD</Text>
                </CustomButton>
              ) : (
                <LoadingComponent />
              )}
            </>
          </Pressable>
          <CustomButton hideBorder width={200} onPress={() => setModalVisible(false)}>
            <Text style={styles.canceltext}>cancel</Text>
          </CustomButton>
        </View>
      </CustomModal>

      <Flex
        direction="column"
        textAlign="left"
      >
        {editProfile ? (
          <Pressable
            onPress={() => setModalVisible(true)}
            textAlign="left"
          >
            <ImageDisplay image={image} editProfile={editProfile} />
          </Pressable>
        ) : (
          <ImageDisplay image={image} editProfile={editProfile} />
        )}
      </Flex>
    </>
  );
}

const styles = StyleSheet.create({
  types: {
    color: colors.grey20,
    marginVertical: 10,
  },
  container: {
    margin: 20,
  },
  image: {
    aspectRatio: 3 / 2,
    borderRadius: 10,
    width: "100%",
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    fontWeight: "500"
  },
  canceltext: {
    fontSize: 16,
    lineHeight: 21,
    letterSpacing: 0.25,
    color: "black",
  },
  textStyle: {
    color: "white",
    textAlign: "center",
  },
  logoImage: {
    height: 150,
    width: 150,
    borderRadius: 100,
    marginTop: 20,
    borderWidth: 1,
  },
});
