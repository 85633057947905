import * as Linking from "expo-linking";
import { useContext, useEffect, useState } from "react";
import { ErrorDisplay } from "../CustomElements/ErrorDisplay";
import { UserDataContext } from "../../utils/UserContextProvider";
import {
    StyleSheet,
    Text,
    Image,
    View,
    useWindowDimensions,
    Platform
} from "react-native";
import { useSelector } from "react-redux";
import CustomModal from "../CustomElements/CustomModal";
import CustomSection from "../CustomElements/CustomSection";
import { colors } from "../../styles/colors";
import CustomButton from "../CustomElements/CustomButton";
import { globalStyles } from "../../styles/styles";
import CloseButtonUnderlined from "../CustomElements/CloseButtonUnderlined";
import { LoadingComponent } from "../LoadingComponent";
import { createIDVerificationUser } from "../../api/IDVerificationAPI";
import DateOfBirthInput from "../other/DateOfBirthInput";
import RNDateTimePicker from '@react-native-community/datetimepicker';


export const UserConsentForIDVerificationModal = ({
    modalVisible,
    setModalVisible,
    continueToVerification,
    loadingPortal,
    dob,
    setdob,
    webPlatform
}) => {
    const { mobileView } = useSelector(state => state.currentUser);
    const { width } = useWindowDimensions();
    const [showDatePickerMobile, setShowDatePickerMobile] = useState(false);
    const [displayDateMobile, setDisplayDateMobile] = useState("");
    const [month, setmonth] = useState("MM");
    const [day, setday] = useState("dd");
    const [year, setyear] = useState("YYYY");

    const setDateMobile = (e, date) => {
        setdob(date);
        const formattedDate = new Intl.DateTimeFormat('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        }).format(date);
        setDisplayDateMobile(formattedDate);
        setShowDatePickerMobile(false)
    };

    useEffect(() => {
        if (webPlatform) {
            if (month !== "MM" && day !== "dd" && year !== "YYYY") {
                setdob(`${day}-${month}-${year}`)
            } else { setdob(null) }
        }
    }, [month, day, year]);


    return (
        <CustomModal
            modalVisible={modalVisible}
            setModalVisible={() => setModalVisible(!modalVisible)}
            width={mobileView ? width / 1.1 : width / 1.7}
        >
            <View style={globalStyles.centerColumn}>
                <Image
                    style={[
                        styles.miniLogoImage,
                        {
                            margin: 10,
                            marginTop: 15,
                            width: mobileView ? 154 : 200,
                            height: mobileView ? 40 : 52
                        }]}
                    source={require("../../../assets/images/hookhub-logo_mini.png")}
                />
                <CustomSection backgroundColor={colors.orange10} customWidth={mobileView ? width / 1.4 : width / 1.8}>
                    <Text style={{ fontSize: 17, textAlign: "center", margin: 20 }}>
                        We believe in a great experience for both Host and Renter. To acheive this, we now require a simple <Text style={{ fontWeight: "500" }}>Identity Verification to prevent fraudulaunt behavior and promote safety and trust.</Text>
                    </Text>
                </CustomSection>
                {loadingPortal ? (
                    <LoadingComponent />
                ) : (
                    <>
                        <View style={[globalStyles.centerColumn, { marginVertical: 10, width: mobileView ? width / 1.3 : width / 1.85 }]}>
                            <Text style={{ textAlign: "center", marginBottom: 5 }}>* Date of Birth is required to begin Identity Verification</Text>

                            <View style={[globalStyles.centerColumn, { marginVertical: 10, width: mobileView ? width / 1.3 : width / 1.85 }]}>
                                <Text style={{ fontSize: 15, textAlign: "center" }}>
                                    To complete verification, you will scan a valid photo ID. The information you provide will help us confirm your identity. Hookhub will only have access to whether your identity is approved or not.
                                </Text>
                            </View>
                        </View>
                        {webPlatform ? (
                            <DateOfBirthInput
                                day={day}
                                setday={(v) => setday(v)}
                                month={month}
                                setmonth={(v) => setmonth(v)}
                                year={year}
                                setyear={(v) => setyear(v)}
                            />
                        ) : (
                            <>
                                <CustomButton onPress={() => setShowDatePickerMobile(!showDatePickerMobile)} height={40} width={200} marginTop={15} marginBottom={15}> <Text style={{ fontSize: 16 }}>{!displayDateMobile ? "Enter date of birth" : displayDateMobile}</Text></CustomButton>
                                {showDatePickerMobile && <RNDateTimePicker value={dob} onChange={(e, d) => setDateMobile(e, d)} mode="date" display="spinner" positiveButton={{ label: 'OK', textColor: 'white' }} negativeButton={{ label: 'CANCEL', textColor: 'grey' }} />}
                            </>
                        )}
                        <CustomButton
                            backgroundColor={colors.orange40}
                            disabled={loadingPortal || (webPlatform ? !dob : !displayDateMobile)}
                            width={170}
                            marginTop={0}
                            height={40}
                            onPress={() => continueToVerification()}
                            marginBottom={20}
                        >
                            <Text style={{ fontSize: 17 }}>Continue</Text>
                        </CustomButton>
                        <CloseButtonUnderlined marginTop={0} padding={0} onPress={() => setModalVisible()} />
                    </>
                )}
            </View >
        </CustomModal >

    );
};

export const IDVerification = ({
    version = "orange50",
    buttonWidth = 200
}) => {
    const { user } = useContext(UserDataContext);
    const { userData, setUserData } = user;
    const updateUser = (data) => setUserData(data);
    const { name, email, sub } = userData;
    const [error, setError] = useState(false);
    const [openUserConsentModal, setOpenUserConsentModal] = useState(false);
    const [loadingPortal, setLoadingPortal] = useState(false);
    const [dob, setdob] = useState(new Date());
    const webPlatform = Platform.OS === "web";

    const formatDate = (date) => {
        let options = [{ day: "2-digit" }, { month: "2-digit" }, { year: 'numeric' }];
        function format(option) {
            let formatter = new Intl.DateTimeFormat('en', option);
            return formatter.format(date);
        }
        return options.map(format).join("-");
    }

    const openPortal = async () => {
        const dateofbirth = webPlatform ? dob : formatDate(dob);
        setLoadingPortal(true);
        const namesArr = name.split(" ");
        const data = {
            firstName: namesArr[0],
            lastName: namesArr[namesArr.length - 1],
            dob: dateofbirth,
            email: email
        };

        const generatedData = await createIDVerificationUser({ data, currentUserData: userData });
        setLoadingPortal(false)
        setOpenUserConsentModal(false)
        if (generatedData) {
            const { generatedLink, updatedUserData } = generatedData;
            updateUser(updatedUserData)
            Linking.openURL(generatedLink);
        } else {
            setError(true)
        }
    }

    const closeModal = () => {
        setError(false)
        setOpenUserConsentModal(false)
        setLoadingPortal(false)
    }

    return (
        <View>
            {error ? (
                <ErrorDisplay error="Error loading portal. Hookhub has been notified." />
            ) : (
                <CustomButton onPress={() => setOpenUserConsentModal(true)} width={buttonWidth} marginTop={10} backgroundColor={version === "orange50" ? colors.orange50 : colors.white}>
                    <Text style={{ color: version === "orange50" ? "#fff" : "#000", fontWeight: "500", fontSize: 15 }}>{version === "orange50" ? "Get the badge" : "Verify Me"}</Text>
                </CustomButton>
            )}
            <UserConsentForIDVerificationModal
                modalVisible={openUserConsentModal}
                setModalVisible={() => closeModal()}
                name={name}
                continueToVerification={() => openPortal()}
                loadingPortal={loadingPortal}
                dob={dob}
                setdob={(v) => setdob(v)}
                sub={sub}
                webPlatform={webPlatform}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    logoImage: {
        justifyContent: "center",
    },
    miniLogoImage: {
        justifyContent: "flex-start",
    },
});
