import React, { useState, useEffect, useContext } from "react";
import { StyleSheet, useWindowDimensions, ScrollView, Text, Image, View, ImageBackground, Platform } from "react-native";
import Header from "../../components/CustomElements/Header"
import LearnMore from "../../components/LearnMore"
import { useSelector } from "react-redux";
import { Entypo } from '@expo/vector-icons';
import { useNavigation } from "@react-navigation/native";
import CustomButton from "../../components/CustomElements/CustomButton";
import { colors } from "../../styles/colors";
import CustomSection from "../../components/CustomElements/CustomSection";
import HelpForm from "../../components/other/HelpForm";
import { Auth } from "aws-amplify";
import getEnvVars from "../../../environment";
import { UserDataContext } from "../../utils/UserContextProvider";
import ComparisonToCompetitorsChart from "../../components/other/ComparisonToCompetitorsChart";
import { globalStyles } from "../../styles/styles";
import TextBody from "../../components/CustomElements/TextBody";
import FooterView from "../../components/other/FooterView";
import { NumberDisplayOne, NumberDisplayTwo } from "../../components/CustomElements/NumberDisplays";
import { HeroHeader } from "../../components/CustomElements/HeroHeaderDisplay";
import { ReviewDisplaySection } from "../../components/other/ReviewDisplay";

const Buttons = ({ displayHostButton = true }) => {
    const navigation = useNavigation();

    const goToHosting = () => {
        navigation.navigate('host')
    }

    return (
        <View style={[globalStyles.flexEndColumn, { marginVertical: 5 }]}>
            {displayHostButton && (
                <CustomButton
                    width={220}
                    backgroundColor={colors.orange40}
                    textAlign="center"
                    margin={2}
                    marginRight={4}
                    marginLeft={4}
                    border={colors.orange90}
                    shadowColor={colors.orange90}
                    height={45}
                    onPress={() => goToHosting()}
                    marginBottom={12}
                >
                    <View style={[globalStyles.spaceBetween, { padding: 10, alignItems: "center" }]}>
                        <TextBody>Learn about Hosting</TextBody>
                        <Entypo name="arrow-right" size={24} color={colors.amplifyNeutral90} />
                    </View>
                </CustomButton>
            )}
        </View>
    )
}

const MissionVisionSection = ({
    mobileView,
    width
}) => {
    const customWidth = mobileView ? width / 1.1 : width / 1.4
    return (
        <View>
            <View style={{ marginVertical: 55 }}>
                <Header fontWeight={300} level={3} text="OUR" color="#000" padding={0} />
                <Header marginTop={-10} level={3} text="MISSION" color={colors.orange40} padding={0} />
                <CustomSection customWidth={customWidth}>
                    <View style={[globalStyles.flexStart, { width: customWidth, padding: 10, paddingTop: 15 }]}>
                        <NumberDisplayOne />
                        <View style={[globalStyles.flexStartColumn, { width: customWidth / 1.35, padding: 15 }]}>
                            <Text style={[globalStyles.boldText, { textAlign: "left", marginBottom: 10 }]}>Facilitate Land Sharing for a Better World</Text>
                            <Text style={{ fontSize: 17, textAlign: "left" }}>
                                By connecting people who have private land with renters, HookHub works to bring people together. By connecting hosts with travelers, we create a global community committed to responsible and enriching travel experiences.
                            </Text>
                        </View>
                    </View>
                </CustomSection>
            </View>

            <View style={{ marginVertical: 55 }}>
                <Header fontWeight={300} level={3} text="OUR" color="#000" padding={0} />
                <Header marginTop={-10} level={3} text="VISION" color={colors.orange40} padding={0} />
                <CustomSection customWidth={customWidth}>
                    <View style={[globalStyles.flexStart, { width: customWidth, padding: 10, paddingTop: 15 }]}>
                        <NumberDisplayOne />
                        <View style={[globalStyles.flexStartColumn, { width: customWidth / 1.35, padding: 15 }]}>
                            <Text style={[globalStyles.boldText, { textAlign: "left", marginBottom: 10 }]}>Unleash the Potential of Spare Land</Text>
                            <Text style={{ fontSize: 17, textAlign: "left" }}>
                                HookHub is your community to fill your RV parking needs or bring in extra income without extra work.                            </Text>
                        </View>
                    </View>
                </CustomSection>
                <CustomSection customWidth={customWidth}>
                    <View style={[globalStyles.flexStart, { width: customWidth, padding: 10, paddingTop: 15 }]}>
                        <NumberDisplayTwo />
                        <View style={[globalStyles.flexStartColumn, { width: customWidth / 1.35, padding: 15 }]}>
                            <Text style={[globalStyles.boldText, { textAlign: "left", marginBottom: 10 }]}>Building a Community of Explorers</Text>
                            <Text style={{ fontSize: 17, textAlign: "left" }}>HookHub's mission is to build a vibrant community of explorers, where hosts share their unique spaces, and travelers discover new horizons. Together, we promote sustainable and inclusive travel for a better world. </Text>
                        </View>
                    </View>
                </CustomSection>
            </View>
        </View>
    )
}

function AboutScreen() {
    const { cloudfrontURLStaticImages } = getEnvVars();
    const navigation = useNavigation();
    const { width, height } = useWindowDimensions();
    const [authed, setAuthed] = useState(false)
    const { mobileView } = useSelector(state => state.currentUser);

    const authCheck = async () => {
        await Auth.currentUserInfo()
            .then((userInfo) => {
                if (userInfo && userInfo.attributes) {
                    setAuthed(true)
                } else { setAuthed(false) }
            })
    }

    useEffect(() => {
        authCheck();
    }, [])

    return (
        <ScrollView style={styles.scrollView}>
            <View style={[globalStyles.centerColumn, { backgroundColor: "#fff", paddingBottom: 100 }]}>

                {!mobileView && (
                    <>
                        <ImageBackground
                            source={require("../../../assets/icons/Banner-IMG2.svg")}
                            resizeMode="cover"
                            style={styles.image}
                        >
                            <View style={[mobileView ? globalStyles.flexStartColumn : globalStyles.center, { height: Platform.OS !== 'web' ? height / 4.5 : mobileView ? height / 1.5 : height / 2.6, width: width }]}>
                                <View style={[globalStyles.flexStartColumn, { width: width / 1.5 }]}>
                                    <View style={[globalStyles.flexStartColumn]}>
                                        <Text style={[globalStyles.shadowedText, { fontSize: 25, color: colors.orange40, fontStyle: "italic", }]}>
                                            where
                                        </Text>
                                        <Text style={[globalStyles.shadowedText, { fontSize: 30, fontWeight: "bold", color: "white" }]}>
                                            UNUSED LAND SPACE
                                        </Text>
                                        <Text style={[globalStyles.shadowedText, { fontSize: 25, fontStyle: "italic", color: colors.orange40 }]}>
                                            meets
                                        </Text>
                                        <Text style={[globalStyles.shadowedText, { fontSize: 30, fontWeight: "bold", color: "white" }]}>
                                            EXTRAORDINARY RV PARKING
                                        </Text>
                                    </View>
                                    <Buttons mobileView={mobileView} displayHostButton={false} />
                                </View>
                            </View>
                        </ImageBackground>
                    </>)}


                <HeroHeader
                    bodyText="Whether it be Vacation or a Yearly Stay find your next RV Parking Space"
                    headerText={<><Text style={{ color: colors.orange30 }}>Discover</Text> and<Text style={{ color: colors.orange30 }}> Book </Text>RV Parking</>}
                    button={<CustomButton backgroundColor={colors.orange40} width={165} onPress={() => navigation.navigate("explore")}><Text style={[{ fontSize: 18, fontWeight: 600, color: "#fff" }]}>Look for Spaces</Text></CustomButton>}
                />

                <View style={{ marginVertical: 50 }}>
                    <ReviewDisplaySection width={width} mobileView={mobileView} />
                </View>

                <View style={{ marginVertical: 50 }}>
                    <ComparisonToCompetitorsChart hostView={false} />
                </View>

                <MissionVisionSection mobileView={mobileView} width={width} />

                <View style={{ marginTop: 55, marginBottom: 120 }}>
                    <LearnMore />
                </View>

                <HelpForm authed={authed} />
            </View >
            {!mobileView && <FooterView />}
        </ScrollView >
    );
}

const styles = StyleSheet.create({
    scrollView: {
        width: "100%",
    },
    image: {
        flex: 1,
        justifyContent: "center"
    },
});

export default AboutScreen;
