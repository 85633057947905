import React, { useState, useContext, useEffect } from "react";
import { useWindowDimensions, StyleSheet, ScrollView, Text, Linking, View } from "react-native";
import Flex from "../../components/CustomElements/Flex"
import Header from "../../components/CustomElements/Header"
import CustomButton from "../../components/CustomElements/CustomButton"
import { UserDataContext } from "../../utils/UserContextProvider";
import { Entypo } from '@expo/vector-icons';
import { LoadingComponent } from "../../components/LoadingComponent";
import getEnvVars from '../../../environment';
import { useDispatch, useSelector } from "react-redux";
import BackButton from "../../components/other/BackButton";
import { setPayoutStatus } from "../../redux/actions/CurrentUserActions";
import { BookingsContext } from "../../utils/BookingsContextProvider";
import { ErrorDisplay } from "../../components/CustomElements/ErrorDisplay";
import Card from "../../components/CustomElements/Card";
import { colors } from "../../styles/colors";
import { updateUserMutation } from "../../api/user";
import { globalStyles } from "../../styles/styles";
const { REACT_APP_ENV } = getEnvVars();


function PaymentSettingsScreen() {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { user } = useContext(UserDataContext);
  const { userData, setUserData } = user;
  const { myHosting } = useContext(BookingsContext);
  const { mySpaces } = myHosting;
  const updateUserData = (data) => setUserData(data);
  const { mobileView } = useSelector(state => state.currentUser);
  const screenWidth = useWindowDimensions().width / 1.25;
  const [loading, setLoading] = useState(true);
  const [sessionURL, setSessionURL] = useState(null);
  const [payoutURL, setPayoutURL] = useState(null);
  const [error, setError] = useState(false);
  const stripeData = userData?.userData;

  const fetchPayoutAccount = async () => {
    const stripeID = !!stripeData.stripeConnectedAccountId ? stripeData.stripeConnectedAccountId : null;
    try {
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
          id: userData.sub,
          email: userData.email,
          enviro: REACT_APP_ENV,
          existingAccountID: stripeID
        })
      };
      const response = await fetch('https://5smptjrhx7d7itxb2vx2c6fyha0ctxyw.lambda-url.us-west-2.on.aws/', requestOptions)
      const session = await response.json();
      const { link, accountID } = session;
      if (!stripeData.stripeConnectedAccountId) {
        let data = {
          id: userData.sub,
          stripePayoutsEnabled: true,
          stripeConnectedAccountId: accountID
        }
        let updatedUser = await updateUserMutation({ currentUserData: userData, data })
        if (updatedUser) {
          updateUserData(updatedUser)
        } else {
          setLoading(false);
          setEditProfile(false);
          setError(true)
        }
      }
      setPayoutURL(link.url)
    } catch (e) {
      console.log("ERROR fetching stripe account > ", e)
      setError(true);
    }
  };

  const fetchPaymentPortal = async () => {
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({
        stripeID: stripeData.stripeCustomerID,
        enviro: REACT_APP_ENV
      })
    };
    const response = await fetch('https://6gwkwdj56rvatvj5qgfsurfjne0txmmh.lambda-url.us-west-2.on.aws/', requestOptions)
    const session = await response.json();
    if (session && session?.stripePortal) {
      const { url } = session.stripePortal;
      setSessionURL(url);
    } else { setError(true) }
  };

  const fetchStripePortals = async () => {
    await fetchPayoutAccount();
    !!stripeData?.stripeCustomerID && await fetchPaymentPortal();
    setLoading(false);
  }

  const goToPortal = () => {
    Linking.openURL(sessionURL);
  };

  const goToSetPayoutMethod = () => {
    dispatch(setPayoutStatus({ payoutNeeded: false }))
    Linking.openURL(payoutURL);
  };

  useEffect(() => {
    fetchStripePortals();
  }, []);

  return (
    <ScrollView>
      <Flex
        justifyContent="space-between"
        direction="column"
        marginBottom={16}
      >
        <BackButton goBack={false} />
        <View style={[{marginTop: 30, marginBottom: 8}]}>
          <Header level={1.5} marginBottom={8} text="Payment and Payout Settings"></Header>
          <Text style={{ fontSize: 17, marginLeft: 15 }}>We partner with Stripe to provide a secure payment and payout system.</Text>
          {error ? (
            <ErrorDisplay error="ERROR loading Stripe. Please try again or contact Hookhub Support." />
          ) : loading ? (
            <View style={[mobileView ? globalStyles.centerColumn : globalStyles.center, {marginLeft: mobileView ? 0 : 35, marginTop: 50}]}>
              <LoadingComponent />
              <Text style={{ marginLeft: 15 }}>Please hold, loading Stripe now...</Text>
            </View>
          ) : (
            <View style={[mobileView ? globalStyles.centerColumn : globalStyles.flexStartColumn, {marginLeft: mobileView ? 0 : 35, marginTop: 40}]}>
              {!!sessionURL && (
                <CustomButton
                  marginTop={20}
                  onPress={goToPortal}
                  width={mobileView ? width / 1.25 : 300}
                  backgroundColor={colors.lightBlue}
                >
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    direction="row"
                  >
                    <Text style={[styles.buttonText, { fontSize: !!mobileView ? 13 : 16 }]}>STRIPE PORTAL</Text>
                    <Entypo name="arrow-right" size={20} color="white" />
                  </Flex>
                </CustomButton>
              )}
              {!!mySpaces.length && (
                <>
                  {stripeData?.stripePayoutsEnabled ? (
                    <CustomButton
                      onPress={goToSetPayoutMethod}
                      width={mobileView ? width / 1.25 : 300}
                      marginTop={10}
                      backgroundColor="#5b5b5b"
                    >
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        direction="row"
                      >
                        <Text style={[styles.buttonText, { fontSize: !!mobileView ? 13 : 16 }]}>EDIT PAYOUT METHOD</Text>
                      </Flex>
                    </CustomButton>
                  ) : (
                    <CustomButton
                      onPress={goToSetPayoutMethod}
                      backgroundColor="#6ca6c4"
                      width={300}
                      marginTop={10}
                    >
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        direction="row"
                      >
                        <Text style={[styles.buttonText, { fontSize: !!mobileView ? 13 : 16 }]}>SET PAYOUT METHOD</Text>
                      </Flex>
                    </CustomButton>
                  )}
                  <Text style={{ marginTop: 10, padding: 10 }}>The income you receive from hosting your space is a 'Payout'.</Text>
                </>
              )}
              {!sessionURL && !mySpaces.length && (
                <Card width={!!mobileView ? screenWidth : screenWidth / 1.5}>
                  <Text style={{ fontSize: 17 }}>You have no spaces nor bookings yet, Stripe is not available.</Text>
                </Card>
              )}
            </View>
          )}
        </View >
      </Flex>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonText: {
    color: "#fff",
  },
});

export default PaymentSettingsScreen;
