import React from "react";
import { View, StyleSheet } from "react-native";
import { colors } from "../styles/colors";

export default function CustomDivider({ alignDivider = "center", width="100%", marginVertical=30 }) {
  return (
    <View style={[styles.divider, {
      justifyContent: alignDivider,
      textAlign: alignDivider,
      alignItems: alignDivider,
      width: width,
      marginVertical: marginVertical
    }]}></View>
  )
}

const styles = StyleSheet.create({
  divider: {
    borderBottomColor: colors.grey20,
    borderBottomWidth: 1.5
  },
});