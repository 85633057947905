import React, { useState, useEffect } from "react";
import {
    View,
    StyleSheet,
    useWindowDimensions,
    Text,
    Platform
} from "react-native";
import BookingModal from "./BookingModal";
import StarRatingDisplay from "../review/StarRatingDisplay";
import { useNavigation } from "@react-navigation/native";
import CustomHorizontalScrollView from "../other/CustomHorizontalScrollView";
import Flex from "../CustomElements/Flex"
import Header from "../CustomElements/Header"
import Card from "../CustomElements/Card"
import { colors } from "../../styles/colors"
import { formatMonthDay, formatYear } from "../../utils/formatDate";
import CustomCardTabs from "../CustomElements/CustomCardTabs";
import ViewDetailsButton from "../CustomElements/ViewDetailsButton";
import CustomButton from "../CustomElements/CustomButton";
import { getSpaceAndPropertyDetails } from "../../api/space";
import { isTodayCheck } from "../../utils/isTodayCheck";
import ReviewPropertyModal from "../review/ReviewPropertyModal";
import { globalStyles } from "../../styles/styles";

const RentalCard = ({ booking, mobileView, openBookingModal, activeTabIndex, reviewable }) => {
    const { bookingRequest, cancelled, spaceID, check_out } = booking;
    const { spaceCity, spaceState, spaceTitle, propertyTitle } = bookingRequest;
    const location = `${spaceCity}, ${spaceState}`;
    const [checkIn, setCheckIn] = useState(null);
    const [checkOut, setCheckOut] = useState(null);
    const [checkInYear, setCheckInYear] = useState(null);
    const [checkOutYear, setCheckOutYear] = useState(null);
    const [alreadyReview, setAlreadyReview] = useState(null);
    const [loadingReview, setLoadingReview] = useState(true);
    const [ableToReview, setAbleToReview] = useState(reviewable);
    const [reviewModalOpen, setReviewModalOpen] = useState(false);

    const formatDates = () => {
        const { check_in, check_out } = (bookingRequest);
        const start = formatMonthDay(check_in);
        const end = formatMonthDay(check_out);
        const startYear = formatYear(check_in);
        const endYear = formatYear(check_out);
        setCheckIn(start);
        setCheckInYear(startYear);
        setCheckOut(end);
        setCheckOutYear(endYear);
    };

    const checkForReview = async () => {
        setLoadingReview(true)
        setAbleToReview(false)
        const bookingEndsToday = activeTabIndex === 'current' && isTodayCheck(check_out);

        if (reviewable || bookingEndsToday) {
            setAbleToReview(true)
            let space = await getSpaceAndPropertyDetails(spaceID)
            let reviews = space?.property?.reviews.items;
            if (!!reviews?.length) {
                setAlreadyReview(reviews[0].rating)
            }
        }
        setLoadingReview(false)
    };

    useEffect(() => {
        setAlreadyReview(null)
        formatDates();
        checkForReview();
    }, [booking]);

    return (
        <Card
            backgroundColor={cancelled ? colors.amplifyNeutral60 : colors.amplifyNeutral20}
            alignItems="center"
            textAlign="center"
            margin={5}
            width={mobileView ? 200 : 210}
        >
            <Flex
                direction="column"
                alignItems="center"
                justifyContent="center"
                alignContent="center"
                textAlign="center"
                width={mobileView ? 190 : 210}
                marginTop={20}
            >
                {!!cancelled && (
                    <View style={styles.cancelled}>
                        <Text style={{ fontSize: 16, lineHeight: 22, color: "black" }}>booking cancelled</Text>
                    </View>
                )}
                {spaceTitle === propertyTitle ? (
                    <Header text={spaceTitle} textAlign="center" />
                ) : (
                    <>
                        <Header textAlign="center" text={propertyTitle} padding={1} />
                        <Header level={.8} text={spaceTitle} padding={1} marginBottom={10} textAlign="center" />
                    </>
                )}
                <Text marginBottom={!mobileView ? 6 : 0}>{location}</Text>
                <Flex
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    marginBottom={5}
                    marginTop={10}
                >
                    <Card
                        backgroundColor={cancelled ? "grey" : colors.orange80}
                        alignItems="center"
                        textAlign="center"
                        width={!!mobileView && 90}
                    >
                        <Text>{!mobileView && "check"} IN</Text>
                        <Header textAlign="center" alignSelf="center" color={colors.amplifyNeutral90} text={checkIn} />
                        <Text>{checkInYear}</Text>
                    </Card>
                    <Card
                        backgroundColor={cancelled ? "grey" : colors.orange80}
                        alignItems="center"
                        textAlign="center"
                        width={!!mobileView && 90}
                    >
                        <Text>{!mobileView && "check"} OUT</Text>
                        <Header textAlign="center" alignSelf="center" color={colors.amplifyNeutral90} text={checkOut} />
                        <Text>{checkOutYear}</Text>
                    </Card>
                </Flex>
                {!cancelled && (<ViewDetailsButton onPress={() => openBookingModal(booking, false)} cancelled={cancelled} />)}
                {!loadingReview && ableToReview && (
                    <>
                        {!!alreadyReview ? (
                            <StarRatingDisplay stars={alreadyReview} />
                        ) : (
                            <>
                                <CustomButton
                                    width={145}
                                    height={40}
                                    marginTop={5}
                                    backgroundColor={colors.amplifyBlue20}
                                    onPress={() => setReviewModalOpen(true)}
                                >
                                    <Text style={{ fontSize: mobileView ? 17 : 18, fontWeight: "400" }}>Review Space</Text>
                                </CustomButton>
                            </>
                        )}
                    </>
                )}
                <ReviewPropertyModal
                    modalVisible={reviewModalOpen}
                    setModalVisible={() => setReviewModalOpen(!reviewModalOpen)}
                    setAlreadyReview={(v) => setAlreadyReview(v)}
                    bookingDetails={booking}
                />
            </Flex>
        </Card>
    )
};

export const MyTrips = ({
    mobileView,
    activeTabIndex,
    setActiveTabIndex,
    currentReservations,
    upcomingReservations,
    pastReservations
}) => {
    const { width } = useWindowDimensions();
    const navigation = useNavigation();
    const [rentalModalOpen, setRentalModalOpen] = useState(false);
    const [bookingToDisplay, setBookingToDisplay] = useState(null);
    const [displayReservations, setDisplayReservations] = useState([]);
    const [reviewable, setReviewable] = useState(false);

    const openBookingModal = (v) => {
        setBookingToDisplay(v);
        setRentalModalOpen(true);
    };

    const navigateToSpace = async (propertyID) => {
        setRentalModalOpen(false);
        navigation.navigate('space', {
            propertyID: propertyID
        })
    };

    useEffect(() => {
        if (activeTabIndex === 'current') {
            setDisplayReservations(currentReservations)
            setReviewable(false)
        } else if (activeTabIndex === 'upcoming') {
            setDisplayReservations(upcomingReservations)
            setReviewable(false)
        } else {
            setDisplayReservations(pastReservations)
            setReviewable(true)
        }
    }, [activeTabIndex]);

    return (
        <View style={styles.wrapperView}>
            {Platform.OS !== 'web' && (<CustomCardTabs mobileView={mobileView} activeTabIndex={activeTabIndex} setActiveTabIndex={(v) => setActiveTabIndex(v)} />)}
            <Flex
                direction="column"
                marginBottom={4}
                marginTop={20}
                textAlign="left"
            >
                {Platform.OS === 'web' && (<CustomCardTabs mobileView={mobileView} activeTabIndex={activeTabIndex} setActiveTabIndex={(v) => setActiveTabIndex(v)} />)}
                <Card
                    backgroundColor={colors.lightBlue}
                    width={mobileView ? width / 1.02 : width / 1.1}
                    padding={20}
                    alignItems="flex-start"
                >
                    <Header
                        marginTop={5}
                        color={colors.amplifyNeutral90}
                        text="MY TRIPS"
                    />
                    {
                        displayReservations.length ? (
                            <View style={[globalStyles.flexStart, { flexWrap: "wrap" }]}>
                                {
                                    displayReservations.map((booking, key) => (
                                        <View key={key}>
                                            <RentalCard
                                                mobileView={mobileView}
                                                width={width}
                                                reviewable={reviewable}
                                                booking={booking}
                                                activeTabIndex={activeTabIndex}
                                                openBookingModal={(v) => openBookingModal(v)}
                                            />
                                        </View>
                                    ))
                                }
                            </View>
                        ) : (
                            <Text style={{ margin: 30 }}>No {activeTabIndex} trips yet</Text>
                        )
                    }
                </Card>
            </Flex>
            {!!bookingToDisplay && !!Object.keys(bookingToDisplay).length &&
                <>
                    <BookingModal
                        listReservations={null}
                        ImTheHost={false}
                        navigate={(v) => navigateToSpace(v)}
                        modalVisible={rentalModalOpen}
                        setModalVisible={() => setRentalModalOpen(!rentalModalOpen)}
                        bookingDetails={bookingToDisplay}
                    />
                </>
            }
        </View>
    );
};

const styles = StyleSheet.create({
    wrapperCustom: {
        padding: 6,
    },
    cancelled: {
        textAlign: "center",
        backgroundColor: "grey",
        padding: 7
    },
    spaceCustomButtonText: {
        color: "#363636",
        fontSize: 16,
        fontWeight: "bold"
    },
    spaceCustomButton: {
        borderColor: "#363636",
        padding: 10,
        borderWidth: 1,
        alignItems: "center",
        borderBottomWidth: 2,
        borderRightWidth: 2,
        justifyContent: "center",
        alignSelf: "center",
        height: 43,
        margin: 2
    },
    closeText: {
        fontWeight: "bold",
        textDecorationLine: "underline"
    },
    logBox: {
        padding: 20,
        margin: 10,
        borderWidth: StyleSheet.hairlineWidth,
        borderColor: colors.amplifyNeutral20,
        backgroundColor: "#f9f9f9",
    },
    CustomButtonText: {
        color: "#000",
        marginBottom: 16
    },
    CustomButton: {
        borderColor: colors.lightBlue,
        width: 100,
        backgroundColor: colors.lightBlue,
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "center",
        height: 30,
        marginBottom: 20
    },
    wrapperView: {
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "center",
        marginBottom: 100,
        marginTop: 20
    },
    reservationsView: {
        margin: 10,
        paddingBottom: 15,
        backgroundColor: colors.lightBlue,
        alignItems: "flex-start",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    image: {
        flex: 1,
        justifyContent: "center",
        padding: 12,
        marginBottom: 10
    },
    title: {
        textAlign: "left",
        paddingLeft: 4,
        backgroundColor: "#000000c0"
    },
});
