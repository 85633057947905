import React, { useEffect } from "react";
import {
    StyleSheet,
    Text,
    View,
} from "react-native";
import Flex from "../CustomElements/Flex"
import Header from "../CustomElements/Header";
import { FontAwesome5 } from "@expo/vector-icons";
import { colors } from "../../styles/colors";
import * as ImagePicker from "expo-image-picker";
import { LoadingComponent } from "../LoadingComponent";
import CustomTextInput from "../CustomElements/CustomTextInput";
import CustomButton from "../CustomElements/CustomButton";
import { validateNumbersOnly } from "../../utils/fieldValidators";
import { ErrorDisplay } from "../CustomElements/ErrorDisplay";
import { AvatarImage } from "./AvatarImage";
import { globalStyles } from "../../styles/styles";
import CancelButton from "../CustomElements/CancelButton";


const EditDetailsMyRig = ({
    setEditRigData,
    image,
    setImage,
    rigYear,
    setRigYear,
    make,
    model,
    rigWidth,
    rigLength,
    setmake,
    setmodel,
    setrigLength,
    setrigWidth,
    uploadResourceS3,
    mobileView,
    loading,
    width,
    handleSubmit
}) => {

    const checkAndSetVal = (type, val) => {
        const checkedVal = validateNumbersOnly(val);
        if (!!checkedVal) {
            type === 'width' ? setrigWidth(checkedVal) : setrigLength(checkedVal)
        } else {
            type === 'width' ? setrigWidth('') : setrigLength('')
        }
    };

    useEffect(() => {
        (async () => {
            const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
            if (status !== 'granted') {
                alert('Sorry, we need camera roll permissions to make this work!');
            }
        })();
    }, []);

    return (
        <View>
            <View>
                <AvatarImage
                    editProfile={true}
                    image={image}
                    uploadResourceS3={(v) => uploadResourceS3(v)}
                    setImage={(v) => setImage(v)}
                />
                <View>
                    <Text style={styles.boldText}>year</Text>
                    <CustomTextInput
                        isRequired={true}
                        width={100}
                        height={40}
                        errorMessage="There is an error"
                        onChangeText={(e) => setRigYear(e)}
                        value={rigYear ? rigYear.toString() : ''}
                    />
                    <Text style={styles.boldText}>make</Text>
                    <CustomTextInput
                        isRequired={false}
                        width={mobileView ? width / 1.1 : 300}
                        height={40}
                        errorMessage="There is an error"
                        onChangeText={(e) => setmake(e)}
                        value={make}
                    />


                    <Text style={styles.boldText}>model</Text>
                    <CustomTextInput
                        isRequired={false}
                        width={mobileView ? width / 1.1 : 300}
                        height={40}
                        errorMessage="There is an error"
                        onChangeText={(e) => setmodel(e)}
                        value={model}
                    />

                    <Text style={styles.boldText}>length (feet)</Text>
                    <CustomTextInput
                        isRequired={false}
                        width={80}
                        height={40}
                        errorMessage="There is an error"
                        onChangeText={(e) => checkAndSetVal('length', e)}
                        value={rigLength}
                    />
                    <Text style={styles.boldText}>width (feet)</Text>
                    <CustomTextInput
                        isRequired={false}
                        width={80}
                        height={40}
                        errorMessage="There is an error"
                        onChangeText={(e) => checkAndSetVal('width', e)}
                        value={rigWidth}
                    />
                </View>
            </View>
            <View style={globalStyles.centerColumn}>
                <Text style={{ textAlign: "center", color: colors.orange90, width: 250, fontSize: 17, flexWrap: "wrap", margin: 15, width: width / 1.1 }}>IMAGE and YEAR of your 'rig' are required to reserve a space</Text>
                <View style={[globalStyles.center, {marginVertical: 5}]}>
                    <CancelButton onPress={() => setEditRigData(false)} disabled={loading}/>
                    <CustomButton
                        width={100}
                        backgroundColor={colors.orange80}
                        textAlign="center"
                        marginLeft={25}
                        border={colors.amplifyNeutral90}
                        onPress={() => handleSubmit()}
                        disabled={loading || !rigYear || !image}
                    >
                        <Text style={styles.forwardButtonText}>Save</Text>
                    </CustomButton>
                </View>
            </View>
        </View>
    );
};

const ShowDetailsMyRig = ({ image, rigYear, make, model, rigWidth, rigLength, setEditRigData, loading }) => {
    return (
        <Flex direction="column">
            {image ? (<AvatarImage image={image} />) : (
                <>
                    <FontAwesome5
                        name="exclamation-circle"
                        size={80}
                        color="#f2b877"
                    />
                    <Text style={{ color: colors.orange50 }}>Your RV/trailer details are required to reserve a space</Text>
                </>
            )}
            <Flex direction="column" marginTop={10} marginBottom={15}>
                <>{rigYear && <Text>Year: {rigYear}</Text>}</>
                <>{rigWidth && rigLength && <Text>Dimensions: {rigWidth}ft. X {rigLength}ft.</Text>}</>
                <>{make && <Text>Make: {make}</Text>}</>
                <>{model && <Text>Model: {model}</Text>}</>
            </Flex>
            <View>
                <CustomButton
                    width={150}
                    marginTop={20}
                    backgroundColor={colors.white}
                    onPress={() => setEditRigData(true)}
                ><Text>Edit my Rig</Text>
                </CustomButton>
            </View>
        </Flex>
    );
};

export const MyRig = ({
    uploadResourceS3,
    userData,
    mobileView,
    image,
    setImage,
    setError,
    error,
    handleSubmit,
    rigYear,
    setRigYear,
    make,
    setmake,
    model,
    setmodel,
    rigLength,
    setrigLength,
    rigWidth,
    setrigWidth,
    rigData,
    editRigData,
    setEditRigData,
    loading,
    width
}) => {

    if (loading) {
        return (<LoadingComponent />)
    }
    return (
        <View>
            <Header text="My Rig" />
            {editRigData ? (
                <EditDetailsMyRig
                    width={width}
                    userID={userData?.sub}
                    mobileView={mobileView}
                    editRigData={editRigData}
                    setEditRigData={() => setEditRigData(!editRigData)}
                    image={image}
                    setImage={(v) => setImage(v)}
                    setError={(v) => setError(v)}
                    rigYear={rigYear}
                    setRigYear={(v) => setRigYear(v)}
                    make={make}
                    setmake={(v) => setmake(v)}
                    model={model}
                    setmodel={(v) => setmodel(v)}
                    rigLength={rigLength}
                    setrigLength={(v) => setrigLength(v)}
                    rigWidth={rigWidth}
                    setrigWidth={(v) => setrigWidth(v)}
                    rigData={rigData}
                    uploadResourceS3={() => uploadResourceS3()}
                    handleSubmit={() => handleSubmit()}
                />
            ) : (
                <ShowDetailsMyRig
                    image={image}
                    rig={userData?.rig}
                    rigYear={rigYear}
                    make={make}
                    model={model}
                    rigLength={rigLength}
                    rigWidth={rigWidth}
                    setEditRigData={() => setEditRigData(!editRigData)}
                />
            )}
            {error && <ErrorDisplay error='Error updating rig details- year and image are required.' />}
        </View>
    );
};

const styles = StyleSheet.create({
    types: {
        color: colors.grey20,
        marginVertical: 10,
    },
    editRigDataImage: {
        height: 200,
        justifyContent: "center",
        width: 200,
        margin: 10,
        marginTop: 15,
        borderRadius: 100,
    },
    boldText: {
        fontWeight: "bold",
        marginRight: 10
    },
    forwardButtonText: {
        color: "#fff",
        fontSize: 20,
    },
    forwardButton: {
        borderColor: "#616A6B",
        borderRadius: 10,
        width: 100,
        borderWidth: 1,
        backgroundColor: "#065d8c",
        alignItems: "center",
        borderBottomWidth: 2,
        justifyContent: "center",
        alignSelf: "center",
        height: 40,
        marginLeft: 40,
    },
    disabledButton: {
        borderColor: "grey",
        borderRadius: 10,
        width: 220,
        borderWidth: 1,
        backgroundColor: "grey",
        alignItems: "center",
        borderBottomWidth: 2,
        justifyContent: "center",
        alignSelf: "center",
        height: 40,
        marginLeft: 40,
    },
    container: {
        margin: 20,
    },
    image: {
        aspectRatio: 3 / 2,
        borderRadius: 10,
        width: "100%",
    },
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 22,
    },
});
