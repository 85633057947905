import React, { useState } from "react";
import { Pressable, useWindowDimensions, Image, Text } from "react-native";
import {
    AntDesign,
    FontAwesome5,
    MaterialIcons,
} from "@expo/vector-icons";
import { useSelector } from "react-redux";
import Flex from "./CustomElements/Flex"
import Card from "./CustomElements/Card"
import Header from "./CustomElements/Header"
import { colors } from "../styles/colors";
import { View } from "react-native";
import { globalStyles } from "../styles/styles";
import OrangeUnderlineHeader from "./other/OrangeUnderlineHeader";
import CustomDropdown from "./CustomElements/CustomDropdown";


const QuestionAccordian = ({ question, mobileView }) => {
    const [openAccordian, setOpenAccordian] = useState(false);
    const { width } = useWindowDimensions();

    return (
        <Card
            backgroundColor={colors.amplifyNeutral20}
            border="#4c4e52"
            padding={!!mobileView ? 2 : 8}
            alignItems="flex-start"
            marginTop={6}
            textAlign="center"
            width={!!mobileView ? width / 1.18 : width / 1.3}
        >
            <View style={globalStyles.flexStartColumn}>
                <Pressable onPress={() => setOpenAccordian(!openAccordian)} margin={12}>
                    <View style={globalStyles.center}>
                        <AntDesign marginRight={15} name={openAccordian ? "caretdown" : "caretright"} size={20} color="grey" />
                        <Header level={mobileView ? .9 : 1} text={question.q}></Header>
                    </View>
                </Pressable>
                {openAccordian && <Text style={{ fontSize: 16, margin: 10, marginTop: 20 }}>{question.a}</Text>}
            </View>
        </Card >
    );
};

const InfoCard = ({ title, icon, width, mobileView, questions }) => {
    const [openAccordian, setOpenAccordian] = useState(false);

    return (
        <Card width={!!mobileView ? width / 1.1 : width / 1.2} alignItems="flex-start" backgroundColor={colors.orange20}>
            <Pressable onPress={() => setOpenAccordian(!openAccordian)}>
                <View style={[globalStyles.spaceBetween, { alignItems: "center" }]}>
                    <View style={globalStyles.center}>
                        <Image
                            style={{ width: 15, height: 15, marginBottom: 9 }}
                            source={require("../../assets/icons/lightbulb-on.svg")}
                        />
                        <Header
                            color="#4c4e52"
                            level={1}
                            text={title}
                            marginRight={mobileView ? 6 : 10}
                        />
                        <Text>Answers to your questions</Text>
                    </View>
                    <View>
                        {openAccordian ? <MaterialIcons name="keyboard-arrow-up" size={25} color="#000" /> : <MaterialIcons name="keyboard-arrow-down" size={25} color="#000" />}
                    </View>
                </View>
            </Pressable>
            {openAccordian && (
                <View style={{ marginTop: 10 }}>
                    {questions.map((v, i) => (
                        <QuestionAccordian mobileView={mobileView} question={v} key={i} />
                    ))}
                </View>
            )}
        </Card>
    );
};

function LearnMore({ hostViewOnly = false }) {
    const hostQs = [
        {
            q: "Is my space right for Hosting?",
            icon:
                <Image
                    style={{ width: 25, height: 25 }}
                    source={require("../../assets/icons/lightbulb-on.svg")}
                />,
            a: [{
                text: "If you have space on your property of at least 10 foot by 20 foot- you can host on hookhub! Only space is needed, but you can also offer extra amenities if you'd like to charge higher prices."
            }]
        },
        {
            q: "What does it take to get my space setup?",
            icon:
                <Image
                    style={{ width: 25, height: 25 }}
                    source={require("../../assets/icons/lightbulb-on.svg")}
                />,
            a: [{
                text: "Our create your space tool will guide you through a few questions and you can have your space up and running in under 10 minutes. It is free to list your space on Hookhub... so what do you have to lose?! We recommend taking your pictures beforehand. Our team is always ready to help and will even assist with getting great photos"
            }]
        }, 
        {
            q: "Is there a minimum or maximum amount of time I have to let people rent my space?",
            icon:
                <Image
                    style={{ width: 25, height: 25 }}
                    source={require("../../assets/icons/lightbulb-on.svg")}
                />,
            a: [{
                text: "Absolutely not, it is entirely up to you on when and how long people can rent your space. You can offer your space for short-term rentals, long-term monthly stays or even just for storage space."
            }]
        },
        {
            q: "What do I have to do as a Host?",
            icon:
                <Image
                    style={{ width: 25, height: 25 }}
                    source={require("../../assets/icons/lightbulb-on.svg")}
                />,
            a: [{
                text: "Its simple- just create your space in a few simple steps and set your space availability. Thats it! You will get booking requests and then be able to decide at that time if you like the booking or renter that is requesting."
            }]
        },
        {
            q: "How do I know I can trust renters?",
            icon:
                <Image
                    style={{ width: 25, height: 25 }}
                    source={require("../../assets/icons/lightbulb-on.svg")}
                />,
            a: [{
                text: "We review the renters and will even verify their identity if fraudulent activity is suspected. You also will have the chance to review your renters details and speak to them prior to agreeing to their reservation request."
            }]
        },
        {
            q: "Can I host my space if it is just empty land?",
            icon:
                <Image
                    style={{ width: 25, height: 25 }}
                    source={require("../../assets/icons/lightbulb-on.svg")}
                />,
            a: [{
                text: "Yes! Amenities (electric, water, sewage, etc) are an extra feature if you choose to list it. Otherwise, only space is needed."
            }]
        },
        {
            q: "What is the host cancellation policy?",
            icon:
                <Image
                    style={{ width: 25, height: 25 }}
                    source={require("../../assets/icons/lightbulb-on.svg")}
                />,
            a: [{
                text: "As a host, you can cancel a booking up to 1 week before the check-in date. If the booking is more than a month long, you can cancel the booking after check-in, as long as it is still 28 days before the check-out date. A 28 days notice will be required."
            }]
        },
        {
            q: "What are Hookhub's fees?",
            icon:
                <Image
                    style={{ width: 25, height: 25 }}
                    source={require("../../assets/icons/lightbulb-on.svg")}
                />,
            a: [{
                text: "To keep our platform up and provide support- Hookhub collects 10% of the total being paid by your renter as well as a Stripe transaction fee (per Stripe) of 2.9% plus $.30."
            }]
        },
    ]
    const renterQs = [
        {
            q: "How does the hookhub rental process work?",
            icon:
                <Image
                    style={{ width: 25, height: 25 }}
                    source={require("../../assets/icons/process.svg")}
                />,
            a: [{
                text: "Search your destination and find a rental space that fits your needs- with amenities and stay types (long-term, short-term and storage). Then simply create your booking request in a couple of clicks."
            }]
        },
        {
            q: "Is my payment secure?",
            icon:
                <Image
                    style={{ width: 25, height: 25 }}
                    source={require("../../assets/icons/payment.svg")}
                />,
            a: [{
                text: "Absolutely. We partner with Stripe to provide a secure payment and payout system."
            }]
        },
        {
            q: "What can I use Hookhub for?",
            icon:
                <Image
                    style={{ width: 25, height: 25 }}
                    source={require("../../assets/icons/click.svg")}
                />,
            a: [{
                text: "Hookhub is your online platform to rent space to park your RV, trailer, boat, etc. for long term living, short term vacation rental space or even just storage. Each host will list any available amenities included in your rental (electricity, sewage hookup, water, etc)"
            }]
        },
        {
            q: "What are Hookhub's fees?",
            icon:
                <Image
                    style={{ width: 25, height: 25 }}
                    source={require("../../assets/icons/fees.svg")}
                />,
            a: [{
                text: "To keep our platform up and provide support- Hookhub collects 10% of your rental total and Stripe fees of 2.9%. Stripe"
            }]
        },
    ]

    return (
        <View style={globalStyles.centerColumn}>
            <Header textAlign="center" color={colors.black} level={1.4} text={`Frequently Answered Questions`}></Header>
            <OrangeUnderlineHeader />
            <View style={{ marginTop: 20 }}>
                {hostViewOnly ? <></> : (
                    <CustomDropdown
                        header="Renter"
                        icon={
                            <Image
                                style={{ width: 30, height: 30 }}
                                source={require("../../assets/icons/lightbulb-on.svg")}
                            />
                        }
                        bodyItems={renterQs}
                    />
                )}
                <CustomDropdown
                    header="Host"
                    hostViewOnly={hostViewOnly}
                    icon={
                        <Image
                            style={{ width: 30, height: 30 }}
                            source={require("../../assets/icons/host.svg")}
                        />
                    }
                    bodyItems={hostQs}
                />
            </View>
        </View>
    );
}

export default LearnMore;
