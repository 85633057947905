import React, { useState } from 'react';
import { Pressable, Text } from 'react-native';
import { colors } from '../../styles/colors';
import { useSelector } from 'react-redux';

const Badge = ({
    children,
    alignContent = "center",
    textAlign = "center",
    alignItems = "center",
    justifyContent = "center",
    marginTop = 4,
    marginBottom = 4,
    margin = 5,
    direction = "row",
    wrap = 'nowrap',
    shadow = true,
    width = "90%",
    noWidth = false,
    padding = 3,
    paddingRight = 9,
    paddingLeft = 9,
    onPress,
    opacity=.83,
    height = 31,
    color=colors.grey20
}) => {
    const { mobileView } = useSelector(state => state.currentUser);
    const [onHover, setOnHover] = useState(false)

    return (
        <Pressable
            onHoverIn={() => setOnHover(true)}
            onHoverOut={() => setOnHover(false)}
            onPress={onPress ? () => onPress() : null}
            disabled={!onPress}
            style={[{
                height: height,
                alignContent: alignContent,
                textAlign: textAlign,
                alignItems: alignItems,
                justifyContent: justifyContent,
                marginTop: marginTop,
                marginBottom: marginBottom,
                flexDirection: direction,
                flexWrap: wrap,
                padding: padding,
                paddingRight: paddingRight,
                paddingLeft: paddingLeft,
                margin: margin,
                backgroundColor: color,
                borderRadius: 12,
                borderColor: colors.amplifyNeutral60,
                elevation: 9,
                opacity: opacity
            }, shadow && {
                shadowOffset: {
                    width: .1,
                    height: .5,
                },
                shadowOpacity: 0.3,
                shadowRadius: 3,
            }, !noWidth && {
                width: width
            }, !!onPress && onHover && !mobileView && {
                opacity: 1
            }]}>
            <Text>
                {children}
            </Text>
        </Pressable>
    );
};

export default Badge;