import { Image, Text, useWindowDimensions, View } from "react-native";
import { useSelector } from "react-redux";
import { globalStyles } from "../../styles/styles";

export const HeroHeader = ({ bodyText, button, headerText }) => {
    const { width } = useWindowDimensions();
    const { mobileView } = useSelector(state => state.currentUser);

    return (
        <View>
            {mobileView ? (
                <View style={[globalStyles.centerColumn, { marginVertical: 100 }]}>
                    <View style={{ width: width / 1.1 }}>
                        <Text style={[globalStyles.boldText, { textAlign: "left", marginBottom: 10, fontSize: 26 }]}>{headerText}</Text>
                        <Text style={{ fontSize: 17, marginBottom: 20 }}>{bodyText}</Text>
                        {button}
                    </View>
                    <View style={{ marginRight: -350}}>
                        <Image
                            style={{ width: width, height: width / 2, borderRadius: 5 }}
                            source={require("../../../assets/images/DemoImg1.png")}
                        />
                    </View>
                </View>
            ) : (
                <View style={[globalStyles.flexStart, { marginTop: 130, width: width, marginBottom: 90 }]}>
                    <View>
                        <Image
                            style={{ width: (width / 2), height: (width / 4), marginLeft: -80, borderRadius: 5 }}
                            source={require("../../../assets/images/DemoImg2.png")}
                        />
                    </View>
                    <View style={{ width: width / 2, marginHorizontal: 30 }}>
                        <Text style={[globalStyles.boldText, { textAlign: "left", marginBottom: 10, fontSize: 26 }]}>{headerText}</Text>
                        <Text style={{ fontSize: 17, marginBottom: 20 }}>{bodyText}</Text>
                        {button}
                    </View>
                </View>
            )
            }
        </View >
    )
}