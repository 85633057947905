import React, { useState, useEffect, useContext } from "react";
import {
  StyleSheet,
  Text,
  ScrollView,
  useWindowDimensions,
  Pressable,
  View
} from "react-native";
import moment from "moment";
import { useNavigation } from "@react-navigation/native";
import { Entypo, Ionicons, FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';
import { MyTrips } from "../../components/booking/MyTrips"
import Flex from "../../components/CustomElements/Flex"
import Card from "../../components/CustomElements/Card"
import Header from "../../components/CustomElements/Header"
import { LoadingComponent } from "../../components/LoadingComponent";
import { UserDataContext } from "../../utils/UserContextProvider";
import RequestModal from "../../components/booking/RequestModal";
import { useSelector } from "react-redux";
import { formatMonthDay } from "../../utils/formatDate";
import { colors } from "../../styles/colors"
import CustomButton from "../../components/CustomElements/CustomButton";
import BackButton from "../../components/other/BackButton";
import Badge from "../../components/CustomElements/Badge";
import { globalStyles } from "../../styles/styles";
import { IDVerificationRequiredComponent } from "../../components/user/IDVerificationRequiredComponent";
import { listBookingRequestsQuery } from "../../api/bookingRequests";
import { listBookingsQuery } from "../../api/bookings";


const PendingReservation = ({ mobileView, reservationDetail }) => {
  const { check_in, check_out, spaceCity, spaceState, spaceTitle, spaceID, id } = reservationDetail;
  const [checkIn, setCheckIn] = useState('');
  const [checkOut, setCheckOut] = useState('');
  const location = `${spaceCity}, ${spaceState}`;

  useEffect(() => {
    setCheckIn(formatMonthDay(check_in));
    setCheckOut(formatMonthDay(check_out));
  }, []);

  return (
    <Card
      backgroundColor={colors.grey20}
      border="grey"
      alignItems="center"
      textAlign="center"
      width={170}
      justifyContent="center"
      alignContent="center"
    >
      <View style={{ alignSelf: "flex-end" }}>
        <Ionicons name="open-outline" size={24} color="#676767" />
      </View>
      <Entypo name="time-slot" size={24} color="grey" />
      <Header textAlign="center" color={colors.amplifyNeutral90} marginBottom={10} level={1} text="Pending Request..."></Header>
      <Text>{spaceTitle}</Text>
      <Text>Dates: {checkIn}-{checkOut}</Text>
    </Card>
  )
};

const PendingReservations = ({ pendingBookingRequests, cancelRequest }) => {
  const { width } = useWindowDimensions();
  const navigation = useNavigation();
  const { mobileView } = useSelector(state => state.currentUser);
  const boxWidth = width > 580 ? width / 1.3 : width / 1.4;
  const [requestToDisplay, setRequestToDisplay] = useState(null);
  const [rentalModalOpen, setRentalModalOpen] = useState(null);

  const viewThisRequest = (v) => {
    setRequestToDisplay(v);
    setRentalModalOpen(true)
  };

  const navigate = async () => {
    setRentalModalOpen(false);
    navigation.navigate('explore', {
      screen: 'space',
      params: {
        propertyID: requestToDisplay.propertyID,
      },
    });
  }


  return (
    <View style={styles.wrapperView}>
      {pendingBookingRequests.map((v, i) => (
        <View key={i}>
          <Pressable
            key={i}
            onPress={() => viewThisRequest(v)}
          >
            <PendingReservation
              boxWidth={boxWidth}
              width={width}
              mobileView={mobileView}
              reservationDetail={v}
              cancelRequest={(v) => cancelRequest(v)}
            />
          </Pressable>
        </View>
      ))}
      {!!requestToDisplay && !!Object.keys(requestToDisplay).length &&
        <RequestModal
          cancelRequest={cancelRequest}
          hostView={false}
          modalVisible={rentalModalOpen}
          setModalVisible={() => setRentalModalOpen(!rentalModalOpen)}
          bookingToDisplay={requestToDisplay}
          width={width}
          navigate={() => navigate()}
        />
      }
    </View>
  );
};

function TripsScreen() {
  const navigation = useNavigation();
  const [pastReservations, setPastReservations] = useState([]);
  const [upcomingReservations, setUpcomingReservations] = useState([]);
  const [currentReservations, setCurrentReservations] = useState([]);
  const [pendingReservations, setPendingReservations] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState('current');
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const { user } = useContext(UserDataContext);
  const { userData } = user;
  const { sub } = userData;
  const { width } = useWindowDimensions();
  const { mobileView, identityVerificationNotComplete, temporarilyHeldBookingRequestData } = useSelector(state => state.currentUser);
  const [reservationsPresent, setReservationsPresent] = useState(false);

  const listBookingReservations = async () => {
    setLoaded(false);
    const data = {
      renterID: { eq: sub },
      cancelledBeforeCheckIn: { eq: false }
    }
    const bookings = await listBookingsQuery({ data });

    if (bookings) {
      const today = moment(new Date()).format("YYYY-MM-DD");
      const upcoming = bookings.filter(function (el) {
        return moment(moment().format("YYYY-MM-DD")).isBefore(moment(el.check_in))
      });
      const current = bookings.filter(function (el) {
        return moment(moment().format("YYYY-MM-DD")).isBetween(el.check_in, el.check_out, 'days', '[]');
      });
      const past = bookings.filter(function (el) {
        return moment(el.check_out, "YYYY-MM-DD").isBefore(today)
      });
      const toShow = !!current.length ? 'current' : !!upcoming.length ? 'upcoming' : 'past';
      setActiveTabIndex(toShow);
      setUpcomingReservations(upcoming);
      setCurrentReservations(current);
      setPastReservations(past);
      setReservationsPresent(!!bookings.length);
    } else {
      console.log("error getting your reservations:   ", e)
      setError(e)
    }
    setLoaded(true)
  };

  const listBookingRequests = async () => {
    const data = {
      renterID: { eq: sub },
      reviewed: { eq: false },
      cancelled: { ne: true }
    }
    const bookingRequests = await listBookingRequestsQuery({ data });
    if (bookingRequests) {
      if (bookingRequests.length) {
        setPendingReservations(bookingRequests);
      } else if (!!temporarilyHeldBookingRequestData && !!Object.keys(temporarilyHeldBookingRequestData).length) {
        const tempArr = [];
        setPendingReservations([...tempArr, { ...temporarilyHeldBookingRequestData }])
      }
    }
  };

  const cancelRequest = async (v) => {
    const requestArray = pendingReservations.filter(function (el) { return el.id !== v });
    setPendingReservations(requestArray)
  };

  const goToHome = () => {
    navigation.reset({ routes: [{ name: 'explore' }] })
  }

  useEffect(() => {
    listBookingReservations();
  }, []);

  useEffect(() => {
    listBookingRequests();
  }, []);

  return (
    <ScrollView>
      <BackButton goBack={false} />
      {loaded ? (
        <View style={{ marginTop: mobileView ? 5 : 15 }}>
          {!!upcomingReservations && upcomingReservations.length ?
            <View style={[globalStyles.center, { width: mobileView ? width / 1.05 : 400, marginBottom: 10 }]}>
              <Badge color={colors.amplifyBlue20} height={30}>
                <View style={globalStyles.center}>
                  <MaterialCommunityIcons
                    name="rv-truck"
                    size={25}
                    color={colors.amplifyNeutral90}
                  />
                  <Text style={{ fontSize: 14, marginLeft: 10 }}>you have an upcoming trip</Text>
                </View>
              </Badge>
            </View>
            : <></>
          }
          <>
            {identityVerificationNotComplete && !!temporarilyHeldBookingRequestData && !!Object.keys(temporarilyHeldBookingRequestData).length ? (
              <View style={[mobileView ? globalStyles.centerColumn : globalStyles.center, { marginBottom: 200 }]}>
                <Card width={mobileView ? width / 1.15 : width / 2.5} padding={15}>
                  <IDVerificationRequiredComponent />
                </Card>
                <PendingReservations pendingBookingRequests={pendingReservations} cancelRequest={(v) => cancelRequest(v)} />
              </View>
            ) : (
              <>
                {!pendingReservations.length && !reservationsPresent ? (
                  <Flex
                    marginTop={40}
                    alignContent="center"
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                  >
                    <Text style={{ marginBottom: 40, fontSize: 16 }}>No trips planned yet!</Text>
                    <CustomButton
                      onPress={goToHome}
                      width={mobileView ? width / 1.25 : 300}
                      style={[styles.goToHomeButton]}
                    >
                      <View style={{ flexDirection: "row" }}>
                        <Text style={styles.goToHomeButtonText}>check out spaces</Text>
                        <FontAwesome5 name="arrow-right" size={mobileView ? 20 : 24} color="#403f3f" />
                      </View>
                    </CustomButton>
                  </Flex>
                ) : (
                  <>
                    {!!pendingReservations.length && <PendingReservations pendingBookingRequests={pendingReservations} cancelRequest={(v) => cancelRequest(v)} />}
                    {!!reservationsPresent &&
                      <MyTrips
                        mobileView={mobileView}
                        activeTabIndex={activeTabIndex}
                        setActiveTabIndex={(v) => setActiveTabIndex(v)}
                        currentReservations={currentReservations}
                        upcomingReservations={upcomingReservations}
                        pastReservations={pastReservations}
                      />}
                  </>
                )}
              </>
            )}
          </>
        </View>
      ) : (
        <Flex
          marginTop={100}
          alignContent="center"
          justifyContent="center"
          alignItems="center"
        >
          <LoadingComponent />
        </Flex>
      )}
    </ScrollView >
  );
}

const styles = StyleSheet.create({
  goToHomeButtonText: {
    color: "#303030",
    fontSize: 17,
    marginRight: 20
  },
  goToHomeButton: {
    borderColor: "grey",
    borderRadius: 10,
    marginTop: 40,
    borderWidth: 1,
    backgroundColor: "#c4833d",
    alignItems: "center",
    borderBottomWidth: 2,
    justifyContent: "center",
    alignSelf: "center",
    height: 40,
    padding: 20,
    shadowColor: "#252625",
    shadowOffset: { width: -2, height: 3 },
    shadowOpacity: 0.5,
  },
  wrapperView: {
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
  },
});

export default TripsScreen;
