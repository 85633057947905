import React, { useState, useEffect } from "react";
import {
  View,
  Image,
  StyleSheet,
  useWindowDimensions,
  Text as ReactText,
  Platform,
  Text
} from "react-native";
import Flex from "../CustomElements/Flex"
import Card from "../CustomElements/Card"
import Header from "../CustomElements/Header";
import CustomHorizontalScrollView from "../other/CustomHorizontalScrollView";
import { colors } from "../../styles/colors"
import { useSelector } from "react-redux";
import CustomSection from "../CustomElements/CustomSection";
import ContactView from "../space/ContactView";
import CustomCardTabs from "../CustomElements/CustomCardTabs";
import CustomButton from "../CustomElements/CustomButton";
import ViewDetailsButton from "../CustomElements/ViewDetailsButton";
import { formatMonthDay, formatYear } from "../../utils/formatDate";
import { isTodayCheck } from "../../utils/isTodayCheck";
import StarRatingDisplay from "../review/StarRatingDisplay";
import { renterReviewCheck } from "../../api/review";
import RenterReviewModal from "../review/ReviewModalRenter";
import { globalStyles } from "../../styles/styles";


const RentalCard = ({ booking, reviewable, activeTabIndex, setOpenBookingDetailsModal }) => {
  const {
    bookingRequest,
    renterID,
    hostID,
    id,
    check_out,
    check_in
  } = booking;
  const { propertyTitle, spaceTitle, spaceID, propertyID, spaceCity, spaceState } = bookingRequest;
  const [displayImage, setDisplayImage] = useState(null);
  const [checkIn, setCheckIn] = useState(null);
  const [checkOut, setCheckOut] = useState(null);
  const [checkInYear, setCheckInYear] = useState(null);
  const [checkOutYear, setCheckOutYear] = useState(null);
  const { mobileView } = useSelector(state => state.currentUser);
  const [alreadyReview, setAlreadyReview] = useState(null);
  const [loadingReview, setLoadingReview] = useState(true);
  const [ableToReview, setAbleToReview] = useState(reviewable);
  const [reviewModalOpen, setReviewModalOpen] = useState(false);

  const formatDates = () => {
    setCheckIn(formatMonthDay(check_in));
    setCheckInYear(formatYear(check_in));
    setCheckOut(formatMonthDay(check_out));
    setCheckOutYear(formatYear(check_out));
  };

  const checkForReview = async () => {
    setLoadingReview(true)
    setAbleToReview(false)
    setAlreadyReview(null)
    const bookingEndsToday = activeTabIndex === 'current' && isTodayCheck(check_out);

    if (reviewable || bookingEndsToday) {
      setAbleToReview(true)
      let reviewCheck = await renterReviewCheck({ bookingID: id, renterID })
      if (!!reviewCheck?.items.length) {
        setAlreadyReview(reviewCheck?.items[0].rating)
      }
    } else { setAbleToReview(reviewable) }
    setLoadingReview(false)
  }

  useEffect(() => {
    formatDates();
    checkForReview();
  }, [id]);

  return (
    <>
      <Card
        backgroundColor={colors.amplifyNeutral20}
        alignItems="center"
        textAlign="center"
        margin={4}
        width={220}
        padding={15}
      >
        <Flex
          direction="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          marginBottom={25}
          width={mobileView ? 210 : 170}
        >
          {!!displayImage && (
            <Image
              source={{ uri: displayImage }}
              padding={12}
              style={{ borderRadius: 12, width: 70, height: 70, margin: 5, justifyContent: "flex-end", alignContent: "flex-end", alignSelf: "flex-end" }}
            />
          )}
          {spaceTitle === propertyTitle ? (
            <Header text={spaceTitle} textAlign="center" marginTop={18} level={1.1}></Header>
          ) : (
            <>
              <Header textAlign="center" marginTop={18} text={propertyTitle} padding={1} level={1.1}></Header>
              <Header level={.8} text={spaceTitle} padding={1} marginBottom={10} textAlign="center"></Header>
            </>
          )}
          <View style={globalStyles.center}>
            <Card
              backgroundColor={colors.orange10}
              alignItems="center"
              textAlign="center"
              justifyContent="center"
              alignContent="center"
              width={mobileView ? 90 : 80}
            >
              <ReactText style={styles.centerText}>{mobileView ? 'IN' : 'CHECK-IN'}</ReactText>
              <Flex
                alignItems="center"
                textAlign="center"
                justifyContent="center"
                alignContent="center"
              ><Header textAlign="center" level={1} text={checkIn} color={colors.amplifyNeutral90} /></Flex>
              <ReactText style={styles.centerText}>{checkInYear}</ReactText>
            </Card>
            <Card
              backgroundColor={colors.orange10}
              alignItems="center"
              justifyContent="center"
              alignContent="center"
              textAlign="center"
              width={mobileView ? 90 : 80}
            >
              <ReactText style={styles.centerText}>{mobileView ? 'OUT' : 'CHECK-OUT'}</ReactText>
              <Flex
                alignItems="center"
                textAlign="center"
                justifyContent="center"
                alignContent="center"
              >
                <Header textAlign="center" level={1} text={checkOut} color={colors.amplifyNeutral90} />
              </Flex>
              <ReactText style={styles.centerText}>{checkOutYear}</ReactText>
            </Card>
          </View>
          <View style={globalStyles.center}>
            <ContactView
              viewWidth={150}
              centerView={true}
              ownerID={hostID}
              hosteeID={renterID}
              spaceID={spaceID}
              propertyTitle={propertyTitle}
              propertyID={propertyID}
              spaceTitle={spaceTitle}
              spaceState={spaceState}
              spaceCity={spaceCity}
              contactToHost={false}
            />
          </View>
          {!loadingReview && ableToReview && (
            <>
              {!!alreadyReview ? (
                <>
                  <Text>My Review of Renter:</Text>
                  <StarRatingDisplay stars={alreadyReview} />
                </>
              ) : (
                <>
                  <CustomButton
                    width={145}
                    height={40}
                    marginTop={5}
                    backgroundColor={colors.amplifyBlue20}
                    onPress={() => setReviewModalOpen(true)}
                  >
                    <ReactText style={{ fontSize: mobileView ? 17 : 18, fontWeight: "400" }}>Review Renter</ReactText>
                  </CustomButton>
                </>
              )}
            </>
          )}
          <ViewDetailsButton onPress={() => setOpenBookingDetailsModal()} />
        </Flex>
      </Card>
      <RenterReviewModal
        modalVisible={reviewModalOpen}
        setModalVisible={() => setReviewModalOpen(!reviewModalOpen)}
        setAlreadyReview={(v) => setAlreadyReview(v)}
        bookingDetails={booking}
      />
    </>
  )
};

export const MySpaceRentals = ({
  bookings,
  activeTabIndex = null,
  setActiveTabIndex,
  setOpenBookingDetailsModal
}) => {
  const { width } = useWindowDimensions();
  const { mobileView } = useSelector(state => state.currentUser);
  const [bookingsView, setBookingsView] = useState({});

  useEffect(() => {
    activeTabIndex ? setBookingsView(bookings[activeTabIndex]) : setBookingsView({});
  }, [activeTabIndex]);

  return (
    <View style={styles.wrapperView}>
      {Platform.OS !== 'web' && (<CustomCardTabs mobileView={mobileView} activeTabIndex={activeTabIndex} setActiveTabIndex={(v) => setActiveTabIndex(v)} />)}
      <Flex
        direction="column"
        marginBottom={4}
        marginTop={20}
        textAlign="left"
        margin={5}
      >
        {Platform.OS === 'web' && (<CustomCardTabs mobileView={mobileView} activeTabIndex={activeTabIndex} setActiveTabIndex={(v) => setActiveTabIndex(v)} />)}
        <CustomSection backgroundColor={colors.amplifyBlue20} marginBottom={20}>
          <Header
            level={.9}
            marginTop={mobileView ? 10 : 15}
            paddingLeft={4}
            marginBottom={20}
            color={colors.amplifyNeutral90}
            text="MY SPACE RENTALS"
          />
          {activeTabIndex && Object.keys(bookingsView).length ? (
            <CustomHorizontalScrollView>
              {bookingsView.map((booking, key) => (
                <View key={key}>
                  <RentalCard
                    width={width}
                    booking={booking}
                    reviewable={activeTabIndex === 'past'}
                    activeTabIndex={activeTabIndex}
                    setOpenBookingDetailsModal={() => setOpenBookingDetailsModal(booking)}
                  />
                </View>
              ))}
            </CustomHorizontalScrollView>
          ) : (
            <ReactText style={{ margin: 30 }}>No {activeTabIndex} reservations yet</ReactText>
          )}
        </CustomSection>
      </Flex>
    </View>

  );
};

const styles = StyleSheet.create({
  wrapperView: {
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    marginVertical: 15
  },
  centerText: {
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    textAlign: "center"
  },
});
