import React, { useState, useEffect } from "react";
import { Entypo } from "@expo/vector-icons";
import { Calendar } from "react-native-calendars";
import { StyleSheet, View as ReactView, Text, View } from "react-native";
import Card from "../CustomElements/Card"
import moment from "moment";
import { colors } from "../../styles/colors";
import FixedHeightCard from "../CustomElements/FixedHeightCard";

const CalendarView = ({ space, setCurrentDateSpan, currentDateSpan, initialDate, mySpace = false, customWidth, mobileView, error, setError }) => {
  const [firstDay, setFirstDay] = useState(null);
  const [numClicks, setNumClicks] = useState(0);
  const [selected, setSelected] = useState({});
  const [unavailableTimesDisplay, setUnavailableTimesDisplay] = useState({});
  const [displayTimes, setDisplayTimes] = useState({});

  const getDaysArray = (start, end) => {
    let obj = {};
    for (
      const dt = new Date(`${start}T00:00:00`);
      dt <= new Date(`${end}T00:00:00`);
      dt.setDate(dt.getDate() + 1)
    ) {
      const year = dt.toLocaleString("default", { year: "numeric" });
      const month = dt.toLocaleString("default", { month: "2-digit" });
      const day = dt.toLocaleString("default", { day: "2-digit" });
      const theDate = year + "-" + month + "-" + day;
      obj = { ...obj, [theDate]: { color: colors.orange30 } };
    }
    return obj;
  };

  const checkIfSpanIsInvalid = (unavailDates, selectedDates) => {
    let invalidError = "Invalid! Your selected date span has unavailable days within it. Please select different dates.";
    if (!!unavailDates && !!selectedDates && !!Object.keys(unavailDates) && !!Object.keys(selectedDates)) {
      const unavailDays = Object.keys(unavailDates)
      const selectedDays = Object.keys(selectedDates);
      const lastSelectedDay = selectedDays[selectedDays.length - 1];
      const firstSelectedDay = selectedDays[0];
      const lastDayUnavail = !!(unavailDays.find((v) => v === lastSelectedDay))
      const firstDayUnavail = !!(unavailDays.find((v) => v === firstSelectedDay))
      if (!lastDayUnavail && !firstDayUnavail) {
        for (let i = 0; i < unavailDays.length; i++) {
          const thisDay = unavailDays[i];
          const firstDayBeforeThisDay = moment(firstSelectedDay, "YYYY-MM-DD").isBefore(thisDay)
          const lastDayIsAfterThisDay = moment(thisDay, "YYYY-MM-DD").isBefore(lastSelectedDay)
          let localError = firstDayBeforeThisDay && lastDayIsAfterThisDay;
          if (localError) {
            setError(invalidError)
            i = unavailDays.length;
          } else {
            i++;
            setError(null);
          }
        }
      } else {
        setError(invalidError)
      }
    }
  }

  const setDayClicked = (v) => {
    const { dateString } = v;
    if (numClicks === 0) {
      setNumClicks(1);
      setFirstDay(dateString);
      setDisplayTimes({
        ...unavailableTimesDisplay,
        [dateString]: { color: colors.orange30 },
      });
    } else if (numClicks === 1) {
      setNumClicks(2);
      const updatedDatesObj = getDaysArray(firstDay, dateString);
      setSelected(updatedDatesObj);
      const updatedSpan = { ...unavailableTimesDisplay, ...updatedDatesObj }
      checkIfSpanIsInvalid(unavailableTimesDisplay, updatedDatesObj)
      setDisplayTimes(updatedSpan);
      setCurrentDateSpan(Object.keys(updatedDatesObj));
    } else {
      setNumClicks(1);
      setFirstDay(dateString);
      setDisplayTimes({
        ...unavailableTimesDisplay,
        [dateString]: { color: colors.orange30 },
      });
      setSelected({});
    }
  };

  const updateDisplay = async () => {
    let newObj = {};
    await checkInvalidDates();
    for (let i = 0; i < Object.keys(currentDateSpan).length; i++) {
      let newKey = currentDateSpan[i];
      newObj[newKey] = { color: colors.orange30 };
    }
    setDisplayTimes({ ...newObj, ...unavailableTimesDisplay });
    checkIfSpanIsInvalid(unavailableTimesDisplay, newObj)
    setSelected(newObj);
  };

  const checkInvalidDates = async () => {
    let newObj = {};
    const today = moment(new Date()).format("YYYY-MM-DD");
    let spaceBookings = space?.bookings?.items;
    let spaceBookingRequests = space?.bookingRequests?.items;
    let unavailableDays = space?.unavailable_days;
    newObj[today] = {
      textColor: "#d6d4d4",
      color: "#bdbbbb",
      disabled: true,
      disableTouchEvent: true,
    };
    if (!!spaceBookingRequests && !!spaceBookingRequests.length) {
      for (let i = 0; i < spaceBookingRequests.length; i++) {
        const thisBookingRequest = spaceBookingRequests[i];
        if (!thisBookingRequest.reviewed) {
          for (let i = 0; i < thisBookingRequest.bookedDays.length; i++) {
            let newKey = thisBookingRequest.bookedDays[i];
            newObj[newKey] = {
              textColor: "#d6d4d4",
              color: "#8a8888",
              disabled: true,
              disableTouchEvent: true,
            };
          }
        }
      }
    }
    if (!!unavailableDays && !!unavailableDays.length) {
      for (let i = 0; i < unavailableDays.length; i++) {
        let newKey = unavailableDays[i];
        newObj[newKey] = {
          textColor: "#d6d4d4",
          color: "#bdbbbb",
          disabled: true,
          disableTouchEvent: true,
        };
      }
    }
    if (!!spaceBookings?.length) {
      for (let i = 0; i < spaceBookings.length; i++) {
        const thisBooking = spaceBookings[i];
        if (!thisBooking.cancelled) {
          for (let i = 0; i < Object.keys(thisBooking.bookedDays).length; i++) {
            let newKey = thisBooking.bookedDays[i];
            newObj[newKey] = {
              textColor: "#d6d4d4",
              color: "#bdbbbb",
              disabled: true,
              disableTouchEvent: true,
            };
          }
        }
      }
    }
    setUnavailableTimesDisplay(newObj);
    setDisplayTimes(newObj);
  }

  useEffect(() => {
    if (!!Object.keys(currentDateSpan).length) {
      updateDisplay();
    } else {
      checkInvalidDates();
    }
  }, [currentDateSpan]);

  return (
    <View>
      <Text style={{ color: "red" }}>{error}</Text>
      <Calendar
        markingType={"period"}
        markedDates={displayTimes}
        onDayPress={(day) => {
          setDayClicked(day);
        }}
        minDate={new Date()}
        disabled={mySpace}
        initialDate={initialDate}
        renderArrow={(direction) => {
          return (
            <>
              {direction == "right" ? (
                <Entypo
                  name={"arrow-with-circle-right"}
                  size={24}
                  color={colors.amplifyNeutral80}
                />
              ) : (
                <Entypo
                  name={"arrow-with-circle-left"}
                  size={24}
                  color={colors.amplifyNeutral80}
                />
              )}
            </>
          );
        }}
        firstDay={1}
        enableSwipeMonths={true}
        monthFormat={mobileView ? 'MMM' : 'MMMM'}
        dayFormat={'d'}
        theme={{
          backgroundColor: colors.orange10,
          calendarBackground: colors.orange10,
          textSectionTitleColor: "#b6c1cd",
          selectedDayBackgroundColor: colors.orange30,
          todayTextColor: colors.orange30,
          dayTextColor: colors.amplifyNeutral90,
          monthTextColor: colors.amplifyNeutral90,
          indicatorColor: colors.orange30,
          textDayFontWeight: 300,
          textMonthFontWeight: "bold",
          textDayHeaderFontWeight: 300,
          textDayFontSize: !!mobileView ? 15 : 20,
          textMonthFontSize: !!mobileView ? 18 : 20,
          textDayHeaderFontSize: !!mobileView ? 16 : 17,
        }}
        style={{
          width: customWidth - 20
        }}
      />
    </View>
  );
};

export default CalendarView;
