import React from 'react';
import {
    View,
    Dimensions,
    StyleSheet,
    Platform,
} from 'react-native';
import { colors } from '../../styles/colors';
import { globalStyles } from '../../styles/styles';

const FixedHeightCard = ({
    children,
    elevation = 3,
    backgroundColor = "#fff",
    width = Dimensions.get('window').width / 1.2,
    height = 400,
    marginTop = 5,
    margin = 5,
    marginBottom = 5,
    marginLeft = 5,
    marginRight = 5,
    padding = 10,
    textAlign = "center",
    alignItems = "center",
    shadow = true,
    borderRadius = 7,
    maxWidth = null,
}) => {

    const cardStyle = Platform.select({
        ios: () =>
            StyleSheet.create({
                container: {
                    shadowRadius: elevation,
                    shadowOffset: { width: 0, height: elevation },
                }
            }),
        android: () =>
            StyleSheet.create({
                container: {
                    elevation: elevation,
                }
            }),
        web: () =>
            StyleSheet.create({
                container: {
                    elevation: elevation,
                }
            })
    })();

    return (
        <View style={[cardStyle.container, styles.card, shadow && styles.shadowProp, {
            margin: margin,
            marginTop: marginTop,
            marginBottom: marginBottom,
            marginLeft: marginLeft,
            marginRight: marginRight,
            width: width,
            height: height,
            maxWidth: maxWidth ? maxWidth : width,
            padding: padding,
            textAlign: textAlign,
            alignItems: alignItems,
            backgroundColor: backgroundColor,
            borderRadius: borderRadius
        }]}>
            {children}
        </View>
    )

}

const styles = StyleSheet.create({
    card: {
        marginVertical: 10,
    },
    shadowProp: {
        shadowColor: colors.amplifyNeutral80,
        shadowOffset: { width: 1, height: 2 },
        shadowOpacity: .4,
        shadowRadius: 5,
    },
})
export default FixedHeightCard;