import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import ProfileScreen from '../screens/account/ProfileScreen';
import AccountScreen from '../screens/account/AccountScreen';
import PaymentSettingsScreen from '../screens/account/PaymentSettingsScreen';
import HostAccountNavigator from './HostAccountNavigator';

const Stack = createStackNavigator();

function AccountNavigator() {
  return (
    <Stack.Navigator screenOptions={{
      headerTitleAlign: 'center',
      cardStyle: { backgroundColor: "#fff" },
    }}>
      <Stack.Screen
        component={AccountScreen}
        name={'options'}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        component={HostAccountNavigator}
        name={'hostaccount'}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        component={ProfileScreen}
        name={'profile'}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        component={PaymentSettingsScreen}
        name={'paymentsettings'}
        options={{ headerShown: false }}
      />
      {/* <Stack.Screen
        component={NotificationsSettingsScreen}
        name={'NotificationSettings'}
        options={{headerShown: false}}
      /> */}
      {/* <Stack.Screen
        component={LocationPreferencesScreen}
        name={'LocationPreferences'}
        options={{headerShown: false}}
      /> */}
    </Stack.Navigator>
  );
}

export default AccountNavigator;
