import {
  Linking,
  Platform,
  Text,
  View
} from "react-native";
import Header from "../CustomElements/Header"
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { colors } from "../../styles/colors";
import { globalStyles } from "../../styles/styles";
import { IDVerification } from "./IDVerification";
import CustomButtonUnderlined from "../CustomElements/CustomButtonUnderlined";
import { VerifyIdentityIcon } from "./VerifyIdentityIcon";

export const AccountStatus = ({ userState, identityVerificationNotComplete }) => {

  return (
    <View>
      <Header padding={0} marginBottom={20} text="Account status"></Header>
      <View style={[globalStyles.flexStart, { marginBottom: 15 }]}  >
        <View style={globalStyles.center} >
          <Text style={{ fontWeight: "bold", marginRight: 15 }}>Email: </Text>
          {userState.email_verified ? <Ionicons name="checkmark-circle" size={25} color={colors.amplifyNeutral80} /> : <MaterialCommunityIcons name="alert-box" size={25} color={colors.orange50} />}
          <Text>{userState.email_verified ? "Confirmed" : "Unconfirmed"}</Text>
        </View>
      </View>
      {identityVerificationNotComplete ? (
        <View style={globalStyles.flexStartColumn}>
          <View style={globalStyles.center}>
            <Text style={{ fontWeight: "bold", marginRight: 15 }}>Identity Verification </Text>
            <MaterialCommunityIcons name="police-badge-outline" size={25} color={colors.orange50} />
            <Text style={{ marginLeft: 5 }}>Not Verified!</Text>
          </View>
          <View style={[globalStyles.flexStartColumn, { width: 300, margin: 10, marginTop: 15 }]} >
            <Text>Show others you're really you with the Identity Verification badge.</Text>
            <Text style={{ fontStyle: "italic", marginTop: 5 }}>Your Identity must be Verified to create your first Property or first Booking. This is a one-time process and you wont have to complete it again.</Text>
            {Platform.OS === "web" ? (
            <a href={"https://info.hookhub.co/blog/008"} target="_blank" style={{ textDecorationLine: "none" }}>
              <CustomButtonUnderlined onPress={() => console.log("open blog article")} alignItems="flex-start">
                <Text style={{ textDecorationLine: "underline" }}>Learn more about Identity Verification</Text>
              </CustomButtonUnderlined>
            </a>
            ) : (
              <CustomButtonUnderlined onPress={() => Linking.openURL("https://info.hookhub.co/blog/008")} alignItems="flex-start">
                <Text style={{ textDecorationLine: "underline" }}>Learn more about Identity Verification</Text>
              </CustomButtonUnderlined>
            )}
            <IDVerification />
          </View>
        </View>
      ) : (
        <View style={globalStyles.flexStart}>
          <View style={globalStyles.center}>
            <Text style={{ fontWeight: "bold", marginRight: 15 }}>Identity Verification </Text>
            <View style={globalStyles.center} >
              <VerifyIdentityIcon />
              <Text style={{ marginLeft: 5 }}>ID Verified</Text>
            </View>
          </View>
        </View>
      )}
    </View >
  );
};
