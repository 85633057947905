import { notifyHookhub } from "../lambdaFunctions/notifyHookhub";
import { alertNoticeOfSuccess, setIdentityVerificationNotComplete, temporarilyHoldBookingRequestData } from "../redux/actions/CurrentUserActions";
import { store } from "../redux/store";
import { createBookingRequestMutation } from "./bookingRequests";
import { createBookingMutation } from "./bookings";
import { updatePropertyMutation } from "./property";
import { updateUserMutation } from "./user";

const updateUser = async ({ userAccessCode, currentUserData, JWT, fullName }) => {
    let data = {
        identityVerificationAccessCode: userAccessCode,
        id: currentUserData.sub
    }
    let updatedUser = await updateUserMutation({ currentUserData: currentUserData, data })
    if (!updatedUser) {
        let userID = currentUserData.sub;
        let userEmail = currentUserData.email;
        let subject = `ERROR updating userAccessCode`
        let body = `On attempt to update the user with ID verification userAcces code. The userID is: ${userID} and the userAccessCode is: ${userAccessCode}`
        return notifyHookhub({ subject, body, userID: userID, userEmail: userEmail })
    } else {
        console.log("SUCCESS >")
        return getIDVerificationUserConsent({ userAccessCode, JWT, fullName, updatedUser })
    }
};

export const createIDVerificationUser = async ({ data, currentUserData }) => {
    const { firstName, lastName } = data;
    let fullName = firstName + " " + lastName;

    const options = {
        method: 'POST',
        headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            authorization: 'Bearer MZ5VU3RMOM5EASZGMJWWM4BMOEVGQ3CJJBME2PZVENLEGPSD'
        },
        body: JSON.stringify(data)
    };
    return await fetch('https://api-v3.authenticating.com/user/create', options)
        .then(response => response.json())
        .then(response => getIDVerificationJWT({ userAccessCode: response.userAccessCode, currentUserData, fullName }))
        .catch(err => false);
};

const getIDVerificationJWT = async ({ userAccessCode, currentUserData, fullName }) => {
    const options = {
        method: 'POST',
        headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            authorization: 'Bearer MZ5VU3RMOM5EASZGMJWWM4BMOEVGQ3CJJBME2PZVENLEGPSD'
        },
        body: JSON.stringify({
            userAccessCode: userAccessCode,
            preferredWorkflowID: 'A5izvQ8EeA'
        })
    };

    return await fetch('https://api-v3.authenticating.com/user/jwt', options)
        .then(response => response.json())
        .then(response => {
            return updateUser({ currentUserData, userAccessCode, JWT: response.jwt, fullName })
        })
        .catch(err => false);
};

const getIDVerificationUserConsent = async ({ userAccessCode, JWT, fullName, updatedUser }) => {
    const options = {
        method: 'POST',
        headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            authorization: 'Bearer MZ5VU3RMOM5EASZGMJWWM4BMOEVGQ3CJJBME2PZVENLEGPSD'
        },
        body: JSON.stringify({
            userAccessCode: userAccessCode,
            isBackgroundDisclosureAccepted: 1,
            GLBPurposeAndDPPAPurpose: 1,
            FCRAPurpose: 1,
            fullName: fullName
        })
    };

    const url = await fetch('https://api-v3.authenticating.com/user/consent', options)
        .then(response => response.json())
        .then(response => {
            return {
                generatedLink: `https://verify.authenticating.com/?token=${JWT}`,
                updatedUserData: updatedUser
            }
        })
        .catch(err => false);
    return url;
};

export const getIDVerificationStatus = async ({ userAccessCode }) => {
    const options = {
        method: 'POST',
        headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            authorization: 'Bearer MZ5VU3RMOM5EASZGMJWWM4BMOEVGQ3CJJBME2PZVENLEGPSD'
        },
        body: JSON.stringify({ userAccessCode: userAccessCode })
    };
    return await fetch('https://api-v3.authenticating.com/user/getTestResult', options)
        .then(response => response.json())
        .then(response => {
            const { identityProof, idVerification, verifyUI } = response;
            const { scannedUser } = verifyUI;
            const idVerifyStatus = idVerification.status;
            const kbaVerifyStatus = identityProof.status;
            const selfieAndDLStatus = !!scannedUser && scannedUser.result === "complete" && scannedUser.confidence_rating > 90 && scannedUser.face_match_score > 85 && scannedUser.liveness_result?.score > 85;

            return !!(idVerifyStatus || kbaVerifyStatus || selfieAndDLStatus)
        })
        .catch(err => console.error(err));
}

export const createWebhookAuthenticate = async () => {
    const options = {
        method: 'POST',
        headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            authorization: 'Bearer MZ5VU3RMOM5EASZGMJWWM4BMOEVGQ3CJJBME2PZVENLEGPSD'
        },
        body: JSON.stringify({
            companyAccessCode: '6f0d89dd-7ca4-4545-9c05-dfcb36cd3005',
            webhookURL: 'https://zvlakqn2ft67eqmwkw5jdvds5m0xojnv.lambda-url.us-west-2.on.aws/'
        })
    };

    return await fetch('https://api-v3.authenticating.com/company/webhook', options)
        .then(response => response.json())
        .then(response => {
            console.log("RESPONSE > ", response)
        })
        .catch(err => console.error(err));
}

export const idVerificationCompleteCheck = async ({
    identityVerificationAccessCode,
    identityVerificationStatus,
    userID,
    host,
    myHostedProperties,
    currentUserData
}) => {
    const verifyNeeded = !identityVerificationStatus || identityVerificationStatus !== "VERIFIED";

    if (verifyNeeded) {
        if (!identityVerificationAccessCode) {
            store.dispatch(setIdentityVerificationNotComplete({ required: true })) 
            return false;
        }
        const verifyChecksOut = await getIDVerificationStatus({ userAccessCode: identityVerificationAccessCode });
        console.log("THE VERIFY STATUS     > ", verifyChecksOut)
        if (verifyChecksOut) {
            let temporarilyHeldBookingRequestData = store.getState().currentUser.temporarilyHeldBookingRequestData;
            let notificationMsg = ""
            let userData = {
                identityVerificationAccessCode: identityVerificationAccessCode,
                identityVerificationStatus: "VERIFIED",
                id: userID
            }
            await updateUserMutation({ currentUserData, data: userData });

            if (host) {
                const data = {
                    id: myHostedProperties.items[0].id,
                    approved: true
                };
                const updatedProperty = await updatePropertyMutation({ data });
                if (updatedProperty) {
                    notificationMsg = "Your new Property is now Active."
                }
                store.dispatch(setIdentityVerificationNotComplete({ required: false }))
                store.dispatch(alertNoticeOfSuccess({ success: true, actionCompletedAfterIDVerified: updatedProperty ? notificationMsg : "" }))
                return true;

            } else if (!!Object.keys(temporarilyHeldBookingRequestData).length) {
                let { instant_book } = temporarilyHeldBookingRequestData;
                delete temporarilyHeldBookingRequestData.instant_book
                temporarilyHeldBookingRequestData.reviewed = true,
                temporarilyHeldBookingRequestData.cancelled = false
                const bookingRequestCreated = await createBookingRequestMutation({ data: temporarilyHeldBookingRequestData });
                if (bookingRequestCreated) {
                    notificationMsg = "Your Booking Request is complete!"
                    console.log(" I have an instant book")
                    if (instant_book) {
                        delete temporarilyHeldBookingRequestData.reviewed;
                        let bookingData = {
                            ...temporarilyHeldBookingRequestData,
                            cancelledBeforeCheckIn: false
                        }
                        await createBookingMutation({ data: bookingData })
                    }
                }

                store.dispatch(setIdentityVerificationNotComplete({ required: false }))
                store.dispatch(alertNoticeOfSuccess({ success: true, actionCompletedAfterIDVerified: bookingRequestCreated ? notificationMsg : "" }))
                store.dispatch(temporarilyHoldBookingRequestData({ bookingRequestData: {} }))
                return true;
            } else {
                store.dispatch(setIdentityVerificationNotComplete({ required: false }))
                store.dispatch(alertNoticeOfSuccess({ success: true, actionCompletedAfterIDVerified: "" }))
                return true;
            }
        } else {
             store.dispatch(setIdentityVerificationNotComplete({ required: true }));
             return false;
        }
    } else {
        store.dispatch(setIdentityVerificationNotComplete({ required: false })) 
        return false;
    }
}

