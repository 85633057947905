import {
    View,
    useWindowDimensions,
    Text,
} from 'react-native';
import TextBody from '../CustomElements/TextBody';
import { globalStyles } from '../../styles/styles';
import { useEffect, useState } from 'react';
import SelectField from '../CustomElements/SelectField';


let minDaysInMonth = [
    "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28"
]

let months = [
    "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"
]

const monthAndDays =
{
    "01": [...minDaysInMonth, "29", "30", "31"],
    "02": [...minDaysInMonth],
    "03": [...minDaysInMonth, "29", "30", "31"],
    "04": [...minDaysInMonth, "29", "30"],
    "05": [...minDaysInMonth, "29", "30", "31"],
    "06": [...minDaysInMonth, "29", "30"],
    "07": [...minDaysInMonth, "29", "30", "31"],
    "08": [...minDaysInMonth, "29", "30", "31"],
    "09": [...minDaysInMonth, "29", "30"],
    "10": [...minDaysInMonth, "29", "30", "31"],
    "11": [...minDaysInMonth, "29", "30"],
    "12": [...minDaysInMonth, "29", "30", "31"]
}

const years = ['2007', '2006', '2005', '2004', '2003', '2002', '2001', '2000', '1999', '1998', '1997', '1996', '1995', '1994', '1993', '1992', '1991', '1990', '1989', '1988', '1987', '1986', '1985', '1984', '1983', '1982', '1981', '1980', '1979', '1978', '1977', '1976', '1975', '1974', '1973', '1972', '1971', '1970', '1969', '1968', '1967', '1966', '1965', '1964', '1963', '1962', '1961', '1960', '1959', '1958', '1957', '1956', '1955', '1954', '1953', '1952', '1951', '1950', '1949', '1948', '1947', '1946', '1945', '1944', '1943', '1942', '1941', '1940', '1939', '1938', '1937', '1936', '1935', '1934', '1933', '1932', '1931', '1930', '1929', '1928', '1927', '1926', '1925', '1924', '1923', '1922', '1921', '1920']

const DateOfBirthInput = ({
    year,
    setyear,
    month,
    setmonth,
    day,
    setday
}) => {
    const { width } = useWindowDimensions();
    const [daysToDisplay, setDaysToDisplay] = useState(monthAndDays["01"]);

    useEffect(() => {
        const leapYear = year % 4 === 0;
        if (leapYear) {
            monthAndDays["02"] = [...minDaysInMonth, "29"]
        } else {
            monthAndDays["02"] = [...minDaysInMonth]
        }
    }, [year]);

    useEffect(() => {
        let days = monthAndDays[month]
        month !== "MM" && setDaysToDisplay(days)
    }, [month, year]);

    return (
        <View style={[globalStyles.centerColumn, { width: width, marginBottom: 40, marginTop: 20 }]}>
            <TextBody style={{ fontStyle: "italic", fontSize: 17 }}>Enter Date of Birth</TextBody>
            <View style={width < 400 ? globalStyles.centerColumn : globalStyles.center}>
                <View style={globalStyles.centerColumn}>
                    <Text style={{ fontSize: 10 }}>YYYY</Text>
                    <SelectField
                        options={years}
                        value={year}
                        onChange={(e) => setyear(e)}
                        width={100}
                        placeholder="YYYY"
                        label="year"
                    />
                </View>
                <View style={globalStyles.centerColumn}>
                    <Text style={{ fontSize: 10 }}>MM</Text>
                    <SelectField
                        options={months}
                        value={month}
                        onChange={(e) => setmonth(e)}
                        width={100}
                        placeholder="MM"
                        label="month"
                    />
                </View>
                <View style={globalStyles.centerColumn}>
                    <Text style={{ fontSize: 10 }}>dd</Text>
                    <SelectField
                        options={daysToDisplay}
                        value={day}
                        onChange={(e) => setday(e)}
                        width={100}
                        placeholder="dd"
                        label="day"
                    />
                </View>
            </View>
        </View>
    )
}

export default DateOfBirthInput;

