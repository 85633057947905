import { Text } from 'react-native';

const TextTruncate = ({ children, fontSize = 17 }) => {
    return (

        <Text
            numberOfLines={1}
            ellipsizeMode="tail"
            style={{
                fontSize: fontSize
            }}>{children}</Text>
    );
};

export default TextTruncate;