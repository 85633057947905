import {
    StyleSheet,
    View,
} from "react-native";
import { Ionicons, FontAwesome } from "@expo/vector-icons";
import { colors } from "../../styles/colors";

export const VerifyIdentityIcon = ({ largeVersion = false }) => {


    return (
        <View>
            {largeVersion ? (
                <View style={{ height: 200, width: 150 }}>
                    <View style={{ position: "absolute", top: 20, left: 10 }}>
                        <View style={{ backgroundColor: colors.amplifyNeutral40, width: 150, height: 115 }} />
                        <View style={{ position: "absolute", top: 2, left: 1 }}>
                            <View style={{ backgroundColor: colors.white, width: 147, height: 100, borderRadius: 10 }} />
                            <View style={{ position: "absolute", top: -11, left: 0 }}>
                                <FontAwesome name="id-card-o" size={130} color={colors.amplifyNeutral40} />
                            </View>
                        </View>
                    </View>
                    <View>
                        <View style={{ backgroundColor: colors.brightBlue, borderRadius: 100, height: 65, width: 65 }} />
                        <View style={{ position: "absolute", top: 7, left: 7 }}>
                            <Ionicons name="shield-checkmark-sharp" size={50} color={"#fff"} />
                        </View>
                    </View>
                </View>
            ) : (
                <View>
                    <Ionicons name="shield-checkmark-sharp" size={25} color={colors.amplifyNeutral80} />
                </View>

            )}
        </View>
    );
};

const styles = StyleSheet.create({
    types: {
        color: colors.grey20,
        marginVertical: 10,
    },
    editProfileImage: {
        height: 200,
        justifyContent: "center",
        width: 200,
        margin: 10,
        marginTop: 15,
        borderRadius: 100,
    },
    container: {
        margin: 20,
    },
    image: {
        aspectRatio: 3 / 2,
        borderRadius: 10,
        width: "100%",
    },
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 22,
    },
    modalView: {
        margin: 20,
        backgroundColor: "white",
        borderRadius: 20,
        padding: 35,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    button: {
        borderRadius: 20,
        padding: 10,
        elevation: 2,
    },
    cancelbutton: {
        alignItems: "center",
        justifyContent: "center",
        paddingVertical: 1,
        paddingHorizontal: 32,
        borderRadius: 4,
        elevation: 3,
        backgroundColor: "white",
    },
    canceltext: {
        fontSize: 16,
        lineHeight: 21,
        fontWeight: "bold",
        letterSpacing: 0.25,
        color: "black",
    },
    textStyle: {
        color: "white",
        fontWeight: "bold",
        textAlign: "center",
    },
    logoImage: {
        height: 120,
        display: "block",
        width: 120,
        borderRadius: 100,
        borderWidth: 1,
    },
});












