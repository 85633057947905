import { StyleSheet, Text, View } from "react-native";
import { colors } from "../../styles/colors";
import Badge from "../CustomElements/Badge";
import { globalStyles } from "../../styles/styles";

const SelectStayType = ({
    property = null,
    setSelectedType,
    selectedType,
    largeVersion=false
}) => {

    return (
        <View style={globalStyles.center}>
            {(property === null || property?.type_shortterm) && <Badge onPress={() => setSelectedType('vacation')} noWidth={true} margin={2} padding={0} paddingLeft={largeVersion ? 20 : 5} paddingRight={largeVersion ? 20 : 5} color={selectedType === 'vacation' ? colors.orange40 : colors.grey10} height={largeVersion ? 35 : 28}> <><Text style={[styles.fontSize, { fontSize: largeVersion ? 15 : 12, color: selectedType === 'vacation' ? "white" : "#000" }]}>Nightly</Text></></Badge>}
            {(property === null || property?.type_longterm) && <Badge onPress={() => setSelectedType('monthly')} noWidth={true} margin={2} padding={0} paddingLeft={largeVersion ? 20 : 5} paddingRight={largeVersion ? 20 : 5} color={selectedType === 'monthly' ? colors.orange40 : colors.grey10} height={largeVersion ? 35 : 28}> <><Text style={[styles.fontSize, { fontSize: largeVersion ? 15 : 12, color: selectedType === 'monthly' ? "white" : "#000" }]}>Monthly</Text></></Badge>}
            {(property === null || property?.type_yearly) && <Badge onPress={() => setSelectedType('type_yearly')} noWidth={true} margin={2} padding={0} paddingLeft={largeVersion ? 20 : 5} paddingRight={largeVersion ? 20 : 5} color={selectedType === 'type_yearly' ? colors.orange40 : colors.grey10} height={largeVersion ? 35 : 28}> <><Text style={[styles.fontSize, { fontSize: largeVersion ? 15 : 12, color: selectedType === 'type_yearly' ? "white" : "#000" }]}>Yearly</Text></></Badge>}
            {(property === null || property?.type_storage) && <Badge onPress={() => setSelectedType('storage')} noWidth={true} margin={2} padding={0} paddingLeft={largeVersion ? 20 : 5} paddingRight={largeVersion ? 20 : 5} color={selectedType === 'storage' ? colors.orange40 : colors.grey10} height={largeVersion ? 35 : 28}> <><Text style={[styles.fontSize, { fontSize: largeVersion ? 15 : 12, color: selectedType === 'storage' ? "white" : "#000" }]}>Storage</Text></></Badge>}
        </View>
    );
};

export default SelectStayType;


const styles = StyleSheet.create({
    fontSize: {
        fontWeight: 700,
        marginBottom: 2
    }
})