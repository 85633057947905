import { colors } from "../styles/colors";

export const getCalendarDisplayDates = (space, bookings, bookingRequests) => {
    const allDates = {};

    if (!!space.unavailable_days && !!space.unavailable_days.length) {
        for (let i = 0; i <= space.unavailable_days.length; i++) {
            let newKey = space.unavailable_days[i];
            allDates[newKey] = {
                textColor: "#d6d4d4",
                color: "#bdbbbb",
            };
        }
    }
    if (!!bookings && bookings.length) {
        for (let i = 0; i < bookings.length; i++) {
            const thisBooking = bookings[i];
            if (thisBooking.spaceID === space.id) {
                for (let i = 0; i <= thisBooking.bookingRequest.bookedDays.length; i++) {
                    let newKey = thisBooking.bookingRequest.bookedDays[i];
                    allDates[newKey] = {
                        textColor: "#d6d4d4",
                        color: "#42b0f5",
                        disabled: true,
                        disableTouchEvent: true,
                    };
                }
            }
        }
    }
    if (!!bookingRequests && bookingRequests.length) {
        for (let i = 0; i < bookingRequests.length; i++) {
            const thisBookingRequest = bookingRequests[i];
            if (thisBookingRequest.spaceID === space.id) {
                for (let i = 0; i <= thisBookingRequest.bookedDays.length; i++) {
                    let newKey = thisBookingRequest.bookedDays[i];
                    allDates[newKey] = {
                        textColor: "#d6d4d4",
                        color: colors.orange50,
                        disabled: true,
                        disableTouchEvent: true,
                    };
                }
            }
        }
    }
    return allDates;
};
