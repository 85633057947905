import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { NavigationContainer, useFocusEffect, useNavigation } from "@react-navigation/native";
import { useDispatch, useSelector } from "react-redux";
import {
  View,
  StyleSheet,
  Image,
  Pressable,
  useWindowDimensions,
  Text,
  Platform
} from "react-native";
import {
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
  Entypo,
  FontAwesome,
  AntDesign
} from "@expo/vector-icons";
import moment from "moment";
import * as Linking from "expo-linking";
import TripsScreen from "../screens/account/TripsScreen";
import PropertyScreen from '../screens/space/PropertyScreen';
import LoadingScreen from "../screens/LoadingScreen";
import HelpScreen from "../screens/info/HelpScreen";
import AboutScreen from "../screens/info/AboutScreen";
import FavoritesSavedScreen from "../screens/account/FavoritesSavedScreen";
import HostCreateSpaceNavigator from "./HostCreateSpaceNavigator";
import AccountNavigator from "./AccountNavigator";
import HostAccountNavigator from "./HostAccountNavigator";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { loadStripe } from '@stripe/stripe-js';
import { setStripePromise } from "../redux/actions/CurrentUserActions";
import { AppContextProvider } from '../utils/AppContextProvider';
import { UserDataContext } from "../utils/UserContextProvider";
import getEnvVars from '../../environment';
import UserContextProvider from '../utils/UserContextProvider';
import HomeTabNavigator from "./HomeTabNavigator";
import { BookingsContext } from "../utils/BookingsContextProvider";
import Authenticator from "../utils/Authenticator";
import { colors } from "../styles/colors";
import { CustomDrawerContent } from "../components/CustomElements/CustomDrawerContent";
import HelpChat from "../components/other/HelpChatMessageCard";
import HostSplashScreen from "../screens/info/HostSplashScreen";
import SecurityScreen from "../screens/info/SecurityScreen";
import CustomButtonUnderlined from "../components/CustomElements/CustomButtonUnderlined";
import MessagesScreen from "../screens/account/MessagesScreen";
import { PhoneNumberField } from "@aws-amplify/ui-react";
import { SuccessVerifyingIdentityModal } from "../components/user/SuccessVerifyingIdentityModal";
import { listBookingsQuery } from "../api/bookings";
import { globalStyles } from "../styles/styles";
const { stripePublishableKey } = getEnvVars();
const stripePromise = loadStripe(stripePublishableKey);


const HostButton = ({ imAHost }) => {
  const navigation = useNavigation();
  if (!imAHost) {
    return (
      <CustomButtonUnderlined
        width={120}
        backgroundColor={colors.amplifyNeutral20}
        onPress={(e) => navigation.reset({ routes: [{ name: 'host' }] })}
      >
        <Text>Host my space</Text>
      </CustomButtonUnderlined>
    )
  }
};

const headerRight = ({ mobileView, imAHost, unreadBoolean }) => {
  const navigation = useNavigation();
  const { user } = useContext(UserDataContext);
  const { userData } = user;
  const { payoutNeeded, identityVerificationNotComplete } = useSelector(state => state.currentUser);
  const { availabilityMissing, bookingRequests } = useContext(BookingsContext);
  const { availabilityNeeded } = availabilityMissing;
  const { bookingRequestsArray } = bookingRequests;
  const nextStepsRequired = !!availabilityNeeded.length || !!bookingRequestsArray.length || payoutNeeded;
  const [upcomingTrip, setUpcomingTrip] = useState(false);

  const checkForUpcomingTrip = async () => {
    const today = moment(new Date()).format("YYYY-MM-DD");
    let data = {
      renterID: { eq: userData.sub },
      cancelledBeforeCheckIn: { eq: false },
      check_in: { gt: today }
    }
    const bookings = await listBookingsQuery({ data });
    if (bookings) {
      !!bookings.length && setUpcomingTrip(true)
    }
  };

  useEffect(() => {
    checkForUpcomingTrip()
  }, []);

  return (
    <View style={styles.headerRight}>
      {upcomingTrip && <UpcomingTripIcon mobileView={mobileView} />}
      {unreadBoolean && <UnreadMessagesIcon mobileView={mobileView} />}
      {nextStepsRequired && <NextStepsRequired mobileView={mobileView} />}
      <View style={{ marginTop: 3 }}>
        <Pressable
          style={[styles.drawerButton, { marginLeft: mobileView ? 2 : 10 }]}
          onPress={() => navigation.navigate("explore", {
            screen: "account"
          })}
        >
          {!!userData && !!userData.avatarImage ? (
            <Image
              style={styles.avatarImage}
              source={{ uri: userData.avatarImage }}
            />
          ) : (
            <Ionicons name="person-circle" size={53} color="grey" />
          )}
          {identityVerificationNotComplete ? (
            <View style={{ position: "absolute", top: -5, right: -5 }}>
              <View>
                <View style={{ backgroundColor: "#fff", height: 17, width: 17, borderRadius: 10, position: "absolute", top: 4.5 }} />
                <FontAwesome name="exclamation-circle" size={24} color={colors.orange50} />
              </View>
            </View>
          ) : <></>}
        </Pressable>
      </View>
    </View>
  )
};

const UnreadMessagesIcon = ({ mobileView }) => {
  const navigation = useNavigation();
  return (
    <Pressable
      onPress={() => navigation.navigate("explore", { screen: "messages" })}
      style={{ marginHorizontal: mobileView ? 5 : 8 }}
    >
      <View>
        <Entypo name="mail" size={mobileView ? 25 : 28} color={colors.amplifyNeutral80} />
        <View style={{ position: "absolute", bottom: mobileView ? 13 : 15, left: mobileView ? 17 : 18 }}>
          <FontAwesome name="exclamation-circle" size={mobileView ? 18 : 20} color="#48a8db" />
        </View>
      </View>
    </Pressable>
  );
};

const UpcomingTripIcon = ({ mobileView }) => {
  const navigation = useNavigation();
  return (
    <Pressable
      onPress={() => navigation.navigate("trips")}
      style={{ marginHorizontal: mobileView ? 5 : 8 }}
    >
      <MaterialCommunityIcons
        name="rv-truck"
        size={mobileView ? 27 : 30}
        color={colors.amplifyNeutral80}
      />
    </Pressable>
  );
};

const NextStepsRequired = ({ mobileView }) => {
  const navigation = useNavigation();
  return (
    <Pressable
      onPress={() => navigation.navigate("hostaccount", { screen: "My Hosting" })}
      style={{ marginHorizontal: mobileView ? 5 : 8 }}
    >
      <View>
        <MaterialCommunityIcons name="folder-multiple-plus" size={mobileView ? 24 : 27} color={colors.amplifyNeutral80} />
        <View style={{ position: "absolute", bottom: mobileView ? 13 : 16, left: mobileView ? 17 : 20 }}>
          <FontAwesome name="exclamation-circle" size={mobileView ? 18 : 20} color="#48a8db" />
        </View>
      </View>
    </Pressable>
  );
}

const AuthNavigatorBody = ({ mobileView, width }) => {
  const navigation = useNavigation();
  const Drawer = createDrawerNavigator();
  const dispatch = useDispatch();
  const { user, loading } = useContext(UserDataContext);
  const { userData } = user;
  const { unreadBoolean, conversations } = useSelector((state) => state.conversations);
  const [imAHost, setImAHost] = useState(false)
  const hostAccountLabel = !!mobileView ? "My Hosting" : "Manage My Hosting";


  const userDataCheck = async () => {
    const myHostedProperties = userData.userData.myHostedProperties
    const host = !!myHostedProperties.items && !!myHostedProperties.items.length;
    setImAHost(host)
    dispatch(setStripePromise({ stripePromise: stripePromise }))
  };

  const tripsIcon = ({ }) => {
    return (
      <Pressable
        justifyContent="flex-start"
        onPress={() => { navigation.navigate("explore") }}
      >
        <Image
          style={{ width: 30, height: 30, marginRight: 20 }}
          source={require("../../assets/icons/my-trips.svg")}
        />
      </Pressable>

    )
  }

  const hostAccountIcon = ({ }) => {
    return (
      <Pressable
        justifyContent="flex-start"
        onPress={() => { navigation.navigate("hostaccount"); }}
      >
        <Image
          style={{ width: 25, height: 25, marginRight: 20 }}
          source={require("../../assets/icons/manage-hosting.svg")}
        />
      </Pressable>
    )

  }

  const favoritesIcon = ({ }) => {
    return (
      <Pressable
        justifyContent="flex-start"
        onPress={() => { navigation.navigate("explore") }}
      >
        <Image
          style={{ width: 25, height: 25, marginRight: 20 }}
          source={require("../../assets/icons/favorites-saved.svg")}
        />
      </Pressable>
    )
  }

  const hostSpaceIcon = ({ }) => {
    return (
      <Pressable
        justifyContent="flex-start"
        onPress={() => {
          navigation.navigate("HostCreateSpaceNavigator");
        }}
      >
        <Image
          style={{ width: 25, height: 25, marginRight: 20 }}
          source={require("../../assets/icons/add-new-space.svg")}
        />
      </Pressable>
    )
  }

  const myAccountIcon = ({ }) => {
    return (
      <Pressable
        justifyContent="flex-start"
        onPress={() => {
          navigation.navigate("explore")
        }}
      >
        <Image
          style={{ width: 25, height: 25, marginRight: 20 }}
          source={require("../../assets/icons/my-account.svg")}
        />
      </Pressable>
    )
  };

  const myMessagesIcon = ({ }) => {
    return (
      <Pressable
        justifyContent="flex-start"
        onPress={() => {
          navigation.navigate("messages");
        }}
      >
        <MaterialIcons name="message" size={19} />
      </Pressable>
    )
  };

  useEffect(() => {
    !loading && userDataCheck();
  }, [loading]);

  if (loading) {
    return (<LoadingScreen />)
  } else {
    return (
      <Drawer.Navigator
        useLegacyImplementation={false}
        backBehavior="history"
        defaultStatus="closed"
        drawerContent={(props) => <CustomDrawerContent {...props} isAuthed={true} />}
        screenOptions={({ navigation }) => ({
          swipeEdgeWidth: 0,
          swipeEnabled: false,
          title: "",
          drawerActiveTintColor: colors.orange80,
          drawerStyle: { backgroundColor: colors.grey10, marginTop: 15, borderTopRightRadius: 10 },
          headerLeft: ({ focused, size }) => (
            <View style={styles.headerRight}>
              <Pressable
                style={styles.drawerButton}
                onPress={() => navigation.openDrawer()}
              >
                <Image
                  style={{ width: 25, height: 25, marginLeft: 10 }}
                  source={require("../../assets/icons/burger-icon.svg")}
                />
              </Pressable>
              <Pressable
                onPress={() => navigation.reset({ routes: [{ name: 'explore' }] })}
              >
                <Image
                  style={{ width: 163.35, height: 42.35, marginLeft: 10 }}
                  source={require("../../assets/images/hookhub-logo-orange-mini.png")}
                />
              </Pressable>
            </View>
          ),
          headerRight: ({ focused }) => headerRight({ mobileView, imAHost, unreadBoolean, focused })
        })}
      >
        <Drawer.Screen
          name="explore"
          headerShown={false}
          component={HomeTabNavigator}
          options={{
            drawerIcon: ({ focused, size }) => (
              <View style={[globalStyles.center, { borderBottomColor: colors.orange30 }]}>
                <AntDesign name="close" size={35} color={colors.amplifyNeutral60} style={{ marginRight: 170 }} />
                <Image
                  style={styles.miniLogoImage}
                  source={require("../../assets/images/favicon.png")}
                />
              </View>
            ),
            drawerActiveBackgroundColor: colors.white,
            drawerInactiveBackgroundColor: colors.white,
          }}
        />
        <Drawer.Screen
          name="space"
          component={PropertyScreen}
          options={{ drawerItemStyle: { height: 0 } }}
        />
        <Drawer.Screen
          name="trips"
          component={TripsScreen}
          options={{
            drawerLabel: "My Trips",
            drawerIcon: tripsIcon,
            drawerActiveBackgroundColor: colors.white,
            drawerInactiveBackgroundColor: colors.white,
          }}
        />
        <Drawer.Screen
          name="hostaccount"
          component={HostAccountNavigator}
          options={{
            drawerLabel: hostAccountLabel,
            drawerIcon: hostAccountIcon,
            drawerActiveBackgroundColor: colors.white,
            drawerInactiveBackgroundColor: colors.white,
          }}
        />
        <Drawer.Screen
          name="HostCreateSpaceNavigator"
          headerShown={false}
          component={HostCreateSpaceNavigator}
          options={{
            drawerLabel: "Add new space",
            drawerIcon: hostSpaceIcon,
            drawerActiveBackgroundColor: colors.white,
            drawerInactiveBackgroundColor: colors.white,
          }}
        />
        <Drawer.Screen
          name="FavoritesSaved"
          component={FavoritesSavedScreen}
          options={{
            drawerLabel: "Favorites Saved",
            drawerIcon: favoritesIcon,
            drawerActiveBackgroundColor: colors.white,
            drawerInactiveBackgroundColor: colors.white,
          }}
        />
        <Drawer.Screen
          name="account"
          component={AccountNavigator}
          options={{
            drawerLabel: "My Account",
            drawerIcon: myAccountIcon,
            drawerActiveBackgroundColor: colors.white,
            drawerInactiveBackgroundColor: colors.white,
          }}
        />
        <Drawer.Screen
          name="messages"
          component={MessagesScreen}
          options={{ drawerItemStyle: { height: 0 } }}
        // options={{
        //   drawerLabel: "My Messages",
        //   drawerIcon: myMessagesIcon,
        //   drawerActiveBackgroundColor: colors.white,
        //   drawerInactiveBackgroundColor: colors.white
        // }}
        />
        <Drawer.Screen
          name="about"
          component={AboutScreen}
          options={{ drawerItemStyle: { height: 0 } }}
        />
        <Drawer.Screen
          name="help"
          component={HelpScreen}
          options={{ drawerItemStyle: { height: 0 } }}
        />
        <Drawer.Screen
          name="host"
          component={HostSplashScreen}
          options={{ drawerItemStyle: { height: 0 } }}
        />
        <Drawer.Screen
          name="security"
          component={SecurityScreen}
          options={{ drawerItemStyle: { height: 0 } }}
        />
        {/* <Drawer.Screen
          name="admin"
          component={AdminScreen}
        /> */}
      </Drawer.Navigator>
    )
  }
}

const DefaultPhoneNumberField = () => {
  <PhoneNumberField label="Phone Number" defaultDialCode="+1" />
}

const AuthenticatedNavigator = ({ authenticate, authMethod }) => {
  const { width } = useWindowDimensions();
  const prefix = [Linking.createURL('/'), 'https://hookhub.co', 'https://www.hookhub.co']
  const mobileWebView = width < 450 && Platform.OS === 'web';
  const config = {
    screens: {
      explore: {
        path: '',
        screens: {
          messages: "/messages"
        }
      },
      space: {
        path: "/space/:propertyID"
      },
      account: {
        screens: {
          profile: 'profile'
        },
      },
      about: {
        path: "/about"
      },
      host: {
        path: "/host"
      },
      security: {
        path: "/security"
      },
      help: {
        path: "/help"
      }
    }
  };
  const linking = {
    enabled: true,
    prefixes: [prefix],
    config
  };

  return (
    <Authenticator
      component={{ PhoneNumberField: DefaultPhoneNumberField }}
      authenticate={() => authenticate()}
      authMethod={authMethod}
    >
      <UserContextProvider>
        <AppContextProvider>
          <NavigationContainer
            linking={linking}
            documentTitle={{
              formatter: () => `Hookhub | RV Parking`
            }}
          >
            <AuthNavigatorBody mobileView={mobileWebView} width={width} />
            <SuccessVerifyingIdentityModal />
          </NavigationContainer>
          {width < 900 ? <></> : <HelpChat />}
        </AppContextProvider>
      </UserContextProvider>
    </Authenticator>
  )
};

const styles = StyleSheet.create({
  logoButton: {
    width: 50,
    borderRadius: 20,
    padding: 10,
    marginBottom: 20,
    shadowColor: "#303838",
    shadowOffset: { width: 0, height: 5 },
    shadowRadius: 10,
    shadowOpacity: 0.35,
  },
  avatarImage: {
    borderRadius: 100,
    marginLeft: 5,
    width: 45,
    height: 45,
    borderWidth: 1,
  },
  headerRight: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    marginRight: 10,
    justifyContent: "center",
    alignContent: "center",
  },
  drawerButton: {
    flexDirection: "row",
    textAlign: "center",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    marginBottom: 5,
    marginLeft: 5
  },
  logoImage: {
    height: 20,
    width: 80
  },
  miniLogoImage: {
    width: 40,
    height: 40,
    margingLeft: 200
  },
  forwardButtonText: {
    color: "#fff",
    fontSize: 20,
  },
  forwardButton: {
    borderColor: "#616A6B",
    borderRadius: 10,
    width: 100,
    borderWidth: 1,
    backgroundColor: "#065d8c",
    alignItems: "center",
    borderBottomWidth: 2,
    justifyContent: "center",
    alignSelf: "center",
    height: 40,
    marginLeft: 40,
  },
  logoButton: {
    width: 50,
    borderRadius: 20,
    padding: 10,
    marginBottom: 20,
    shadowColor: "#303838",
    shadowOffset: { width: 0, height: 5 },
    shadowRadius: 10,
    shadowOpacity: 0.35,
  },
  hamburgerButton: {
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    marginRight: 10,
    marginTop: 4,
    height: 38
  },
  loginButtonText: {
    fontSize: 17,
  },
});

export default AuthenticatedNavigator;
