import React, { useEffect, useState } from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import {
  MaterialIcons,
  FontAwesome5,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import AccountNavigator from "../navigation/AccountNavigator";
import MessagesScreen from "../screens/account/MessagesScreen";
import { useSelector } from "react-redux";
import TripsScreen from "../screens/account/TripsScreen";
import HostAccountNavigator from "../navigation/HostAccountNavigator";
import { colors } from "../styles/colors";
import { Platform } from "react-native";
import HomeScreen from "../screens/HomeScreen";

function HomeTabNavigator() {
  const { mobileView, imAHost } = useSelector(state => state.currentUser);
  const { conversations } = useSelector((state) => state.conversations);
  const [showMessagesIcon, setShowMessagesIcon] = useState(false);
  const [loading, setLoading] = useState(true);
  const Tab = createBottomTabNavigator()

  useEffect(() => {
    if (conversations.length !== 0) {
      setShowMessagesIcon(true)
    }
    setLoading(false)
  }, [conversations]);

  return (
    <>
      {!loading ? (
        <Tab.Navigator
          initialRouteName="explore"
          firstRoute="explore"
          screenOptions={{
            headerShown: false,
            tabBarShowLabel: !mobileView,
            tabBarLabelStyle: { fontSize: 15, fontWeight: "bold" },
            tabBarActiveTintColor: "#fff",
            tabBarInactiveTintColor: colors.grey30,
            tabBarInactiveBackgroundColor: "#d47620",
            tabBarStyle:
            {
              paddingBottom: mobileView ? 5 : 10,
              height: mobileView && Platform.OS === 'web' ? 40 : 60,
              backgroundColor: colors.orange50,
            },
          }}
        >
          <Tab.Screen
            component={HomeScreen}
            name={"explore"}
            options={{
              tabBarLabel: "Explore",
              tabBarIcon: ({ color }) => (
                <FontAwesome5 name="search" size={mobileView ? 19 : 25} color={color} />),
            }}
          />

          {!!imAHost ? (
            <Tab.Screen
              component={HostAccountNavigator}
              name={"hostaccount"}
              options={{
                tabBarLabel: "My Hosting",
                tabBarIcon: ({ color }) => (
                  <MaterialCommunityIcons
                    name="rv-truck"
                    size={mobileView ? 25 : 32}
                    color={color}
                  />
                ),
              }}
            />
          ) : (
            <Tab.Screen
              component={TripsScreen}
              name={"trips"}
              options={{
                tabBarLabel: "My Trips",
                tabBarIcon: ({ color }) => (
                  <MaterialCommunityIcons
                    name="rv-truck"
                    size={mobileView ? 25 : 32}
                    color={color}
                  />
                ),
              }}
            />
          )}

          {!!showMessagesIcon &&
            <Tab.Screen
              component={MessagesScreen}
              name={"messages"}
              options={{
                tabBarLabel: "Messages",
                tabBarIcon: ({ color }) => (
                  <MaterialIcons name="message" size={mobileView ? 23 : 30} color={color} />
                ),
              }}
            />
          }

          <Tab.Screen
            component={AccountNavigator}
            name={"account"}
            options={{
              tabBarLabel: "My Stuff",
              tabBarIcon: ({ color }) => (
                <FontAwesome5 name="plug" size={mobileView ? 23 : 30} color={color} />
              ),
            }}
          />
        </Tab.Navigator>

      ) : (<></>)}
    </>
  );
}

export default HomeTabNavigator;
