import React from 'react';
import {
    View,
    Dimensions,
    StyleSheet
} from 'react-native';
import { colors } from '../../styles/colors';

const Card = ({ 
    children,  
    backgroundColor="#fff",
    width=Dimensions.get('window').width /1.2,
    marginTop = 5,
    margin = 5,
    marginBottom=5,
    marginLeft=5,
    marginRight=5,
    padding=10,
    textAlign="center",
    alignItems="center",
    borderRadius=7,
    maxWidth=null,
    shadowColor=colors.amplifyNeutral80
}) => {

    return (
        <View style={[styles.card, {
            margin: margin,
            marginTop: marginTop,
            marginBottom: marginBottom,
            marginLeft: marginLeft,
            marginRight: marginRight,
            width:width,
            maxWidth: maxWidth ? maxWidth : width,
            padding:padding,
            textAlign:textAlign,
            alignItems:alignItems,
            backgroundColor: backgroundColor,
            borderRadius: borderRadius,
            borderColor: shadowColor,
            shadowColor: shadowColor,
            shadowOffset: { width: .1, height: .5},
            shadowOpacity: .3,
            shadowRadius: 3,
            elevation: 9
        }]}>
            {children}
        </View>
    )

}

const styles = StyleSheet.create({
  card: {
    marginVertical: 10,
  },
})
export default Card;