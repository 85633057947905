import { useRef } from "react";
import { PanGestureHandler, ScrollView } from "react-native-gesture-handler";


const WebPullToRefresh = ({
    setRefresh,
    children,
}) => {
    const scrollViewRef = useRef();

    const onPullDown = (e) => {
        const scrolledToTop = scrollViewRef.current.scrollTop === 0;
        const pullingDown = e.nativeEvent.velocityY > 0;
        scrolledToTop && pullingDown && setRefresh(true);
    }

    return (
        <PanGestureHandler onGestureEvent={onPullDown}>
            <ScrollView ref={scrollViewRef}>
                { children }
            </ScrollView>
        </PanGestureHandler>
    )
}

export default WebPullToRefresh;


// import { useRef, useState } from "react";
// import { Image, PanResponder, useWindowDimensions, View } from "react-native";
// import Animated, { useAnimatedScrollHandler, useAnimatedStyle, useSharedValue, withDelay, withTiming } from "react-native-reanimated";
// // import { AnimatedScrollView } from "react-native-reanimated/lib/typescript/reanimated2/component/ScrollView";



// const WebPullToRefresh = ({
//     setRefresh,
//     children,
// }) => {
//     const { height } = useWindowDimensions()
//     const scrollViewRef = useRef();
//     const scrollPosition = useSharedValue(0);
//     const pullDownPosition = useSharedValue(height);
//     const isReadyToRefresh = useSharedValue(false);
//     const [refreshing, setRefreshing] = useState(false);

//     const scrollHandler = useAnimatedScrollHandler({
//         onScroll: (event) => {
//             console.log(event.contentOffset.y);
//             scrollPosition.value = event.contentOffset.y;
//         },
//     });

//     const onRefresh = (done) => {
//         setRefreshing(true)

//         setTimeout(() => {
//             setRefreshing(false)
//             done();
//         }, 7500);
//     };


//     const onPanRelease = () => {
//         pullDownPosition.value = withTiming(isReadyToRefresh.value ? 75 : 0, {
//             duration: 180,
//         });

//         if (isReadyToRefresh.value) {
//             isReadyToRefresh.value = false;

//             const onRefreshComplete = () => {
//                 pullDownPosition.value = withTiming(0, { duration: 180 });
//             };

//             onRefresh(onRefreshComplete);
//         }
//     };


//     const panResponderRef = useRef(
//         PanResponder.create({
//             onMoveShouldSetPanResponder: (event, gestureState) =>
//                 scrollPosition.value <= 0 && gestureState.dy >= 0,
//             onPanResponderMove: (event, gestureState) => {
//                 const maxDistance = 150;
//                 pullDownPosition.value = Math.max(Math.min(maxDistance, gestureState.dy), 0);

//                 if (
//                     pullDownPosition.value >= maxDistance / 2 &&
//                     isReadyToRefresh.value === false
//                 ) {
//                     isReadyToRefresh.value = true;
//                     console.log('Ready to refresh');
//                 }

//                 if (
//                     pullDownPosition.value < maxDistance / 2 &&
//                     isReadyToRefresh.value === true
//                 ) {
//                     isReadyToRefresh.value = false;
//                     console.log('Will not refresh on release');
//                 }
//             },
//             onPanResponderRelease: onPanRelease,
//             onPanResponderTerminate: onPanRelease,
//         })
//     );

//     const refreshContainerStyles = useAnimatedStyle(() => {
//         return {
//             height: pullDownPosition.value,
//         };
//     });

//     const refreshIconStyles = useAnimatedStyle(() => {
//         const scale = Math.min(1, Math.max(0, pullDownPosition.value / 75));

//         return {
//             opacity: refreshing
//                 ? withDelay(100, withTiming(0, { duration: 20 }))
//                 : Math.max(0, pullDownPosition.value - 25) / 50,
//             transform: [
//                 {
//                     scaleX: refreshing ? withTiming(0.15, { duration: 120 }) : scale,
//                 },
//                 {
//                     scaleY: scale,
//                 },
//                 {
//                     rotate: `${pullDownPosition.value * 3}deg`,
//                 },
//             ],
//             backgroundColor: refreshing ? '#fff' : 'transparent',
//         };
//     }, [refreshing]);

//     const pullDownStyles = useAnimatedStyle(() => {
//         return {
//             transform: [
//                 {
//                     translateY: pullDownPosition.value,
//                 },
//             ],
//         };
//     });

//     return (
//         <View style={{ flex: 1, backgroundColor: "#333" }} pointerEvents={refreshing ? 'none' : 'auto'}>
//             <Animated.View
//                 // style={pullDownStyles}
//                 style={[refreshContainerStyles]}
//                 {...panResponderRef.current.panHandlers}
//             >
//                 {refreshing && (
//                     <Image
//                         source={require("../../../assets/images/hookhub-logo_mini.png")}
//                         style={{ width: 280, height: '100%', objectFit: 'cover' }}
//                     />
//                 )}
//                 {/* <Animated.Image
//                     source={require("../../../assets/images/hookhub-logo_mini.png")}
//                     style={[refreshIconStyles]}
//                 /> */}
//                 <Animated.ScrollView
//                     ref={scrollViewRef}
//                     onScroll={scrollHandler}
//                     scrollEventThrottle={16}
//                 >
//                     {children}
//                 </Animated.ScrollView>
//             </Animated.View>
//         </View>
//     )
// }

// export default WebPullToRefresh;