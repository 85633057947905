import React, { useState, useEffect } from "react";
import {
    View,
    Text
} from "react-native";
import Flex from "../CustomElements/Flex"
import Header from "../CustomElements/Header"
import { CustomSimpleToggleButton } from "../CustomElements/CustomSimpleToggleButton";
import SelectField from "../CustomElements/SelectField";
import { globalStyles } from "../../styles/styles";

const hours = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12"
];

export const TimePicker = ({ type, time, updateTime, subText = null, enabled, width, mobileView }) => {
    const timeDayString = time.slice(-2);
    const timeString = time.slice(0, -2);
    const [hour, sethour] = useState(timeString);
    const [amORpm, setamORpm] = useState(timeDayString || 'PM');

    useEffect(() => {
        if (hour && amORpm) {
            updateTime((hour + amORpm).toString())
        }
    }, [hour, amORpm]);

    return (
        <View style={{ marginTop: 45, marginBottom: 15, flexDirection: mobileView ? "column" : "row" }}>
            <Header text={type}></Header>
            <View style={mobileView ? globalStyles.centerColumn : globalStyles.center}>
                {!!subText && <Text>{subText}</Text>}
                    <SelectField
                        options={hours}
                        label="hour"
                        placeholder="hour"
                        value={hour}
                        onChange={(e) => sethour(e)}
                    />
                <View style={globalStyles.center}>
                    <CustomSimpleToggleButton
                        isPressed={amORpm === 'AM'}
                        onChange={() => setamORpm('AM')}
                        width={60}
                        value={'AM'}
                    />
                    <CustomSimpleToggleButton
                        isPressed={amORpm === 'PM'}
                        onChange={() => setamORpm('PM')}
                        width={60}
                        value={'PM'}
                    />
                </View>
            </View>
        </View>
    );
};
