import {
    View,
    Image,
    Pressable,
} from 'react-native';
import { globalStyles } from '../../styles/styles';

const CustomCarouselDisplay = ({
    width,
    children,
    navLeft=null,
    navRight=null
}) => {

    return (
        <View style={[globalStyles.center, { width: width }]}>
            <Pressable onPress={() => navLeft()}><Image style={{ width: 25, height: 25 }} source={require("../../../assets/icons/left-caret.svg")} /></Pressable>
            {children}
            <Pressable onPress={() => navRight()}><Image style={{ width: 25, height: 25 }} source={require("../../../assets/icons/right-caret.svg")} /></Pressable>
        </View>
    )
}

export default CustomCarouselDisplay;
