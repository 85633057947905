import React from "react";
import {
    Linking,
    Platform,
    StyleSheet,
    Text,
    View,
    useWindowDimensions
} from "react-native";
import { globalStyles } from "../../styles/styles";
import { colors } from "../../styles/colors";
import { IDVerification } from "./IDVerification";
import CustomButtonUnderlined from "../CustomElements/CustomButtonUnderlined";
import { useSelector } from "react-redux";
import { VerifyIdentityIcon } from "./VerifyIdentityIcon";

export const IDVerificationRequiredComponent = ({ }) => {
    const { mobileView } = useSelector(state => state.currentUser);
    const { width } = useWindowDimensions();

    return (
        <View style={[globalStyles.centerColumn, { width: mobileView ? width / 1.25 : 330 }]}>
            <VerifyIdentityIcon largeVersion={true} />
            <Text style={styles.text}>
                Verify your identity to finigh booking your trip.
            </Text>
            <Text style={styles.subText}>
                We ask hosts and renters to get verified to keep our community safe and promote trust.
            </Text>
            {Platform.OS === "web" ? (
                <a href={"https://info.hookhub.co/blog/008"} target="_blank" style={{ textDecorationLine: "none" }}>
                    <CustomButtonUnderlined onPress={() => console.log(" open blog article")} marginBottom={mobileView ? 20 : 50}>
                        <Text style={{ textDecorationLine: "underline" }}>Learn more about Identity Verification</Text>
                    </CustomButtonUnderlined>
                </a>
            ) : (
                <CustomButtonUnderlined onPress={() => Linking.openURL("https://info.hookhub.co/blog/008")} marginBottom={mobileView ? 20 : 50}>
                    <Text style={{ textDecorationLine: "underline" }}>Learn more about Identity Verification</Text>
                </CustomButtonUnderlined>
            )}
            <IDVerification />
        </View>
    );
};

const styles = StyleSheet.create({
    subText: {
        fontSize: 14,
        textAlign: "center",
        color: colors.amplifyNeutral60
    },
    text: {
        fontSize: 16,
        textAlign: "center",
        color: colors.amplifyNeutral90
    }
});


