import React, { useState, useEffect, useContext } from "react";
import {
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
  Platform,
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { BookingsContext } from "../../utils/BookingsContextProvider";
import CustomHorizontalScrollView from "../other/CustomHorizontalScrollView";
import { useSelector } from "react-redux";
import Flex from "../CustomElements/Flex"
import Card from "../CustomElements/Card"
import Header from "../CustomElements/Header"
import { useNavigation } from "@react-navigation/native";
import { colors } from "../../styles/colors"
import CustomButton from "../CustomElements/CustomButton";
import CustomSection from "../CustomElements/CustomSection";
import { globalStyles } from "../../styles/styles";
import { IDVerification } from "../user/IDVerification";

const RequiredTag = ({ mobileView }) => {

  return (
    <View style={[globalStyles.center, {marginTop: 15}]}>
      <Text style={{ textAlign: "right" }}>Required</Text>
      <MaterialCommunityIcons
        name="alert-decagram-outline"
        size={24}
        color="black"
      />
    </View>
  )
}

const NextStepsCard = ({
  todoItem,
  header,
  todoTitle,
  required,
  todoDescription,
  mobileView
}) => {
  return (
    <Card
      backgroundColor={required ? colors.orange10 : colors.grey20}
      width={mobileView ? 165 : 220}
      height={550}
      alignItems="space-between"
      textAlign="center"
      margin={4}
    >
      <Header level={1} color={colors.amplifyNeutral80} text={header} marginBottom={mobileView ? 5 : 25}></Header>
      <Text style={{ textAlign: 'center', marginBottom: mobileView ? 30 : 70 }}>{todoTitle}</Text>
      <Flex justifyContent="center" alignContent="center" alignItems="center" alignSelf="center" width={mobileView ? 145 : 220}>
        <CustomButton
          width={mobileView ? 85 : 200}
          backgroundColor={colors.white}
          border="#4c4e52"
          onPress={todoItem}
          marginTop={20}
        >
          <Text>{todoDescription}</Text>
        </CustomButton>
      </Flex>
      {required ? (
        <RequiredTag mobileView={mobileView} />
      ) : (
        <Text>optional</Text>
      )}
    </Card>
  );
};

const PendingReservationCard = ({
  bookingRequests,
  reviewRequest,
  mobileView
}) => {
  const { check_in, check_out, rentalType, reviewed, id, propertyID, propertyTitle, spaceTitle } = bookingRequests;
  const webMobileView = mobileView && Platform.OS === 'web';
  const [checkIn, setCheckIn] = useState(null);
  const [checkOut, setCheckOut] = useState(null);
  const [propertySpacesMultipleBoolean, setpropertySpacesMultipleBoolean] = useState(false)


  const formatDates = () => {
    const start = `${check_in.slice(5, 7)}/${check_in.slice(8, 10)}/${check_in.slice(0, 4)}`;
    const end = `${check_out.slice(5, 7)}/${check_out.slice(8, 10)}/${check_out.slice(0, 4)}`;
    setCheckIn(start);
    setCheckOut(end);
  };

  useEffect(() => {
    !reviewed && formatDates();
  }, [id]);

  useEffect(() => {
    setpropertySpacesMultipleBoolean(propertyTitle !== spaceTitle)
  }, [])

  return (
    <>
      {!reviewed && (
        <Card
          backgroundColor={colors.orange10}
          border="#000"
          paddingTop={8}
          alignItems="center"
          textAlign="center"
          margin={4}
          width={webMobileView ? 165 : 220}
        >
          <View style={[styles.title, { width: webMobileView ? 165 : 220, marginBottom: webMobileView ? 5 : 20 }]}>
            <Text style={{ fontSize: 16, textAlign: "center", lineHeight: webMobileView ? 50 : 60, color: "white" }}>
              RESERVATION REQUEST
            </Text>
          </View>
          {propertySpacesMultipleBoolean && (<Header color={colors.amplifyNeutral80} text={`Property: ${propertyTitle}`} textAlign="center" marginBottom={10} />)}
          <Header color={colors.amplifyNeutral80} text={spaceTitle} textAlign="center" marginBottom={10} />
          <Text style={styles.descriptionText}>Dates: {checkIn} to {checkOut}</Text>
          <Text style={styles.descriptionText}>Rental Type: {rentalType}</Text>
          <CustomButton
            width={150}
            backgroundColor={colors.white}
            border="#4c4e52"
            marginTop={20}
            onPress={() => reviewRequest(bookingRequests)}
          ><Text>Approve / Deny</Text></CustomButton>
          <RequiredTag mobileView={mobileView} />
        </Card>
      )}
    </>
  );
};

const PayoutNeededCard = ({
  mobileView
}) => {
  const navigation = useNavigation();
  const webMobileView = mobileView && Platform.OS === 'web';

  return (
    <Card
      backgroundColor={colors.orange10}
      borderRadius={8}
      paddingTop={8}
      alignItems="center"
      textAlign="center"
      margin={4}
      width={webMobileView ? 165 : 220}
    >
    <View style={[styles.title, { width: webMobileView ? 165 : 220, marginBottom: webMobileView ? 5 : 20, height: webMobileView ? 50 : 60 }]}>
      <Text style={{ fontSize: 16, textAlign: "center", fontWeight: "500", color: "white" }}>
          Set Payout Method
        </Text>
      </View>
      <Text style={{ width: webMobileView ? 150 : 180, textAlign: "center", marginTop: 15 }}>Set where I'd like to receive my payments</Text>
      <CustomButton
        width={webMobileView ? 130 : 160}
        marginTop={20}
        backgroundColor={colors.white}
        border="#4c4e52"
        onPress={() =>
          navigation.navigate("account", {
            screen: "paymentsettings"
          })}
      >
        <Text>{webMobileView ? "Set Payout" : "Set Payout Method"}</Text>
      </CustomButton>
      <RequiredTag />
    </Card >
  );
};

const IdentityVerificationNeededCard = ({
  mobileView
}) => {
  const webMobileView = mobileView && Platform.OS === 'web';

  return (
    <Card
      backgroundColor={colors.orange10}
      borderRadius={8}
      paddingTop={8}
      alignItems="center"
      textAlign="center"
      margin={4}
      width={webMobileView ? 165 : 220}
    >
      <View style={[styles.title, { width: webMobileView ? 165 : 220, marginBottom: webMobileView ? 5 : 20 }]}>
        <Text style={{ fontSize: 16, textAlign: "center", lineHeight: webMobileView ? 50 : 60, fontWeight: "500", color: "white" }}>
          Verify Identity
        </Text>
      </View>
      <Text style={{ width: webMobileView ? 150 : 180, textAlign: "center", marginTop: 15, marginBottom: 10 }}>Identity Verification needs to be completed for your Property to be active.</Text>
      <IDVerification version="white" buttonWidth={webMobileView ? 130 : 160}/>
      <RequiredTag />
    </Card >
  );
};

export const NextSteps = ({ onPressNext, setRequestDetails, bookingRequests, setReviewRequestModalOpen }) => {
  const { width } = useWindowDimensions();
  const { availabilityMissing } = useContext(BookingsContext);
  const { availabilityNeeded } = availabilityMissing;
  const { payoutNeeded, identityVerificationNotComplete, mobileView } = useSelector(state => state.currentUser);
  const nextStepsRequired = !!availabilityNeeded.length || !!bookingRequests.length || payoutNeeded || identityVerificationNotComplete;

  const setSpaceID = (v) => {
    onPressNext(v.title, v.id)
  };

  const reviewRequest = (v) => {
    setRequestDetails(v);
    setReviewRequestModalOpen(true);
  };

  return (
    <>
      {nextStepsRequired ? (
        <CustomSection backgroundColor="#065d8c" marginBottom={15} alignContent="center" justifyContent="center">
          <View style={globalStyles.flexStartColumn}>
            <View>
              <Header
                color="#fff"
                marginBottom={15}
                textAlign="left"
                level={1.1}
                marginTop={mobileView ? 10 : 15}
                text="NEXT STEPS"
              />
            </View>
            <View>
              <CustomHorizontalScrollView arrowColor="white">
                {!!identityVerificationNotComplete &&
                  <IdentityVerificationNeededCard
                    mobileView={mobileView}
                  />
                }
                {!!payoutNeeded &&
                  <PayoutNeededCard
                    mobileView={mobileView}
                    width={width}
                  />
                }
                {!!bookingRequests.length &&
                  bookingRequests.map((v, i) => (
                    <PendingReservationCard
                      mobileView={mobileView}
                      reviewRequest={(v) => reviewRequest(v)}
                      key={i} width={width}
                      bookingRequests={v}
                    />
                  ))
                }
                {/* {availabilityNeeded.map((space, key) => (
                  <NextStepsCard
                    key={key}
                    todoItem={() => setSpaceID(space)}
                    required={true}
                    todoTitle={space.title}
                    header="Set space availability"
                    todoDescription="Add"
                    mobileView={mobileView}
                  />
                ))} */}
              </CustomHorizontalScrollView>
            </View>
          </View>
        </CustomSection>
      ) : (
        <></>
      )}
    </>

  );
};

const styles = StyleSheet.create({
  title: {
    textAlign: "center",
    backgroundColor: colors.orange50,
    marginTop: 10,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center"
  },
  descriptionText: {
    textAlign: "center",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center"
  }
});
