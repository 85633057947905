import React, { useState } from 'react';
import {
    Dimensions,
    StyleSheet,
    Pressable,
    Text,
    View
} from 'react-native';
import { colors } from '../../styles/colors';
import { useSelector } from 'react-redux';

const CustomButton = ({
    children,
    onPress,
    disabled = false,
    backgroundColor = "#fff",
    width = Dimensions.get('window').width - 100,
    height = 37,
    padding = 0,
    marginBottom = 1,
    marginRight = 1,
    marginLeft = 1,
    marginTop = 1,
    textAlign = "center",
    alignItems = "center",
    hideBorder = false,
    borderColor= null,
    opacity = .9,
}) => {
    const [onHover, setOnHover] = useState(false)
    const { mobileView } = useSelector(state => state.currentUser);
    const color = disabled ? colors.grey30 : backgroundColor;

    return (
        <Pressable
            onPress={() => onPress()}
            onHoverIn={() => setOnHover(true)}
            onHoverOut={() => setOnHover(false)}
            disabled={disabled}
            style={[styles.button, {
                marginLeft: marginLeft,
                marginRight: marginRight,
                marginBottom: marginBottom,
                marginTop: marginTop,
                padding: padding,
                textAlign: textAlign,
                alignItems: alignItems,
                width: width,
                height: height,
                borderRadius: 7,
            }, !!borderColor && {
                borderColor: borderColor,
                borderWidth: 2,
            }, !hideBorder && {
                backgroundColor: color,
                shadowColor: colors.amplifyNeutral80,
                shadowOffset: { width: .1, height: .5},
                shadowOpacity: .3,
                shadowRadius: 3,
                elevation: 9
            }, opacity && {
                opacity: opacity
            }, onHover && !mobileView && {
                opacity: 1
            }]}
        >
            <View><Text>{children}</Text></View>
        </Pressable>
    )

}

const styles = StyleSheet.create({
    button: {
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
        textAlign: "center",
    },
})
export default CustomButton;