import { StyleSheet, Pressable, View, Text, useWindowDimensions } from "react-native";
import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import CustomDivider from "../CustomDivider";
import { LoadingComponent } from "../LoadingComponent";
import CustomButton from "../CustomElements/CustomButton";
import { colors } from "../../styles/colors";
import { globalStyles } from "../../styles/styles";
import { useState } from "react";
import { ErrorDisplay } from "../CustomElements/ErrorDisplay";
import Badge from "../CustomElements/Badge";
import CustomDropdown from "../CustomElements/CustomDropdown";
import Card from "../CustomElements/Card";
import { ScrollView } from "react-native-gesture-handler";
import FixedHeightCard from "../CustomElements/FixedHeightCard";
import MobileOpenReserveCardButton from "../space/MobileOpenReserveCardButton";

const ReserveButton = ({
    mobileView,
    userData = null,
    prepareToReserve,
    setSignUpModalOpen,
    loading,
    mySpace,
    dateSpanSet,
    monthOptionDisabled,
    yearOptionDisabled,
    error,
    customWidth
}) => {

    if (!!loading) {
        return (
            <>
                <CustomButton
                    width={100}
                    height={50}
                    border={colors.grey10}
                >
                    <LoadingComponent />
                </CustomButton>
            </>
        )
    } else if (userData === null) {
        return (
            <>
                <CustomButton
                    width={customWidth - 25}
                    marginTop={10}
                    marginBottom={2}
                    disabled={false}
                    height={mobileView ? 60 : 40}
                    backgroundColor={colors.orange50}
                    borderRadius={8}
                    onPress={() => setSignUpModalOpen()}
                >
                    <Text style={{ fontSize: 17, textAlign: "center", paddingHorizontal: 5 }}>Sign up to reserve your space</Text>
                </CustomButton>
            </>
        )
    } else {
        const disabled = mySpace || !dateSpanSet || monthOptionDisabled || yearOptionDisabled || error;
        return (
            <>
                <CustomButton
                    width={customWidth - 25}
                    height={40}
                    disabled={disabled}
                    backgroundColor={disabled ? colors.amplifyNeutral80 : colors.orange50}
                    onPress={e => userData === null ? setSignUpModalOpen() : prepareToReserve(e)}
                >
                    <Text style={{ fontSize: 17, color: disabled ? "#000" : "#fff", fontWeight: 600 }}>Reserve</Text>
                </CustomButton>
            </>
        )
    }
};

const ReserveFooter = ({
    currentDateSpan,
    spaceTypes,
    activeTabIndex,
    numberOfMonths,
    baseCost,
    renterFee,
    monthlyPrice,
    rentalCost,
    currentMonthSpan,
    setMonthlyPayout,
    monthlyPayout,
    checkinDateError,
    checkoutDateError,
    monthOptionDisabled,
    yearOptionDisabled,
    mySpace,
    width,
    mobileView,
    userData,
    setSignUpModalOpen,
    error,
    prepareToReserve,
    loading,
    dateSpanSet,
    customWidth
}) => {
    return (
        <>
            <View style={[globalStyles.spaceBetween, { width: customWidth - 15, flexWrap: "wrap" }]}>
                <View style={globalStyles.flexStart}>
                    <View style={[globalStyles.flexStartColumn]}>
                        <Text>${`${Number(spaceTypes[activeTabIndex]?.price).toLocaleString("en-US", { maximumFractionDigits: 2 })}`} X {currentDateSpan.length} nights</Text>
                        <Text>Services fee:</Text>
                        {!!numberOfMonths && <Text style={globalStyles.flexEnd}>split over {numberOfMonths} months</Text>}
                    </View>

                    <View style={[globalStyles.flexEndColumn, { marginLeft: 15, alignSelf: "flex-start" }]}>
                        <Text style={globalStyles.flexEnd}>${Number(baseCost).toLocaleString("en-US", { maximumFractionDigits: 2 })}</Text>
                        <Text style={globalStyles.flexEnd}>${Number(renterFee).toLocaleString("en-US", { maximumFractionDigits: 2 })}</Text>
                    </View>
                </View>
                <View style={globalStyles.flexEnd}>
                    {!!monthlyPayout ? (
                        <View style={globalStyles.centerColumn}>
                            <View style={monthlyPrice ? globalStyles.flexStart : globalStyles.center}>
                                <Text>$</Text>
                                <Text style={globalStyles.boldText}>{!!monthlyPrice ? `${Number(monthlyPrice).toLocaleString("en-US", { maximumFractionDigits: 2 })}` : '--'}</Text>
                            </View>
                            <Text>per month</Text>

                        </View>
                    ) : (
                        <View style={globalStyles.center}>
                            <View style={rentalCost ? globalStyles.flexStart : globalStyles.center}>
                                <Text>$</Text>
                                <Text style={globalStyles.boldText}>{!!rentalCost ? `${Number(rentalCost).toLocaleString("en-US", { maximumFractionDigits: 2 })}` : '--'}</Text>
                            </View>
                            <Text> Total</Text>
                        </View>
                    )}
                </View>
            </View>
            {currentMonthSpan >= 1 && (
                <>
                    <View style={[globalStyles.center, { margin: 10 }]}>
                        <Pressable onPress={() => setMonthlyPayout(!monthlyPayout)}>
                            {!monthlyPayout ? (
                                <MaterialIcons name="check-box-outline-blank" size={25} color="black" />
                            ) : (
                                <MaterialIcons name="check-box" size={25} color="black" />
                            )}
                        </Pressable>
                        <Text style={{ marginLeft: 8, fontSize: 14 }}>I'd prefer to make my payments monthly</Text>
                    </View>
                </>
            )}

            <Text style={{ color: "red", marginTop: 5, fontSize: 17 }}>{!!checkinDateError || checkoutDateError}</Text>
            <View>
                {!!baseCost && (monthOptionDisabled || yearOptionDisabled) ? (
                    <View style={globalStyles.centerColumn}>
                        <AntDesign name="exclamationcircle" size={25} color="grey" />
                        <Text style={{ textAlign: "center", width: mobileView ? width - 100 : width / 3.3 }}>{monthOptionDisabled ? 'Stay must be longer than 28 days to reserve this option' : 'Stay must be at least 1 year to reserve this option'}</Text>
                    </View>
                ) : (<></>)}
            </View>

            <ReserveButton
                mySpace={mySpace}
                width={width}
                mobileView={mobileView}
                userData={userData}
                setSignUpModalOpen={() => setSignUpModalOpen()}
                error={error}
                prepareToReserve={(e) => prepareToReserve(e)}
                monthOptionDisabled={monthOptionDisabled}
                yearOptionDisabled={yearOptionDisabled}
                loading={loading}
                dateSpanSet={dateSpanSet}
                customWidth={customWidth}
            />
        </>
    )
}

const ReserveCardBody = ({
    mobileView,
    userData = null,
    mySpace = null,
    currentDateSpan,
    spaceTypes,
    property,
    space,
    width,
    customWidth,
    error,
    activeTabIndex,
    setActiveTabIndex,
    rentalType,
    monthlyPayout,
    setMonthlyPayout,
    setSignUpModalOpen,
    monthOptionDisabled,
    baseCost,
    renterFee,
    rentalCost,
    currentMonthSpan,
    monthlyPrice,
    checkinDate,
    setCheckinDate,
    checkoutDate,
    tryUpdateDateSpan,
    checkinDateError,
    checkoutDateError,
    signUpModalOpen,
    prepareToReserve,
    loading,
    dateSpanSet,
    monthlyPaymentDisplayDates,
    yearOptionDisabled,
    calendarDisplayForDateInput = false,
    initialDate,
    displaySpace,
    setDisplaySpace,
    activeSpaces,
    numberOfMonths,
    datesSelectedError,
    setDatesSelectedError,
    setCurrentDateSpan,
    setMobileReserveCardOpen
}) => {
    const { height } = useWindowDimensions();
    const cardHeight = mobileView ? (height < 600 ? height / 2.7 : height / 1.45) : null
    const [showMonthlyPayoutBreakdown, setShowMonthlyPayoutBreakdown] = useState(false);

    return (
        <View style={[globalStyles.centerColumn, { flexWrap: "nowrap" }]} >
            <FixedHeightCard width={customWidth} height={cardHeight} padding={20}>
                <View style={[globalStyles.spaceBetween, { flexDirection: "row", width: customWidth - 45, marginBottom: 10, flexWrap: "wrap" }]}>

                    <View style={globalStyles.center}>
                        {spaceTypes.map((v, i) => (
                            <View key={i}>
                                <Badge onPress={() => setActiveTabIndex(i)} noWidth={true} margin={2} padding={0} paddingLeft={5} paddingRight={5} color={activeTabIndex === i ? colors.orange40 : colors.grey10} height={28}> <><Text style={[styles.fontSize, { color: activeTabIndex === i ? "white" : "#000" }]}>{v.title}</Text></></Badge>
                            </View>
                        ))}
                        {!!datesSelectedError && <ErrorDisplay error={`That date range is not available for the selected Space: ${displaySpace.title}`} customWidth={width / 3.2} />}
                    </View>
                    <View style={globalStyles.flexEndColumn}>
                        <View style={globalStyles.flexEnd}>
                            <View style={globalStyles.flexStart}>
                                <Text>$</Text>
                                <Text style={globalStyles.boldText}>{`${Number(spaceTypes[activeTabIndex]?.price).toLocaleString("en-US", { maximumFractionDigits: 2 })}`}</Text>
                            </View>
                            <Text style={styles.prices}> night</Text>
                        </View>
                        {spaceTypes[activeTabIndex]?.subtext ? <Text style={styles.subtext}>* {spaceTypes[activeTabIndex]?.subtext}</Text> : <></>}
                    </View>
                </View>
                <ScrollView>
                    {
                        activeSpaces.map((v, i) => (
                            <CustomDropdown
                                header={`RV Space:  ${v.title}`}
                                customWidth={customWidth - 45}
                                spaceDetails={v}
                                displaySpaceDetails
                                displaySpace={displaySpace.id || activeSpaces[0].id}
                                setDisplaySpace={(v) => setDisplaySpace(v)}
                                rentalType={rentalType}
                                initialDate={initialDate}
                                monthlyPaymentDisplayDates={monthlyPaymentDisplayDates}
                                mobileView={mobileView}
                                subtext={spaceTypes[activeTabIndex]?.subtext}
                                price={spaceTypes[activeTabIndex]?.price}
                                increment={spaceTypes[activeTabIndex]?.increment}
                                monthOptionDisabled={monthOptionDisabled}
                                yearOptionDisabled={yearOptionDisabled}
                                baseCost={baseCost}
                                renterFee={renterFee}
                                rentalCost={rentalCost}
                                currentDateSpan={currentDateSpan}
                                currentMonthSpan={currentMonthSpan}
                                monthlyPrice={monthlyPrice}
                                checkinDate={checkinDate}
                                setCheckinDate={(v) => setCheckinDate(v)}
                                checkoutDate={checkoutDate}
                                tryUpdateDateSpan={(v) => tryUpdateDateSpan(v)}
                                setCurrentDateSpan={(v) => setCurrentDateSpan(v)}
                                checkinDateError={checkinDateError}
                                checkoutDateError={checkoutDateError}
                                calendarDisplayForDateInput={calendarDisplayForDateInput}
                                activeSpaces={activeSpaces}
                                datesSelectedError={datesSelectedError}
                                setDatesSelectedError={(v) => setDatesSelectedError(!!v)}
                            />
                        ))
                    }
                </ScrollView>
            </FixedHeightCard>

            {
                mobileView ? (
                    <View style={{ marginTop: 5 }}>
                        <View style={[globalStyles.spaceBetween]}>
                            <Card width={(width / 1.1) - 55} marginLeft={10}>
                                <ReserveFooter
                                    currentDateSpan={currentDateSpan}
                                    spaceTypes={spaceTypes}
                                    activeTabIndex={activeTabIndex}
                                    numberOfMonths={numberOfMonths}
                                    baseCost={baseCost}
                                    renterFee={renterFee}
                                    monthlyPayout={monthlyPayout}
                                    monthlyPrice={monthlyPrice}
                                    rentalCost={rentalCost}
                                    currentMonthSpan={currentMonthSpan}
                                    setMonthlyPayout={(v) => setMonthlyPayout()}
                                    checkinDateError={checkoutDateError}
                                    checkoutDateError={checkoutDateError}
                                    monthOptionDisabled={monthOptionDisabled}
                                    yearOptionDisabled={yearOptionDisabled}
                                    mySpace={mySpace}
                                    width={width}
                                    mobileView={mobileView}
                                    userData={userData}
                                    setSignUpModalOpen={() => setSignUpModalOpen(!signUpModalOpen)}
                                    error={error}
                                    prepareToReserve={(v) => prepareToReserve(v)}
                                    loading={loading}
                                    dateSpanSet={dateSpanSet}
                                    customWidth={(width / 1.1) - 60}
                                />
                            </Card>
                            <View style={{ marginRight: 15, marginTop: 5 }}>
                                <MobileOpenReserveCardButton setMobileReserveCardOpen={() => setMobileReserveCardOpen()} />
                            </View>
                        </View>
                    </View>
                ) : (
                    <>
                        <CustomDivider width={customWidth - 20} marginVertical={10} />
                        <ReserveFooter
                            currentDateSpan={currentDateSpan}
                            spaceTypes={spaceTypes}
                            activeTabIndex={activeTabIndex}
                            numberOfMonths={numberOfMonths}
                            baseCost={baseCost}
                            renterFee={renterFee}
                            monthlyPayout={monthlyPayout}
                            monthlyPrice={monthlyPrice}
                            rentalCost={rentalCost}
                            currentMonthSpan={currentMonthSpan}
                            setMonthlyPayout={(v) => setMonthlyPayout()}
                            checkinDateError={checkoutDateError}
                            checkoutDateError={checkoutDateError}
                            monthOptionDisabled={monthOptionDisabled}
                            yearOptionDisabled={yearOptionDisabled}
                            mySpace={mySpace}
                            width={width}
                            mobileView={mobileView}
                            userData={userData}
                            setSignUpModalOpen={() => setSignUpModalOpen(!signUpModalOpen)}
                            error={error}
                            prepareToReserve={(v) => prepareToReserve(v)}
                            loading={loading}
                            dateSpanSet={dateSpanSet}
                            customWidth={customWidth}
                        />
                    </>
                )
            }
        </View >
    );
};


const styles = StyleSheet.create({
    prices: {
        fontSize: 17,
        marginTop: 5
    },
    fontSize: {
        fontSize: 12,
        fontWeight: 700,
        marginBottom: 2
    },
    buttonText: {
        fontSize: 14,
        color: "white",
    },
    currentTabView: {
        top: 0,
        backgroundColor: colors.lightBlue,
        borderRadius: 16,
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "center",
        height: 50,
        padding: 5
    },
    subtext: {
        color: "blue",
        marginTop: 2
    },
    date: {
        borderColor: "gray",
        borderRadius: 4,
        borderWidth: 2,
        margin: 0,
        fontSize: 16,
        color: "grey",
    },
    dateText: {
        fontSize: 17,
        fontWeight: "bold",
    },
    boldText: {
        fontSize: 17,
    },
    totalText: {
        fontWeight: "bold",
        fontSize: 17,
        marginLeft: 35
    },
});

export default ReserveCardBody;
