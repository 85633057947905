import { Image, Pressable, Text, useWindowDimensions, View } from 'react-native';
import { colors } from '../../styles/colors';
import { globalStyles } from '../../styles/styles';
import { useEffect, useState } from 'react';
import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import TextBody from './TextBody';
import Header from './Header';
import { useSelector } from 'react-redux';
import DistancesView from '../space/DistancesView';
import DimensionsView from '../space/DimensionsView';
import getEnvVars from '../../../environment';
import CalendarView from '../space/CalendarView';
const { cloudfrontURL } = getEnvVars();


const OtherDisplay = ({
    subHeader,
    bodyItems,
    mobileView,
    width
}) => {
    return (
        <>
            {subHeader && <TextBody style={{ marginBottom: 10 }}>{subHeader}</TextBody>}
            <>
                {
                    bodyItems.map((v, i) => (
                        <View key={i}>
                            {
                                v?.q ? (
                                    <CustomDropdown
                                        header={v.q}
                                        icon={v.icon}
                                        bodyItems={v.a}
                                        nestedDropdownPresent={true}
                                        customWidth={mobileView ? width / 1.2 : width / 2.3}
                                    />
                                ) : (
                                    <>
                                        <View style={[globalStyles.flexStart, { marginVertical: 8 }]}>
                                            {v?.icon && v.icon}
                                            <TextBody style={{ paddingLeft: 15, paddingTop: 5 }}>{v.text}</TextBody>
                                        </View>
                                        {v?.subText && <TextBody style={{ paddingLeft: 15, marginTop: 5, fontStyle: "italic" }}>{v.subText}</TextBody>}
                                    </>
                                )}
                        </View >
                    ))
                }
            </>
        </>
    )
}

const SpaceDisplay = ({
    spaceDetails,
    mobileView,
    customWidth,
    currentDateSpan = null,
    initialDate = null,
    datesSelectedError = null,
    setDatesSelectedError = null,
    setCurrentDateSpan = null,
}) => {
    const { water_hookup, electric_hookup, sewage_hookup, width, length } = spaceDetails;
    const amenitiesPresent = (
        water_hookup ||
        electric_hookup ||
        sewage_hookup
    );

    return (
        <View style={{ width: customWidth - 20 }}>
            <View style={[customWidth < 1160 ? globalStyles.center : globalStyles.spaceBetween, { flexWrap: "wrap" }]}>
                {!!amenitiesPresent && (
                    <>
                        <View style={{ marginVertical: 10 }}>
                            <View style={[globalStyles.flexStartColumn]}>
                                <DistancesView space={spaceDetails} mobileView={mobileView} />
                            </View>
                        </View>
                        <View style={customWidth < 1160 ? { borderTopColor: colors.amplifyNeutral40, borderTopWidth: 1, width: customWidth - 40 } : { borderRightColor: colors.amplifyNeutral40, borderRightWidth: 1 }} />
                    </>
                )}
                <View style={{ marginVertical: 10 }}>
                    <DimensionsView
                        width={width}
                        length={length}
                        mobileView={mobileView}
                    />
                </View>
            </View>

            <View>
                <Image
                    source={{ uri: `${cloudfrontURL}${spaceDetails.images[0]}` }}
                    style={[{ width: customWidth - 15, height: (customWidth - 15) / 2.5, borderRadius: 7, marginTop: 10 }]}
                />
            </View>

            <View>
                <CalendarView
                    mobileView={mobileView}
                    space={spaceDetails}
                    setCurrentDateSpan={(v) => setCurrentDateSpan(v)}
                    currentDateSpan={currentDateSpan}
                    initialDate={initialDate}
                    customWidth={customWidth}
                    error={datesSelectedError}
                    setError={(v) => setDatesSelectedError(v)}
                />
                {/* <View style={globalStyles.center}>
                    <DatePicker
                        type={"CHECK-IN"}
                        date={checkinDate}
                        updateDate={(v) => setCheckinDate(v)}
                        error={checkinDateError}
                    />
                    <DatePicker
                        type={"CHECK-OUT"}
                        date={checkoutDate}
                        error={checkoutDateError}
                        updateDate={(v) => tryUpdateDateSpan(v)}
                    />
                </View> */}
            </View>
        </View>
    )
}

const CustomDropdown = ({
    header,
    subHeader,
    bodyItems,
    icon,
    customWidth,
    subtext = null,
    price = null,
    baseCost,
    increment = null,
    monthOptionDisabled = true,
    yearOptionDisabled = true,
    currentDateSpan = null,
    checkinDate = null,
    setCheckinDate = null,
    checkoutDate = null,
    tryUpdateDateSpan = null,
    checkinDateError = null,
    checkoutDateError = null,
    initialDate = null,
    calendarDisplayForDateInput = null,
    activeSpaces = null,
    datesSelectedError = null,
    setDatesSelectedError = null,
    setCurrentDateSpan = null,
    monthlyPaymentDisplayDates,
    renterFee,
    rentalCost,
    currentMonthSpan,
    monthlyPrice,
    rentalType,
    spaceDetails,
    displaySpaceDetails = false,
    displaySpace = null,
    setDisplaySpace = null,
    nestedDropdownPresent = false,
    hostViewOnly = false
}) => {
    const { width } = useWindowDimensions()
    const { mobileView } = useSelector(state => state.currentUser);
    const [dropdownOpen, setDropdownOpen] = useState(hostViewOnly);
    const [highlightedSpace, setHighlightedSpace] = useState(false);
    const dropdownWidth = customWidth ? customWidth : mobileView ? width / 1.1 : width / 2.2;
    const backgroundColor = nestedDropdownPresent ? colors.white : !displaySpaceDetails || highlightedSpace ? colors.orange20 : colors.grey10

    useEffect(() => {
        if (displaySpace) {
            const highlight = displaySpace === spaceDetails?.id;
            setHighlightedSpace(highlight)
            setDropdownOpen(true)
            if (displaySpace !== spaceDetails?.id) {
                setDropdownOpen(false)
            }
        }
    }, [displaySpace]);

    return (
        <View style={{ width: dropdownWidth, paddingVertical: 5 }}>
            <Pressable onPress={() => {
                displaySpace && setDisplaySpace(spaceDetails)
                setDropdownOpen(!dropdownOpen)
            }}>
                <View style={[globalStyles.spaceBetween, { backgroundColor: backgroundColor, borderTopLeftRadius: 8, borderTopRightRadius: 8, padding: 10, width: dropdownWidth }]}>
                    <View style={[globalStyles.spaceBetween, { width: dropdownWidth - 15, alignItems: "center" }]}>
                        {displaySpaceDetails ? (
                            <View>
                                <View style={{ height: 18, width: 18, borderRadius: 20, backgroundColor: "#fff" }} />
                                <View style={{ position: "absolute" }}>
                                    <View style={{ height: 10, width: 10, borderRadius: 20, backgroundColor: highlightedSpace ? colors.orange40 : colors.grey10, top: 4, left: 4 }} />
                                </View>
                            </View>
                        ) : (
                            <View>
                                <View style={{ backgroundColor: colors.orange10, width: 45, height: 45, borderRadius: 7 }} />
                                <View style={{ position: "absolute", top: 7, left: 7 }}>
                                    {icon}
                                </View>
                            </View>
                        )}
                        <Header text={header} padding={0} marginLeft={10} />
                        <View>
                            {dropdownOpen ? <MaterialIcons name="keyboard-arrow-up" size={25} color="#000" /> : <MaterialIcons name="keyboard-arrow-down" size={25} color="#000" />}
                        </View>
                    </View>
                </View>
            </Pressable >
            {dropdownOpen && (
                <View style={[globalStyles.flexStartColumn, { backgroundColor: colors.orange10, padding: 10, paddingVertical: 15, borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }]}>
                    <>
                        {displaySpaceDetails ? (
                            <SpaceDisplay
                                spaceDetails={spaceDetails}
                                mobileView={mobileView}
                                customWidth={customWidth}
                                rentalType={rentalType}
                                initialDate={initialDate}
                                monthlyFuturePaymentDates={monthlyPaymentDisplayDates}
                                subtext={subtext}
                                price={price}
                                increment={increment}
                                monthOptionDisabled={monthOptionDisabled}
                                yearOptionDisabled={yearOptionDisabled}
                                baseCost={baseCost}
                                renterFee={renterFee}
                                rentalCost={rentalCost}
                                currentDateSpan={currentDateSpan}
                                currentMonthSpan={currentMonthSpan}
                                monthlyPrice={monthlyPrice}
                                checkinDate={checkinDate}
                                setCheckinDate={(v) => setCheckinDate(v)}
                                checkoutDate={checkoutDate}
                                tryUpdateDateSpan={(v) => tryUpdateDateSpan(v)}
                                setCurrentDateSpan={(v) => setCurrentDateSpan(v)}
                                checkinDateError={checkinDateError}
                                checkoutDateError={checkoutDateError}
                                calendarDisplayForDateInput={calendarDisplayForDateInput}
                                activeSpaces={activeSpaces}
                                datesSelectedError={datesSelectedError}
                                setDatesSelectedError={(v) => setDatesSelectedError(!!v)}
                            />
                        ) : (
                            <OtherDisplay width={width} subHeader={subHeader} bodyItems={bodyItems} nestedDropdownPresent={nestedDropdownPresent} mobileView={mobileView} />
                        )}
                    </>
                </View>
            )}
        </View >
    );
};

export default CustomDropdown;