import React, { useContext } from "react";
import { useWindowDimensions, ScrollView, View, Text } from "react-native";
import CustomDivider from "../../components/CustomDivider";
import { DetailsAccount } from "../../components/user/DetailsAccount";
import { DetailsPersonal } from "../../components/user/DetailsPersonal";
import { DeleteAccount } from "../../components/user/DeleteAccount";
import { AccountStatus } from "../../components/user/AccountStatus";
import { UserDataContext } from "../../utils/UserContextProvider";
import BackButton from "../../components/other/BackButton";
import { useSelector } from "react-redux";
import FooterView from "../../components/other/FooterView";
import Header from "../../components/CustomElements/Header";

function ProfileScreen() {
  const { user } = useContext(UserDataContext);
  const { userData } = user;
  const screenWidth = useWindowDimensions().width / 1.25;
  const { mobileView, identityVerificationNotComplete } = useSelector(state => state.currentUser);

  return (
    <ScrollView>
      <BackButton goBack={false} />
      <View style={{ marginLeft: mobileView ? 20 : 35 }}>
        <Header padding={0} level={1.5} marginBottom={55} marginTop={25} text={`Hello, ${userData.userName}`} />

        <AccountStatus
          userState={userData}
          identityVerificationNotComplete={identityVerificationNotComplete}
        />

        <View>
          <CustomDivider />
          <DetailsAccount
            screenWidth={screenWidth}
          />
        </View>

        <View>
          <CustomDivider />
          <Header padding={0} marginBottom={10} text="Reviews"></Header>
          <View>
            <Text>no reviews yet</Text>
          </View>
        </View>

        <View>
          <CustomDivider />
          <DetailsPersonal
            userState={userData}
          />
        </View>

        <View>
          <CustomDivider />
          <DeleteAccount
            userState={userData}
          />
        </View>

      </View>
      {!mobileView && <FooterView />}
    </ScrollView>
  );
}

export default ProfileScreen;
