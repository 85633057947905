import {
    Image,
    Pressable,
    StyleSheet,
    Text,
    View,
} from "react-native";
import Badge from "../CustomElements/Badge";
import { globalStyles } from "../../styles/styles";
import CustomButton from "../CustomElements/CustomButton";
import { colors } from "../../styles/colors";
import { Ionicons, Octicons } from "@expo/vector-icons";
import { useState } from "react";
import CustomHorizontalScrollView from "../other/CustomHorizontalScrollView";
import { CustomIconToggleButton } from "../CustomElements/CustomIconToggleButton";
import LocationSearch from "./LocationSearch";
import CustomModal from "../CustomElements/CustomModal";
import SelectStayType from "../space/SelectStayType";

const FilterModal = ({
    modalVisible,
    setModalVisible,
    stayType,
    setStayType,
    mobileView,
    showMap,
    setShowMap,
    width,
    maxWidth = null,
    loading,
    goToHostSplash,
    searchInput,
    setSearchInput,
    clearLocation,
    setCoordinates,
    setnewLocationSearched,
    amazingView,
    setAmazingView,
    shadedSite,
    setShadedSite,
    setPetsAllowed,
    petsAllowed,
    electricAndWater,
    setElectricAndWater,
    featureOptions
}) => {

    const clearFilter = () => {
        clearLocation();
        setModalVisible();
    };

    return (
        <CustomModal modalVisible={modalVisible} setModalVisible={() => setModalVisible(false)}>
            {/* <PriceFilter pricefilter={pricefilter} setPricefilter={(v) => setPricefilter(v)} /> */}
            <View style={globalStyles.center}>
                <CustomHorizontalScrollView customWidth={width / 1.4} customMaxWidth={520} >
                    {featureOptions.map((feature, key) => (
                        <CustomIconToggleButton
                            key={key}
                            customHeight={90}
                            amenityType={feature.value}
                            selectedColor={colors.orange20}
                            selectedColorBorder={colors.orange30}
                            updateAmenityType={() => feature.setValue(!feature.value)}
                            icon={<Image style={{ width: 37, height: 37 }} source={feature.icon} />}
                            amenityTitle={feature.title}
                        />
                    ))}
                </CustomHorizontalScrollView>
            </View>
            <LocationSearch
                width={width / 1.45}
                maxWidth={480}
                mobileView={mobileView}
                searchInput={searchInput}
                setSearchInput={() => setSearchInput(!searchInput)}
                clearLocation={() => clearLocation()}
                setCoordinates={(v) => setCoordinates(v)}
                setnewLocationSearched={(v) => setnewLocationSearched(v)}
            />
            <CustomButton
                width={120}
                marginTop={20}
                backgroundColor={colors.grey10}
                onPress={() => setShowMap()}
                height={30}
                hideBorder={false}
            >
                <View style={globalStyles.center}>
                    <Text>{showMap ? "Hide Map" : "Show Map"}</Text>
                </View>
            </CustomButton>
            <View style={[globalStyles.center, { marginTop: 30 }]}>
                <CustomButton
                    width={mobileView ? 100 : 300}
                    height={40}
                    backgroundColor={colors.grey20}
                    border={colors.amplifyNeutral90}
                    onPress={() => clearFilter()}
                    hideBorder={true}
                >
                    <Text style={{ borderBottomWidth: 1 }}>Clear Filters</Text>
                </CustomButton>
                <CustomButton
                    width={120}
                    marginTop={5}
                    backgroundColor={colors.orange30}
                    onPress={() => setModalVisible()}
                    height={30}
                    hideBorder={false}
                >
                    <View style={globalStyles.center}>
                        <Octicons name="codescan-checkmark" size={21} color="#fff" />
                        <Text style={styles.filterButtonText}>Search</Text>
                    </View>
                </CustomButton>
            </View>
        </CustomModal>
    );
};

const FilterOptions = ({
    stayType,
    setStayType,
    mobileView,
    showMap,
    setShowMap,
    width,
    maxWidth = null,
    loading,
    goToHostSplash,
    searchInput,
    setSearchInput,
    clearLocation,
    setCoordinates,
    setnewLocationSearched,
    amazingView,
    setAmazingView,
    shadedSite,
    setShadedSite,
    setPetsAllowed,
    petsAllowed,
    electricAndWater,
    setElectricAndWater
}) => {
    const [filterModalOpen, setFilterModalOpen] = useState(false);
    const featureOptions = [
        {
            value: amazingView,
            setValue: () => setAmazingView(!amazingView),
            title: "Amazing Views",
            icon: require("../../../assets/icons/amazing-view.svg")
        },
        {
            value: electricAndWater,
            setValue: () => setElectricAndWater(!electricAndWater),
            title: "Water & Electric",
            icon: require("../../../assets/icons/full-hookup.svg")
        },
        {
            value: shadedSite,
            setValue: () => setShadedSite(!shadedSite),
            title: "Shaded Sites",
            icon: require("../../../assets/icons/shaded-view.svg")
        },
        {
            value: petsAllowed,
            setValue: () => setPetsAllowed(!petsAllowed),
            title: "Pet Friendly",
            icon: require("../../../assets/icons/pet-friendly.svg")
        }
    ];

    const updateMapShowing = () => {
        setShowMap(!showMap)
        setFilterModalOpen(false)
    }

    return (
        <View>
            {mobileView ? (
                <View style={[globalStyles.centerColumn, { width, maxWidth: maxWidth ? maxWidth : width }]}>
                    <View style={[globalStyles.spaceBetween, { flexWrap: "wrap", marginTop: 15, marginBottom: 10, backgroundColor: "#fff", padding: 7, borderRadius: 8, alignItems: "center" }]}>
                        <SelectStayType
                            setSelectedType={(v) => setStayType(v)}
                            selectedType={stayType}
                        />
                        <Pressable onPress={() => setFilterModalOpen(true)}>
                            <Ionicons name="options" size={35} color="black" />
                        </Pressable>
                    </View>
                    <FilterModal
                        modalVisible={filterModalOpen}
                        setModalVisible={() => setFilterModalOpen(!filterModalOpen)}
                        setStayType={(v) => setStayType(v)}
                        clearLocation={() => clearLocation()}
                        stayType={stayType}
                        loading={loading}
                        showMap={showMap}
                        setShowMap={() => updateMapShowing()}
                        width={width}
                        featureOptions={featureOptions}
                        goToHostSplash={() => goToHostSplash()}
                        searchInput={searchInput}
                        setCoordinates={(v) => setCoordinates(v)}
                        setnewLocationSearched={(v) => setnewLocationSearched(v)}
                        mobileView={mobileView}
                        amazingView={amazingView}
                        setAmazingView={() => setAmazingView(!amazingView)}
                        electricAndWater={electricAndWater}
                        setElectricAndWater={() => setElectricAndWater(!electricAndWater)}
                        shadedSite={shadedSite}
                        setShadedSite={() => setShadedSite(!shadedSite)}
                        petsAllowed={petsAllowed}
                        setPetsAllowed={() => setPetsAllowed(!petsAllowed)}
                    />
                </View>
            ) : (
                <View style={[globalStyles.centerColumn, { width, maxWidth: maxWidth ? maxWidth : width }]}>
                    <View style={globalStyles.center}>
                        <CustomHorizontalScrollView customWidth={width / 1.4} customMaxWidth={520} >
                            {featureOptions.map((feature, key) => (
                                <CustomIconToggleButton
                                    key={key}
                                    customHeight={90}
                                    amenityType={feature.value}
                                    selectedColor={colors.orange20}
                                    selectedColorBorder={colors.orange30}
                                    updateAmenityType={() => feature.setValue(!feature.value)}
                                    icon={<Image style={{ width: 37, height: 37 }} source={feature.icon} />}
                                    amenityTitle={feature.title}
                                />
                            ))}
                        </CustomHorizontalScrollView>
                    </View>
                    <LocationSearch
                        width={width / 1.45}
                        maxWidth={480}
                        mobileView={mobileView}
                        searchInput={searchInput}
                        setSearchInput={() => setSearchInput(!searchInput)}
                        clearLocation={() => clearLocation()}
                        setCoordinates={(v) => setCoordinates(v)}
                        setnewLocationSearched={(v) => setnewLocationSearched(v)}
                    />
                    <View style={[globalStyles.spaceBetween, { flexWrap: "wrap", marginTop: 15, marginBottom: mobileView ? -10 : 10, backgroundColor: "#fff", padding: 7, borderRadius: 8, alignItems: "center" }]}>
                        <View style={[globalStyles.center, { flexWrap: "wrap" }]}>
                            <Badge onPress={() => setStayType('vacation')} margin={2} padding={mobileView ? 1 : 2} color={stayType == 'vacation' ? colors.orange40 : colors.grey10} height={30} width={width < 420 ? 70 : 84}>
                                <Text style={[styles.fontSize, { color: stayType === 'vacation' ? "#fff" : "#000" }]}>Nightly</Text>
                            </Badge>
                            <Badge onPress={() => setStayType('monthly')} margin={2} padding={mobileView ? 1 : 2} color={stayType == 'monthly' ? colors.orange40 : colors.grey10} height={30} width={width < 420 ? 70 : 84}>
                                <Text style={[styles.fontSize, { color: stayType === 'monthly' ? "#fff" : "#000" }]}>Monthly</Text>
                            </Badge>
                            <Badge onPress={() => setStayType('type_yearly')} margin={2} padding={mobileView ? 1 : 2} color={stayType == 'type_yearly' ? colors.orange40 : colors.grey10} height={30} width={width < 420 ? 63 : 84}>
                                <Text style={[styles.fontSize, { color: stayType === 'type_yearly' ? "#fff" : "#000" }]}>Yearly</Text>
                            </Badge>
                            <Badge onPress={() => setStayType('storage')} margin={2} padding={mobileView ? 1 : 2} color={stayType == 'storage' ? colors.orange40 : colors.grey10} height={30} width={width < 420 ? 70 : 84}>
                                <Text style={[styles.fontSize, { color: stayType === 'storage' ? "#fff" : "#000" }]}>Storage</Text>
                            </Badge>
                        </View>
                        {mobileView ? (
                            <Pressable onPress={() => setFilterModalOpen(true)}>
                                <Ionicons name="options" size={35} color="black" />
                            </Pressable>
                        ) : (
                            <CustomButton
                                width={120}
                                marginTop={5}
                                backgroundColor={colors.orange30}
                                onPress={() => setShowMap(!showMap)}
                                height={30}
                                hideBorder={false}
                            >
                                <View style={globalStyles.center}>
                                    <Text style={styles.filterButtonText}>{showMap ? "Hide Map" : "Show Map"}</Text>
                                </View>
                            </CustomButton>
                        )}
                    </View>
                </View>
            )}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    image: {
        flex: 1,
        justifyContent: "center"
    },
    showmapbutton: {
        alignItems: "center",
        borderRadius: 10,
        backgroundColor: "#fff",
        borderColor: "#d6d6d6",
        borderRadius: 30,
        borderWidth: 1,
        borderBottomWidth: 2,
        justifyContent: "center",
        paddingHorizontal: 15,
    },
    filterButton: {
        alignItems: "center",
        backgroundColor: "#fff",
        borderRadius: 30,
        elevation: 1,
        flexDirection: "row",
        height: 45,
        justifyContent: "center",
        marginHorizontal: 10,
        top: 15,
        width: 110,
        zIndex: 1,
    },
    filterButtonText: {
        marginLeft: 4,
        color: "#fff",
        fontSize: 14,
        fontWeight: "bold",
    },
    fontSize: {
        fontSize: 14,
        paddingBottom: 2,
        fontWeight: 700
    }
});

export default FilterOptions;