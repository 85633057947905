import { Image, View } from "react-native";
import { colors } from "../../styles/colors";
import { CustomIconToggleButton } from "../CustomElements/CustomIconToggleButton";
import { globalStyles } from "../../styles/styles";
import { useSelector } from "react-redux";

const SelectAmenities = ({
    water,
    setWater,
    electric,
    setElectric,
    sewage,
    setSewage,
    deluxe,
    setDeluxe,
    width
}) => {
    const { mobileView } = useSelector(state => state.currentUser);

    return (
        <View style={[globalStyles.center, { width: width / 1.05, flexWrap: "wrap", marginTop: 15 }]}>
            <CustomIconToggleButton
                amenityType={electric}
                updateAmenityType={setElectric}
                selectedColor={colors.white}
                selectedColorBorder={colors.orange40}
                icon={
                    <Image
                        style={{marginBottom:3,  width: 35, height: 35, color: electric ? colors.amplifyNeutral80 : colors.amplifyNeutral60 }}
                        source={require("../../../assets/icons/electricity.svg")}
                    />
                }
                amenityTitle={"Electricity"}
                largeSize={!mobileView}
            />
            <CustomIconToggleButton
                amenityType={water}
                updateAmenityType={setWater}
                selectedColor={colors.white}
                selectedColorBorder={colors.orange40}
                amenityTitle={"Water"}
                largeSize={!mobileView}
                icon={
                    <Image
                        style={{marginBottom:3,  width: 35, height: 35, color: electric ? colors.amplifyNeutral80 : colors.amplifyNeutral60 }}
                        source={require("../../../assets/icons/water.svg")}
                    />}
            />
            <CustomIconToggleButton
                amenityType={sewage}
                updateAmenityType={setSewage}
                selectedColor={colors.white}
                selectedColorBorder={colors.orange40}
                amenityTitle={"Sewage"}
                largeSize={!mobileView}
                icon={
                    <Image
                        style={{marginBottom:3,  width: 35, height: 35, color: electric ? colors.amplifyNeutral80 : colors.amplifyNeutral60 }}
                        source={require("../../../assets/icons/sewage.svg")}
                    />}
            />
            <CustomIconToggleButton
                amenityType={deluxe}
                updateAmenityType={setDeluxe}
                selectedColor={colors.white}
                selectedColorBorder={colors.orange40}
                amenityTitle={"Deluxe*"}
                largeSize={!mobileView}
                icon={
                    <Image
                        style={{marginBottom:3,  width: 35, height: 35, color: electric ? colors.amplifyNeutral80 : colors.amplifyNeutral60 }}
                        source={require("../../../assets/icons/deluxe.svg")}
                    />}
            />
            {/* {easy_entry && ( <MaterialCommunityIcons name="steering" size={mobileView ? 25 : 30} color={colors.amplifyBlue40}/>)}
                    {shower_available && (<FontAwesome5 name="shower" size={mobileView ? 25 : 30} color={colors.amplifyBlue40} />)}
                    {wifi_available && (<Ionicons name="wifi" size={mobileView ? 25 : 30} color={colors.amplifyBlue40} />)}
                    {laundry_available && (<MaterialIcons name="local-laundry-service" size={mobileView ? 25 : 30} color={colors.amplifyBlue40} />)}
                    {pool_available && (<MaterialIcons name="pool" size={mobileView ? 24 : 38} color="black" />)}
                    {hottub_available && (<FontAwesome5 name="hot-tub" size={mobileView ? 25 : 28} color={colors.amplifyBlue40} />)}
                    {indoor_area_available && (<FontAwesome5 name="warehouse" size={mobileView ? 25 : 25} color={colors.amplifyBlue40} />)}
                    {outdoor_area_available && (<MaterialCommunityIcons name="table-picnic" size={mobileView ? 25 : 30} color={colors.amplifyBlue40} />)}
                    {trash_available && (<FontAwesome name="trash-o" size={mobileView ? 23 : 27} color={colors.amplifyBlue40} />)} */}
        </View>
    )
};

export default SelectAmenities;
