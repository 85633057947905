import { API } from "aws-amplify";
import { listBookings } from "../graphql/queries";
import moment from "moment";
import { createBooking, updateBooking } from "../graphql/mutations";
import { formatDay } from "../utils/formatDate";
import { createSubscription } from "../lambdaFunctions/payments/createSubscription";
import getEnvVars from "../../environment";
import { makePayment } from "../lambdaFunctions/payments/makePayment";
import { UUID } from "../utils/UUID";
const { REACT_APP_ENV } = getEnvVars();


export const listSpaceBookings = async ({ myID, spaceID }) => {
    const today = moment(new Date()).format("YYYY-MM-DD");
    const spaceBookings = await API.graphql({
        query: listBookings,
        variables: {
            filter: {
                hostID: { eq: myID },
                cancelled: { eq: false },
                spaceID: { eq: spaceID },
                check_out: { gt: today }
            }
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
    })
    return spaceBookings;
};


export const createBookingMutation = async ({ data }) => {
    const paymentID = UUID();
    delete data.reviewed;
    delete data.confirmed;
    delete data.rentalType;
    delete data.spaceState;
    delete data.spaceCity;
    delete data.spaceTitle;
    delete data.propertyID;
    delete data.propertyTitle;
    delete data.renter_acknowledge_liability;
    const bookingData = {
        bookingRequestId: data.id,
        paymentID,
        cancelledBeforeCheckIn: false,
        ...data
    }

    try {
        let payment = await makePayment({ bookingData });
        console.log("SUCCESS making payment")
        if (payment) {
            const { renterID, id, stripePaymentIntentID, stripeCustomerID, stripePaymentMethodID, monthlyPaymentAmount, check_out, nextPaymentsSchedule, payMonthly, check_in } = data
            if (payMonthly && !!nextPaymentsSchedule.length) {
                console.log("NOW create subscription")
                let productName = `${id}:${check_in}>${check_out}`;
                let monthlyPaymentDayOfMonth = formatDay(check_in);
                let subscriptionData = {
                    enviro: REACT_APP_ENV,
                    userID: renterID,
                    bookingID: id,
                    stripeCustomerID: stripeCustomerID,
                    stripePaymentMethodID: stripePaymentMethodID,
                    stripePaymentIntentID: stripePaymentIntentID,
                    monthlyPaymentAmount: Number(monthlyPaymentAmount * 100).toFixed(0),
                    endDate: moment(check_out).unix(),
                    startDate: moment(nextPaymentsSchedule[0]).unix(),
                    numberOfPayments: nextPaymentsSchedule.length,
                    productName: productName,
                    monthlyPaymentDayOfMonth: monthlyPaymentDayOfMonth
                };
                const subscription = await createSubscription({ subscriptionData });
                console.log("SUBSCRIPTION created >>   ", subscription)
                if (subscription) {
                    console.log("NOW create booking")
                    let newBooking = await API.graphql({
                        query: createBooking,
                        variables: { input: bookingData },
                        authMode: "AMAZON_COGNITO_USER_POOLS",
                    }).then((r) => {
                        return r.data.createBooking;
                    }).catch((e) => {
                        console.log("e in here >>  ", e)
                        return false;
                    })
                    return newBooking;
                } else {
                    return false
                }
            } else {
                let newBooking = await API.graphql({
                    query: createBooking,
                    variables: { input: bookingData },
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                }).then((r) => {
                    return r.data.createBooking;
                }).catch((e) => {
                    console.log("e in here >>  ", e)
                    return false;
                })
                return newBooking;
            }
        } else {
            return false;
        }
    } catch {
        return false;
    }
};

export const updateBookingMutation = async ({ data }) => {
    try {
        const response = await API.graphql({
            query: updateBooking,
            variables: { input: data },
            authMode: "AMAZON_COGNITO_USER_POOLS",
        })
        return response?.data?.updateBooking;
    } catch {
        return false
    }
};

export const listBookingsQuery = async ({ data }) => {
    const bookings = await API.graphql({
        query: listBookings,
        variables: {
            filter: data
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
    }).then((r) => {
        return r.data.listBookings.items;
    }).catch((e) => {
        console.log("e in here >>  ", e)
        return false;
    })
    return bookings;
};
