import React from "react";
import {
    View,
    Text,
    Pressable,
    Image
} from "react-native";
import { useSelector } from "react-redux";
import { colors } from "../../styles/colors";
import { globalStyles } from "../../styles/styles";
import { CustomIconToggleButton } from "../CustomElements/CustomIconToggleButton";
import Header from "../CustomElements/Header";
import { Entypo } from "@expo/vector-icons";
import Card from "../CustomElements/Card";
import LocationSearch from "../search/LocationSearch";
import FilterOptions from "../search/FilterOptions";
import CustomHorizontalScrollView from "./CustomHorizontalScrollView";

export const HomeScreenHeroCard = ({
    width,
    goToHostSplash,
    searchInput,
    setSearchInput,
    clearLocation,
    setCoordinates,
    setnewLocationSearched,
    stayType,
    setStayType,
    showMap,
    setShowMap,
    amazingView,
    setAmazingView,
    shadedSite,
    setShadedSite,
    setPetsAllowed,
    petsAllowed,
    electricAndWater,
    setElectricAndWater,
    loading=false
}) => {
    const { mobileView } = useSelector(state => state.currentUser);

    return (
        <View style={[globalStyles.centerColumn, { marginTop: 115, marginBottom: 100, marginRight: "40%" }]}>
            <Card alignItems="flex-start" width={width / 1.2} maxWidth={550}>
                <View style={globalStyles.flexStartColumn}>
                    <Header level={1.7} text="Find the Perfect RV Parking" padding={5} />
                    <FilterOptions
                        width={width / 1.5}
                        maxWidth={520}
                        stayType={stayType}
                        setStayType={(v) => setStayType(v)}
                        mobileView={mobileView}
                        showMap={showMap}
                        setShowMap={() => setShowMap(!showMap)}
                        searchInput={searchInput}
                        setSearchInput={() => setSearchInput(!searchInput)}
                        clearLocation={() => clearLocation()}
                        setCoordinates={(v) => setCoordinates(v)}
                        setnewLocationSearched={(v) => setnewLocationSearched(v)}
                        loading={loading}
                        goToHostSplash={() => goToHostSplash()}
                        amazingView={amazingView}
                        setAmazingView={() => setAmazingView(!amazingView)}
                        electricAndWater={electricAndWater}
                        setElectricAndWater={() => setElectricAndWater(!electricAndWater)}
                        shadedSite={shadedSite}
                        setShadedSite={() => setShadedSite(!shadedSite)}
                        petsAllowed={petsAllowed}
                        setPetsAllowed={() => setPetsAllowed(!petsAllowed)}
                    />
                </View>
            </Card>
            <Card alignItems="flex-start" width={width / 1.2} maxWidth={550}>
                <View style={[globalStyles.spaceBetween, { width: width / 1.3, maxWidth: 530 }]}>
                    <View style={[globalStyles.flexStartColumn,]}>
                        <Header level={1.7} text="Looking to rent your place?" padding={0} marginBottom={5} />
                        <Pressable onPress={() => goToHostSplash()}>
                            <View style={globalStyles.flexStart}>
                                <Text style={{ marginRight: 5 }}>Learn about hosting</Text>
                                <Entypo name="chevron-thin-right" size={18} color="black" />
                            </View>
                        </Pressable>
                    </View>
                    <Image
                        style={{ width: 80, height: "100%" }}
                        source={require("../../../assets/icons/looking-for-rent.svg")}
                    />
                </View>
            </Card >
        </View >

    );
};
