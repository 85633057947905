import React, { useState, useEffect, useContext, useRef } from "react";
import { ScrollView, View, Text, Platform } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useSelector } from "react-redux";
import Header from "../../components/CustomElements/Header"
import { AntDesign, Entypo } from "@expo/vector-icons";
import ReviewRequestModal from "../../components/booking/ReviewRequestModal";
import BookingModal from "../../components/booking/BookingModal";
import PropertiesGrid from "../../components/space/PropertiesGrid";
import { LoadingComponent } from "../../components/LoadingComponent";
import MessagesPreview from "../../components/message/MessagesPreview";
import { NextSteps } from "../../components/host/NextSteps";
import { MySpaceRentals } from "../../components/host/MySpaceRentals";
import { MyHostCalendar } from "../../components/host/MyHostCalendar";
import { UserDataContext } from "../../utils/UserContextProvider";
import { BookingsContext } from "../../utils/BookingsContextProvider";
import ConnectedAccountInfoNeeded from "../../components/host/ConnectedAccountInfoNeeded";
import { colors } from "../../styles/colors"
import CustomButton from "../../components/CustomElements/CustomButton";
import CustomSection from "../../components/CustomElements/CustomSection";
import moment from "moment";
import { listHostedConversationsQuery } from "../../api/conversations";
import { globalStyles } from "../../styles/styles";
import TextBody from "../../components/CustomElements/TextBody";
import BackButton from "../../components/other/BackButton";

const MyHostedSpaces = ({ spaces, mobileView }) => {
  const navigation = useNavigation();
  return (
    <CustomSection backgroundColor={colors.lightBlue} padding={0}>
      <Header
        color={colors.amplifyNeutral90}
        level={1.1}
        marginTop={mobileView ? 10 : 15}
        marginBottom={10}
        text="MY PROPERTIES"
      />
      <CustomButton
        width={mobileView ? 150 : 170}
        marginLeft={mobileView ? 10 : 20}
        onPress={() => navigation.reset({ routes: [{ name: 'HostCreateSpaceNavigator' }] })}
        backgroundColor={colors.amplifyNeutral20}
        color="#fff"
        marginBottom={mobileView ? 5 : 10}
        border={colors.amplifyNeutral60}
      >
        <View style={globalStyles.center}>
          <AntDesign name="pluscircle" size={20} color="grey" />
          <Text style={{ marginLeft: 10, fontSize: 15 }}>add new space</Text>
        </View>
      </CustomButton>
      <View style={globalStyles.center}>
        <PropertiesGrid properties={spaces} showCardBackground={true} />
      </View>
    </CustomSection>
  );
};

function HostAccountScreenBody({ }) {
  const navigation = useNavigation();
  const { user } = useContext(UserDataContext);
  const { userData } = user;
  const { bookingRequests, bookings, myHosting } = useContext(BookingsContext);
  const { mobileView } = useSelector(state => state.currentUser);
  const { bookingRequestsArray } = bookingRequests;
  const { bookingsArray } = bookings;
  const { mySpaces } = myHosting;
  const { sub, email, name } = userData;
  const { unreadBoolean } = useSelector((state) => state.conversations);
  const [setNavigateSpaceID] = useState(null);
  const [myUnreadMsgs, setMyUnreadMsgs] = useState(null);
  const [reviewRequestModalOpen, setReviewRequestModalOpen] = useState(false);
  const [bookingModalOpen, setBookingModalOpen] = useState(false);
  const [connectedAcountInfoNeededModalOpen, setConnectedAcountInfoNeededModalOpen] = useState(false);
  const [requestDetails, setRequestDetails] = useState(null);
  const [bookingDetails, setBookingDetails] = useState(null);
  const [stripeHostConnectedAccountId, setStripeHostConnectedAccountId] = useState(null);
  const [sortedBookings, setSortedBookings] = useState({});
  const [activeTabIndex, setActiveTabIndex] = useState('current');
  const [unavailDays, setUnavailDays] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hoster, setHoster] = useState(false);

  const fetchHostConversations = async () => {
    const conversations = await listHostedConversationsQuery({ ownerID: sub });
    setMyUnreadMsgs(conversations?.items);
  }

  const openRequestModal = (bool) => {
    if (!!stripeHostConnectedAccountId) {
      setReviewRequestModalOpen(true)
    } else { setConnectedAcountInfoNeededModalOpen(true); }
  };

  const openBookingModal = (v) => {
    setBookingModalOpen(true)
    setBookingDetails(v)
  };

  const getBookingData = () => {
    setLoading(true)
    setActiveTabIndex('')
    const today = moment(new Date()).format("YYYY-MM-DD");
    if (bookingsArray.length) {
      const upcoming = bookingsArray.filter(function (el) {
        return moment(moment().format("YYYY-MM-DD")).isBefore(moment(el.check_in))
      });
      const current = bookingsArray.filter(function (el) {
        return moment(moment().format("YYYY-MM-DD")).isBetween(el.check_in, el?.bookingRequest?.check_out, 'days', '[]');
      });
      const past = bookingsArray.filter(function (el) {
        return moment(el?.bookingRequest?.check_out, "YYYY-MM-DD").isBefore(today)
      });
      let bookings = {
        current: current,
        upcoming: upcoming,
        past: past
      };
      setSortedBookings(bookings);
      const tab = !!current.length ? 'current' : !!upcoming.length ? 'upcoming' : 'past';
      setActiveTabIndex(tab);
      setUnavailDays([...current, ...upcoming])
    }
    setHoster(!!mySpaces.length)
    setLoading(false)
  }


  useEffect(() => {
    if (unreadBoolean) {
      fetchHostConversations()
    }
  }, []);

  useEffect(() => {
    if (userData?.userData && userData.userData?.stripeConnectedAccountId) {
      setStripeHostConnectedAccountId(userData.userData.stripeConnectedAccountId)
    }
  }, [userData]);

  useEffect(() => {
    getBookingData();
  }, [bookingsArray.length, bookingRequestsArray.length]);

  return (
    <View style={globalStyles.centerColumn}>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          <BackButton goBack={false} />
          {hoster ? (
            <View>
              <View style={[globalStyles.flexStartColumn, { padding: 10, marginBottom: 15 }]}>
                <Header textAlign={"left"} text="Welcome back," padding={0} level={1.8} />
                <Text style={{ fontSize: 20 }}>{name}</Text>
              </View>
              {!mobileView && (
                <View style={globalStyles.flexEnd}>
                  <CustomButton
                    width={mobileView ? 150 : 170}
                    marginRight={mobileView ? 15 : 25}
                    onPress={() => navigation.reset({ routes: [{ name: 'HostCreateSpaceNavigator' }] })}
                    backgroundColor={colors.amplifyNeutral20}
                    color="#fff"
                    marginTop={10}
                    border={colors.amplifyNeutral60}
                  >
                    <View style={globalStyles.center}>
                      <AntDesign name="pluscircle" size={20} color="grey" />
                      <Text style={{ marginLeft: 10, fontSize: 15 }}>add new space</Text>
                    </View>
                  </CustomButton>
                </View>
              )}
              <View style={globalStyles.centerColumn}>
                <NextSteps
                  userState={userData}
                  bookingRequests={bookingRequestsArray}
                  setNavigateSpaceID={(v) => setNavigateSpaceID(v)}
                  onPressNext={(name, id) => console.log('add me back')}
                  setRequestDetails={(v) => setRequestDetails(v)}
                  setReviewRequestModalOpen={() => openRequestModal(true)}
                />
                {/* {!!myUnreadMsgs && <MessagesPreview myUnreadMsgs={myUnreadMsgs} />} */}
                {!!bookingsArray.length && activeTabIndex &&
                  <MySpaceRentals
                    setOpenBookingDetailsModal={(v) => openBookingModal(v)}
                    loading={loading}
                    bookings={sortedBookings}
                    activeTabIndex={activeTabIndex}
                    setActiveTabIndex={(v) => setActiveTabIndex(v)}
                  />
                }
                {!!mySpaces.length && <MyHostCalendar bookingRequests={bookingRequestsArray} bookings={bookingsArray} />}
                <MyHostedSpaces spaces={mySpaces} mobileView={mobileView} />
              </View>
            </View>
          ) : (
            <View style={[globalStyles.center, { marginTop: 20 }]}>
              <CustomButton
                width={190}
                backgroundColor={colors.orange30}
                textAlign="center"
                border={colors.orange90}
                shadowColor={colors.orange90}
                height={40}
                onPress={() => navigation.reset({ routes: [{ name: 'host' }] })}
                marginBottom={12}
              >
                <View style={[globalStyles.spaceBetween, { padding: 5, alignItems: "center" }]}>
                  <TextBody>Learn about Hosting</TextBody>
                  <Entypo name="arrow-right" size={24} color={colors.amplifyNeutral80} />
                </View>
              </CustomButton>
            </View>
          )}
          {requestDetails !== null && !loading &&
            <ReviewRequestModal
              myEmail={email}
              modalVisible={reviewRequestModalOpen}
              setModalVisible={() => setReviewRequestModalOpen(!reviewRequestModalOpen)}
              requestDetails={requestDetails}
              stripeHostConnectedAccountId={stripeHostConnectedAccountId}
              unavailDays={unavailDays}
              myID={userData.sub}
            />
          }
          {bookingDetails !== null &&
            <BookingModal
              ImTheHost={true}
              modalVisible={bookingModalOpen}
              setModalVisible={() => setBookingModalOpen(!bookingModalOpen)}
              bookingDetails={bookingDetails}
            />
          }
          <ConnectedAccountInfoNeeded
            mobileView={mobileView}
            modalVisible={connectedAcountInfoNeededModalOpen}
            setModalVisible={() => setConnectedAcountInfoNeededModalOpen(!connectedAcountInfoNeededModalOpen)}
          />
        </>
      )}
      <>
      </>
    </View >
  );
};

const HostAccountScreen = () => {

  return (
    <>
      <ScrollView>
        <HostAccountScreenBody />
      </ScrollView>
    </>
  )
}

export default HostAccountScreen;
