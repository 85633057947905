import { combineReducers } from 'redux';
import hostOnboardingReducer from "./HostOnboardingReducer";
import currentUserReducer from "./CurrentUserReducer";
import conversationsReducer from "./ConversationsReducer";

export const RESET_APP = 'RESET_APP';
export const RESETAPP = (value) => (
  {
    type: RESET_APP,
  }
);

const allReducers = combineReducers({
  hostOnboarding: hostOnboardingReducer,
  currentUser: currentUserReducer,
  conversations: conversationsReducer
});

export const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    return allReducers(undefined, action)
  }
  return allReducers(state, action);
};

