
import Flex from "../CustomElements/Flex"
import Card from "../CustomElements/Card"
import { StyleSheet, Text, useWindowDimensions } from "react-native";
import { useSelector } from "react-redux";
import { CustomRadioButton } from "../CustomElements/CustomRadioButton";
import { CustomNumberInput } from "../CustomElements/CustomNumberInput";

const SelectAdditionalSpacesOption = ({
    additionalSpaces,
    setAdditionalSpaces,
    numberOfSpaces,
    newProperty,
    setNumberOfSpaces
}) => {
    const { width } = useWindowDimensions();
    const { mobileView } = useSelector(state => state.currentUser);

    return (
        <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            width={mobileView ? width / 1.1 : 500}
            marginTop={20}
            marginBottom={20}
        >
            {newProperty && (
                <Flex
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    width={mobileView ? width / 1.2 : 500}
                >
                    <CustomRadioButton
                        text="Just One Space"
                        value={!additionalSpaces}
                        updateValue={() => setAdditionalSpaces()}
                    />
                    <CustomRadioButton
                        text="More than One Space"
                        value={additionalSpaces}
                        updateValue={() => setAdditionalSpaces()}
                    />
                </Flex>
            )}
            {!!additionalSpaces && (
                <Card width={mobileView ? width / 1.2 : 400}>
                    <Flex
                        justifyContent="center"
                        alignItems="center"
                        alignSelf="center"
                        alignContent="center"
                        marginTop={10}
                        width={mobileView ? width / 1.2 : 350}
                        direction={mobileView ? "column" : "row"}
                    >
                        <Text style={{ fontSize: mobileView ? 16 : 19, textAlign: "center", marginRight: 10, marginBottom: mobileView ? 8 : 0 }}>{newProperty ? "How Many?" : "Number of Additional Spaces"}</Text>
                        <CustomNumberInput
                            value={numberOfSpaces}
                            setValue={(v) => setNumberOfSpaces(v)}
                        />
                    </Flex>
                </Card>
            )}
        </Flex>
    );
};

export default SelectAdditionalSpacesOption;

const styles = StyleSheet.create({
    inputArea: {
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "space-between",
        alignItems: "center",
    }
})