import React, { useState, useEffect, useRef } from "react";
import { Text, useWindowDimensions, StyleSheet, View } from "react-native";
import HostOnboarding from "../../components/host/HostOnboarding";
import { useDispatch, useSelector } from 'react-redux';
import { setSpaceDescription } from "../../redux/actions/HostOnboardingActions";
import ThingsInAreaInput from "../../components/host/ThingsInAreaInput";
import CustomTextInput from "../../components/CustomElements/CustomTextInput";
import { colors } from "../../styles/colors";
import Badge from "../../components/CustomElements/Badge";
import Header from "../../components/CustomElements/Header";


const DescriptionInput = ({ description, setdescription, error, width, height, mobileView }) => {

    return (
        <>
            <Header text="Description" textAlign="left" padding={5}/>
            <CustomTextInput
                width={width / 1.15}
                height={height / 3.5}
                multiline={true}
                maxLength={false}
                placeholder="ex: Cozy shaded space with all amenities"
                errorMessage="There is an error"
                value={description}
                marginBottom={4}
                onChangeText={(e) => setdescription(e)}
            />
            <Badge width={250} color={colors.orange40}><Text style={{ fontWeight: "500", fontSize: 17, marginLeft: 5, marginRight: 5, color: colors.amplifyNeutral90, textAlign: "center" }}>required</Text></Badge>
            <View>
                <Text style={styles.titleText}>Ideas on what to include: </Text>
                <Text style={styles.tipText}>&#8226; description of the space</Text>
                <Text style={styles.tipText}>&#8226; highlight special features and what sets you apart</Text>
                <Text style={styles.tipText}>&#8226; your favorite features of the property and your community</Text>
            </View>
        </>
    );
};

function AddDescriptionScreen() {
    const { width, height } = useWindowDimensions();
    const dispatch = useDispatch();
    const scrollRef = useRef(null);
    const { newProperty } = useSelector(state => state.hostOnboarding)
    const [disabled, setDisabled] = useState(true);
    const [disabled2, setDisabled2] = useState(false);
    const [description, setdescription] = useState(useSelector(state => state.hostOnboarding.description) || '');
    const [thingsInArea, setThingsInArea] = useState(useSelector(state => state.hostOnboarding.thingsInArea) || '');
    const [error, setError] = useState(false);
    const { mobileView } = useSelector(state => state.currentUser);

    useEffect(() => {
        if (!description) {
            setDisabled(true)
        } else {
            setDisabled(false);
            setError(false);
        }
    }, [description.length]);

    const saveDescription = () => {
        dispatch(setSpaceDescription({
            description: description,
            thingsInArea: thingsInArea
        }));
    };

    return (
        <HostOnboarding
            heading="Space Description"
            navigateF={"SetDimensions"}
            navigateB="PhotoUpload"
            disabledNextButton={disabled || disabled2}
            nextButtonText="Next"
            onClickNext={() => saveDescription()}
            required="Description"
        >
            <View ref={scrollRef}>
                <DescriptionInput
                    description={description}
                    setdescription={(v) => setdescription(v)}
                    error={error}
                    height={height}
                    width={width}
                    mobileView={mobileView}
                />
                <View style={{ marginTop: 25 }}>
                    <ThingsInAreaInput
                        thingsInArea={thingsInArea}
                        setThingsInArea={(v) => setThingsInArea(v)}
                        width={width}
                        height={height}
                        mobileView={mobileView}
                    />
                </View>
            </View>
        </HostOnboarding>
    )
};

export default AddDescriptionScreen;


const styles = StyleSheet.create({
    tipText: {
        fontStyle: "italic",
        marginLeft: 10
    },
    titleText: {
        fontStyle: "italic",
        marginTop: 15
    },
});