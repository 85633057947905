/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      email
      phoneNumber
      rig {
        items {
          id
          ownerID
          image
          length
          width
          year
          make
          model
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      avatar
      identityVerificationStatus
      identityVerificationAccessCode
      about
      myHostedSpaces {
        items {
          id
          propertyID
          ownerID
          bookings {
            nextToken
            __typename
          }
          bookingRequests {
            nextToken
            __typename
          }
          space_approved
          active
          title
          description
          unavailable_days
          set_availability
          longest_stay_allowed
          shortest_stay_allowed
          width
          length
          electric_hookup
          water_hookup
          sewage_hookup
          wifi_available
          wifi_password
          wifi_network_name
          shower_available
          restroom_available
          food_nearby
          restaurant_nearby
          water_accessible
          easy_entry
          pre_booking_message_required
          pre_booking_message
          instant_book
          distance_electric_hookup
          distance_water_hookup
          distance_sewage_hookup
          parking_spaces
          additional_rules
          parties_allowed
          things_to_do
          images
          laundry_available
          pool_available
          hottub_available
          outdoor_area_available
          indoor_area_available
          trash_available
          extra_amenities_available
          host_acknowledge_liability
          host_acknowledge_tax_responsibility
          check_in_time
          check_out_time
          check_in_instructions
          space_rules
          pets_allowed
          smoking_allowed
          adults_only
          quiet_hours_start
          quiet_hours_end
          outdoor_fires
          profile_photo_required
          full_refund_date
          half_refund_date
          price_storage
          price_shortterm
          price_longterm
          price_yearly
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      myHostedProperties {
        items {
          id
          active
          ownerID
          spaces {
            nextToken
            __typename
          }
          title
          description
          address {
            id
            address
            cityName
            stateName
            zipcode
            countryName
            canadianCivicAddress
            _version
            createdAt
            updatedAt
            owner
            __typename
          }
          coordinates_lat
          coordinates_lng
          type_storage
          type_longterm
          type_shortterm
          type_yearly
          price_storage
          price_shortterm
          price_longterm
          price_yearly
          instant_book
          images
          reviews {
            nextToken
            __typename
          }
          conversations {
            nextToken
            __typename
          }
          _version
          createdAt
          updatedAt
          propertyAddressId
          __typename
        }
        nextToken
        __typename
      }
      favorites
      stripePayoutsEnabled
      stripeConnectedAccountId
      stripeCustomerID
      stripePaymentMethodID
      govID_verified
      bookings {
        items {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        nextToken
        __typename
      }
      bookingRequests {
        items {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        nextToken
        __typename
      }
      bookingsHosted {
        items {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        nextToken
        __typename
      }
      reviewsOfRenter {
        items {
          id
          rating
          cleanlinessRating
          communicationRating
          overallRating
          description
          renterID
          reviewerName
          reviewerAvatar
          reviewerID
          bookingID
          propertyID
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      hosteeConvos {
        items {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        nextToken
        __typename
      }
      hostConvos {
        items {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      _version
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        phoneNumber
        rig {
          items {
            id
            ownerID
            image
            length
            width
            year
            make
            model
            _version
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        avatar
        about
        myHostedSpaces {
          items {
            id
            propertyID
            ownerID
            space_approved
            active
            title
            description
            unavailable_days
            set_availability
            longest_stay_allowed
            shortest_stay_allowed
            width
            length
            electric_hookup
            water_hookup
            sewage_hookup
            wifi_available
            wifi_password
            wifi_network_name
            shower_available
            restroom_available
            food_nearby
            restaurant_nearby
            water_accessible
            easy_entry
            pre_booking_message_required
            pre_booking_message
            instant_book
            distance_electric_hookup
            distance_water_hookup
            distance_sewage_hookup
            parking_spaces
            additional_rules
            parties_allowed
            things_to_do
            images
            laundry_available
            pool_available
            hottub_available
            outdoor_area_available
            indoor_area_available
            trash_available
            extra_amenities_available
            host_acknowledge_liability
            host_acknowledge_tax_responsibility
            check_in_time
            check_out_time
            check_in_instructions
            space_rules
            pets_allowed
            smoking_allowed
            adults_only
            quiet_hours_start
            quiet_hours_end
            outdoor_fires
            profile_photo_required
            full_refund_date
            half_refund_date
            price_storage
            price_shortterm
            price_longterm
            price_yearly
            _version
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        myHostedProperties {
          items {
            id
            active
            ownerID
            title
            description
            coordinates_lat
            coordinates_lng
            type_storage
            type_longterm
            type_shortterm
            type_yearly
            price_storage
            price_shortterm
            price_longterm
            price_yearly
            instant_book
            images
            _version
            createdAt
            updatedAt
            propertyAddressId
            __typename
          }
          nextToken
          __typename
        }
        favorites
        stripePayoutsEnabled
        stripeConnectedAccountId
        stripeCustomerID
        stripePaymentMethodID
        govID_verified
        bookings {
          items {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          nextToken
          __typename
        }
        bookingRequests {
          items {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          nextToken
          __typename
        }
        bookingsHosted {
          items {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          nextToken
          __typename
        }
        reviewsOfRenter {
          items {
            id
            rating
            cleanlinessRating
            communicationRating
            overallRating
            description
            renterID
            reviewerName
            reviewerAvatar
            reviewerID
            bookingID
            propertyID
            _version
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        hosteeConvos {
          items {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          nextToken
          __typename
        }
        hostConvos {
          items {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          nextToken
          __typename
        }
        payments {
          items {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        _version
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRig = /* GraphQL */ `
  query GetRig($id: ID!) {
    getRig(id: $id) {
      id
      ownerID
      image
      length
      width
      year
      make
      model
      _version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRigs = /* GraphQL */ `
  query ListRigs(
    $filter: ModelRigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerID
        image
        length
        width
        year
        make
        model
        _version
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const rigsByOwnerIDAndId = /* GraphQL */ `
  query RigsByOwnerIDAndId(
    $ownerID: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rigsByOwnerIDAndId(
      ownerID: $ownerID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerID
        image
        length
        width
        year
        make
        model
        _version
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSpace = /* GraphQL */ `
  query GetSpace($id: ID!) {
    getSpace(id: $id) {
      id
      propertyID
      ownerID
      bookings {
        items {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          paymentID
          shade_cover
          amazing_view
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        nextToken
        __typename
      }
      bookingRequests {
        items {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        nextToken
        __typename
      }
      space_approved
      active
      title
      description
      unavailable_days
      set_availability
      longest_stay_allowed
      shortest_stay_allowed
      width
      length
      electric_hookup
      water_hookup
      sewage_hookup
      wifi_available
      wifi_password
      wifi_network_name
      shower_available
      restroom_available
      food_nearby
      restaurant_nearby
      water_accessible
      easy_entry
      pre_booking_message_required
      pre_booking_message
      instant_book
      distance_electric_hookup
      distance_water_hookup
      distance_sewage_hookup
      parking_spaces
      additional_rules
      parties_allowed
      things_to_do
      images
      laundry_available
      pool_available
      hottub_available
      outdoor_area_available
      indoor_area_available
      trash_available
      extra_amenities_available
      host_acknowledge_liability
      host_acknowledge_tax_responsibility
      check_in_time
      check_out_time
      check_in_instructions
      space_rules
      pets_allowed
      smoking_allowed
      adults_only
      quiet_hours_start
      quiet_hours_end
      outdoor_fires
      profile_photo_required
      full_refund_date
      half_refund_date
      price_storage
      price_shortterm
      price_longterm
      price_yearly
      _version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSpaces = /* GraphQL */ `
  query ListSpaces(
    $filter: ModelSpaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpaces(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        propertyID
        ownerID
        shade_cover
        amazing_view
        bookings {
          items {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          nextToken
          __typename
        }
        bookingRequests {
          items {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          nextToken
          __typename
        }
        space_approved
        active
        title
        description
        unavailable_days
        set_availability
        longest_stay_allowed
        shortest_stay_allowed
        width
        length
        electric_hookup
        water_hookup
        sewage_hookup
        wifi_available
        wifi_password
        wifi_network_name
        shower_available
        restroom_available
        food_nearby
        restaurant_nearby
        water_accessible
        easy_entry
        pre_booking_message_required
        pre_booking_message
        instant_book
        distance_electric_hookup
        distance_water_hookup
        distance_sewage_hookup
        parking_spaces
        additional_rules
        parties_allowed
        things_to_do
        images
        laundry_available
        pool_available
        hottub_available
        outdoor_area_available
        indoor_area_available
        trash_available
        extra_amenities_available
        host_acknowledge_liability
        host_acknowledge_tax_responsibility
        check_in_time
        check_out_time
        check_in_instructions
        space_rules
        pets_allowed
        smoking_allowed
        adults_only
        quiet_hours_start
        quiet_hours_end
        outdoor_fires
        profile_photo_required
        full_refund_date
        half_refund_date
        price_storage
        price_shortterm
        price_longterm
        price_yearly
        _version
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const spacesByPropertyIDAndId = /* GraphQL */ `
  query SpacesByPropertyIDAndId(
    $propertyID: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spacesByPropertyIDAndId(
      propertyID: $propertyID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        propertyID
        ownerID
        bookings {
          items {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          nextToken
          __typename
        }
        bookingRequests {
          items {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          nextToken
          __typename
        }
        space_approved
        active
        title
        description
        unavailable_days
        set_availability
        longest_stay_allowed
        shortest_stay_allowed
        width
        length
        electric_hookup
        water_hookup
        sewage_hookup
        wifi_available
        wifi_password
        wifi_network_name
        shower_available
        restroom_available
        food_nearby
        restaurant_nearby
        water_accessible
        easy_entry
        pre_booking_message_required
        pre_booking_message
        instant_book
        distance_electric_hookup
        distance_water_hookup
        distance_sewage_hookup
        parking_spaces
        additional_rules
        parties_allowed
        things_to_do
        images
        laundry_available
        pool_available
        hottub_available
        outdoor_area_available
        indoor_area_available
        trash_available
        extra_amenities_available
        host_acknowledge_liability
        host_acknowledge_tax_responsibility
        check_in_time
        check_out_time
        check_in_instructions
        space_rules
        pets_allowed
        smoking_allowed
        adults_only
        quiet_hours_start
        quiet_hours_end
        outdoor_fires
        profile_photo_required
        full_refund_date
        half_refund_date
        price_storage
        price_shortterm
        price_longterm
        price_yearly
        _version
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const spacesByOwnerIDAndId = /* GraphQL */ `
  query SpacesByOwnerIDAndId(
    $ownerID: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spacesByOwnerIDAndId(
      ownerID: $ownerID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        propertyID
        ownerID
        bookings {
          items {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          nextToken
          __typename
        }
        bookingRequests {
          items {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          nextToken
          __typename
        }
        space_approved
        active
        title
        description
        unavailable_days
        set_availability
        longest_stay_allowed
        shortest_stay_allowed
        width
        length
        electric_hookup
        water_hookup
        sewage_hookup
        wifi_available
        wifi_password
        wifi_network_name
        shower_available
        restroom_available
        food_nearby
        restaurant_nearby
        water_accessible
        easy_entry
        pre_booking_message_required
        pre_booking_message
        instant_book
        distance_electric_hookup
        distance_water_hookup
        distance_sewage_hookup
        parking_spaces
        additional_rules
        parties_allowed
        things_to_do
        images
        laundry_available
        pool_available
        hottub_available
        outdoor_area_available
        indoor_area_available
        trash_available
        extra_amenities_available
        host_acknowledge_liability
        host_acknowledge_tax_responsibility
        check_in_time
        check_out_time
        check_in_instructions
        space_rules
        pets_allowed
        smoking_allowed
        adults_only
        quiet_hours_start
        quiet_hours_end
        outdoor_fires
        profile_photo_required
        full_refund_date
        half_refund_date
        price_storage
        price_shortterm
        price_longterm
        price_yearly
        _version
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProperty = /* GraphQL */ `
  query GetProperty($id: ID!) {
    getProperty(id: $id) {
      id
      active
      ownerID
      spaces {
        items {
          id
          propertyID
          ownerID
          bookings {
        items {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        nextToken
        __typename
      }

      bookingRequests {
        items {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        nextToken
        __typename
      }
          space_approved
          active
          title
          description
          unavailable_days
          set_availability
          longest_stay_allowed
          shortest_stay_allowed
          width
          length
          electric_hookup
          water_hookup
          sewage_hookup
          wifi_available
          wifi_password
          wifi_network_name
          shower_available
          restroom_available
          food_nearby
          restaurant_nearby
          water_accessible
          easy_entry
          pre_booking_message_required
          pre_booking_message
          instant_book
          distance_electric_hookup
          distance_water_hookup
          distance_sewage_hookup
          parking_spaces
          additional_rules
          parties_allowed
          things_to_do
          images
          laundry_available
          pool_available
          hottub_available
          outdoor_area_available
          indoor_area_available
          trash_available
          extra_amenities_available
          host_acknowledge_liability
          host_acknowledge_tax_responsibility
          check_in_time
          check_out_time
          check_in_instructions
          space_rules
          pets_allowed
          smoking_allowed
          adults_only
          quiet_hours_start
          quiet_hours_end
          outdoor_fires
          profile_photo_required
          full_refund_date
          half_refund_date
          price_storage
          price_shortterm
          price_longterm
          price_yearly
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      title
      description
      address {
        id
        address
        cityName
        stateName
        zipcode
        countryName
        canadianCivicAddress
        _version
        createdAt
        updatedAt
        owner
        __typename
      }
      coordinates_lat
      coordinates_lng
      type_storage
      type_longterm
      type_shortterm
      type_yearly
      price_storage
      price_shortterm
      price_longterm
      price_yearly
      instant_book
      images
      reviews {
        items {
          id
          rating
          locationRating
          communicationRating
          checkinRating
          valueRating
          description
          propertyID
          reviewerName
          reviewerAvatar
          reviewerID
          bookingID
          images
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      conversations {
        items {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        nextToken
        __typename
      }
      _version
      createdAt
      updatedAt
      propertyAddressId
      __typename
    }
  }
`;
export const listProperties = /* GraphQL */ `
  query ListProperties(
    $filter: ModelPropertyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProperties(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        active
        ownerID
        internalRating
        type
        spaces {
          items {
            id
            propertyID
            shade_cover
            amazing_view
            ownerID
            space_approved
            active
            title
            description
            unavailable_days
            set_availability
            longest_stay_allowed
            shortest_stay_allowed
            width
            length
            electric_hookup
            water_hookup
            sewage_hookup
            wifi_available
            wifi_password
            wifi_network_name
            shower_available
            restroom_available
            food_nearby
            restaurant_nearby
            water_accessible
            easy_entry
            pre_booking_message_required
            pre_booking_message
            instant_book
            distance_electric_hookup
            distance_water_hookup
            distance_sewage_hookup
            parking_spaces
            additional_rules
            parties_allowed
            things_to_do
            images
            laundry_available
            pool_available
            hottub_available
            outdoor_area_available
            indoor_area_available
            trash_available
            extra_amenities_available
            host_acknowledge_liability
            host_acknowledge_tax_responsibility
            check_in_time
            check_out_time
            check_in_instructions
            space_rules
            pets_allowed
            smoking_allowed
            adults_only
            quiet_hours_start
            quiet_hours_end
            outdoor_fires
            profile_photo_required
            full_refund_date
            half_refund_date
            price_storage
            price_shortterm
            price_longterm
            price_yearly
            _version
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        title
        description
        address {
          id
          address
          cityName
          stateName
          zipcode
          countryName
          canadianCivicAddress
          _version
          createdAt
          updatedAt
          owner
          __typename
        }
        coordinates_lat
        coordinates_lng
        type_storage
        type_longterm
        type_shortterm
        type_yearly
        price_storage
        price_shortterm
        price_longterm
        price_yearly
        instant_book
        images
        reviews {
          items {
            id
            rating
            locationRating
            communicationRating
            checkinRating
            valueRating
            description
            propertyID
            reviewerName
            reviewerAvatar
            reviewerID
            bookingID
            images
            _version
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        conversations {
          items {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          nextToken
          __typename
        }
        _version
        createdAt
        updatedAt
        propertyAddressId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
/* LIST PROPERTIES QUERIES  */
export const listPropertiesByInternalRating = /* GraphQL */ `
  query PropertyByInternalRating(
    $type: String!
    $internalRating: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPropertyFilterInput
    $filterSpace: ModelSpaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    propertyByInternalRating(
      type: $type
      internalRating: $internalRating
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        approved
        ownerID
        internalRating
        type
        spaces ( filter: $filterSpace ) {
          items {
            id
            propertyID
            ownerID
            shade_cover
            amazing_view
            space_approved
            active
            title
            description
            unavailable_days
            longest_stay_allowed
            shortest_stay_allowed
            width
            length
            electric_hookup
            water_hookup
            sewage_hookup
            wifi_available
            wifi_password
            wifi_network_name
            shower_available
            restroom_available
            food_nearby
            restaurant_nearby
            water_accessible
            easy_entry
            pre_booking_message_required
            pre_booking_message
            instant_book
            distance_electric_hookup
            distance_water_hookup
            distance_sewage_hookup
            parking_spaces
            additional_rules
            parties_allowed
            things_to_do
            images
            laundry_available
            pool_available
            hottub_available
            outdoor_area_available
            indoor_area_available
            trash_available
            extra_amenities_available
            check_in_time
            check_out_time
            space_rules
            pets_allowed
            smoking_allowed
            adults_only
            quiet_hours_start
            quiet_hours_end
            outdoor_fires
            profile_photo_required
            full_refund_date
            half_refund_date
            price_storage
            price_shortterm
            price_longterm
            price_yearly
            _version
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        title
        description
        address {
          id
          address
          cityName
          stateName
          zipcode
          countryName
          canadianCivicAddress
          _version
          createdAt
          updatedAt
          owner
          __typename
        }
        coordinates_lat
        coordinates_lng
        type_storage
        type_longterm
        type_shortterm
        type_yearly
        price_storage
        price_shortterm
        price_longterm
        price_yearly
        instant_book
        images
        reviews {
          items {
            id
            rating
            locationRating
            communicationRating
            checkinRating
            valueRating
            description
            propertyID
            reviewerName
            reviewerAvatar
            reviewerID
            bookingID
            images
            _version
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        _version
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAddress = /* GraphQL */ `
  query GetAddress($id: ID!) {
    getAddress(id: $id) {
      id
      address
      cityName
      stateName
      zipcode
      countryName
      canadianCivicAddress
      _version
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listAddresses = /* GraphQL */ `
  query ListAddresses(
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddresses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        address
        cityName
        stateName
        zipcode
        countryName
        canadianCivicAddress
        _version
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBooking = /* GraphQL */ `
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      members
      cancelled
      cancelledTooLate
      cancelledTooLatePayout
      cancelledDate
      spaceID
      renterID
      hostID
      bookingRequestId
      bookingRequest {
        id
        members
        confirmed
        reviewed
        cancelled
        rentalType
        stripePaymentMethodID
        stripeCustomerID
        stripePaymentIntentID
        check_in
        check_out
        bookedDays
        spaceID
        spaceTitle
        spaceCity
        spaceState
        propertyTitle
        propertyID
        renterID
        bookingID
        hostID
        hostEmail
        renterEmail
        conversation {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        createdAt
        updatedAt
        renter_acknowledge_liability
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        payMonthly
        numberOfMonths
        paymentSchedule
        nextPaymentDue
        nextPaymentsSchedule
        monthlyPaymentAmount
        monthlyHookhubPayout
        monthlyHostPayout
        full_refund_enabled
        no_refund_enabled
        half_refund_enabled
        full_refund_date
        half_refund_date
        errorCode
        _version
        bookingRequestConversationId
        __typename
      }
      paymentID
      bookedDays
      check_in
      check_out
      stripePaymentMethodID
      stripeCustomerID
      stripeHostConnectedAccountId
      hostEmail
      renterEmail
      createdAt
      updatedAt
      baseBookingCost
      renterFeeCost
      renterTotalBookingCost
      renterStripeFee
      hostFeeCost
      hostPayout
      hookhubPayout
      paymentRequiresAction
      payMonthly
      numberOfMonths
      paymentSchedule
      nextPaymentsSchedule
      nextPaymentDue
      monthlyPaymentAmount
      monthlyHostPayout
      monthlyHookhubPayout
      cancelledFinalHostPayout
      cancelledFinalRenterPayment
      cancelledBeforeCheckIn
      refundRenter
      full_refund_enabled
      no_refund_enabled
      half_refund_enabled
      full_refund_date
      half_refund_date
      stripePaymentIntentID
      _version
      __typename
    }
  }
`;
export const listBookings = /* GraphQL */ `
  query ListBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        members
        cancelled
        cancelledTooLate
        cancelledTooLatePayout
        cancelledDate
        spaceID
        renterID
        hostID
        bookingRequestId
        bookingRequest {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        paymentID
        bookedDays
        check_in
        check_out
        stripePaymentMethodID
        stripeCustomerID
        stripeHostConnectedAccountId
        hostEmail
        renterEmail
        createdAt
        updatedAt
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        paymentRequiresAction
        payMonthly
        numberOfMonths
        paymentSchedule
        nextPaymentsSchedule
        nextPaymentDue
        monthlyPaymentAmount
        monthlyHostPayout
        monthlyHookhubPayout
        cancelledFinalHostPayout
        cancelledFinalRenterPayment
        cancelledBeforeCheckIn
        refundRenter
        full_refund_enabled
        no_refund_enabled
        half_refund_enabled
        full_refund_date
        half_refund_date
        stripePaymentIntentID
        _version
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const bookingsBySpaceIDAndId = /* GraphQL */ `
  query BookingsBySpaceIDAndId(
    $spaceID: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingsBySpaceIDAndId(
      spaceID: $spaceID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        members
        cancelled
        cancelledTooLate
        cancelledTooLatePayout
        cancelledDate
        spaceID
        renterID
        hostID
        bookingRequestId
        bookingRequest {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        paymentID
        bookedDays
        check_in
        check_out
        stripePaymentMethodID
        stripeCustomerID
        stripeHostConnectedAccountId
        hostEmail
        renterEmail
        createdAt
        updatedAt
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        paymentRequiresAction
        payMonthly
        numberOfMonths
        paymentSchedule
        nextPaymentsSchedule
        nextPaymentDue
        monthlyPaymentAmount
        monthlyHostPayout
        monthlyHookhubPayout
        cancelledFinalHostPayout
        cancelledFinalRenterPayment
        cancelledBeforeCheckIn
        refundRenter
        full_refund_enabled
        no_refund_enabled
        half_refund_enabled
        full_refund_date
        half_refund_date
        stripePaymentIntentID
        _version
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const bookingsByRenterIDAndId = /* GraphQL */ `
  query BookingsByRenterIDAndId(
    $renterID: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingsByRenterIDAndId(
      renterID: $renterID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        members
        cancelled
        cancelledTooLate
        cancelledTooLatePayout
        cancelledDate
        spaceID
        renterID
        hostID
        bookingRequestId
        bookingRequest {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        paymentID
        bookedDays
        check_in
        check_out
        stripePaymentMethodID
        stripeCustomerID
        stripeHostConnectedAccountId
        hostEmail
        renterEmail
        createdAt
        updatedAt
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        paymentRequiresAction
        payMonthly
        numberOfMonths
        paymentSchedule
        nextPaymentsSchedule
        nextPaymentDue
        monthlyPaymentAmount
        monthlyHostPayout
        monthlyHookhubPayout
        cancelledFinalHostPayout
        cancelledFinalRenterPayment
        cancelledBeforeCheckIn
        refundRenter
        full_refund_enabled
        no_refund_enabled
        half_refund_enabled
        full_refund_date
        half_refund_date
        stripePaymentIntentID
        _version
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const bookingsByHostIDAndId = /* GraphQL */ `
  query BookingsByHostIDAndId(
    $hostID: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingsByHostIDAndId(
      hostID: $hostID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        members
        cancelled
        cancelledTooLate
        cancelledTooLatePayout
        cancelledDate
        spaceID
        renterID
        hostID
        bookingRequestId
        bookingRequest {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        paymentID
        payment {
          id
          members
          date
          stripePaymentMethodID
          stripeCustomerID
          paid
          hostID
          bookingId
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          createdAt
          updatedAt
          _version
          __typename
        }
        bookedDays
        check_in
        check_out
        stripePaymentMethodID
        stripeCustomerID
        stripeHostConnectedAccountId
        hostEmail
        renterEmail
        createdAt
        updatedAt
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        paymentRequiresAction
        payMonthly
        numberOfMonths
        paymentSchedule
        nextPaymentsSchedule
        nextPaymentDue
        monthlyPaymentAmount
        monthlyHostPayout
        monthlyHookhubPayout
        cancelledFinalHostPayout
        cancelledFinalRenterPayment
        cancelledBeforeCheckIn
        refundRenter
        full_refund_enabled
        no_refund_enabled
        half_refund_enabled
        full_refund_date
        half_refund_date
        stripePaymentIntentID
        _version
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBookingRequest = /* GraphQL */ `
  query GetBookingRequest($id: ID!) {
    getBookingRequest(id: $id) {
      id
      members
      confirmed
      reviewed
      cancelled
      rentalType
      stripePaymentMethodID
      stripeCustomerID
      stripePaymentIntentID
      stripeHostConnectedAccountId
      check_in
      check_out
      bookedDays
      spaceID
      spaceTitle
      spaceCity
      spaceState
      propertyTitle
      propertyID
      renterID
      bookingID
      booking {
        id
        members
        cancelled
        cancelledTooLate
        cancelledTooLatePayout
        cancelledDate
        spaceID
        renterID
        hostID
        paymentID
        bookedDays
        check_in
        check_out
        stripePaymentMethodID
        stripeCustomerID
        stripeHostConnectedAccountId
        hostEmail
        renterEmail
        createdAt
        updatedAt
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        paymentRequiresAction
        payMonthly
        numberOfMonths
        paymentSchedule
        nextPaymentsSchedule
        nextPaymentDue
        monthlyPaymentAmount
        monthlyHostPayout
        monthlyHookhubPayout
        cancelledFinalHostPayout
        cancelledFinalRenterPayment
        cancelledBeforeCheckIn
        refundRenter
        full_refund_enabled
        no_refund_enabled
        half_refund_enabled
        full_refund_date
        half_refund_date
        stripePaymentIntentID
        _version
        __typename
      }
      hostID
      hostEmail
      renterEmail
      createdAt
      updatedAt
      renter_acknowledge_liability
      baseBookingCost
      renterFeeCost
      renterTotalBookingCost
      renterStripeFee
      hostFeeCost
      hostPayout
      hookhubPayout
      payMonthly
      numberOfMonths
      paymentSchedule
      nextPaymentDue
      nextPaymentsSchedule
      monthlyPaymentAmount
      monthlyHookhubPayout
      monthlyHostPayout
      full_refund_enabled
      no_refund_enabled
      half_refund_enabled
      full_refund_date
      half_refund_date
      errorCode
      _version
      bookingRequestConversationId
      __typename
    }
  }
`;
export const listBookingRequests = /* GraphQL */ `
  query ListBookingRequests(
    $filter: ModelBookingRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookingRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        members
        confirmed
        reviewed
        cancelled
        rentalType
        stripePaymentMethodID
        stripeCustomerID
        stripePaymentIntentID
        stripeHostConnectedAccountId
        check_in
        check_out
        bookedDays
        spaceID
        spaceTitle
        spaceCity
        spaceState
        propertyTitle
        propertyID
        renterID
        bookingID
        booking {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        hostID
        hostEmail
        renterEmail
        conversation {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        createdAt
        updatedAt
        renter_acknowledge_liability
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        payMonthly
        numberOfMonths
        paymentSchedule
        nextPaymentDue
        nextPaymentsSchedule
        monthlyPaymentAmount
        monthlyHookhubPayout
        monthlyHostPayout
        full_refund_enabled
        no_refund_enabled
        half_refund_enabled
        full_refund_date
        half_refund_date
        errorCode
        _version
        bookingRequestConversationId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const bookingRequestsBySpaceIDAndId = /* GraphQL */ `
  query BookingRequestsBySpaceIDAndId(
    $spaceID: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingRequestsBySpaceIDAndId(
      spaceID: $spaceID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        members
        confirmed
        reviewed
        cancelled
        rentalType
        stripePaymentMethodID
        stripeCustomerID
        stripePaymentIntentID
        stripeHostConnectedAccountId
        check_in
        check_out
        bookedDays
        spaceID
        spaceTitle
        spaceCity
        spaceState
        propertyTitle
        propertyID
        renterID
        bookingID
        booking {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        hostID
        hostEmail
        renterEmail
        conversation {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        createdAt
        updatedAt
        renter_acknowledge_liability
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        payMonthly
        numberOfMonths
        paymentSchedule
        nextPaymentDue
        nextPaymentsSchedule
        monthlyPaymentAmount
        monthlyHookhubPayout
        monthlyHostPayout
        full_refund_enabled
        no_refund_enabled
        half_refund_enabled
        full_refund_date
        half_refund_date
        errorCode
        _version
        bookingRequestConversationId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const bookingRequestsByRenterIDAndId = /* GraphQL */ `
  query BookingRequestsByRenterIDAndId(
    $renterID: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingRequestsByRenterIDAndId(
      renterID: $renterID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        members
        confirmed
        reviewed
        cancelled
        rentalType
        stripePaymentMethodID
        stripeCustomerID
        stripePaymentIntentID
        stripeHostConnectedAccountId
        check_in
        check_out
        bookedDays
        spaceID
        spaceTitle
        spaceCity
        spaceState
        propertyTitle
        propertyID
        renterID
        bookingID
        booking {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        hostID
        hostEmail
        renterEmail
        conversation {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        createdAt
        updatedAt
        renter_acknowledge_liability
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        payMonthly
        numberOfMonths
        paymentSchedule
        nextPaymentDue
        nextPaymentsSchedule
        monthlyPaymentAmount
        monthlyHookhubPayout
        monthlyHostPayout
        full_refund_enabled
        no_refund_enabled
        half_refund_enabled
        full_refund_date
        half_refund_date
        errorCode
        _version
        bookingRequestConversationId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const bookingRequestsByHostIDAndId = /* GraphQL */ `
  query BookingRequestsByHostIDAndId(
    $hostID: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingRequestsByHostIDAndId(
      hostID: $hostID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        members
        confirmed
        reviewed
        cancelled
        rentalType
        stripePaymentMethodID
        stripeCustomerID
        stripePaymentIntentID
        stripeHostConnectedAccountId
        check_in
        check_out
        bookedDays
        spaceID
        spaceTitle
        spaceCity
        spaceState
        propertyTitle
        propertyID
        renterID
        bookingID
        booking {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        hostID
        hostEmail
        renterEmail
        conversation {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        createdAt
        updatedAt
        renter_acknowledge_liability
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        payMonthly
        numberOfMonths
        paymentSchedule
        nextPaymentDue
        nextPaymentsSchedule
        monthlyPaymentAmount
        monthlyHookhubPayout
        monthlyHostPayout
        full_refund_enabled
        no_refund_enabled
        half_refund_enabled
        full_refund_date
        half_refund_date
        errorCode
        _version
        bookingRequestConversationId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      members
      date
      stripePaymentMethodID
      stripeCustomerID
      paid
      hostID
      bookingId
      booking {
        id
        members
        cancelled
        cancelledTooLate
        cancelledTooLatePayout
        cancelledDate
        spaceID
        renterID
        hostID
        bookingRequestId
        bookingRequest {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        paymentID
        payment {
          id
          members
          date
          stripePaymentMethodID
          stripeCustomerID
          paid
          hostID
          bookingId
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          createdAt
          updatedAt
          _version
          __typename
        }
        bookedDays
        check_in
        check_out
        stripePaymentMethodID
        stripeCustomerID
        stripeHostConnectedAccountId
        hostEmail
        renterEmail
        createdAt
        updatedAt
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        paymentRequiresAction
        payMonthly
        numberOfMonths
        paymentSchedule
        nextPaymentsSchedule
        nextPaymentDue
        monthlyPaymentAmount
        monthlyHostPayout
        monthlyHookhubPayout
        cancelledFinalHostPayout
        cancelledFinalRenterPayment
        cancelledBeforeCheckIn
        refundRenter
        full_refund_enabled
        no_refund_enabled
        half_refund_enabled
        full_refund_date
        half_refund_date
        stripePaymentIntentID
        _version
        __typename
      }
      baseBookingCost
      renterFeeCost
      renterTotalBookingCost
      renterStripeFee
      hostFeeCost
      hostPayout
      hookhubPayout
      createdAt
      updatedAt
      _version
      __typename
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        members
        date
        stripePaymentMethodID
        stripeCustomerID
        paid
        hostID
        bookingId
        booking {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        createdAt
        updatedAt
        _version
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const paymentsByHostIDAndId = /* GraphQL */ `
  query PaymentsByHostIDAndId(
    $hostID: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paymentsByHostIDAndId(
      hostID: $hostID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        members
        date
        stripePaymentMethodID
        stripeCustomerID
        paid
        hostID
        bookingId
        booking {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        createdAt
        updatedAt
        _version
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      id
      rating
      locationRating
      communicationRating
      checkinRating
      valueRating
      description
      propertyID
      reviewerName
      reviewerAvatar
      reviewerID
      bookingID
      images
      _version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        rating
        locationRating
        communicationRating
        checkinRating
        valueRating
        description
        propertyID
        reviewerName
        reviewerAvatar
        reviewerID
        bookingID
        images
        _version
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const reviewsByPropertyIDAndId = /* GraphQL */ `
  query ReviewsByPropertyIDAndId(
    $propertyID: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reviewsByPropertyIDAndId(
      propertyID: $propertyID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rating
        locationRating
        communicationRating
        checkinRating
        valueRating
        description
        propertyID
        reviewerName
        reviewerAvatar
        reviewerID
        bookingID
        images
        _version
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRenterReview = /* GraphQL */ `
  query GetRenterReview($id: ID!) {
    getRenterReview(id: $id) {
      id
      rating
      cleanlinessRating
      communicationRating
      overallRating
      description
      renterID
      reviewerName
      reviewerAvatar
      reviewerID
      bookingID
      propertyID
      _version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRenterReviews = /* GraphQL */ `
  query ListRenterReviews(
    $filter: ModelRenterReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRenterReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        rating
        cleanlinessRating
        communicationRating
        overallRating
        description
        renterID
        reviewerName
        reviewerAvatar
        reviewerID
        bookingID
        propertyID
        _version
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const renterReviewsByRenterIDAndId = /* GraphQL */ `
  query RenterReviewsByRenterIDAndId(
    $renterID: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRenterReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    renterReviewsByRenterIDAndId(
      renterID: $renterID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rating
        cleanlinessRating
        communicationRating
        overallRating
        description
        renterID
        reviewerName
        reviewerAvatar
        reviewerID
        bookingID
        propertyID
        _version
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConversation = /* GraphQL */ `
  query GetConversation($id: ID!) {
    getConversation(id: $id) {
      id
      hostee
      host
      convoCreatorsEmail
      otherMembersEmail
      spaceID
      spaceTitle
      propertyID
      propertyTitle
      propertyLocation
      bookingRequestID
      bookingRequest {
        id
        members
        confirmed
        reviewed
        cancelled
        rentalType
        stripePaymentMethodID
        stripeCustomerID
        stripePaymentIntentID
        check_in
        check_out
        bookedDays
        spaceID
        spaceTitle
        spaceCity
        spaceState
        propertyTitle
        propertyID
        renterID
        bookingID
        booking {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        hostID
        hostEmail
        renterEmail
        conversation {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        createdAt
        updatedAt
        renter_acknowledge_liability
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        payMonthly
        numberOfMonths
        paymentSchedule
        nextPaymentDue
        nextPaymentsSchedule
        monthlyPaymentAmount
        monthlyHookhubPayout
        monthlyHostPayout
        full_refund_enabled
        no_refund_enabled
        half_refund_enabled
        full_refund_date
        half_refund_date
        errorCode
        _version
        bookingRequestConversationId
        __typename
      }
      messages {
        items {
          id
          authorID
          authorName
          authorAvatar
          otherMembersEmail
          content
          convoID
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          type
          createdAt
          updatedAt
          _version
          owner
          __typename
        }
        nextToken
        __typename
      }
      members
      membersNames
      unreadHost
      unreadHostee
      type
      createdAt
      updatedAt
      _version
      __typename
    }
  }
`;
export const listConversations = /* GraphQL */ `
  query ListConversations(
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConversations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        hostee
        host
        convoCreatorsEmail
        otherMembersEmail
        spaceID
        spaceTitle
        propertyID
        propertyTitle
        propertyLocation
        bookingRequestID
        bookingRequest {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        messages {
          items {
            id
            authorID
            authorName
            authorAvatar
            otherMembersEmail
            content
            convoID
            type
            createdAt
            updatedAt
            _version
            owner
            __typename
          }
          nextToken
          __typename
        }
        members
        membersNames
        unreadHost
        unreadHostee
        type
        createdAt
        updatedAt
        _version
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const conversationsByHostee = /* GraphQL */ `
  query ConversationsByHostee(
    $hostee: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conversationsByHostee(
      hostee: $hostee
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        hostee
        host
        convoCreatorsEmail
        otherMembersEmail
        spaceID
        spaceTitle
        propertyID
        propertyTitle
        propertyLocation
        bookingRequestID
        bookingRequest {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        messages {
          items {
            id
            authorID
            authorName
            authorAvatar
            otherMembersEmail
            content
            convoID
            type
            createdAt
            updatedAt
            _version
            owner
            __typename
          }
          nextToken
          __typename
        }
        members
        membersNames
        unreadHost
        unreadHostee
        type
        createdAt
        updatedAt
        _version
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const conversationsByHost = /* GraphQL */ `
  query ConversationsByHost(
    $host: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conversationsByHost(
      host: $host
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        hostee
        host
        convoCreatorsEmail
        otherMembersEmail
        spaceID
        spaceTitle
        propertyID
        propertyTitle
        propertyLocation
        bookingRequestID
        bookingRequest {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        messages {
          items {
            id
            authorID
            authorName
            authorAvatar
            otherMembersEmail
            content
            convoID
            type
            createdAt
            updatedAt
            _version
            owner
            __typename
          }
          nextToken
          __typename
        }
        members
        membersNames
        unreadHost
        unreadHostee
        type
        createdAt
        updatedAt
        _version
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const conversationsByPropertyID = /* GraphQL */ `
  query ConversationsByPropertyID(
    $propertyID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conversationsByPropertyID(
      propertyID: $propertyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        hostee
        host
        convoCreatorsEmail
        otherMembersEmail
        spaceID
        spaceTitle
        propertyID
        propertyTitle
        propertyLocation
        bookingRequestID
        bookingRequest {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        messages {
          items {
            id
            authorID
            authorName
            authorAvatar
            otherMembersEmail
            content
            convoID
            type
            createdAt
            updatedAt
            _version
            owner
            __typename
          }
          nextToken
          __typename
        }
        members
        membersNames
        unreadHost
        unreadHostee
        type
        createdAt
        updatedAt
        _version
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const convosByDate = /* GraphQL */ `
  query ConvosByDate(
    $type: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    convosByDate(
      type: $type
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        hostee
        host
        convoCreatorsEmail
        otherMembersEmail
        spaceID
        spaceTitle
        propertyID
        propertyTitle
        propertyLocation
        bookingRequestID
        bookingRequest {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        messages {
          items {
            id
            authorID
            authorName
            authorAvatar
            otherMembersEmail
            content
            convoID
            type
            createdAt
            updatedAt
            _version
            owner
            __typename
          }
          nextToken
          __typename
        }
        members
        membersNames
        unreadHost
        unreadHostee
        type
        createdAt
        updatedAt
        _version
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      authorID
      authorName
      authorAvatar
      otherMembersEmail
      content
      convoID
      conversation {
        id
        hostee
        host
        convoCreatorsEmail
        otherMembersEmail
        spaceID
        spaceTitle
        propertyID
        propertyTitle
        propertyLocation
        bookingRequestID
        bookingRequest {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        messages {
          items {
            id
            authorID
            authorName
            authorAvatar
            otherMembersEmail
            content
            convoID
            type
            createdAt
            updatedAt
            _version
            owner
            __typename
          }
          nextToken
          __typename
        }
        members
        membersNames
        unreadHost
        unreadHostee
        type
        createdAt
        updatedAt
        _version
        __typename
      }
      type
      createdAt
      updatedAt
      _version
      owner
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        authorID
        authorName
        authorAvatar
        otherMembersEmail
        content
        convoID
        conversation {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        type
        createdAt
        updatedAt
        _version
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messagesByConvoIDAndCreatedAt = /* GraphQL */ `
  query MessagesByConvoIDAndCreatedAt(
    $convoID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByConvoIDAndCreatedAt(
      convoID: $convoID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        authorID
        authorName
        authorAvatar
        otherMembersEmail
        content
        convoID
        conversation {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        type
        createdAt
        updatedAt
        _version
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messageByDate = /* GraphQL */ `
  query MessageByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messageByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        authorID
        authorName
        authorAvatar
        otherMembersEmail
        content
        convoID
        conversation {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        type
        createdAt
        updatedAt
        _version
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
