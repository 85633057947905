import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { SetUpPaymentForm } from "./SetUpPaymentForm";
import { useSelector } from 'react-redux';
import { colors } from "../../styles/colors";
import Card from "../CustomElements/Card";
import Header from "../CustomElements/Header";
import { Text } from "react-native";
import CustomButton from "../CustomElements/CustomButton";
import { ErrorDisplay } from "../CustomElements/ErrorDisplay";
import LoadingBar from "../CustomElements/LoadingBar";
import { LoadingComponent } from "../LoadingComponent";
import { globalStyles } from "../../styles/styles";
import { View } from 'react-native';

const WebStripe = ({
    loading,
    instantBook,
    loadingStatus,
    loadingPercentage,
    error,
    width,
    agreed,
    clientSecret,
    setError,
    handleError,
    mobileView,
    setloading,
    setLoadingPercentage,
    setLoadingStatus,
    handleSubmit,
    bookingRequestID
}) => {
    const stripeConfig = useStripe();
    const elements = useElements();

    const handlePayment = async () => {
        setloading(true)
        setLoadingPercentage("5%")
        if (!stripeConfig || !elements) {
            setError(true)
            return;
        };

        const result = await stripeConfig.confirmSetup({
            elements,
            confirmParams: {
                client_secret: clientSecret,
                return_url: "https://hookhub.co",
            },
            redirect: "if_required"
        });

        if (!!result.error) {
            console.log(result.error.message);
            setError(true)
            const errorSubject = "ERROR Loading Stripe on createBookingRequest"
            handleError({ emailBody: `ERROR loading stripe.confirmSetup on SetUpPaymentForm.js. >>>  ERROR msg: ${result.error.message} >>>  This is the booking request data:  ${bookingRequestID}`, successCreatingRequest: false, errorSubject, errorText: "Error creating new Booking. No worries, we are contacting Hookhub now. Hookhub will reach out to you soon." })
        } else {
            setloading(true);
            checkStatus(result.setupIntent.payment_method);
        }
    };

    const checkStatus = (paymentMethodID) => {
        setLoadingPercentage("15%")
        setLoadingStatus("Let's do this...")
        if (!stripeConfig) {
            return;
        }

        stripeConfig.retrieveSetupIntent(clientSecret)
            .then(({ setupIntent }) => {
                switch (setupIntent.status) {
                    case 'succeeded':
                        console.log('Success! Your payment method has been saved.');
                        handleSubmit(paymentMethodID);
                        break;

                    case 'processing':
                        console.log("Processing payment details. We'll update you when processing is complete.");

                        // ****************************************************
                        // ****************************************************
                        // IN FUTURE, if choose to add bank debits (without LINK) as payment option
                        //          - These types of payment methods can take up to a few days to process.
                        // ****************************************************
                        // ****************************************************

                        break;

                    case 'requires_payment_method':
                        console.log('Failed to process payment details. Please try another payment method.');
                        setError(true)
                        break;
                }
            });
    };

    return (
        <View style={globalStyles.centerColumn}>
            <View>
                {(!!stripeConfig && !!elements) ?
                    <PaymentElement /> :
                    (
                        <Card
                            backgroundColor={colors.orange10}
                            alignItems="center"
                            width={200}
                            textAlign="center"
                        >
                            <Header level={1.5} color="#fff" text="Payment form is not loading on this device." />
                            <Text>Try requesting your booking on a Computer, not Mobile. If this issue persists, please contact Hookhub.</Text>
                        </Card>
                    )}
            </View>
            {loading && (
                <> {instantBook ? <LoadingBar loadingStatus={loadingStatus} loadingPercentage={loadingPercentage} /> : <LoadingComponent />}</>
            )}
            {error && (<ErrorDisplay error="Error processing your request! Hookhub has been notified and we will reach out soon. If this is urgent, please contact us via the Help Screen." />)}
            <CustomButton
                onPress={() => handlePayment()}
                disabled={error || !agreed || !stripeConfig || !!loading}
                alignContent="center"
                alignItems="center"
                justifyContent="center"
                width={150}
                marginTop={20}
                marginBottom={1}
                height={35}
                backgroundColor={(!agreed || !stripeConfig || !!loading || error) ? colors.grey20 : colors.orange50}
                color="#fff"
                border={(!agreed || !stripeConfig || !!loading || error) ? "#4c4e52" : colors.orange90}
            >
                <Text style={{ fontWeight: "550", color: error || !agreed || !stripeConfig || !!loading ? colors.grey80 : "#fff", fontSize: 16}}>Confirm</Text>
            </CustomButton>
        </View >
    )
};

export const ReserveModalBody = ({
    mobileView,
    instantBook,
    payMonthly,
    clientSecret,
    full_refund_date,
    half_refund_date,
    full_refund_enabled,
    half_refund_enabled,
    no_refund_enabled,
    space_rules,
    noSmoking,
    adultsOnly,
    userData,
    loading,
    width,
    setloading,
    loadingPercentage,
    loadingStatus,
    setLoadingPercentage,
    setLoadingStatus,
    handleSubmit,
    halfRefundDateIneligible,
    fullRefundDateIneligible,
    signature,
    setSignature,
    refundPolicy,
    setrefundPolicy,
    agreeRulesAndRegulations,
    setAgreeRulesAndRegulations,
    liability,
    setLiability,
    offPlatformPolicy,
    setOffPlatformPolicy,
    agreed,
    setError,
    error,
    handleError,
    bookingRequestID
}) => {
    const options = { clientSecret: clientSecret };
    const { stripe } = useSelector(state => state.currentUser);

    return (
        <Elements stripe={stripe} options={options}>
            <SetUpPaymentForm
                space_rules={space_rules}
                noSmoking={noSmoking}
                adultsOnly={adultsOnly}
                full_refund_date={full_refund_date}
                half_refund_date={half_refund_date}
                full_refund_enabled={full_refund_enabled}
                half_refund_enabled={half_refund_enabled}
                no_refund_enabled={no_refund_enabled}
                payMonthly={payMonthly}
                mobileView={mobileView}
                width={width}
                userData={userData}
                halfRefundDateIneligible={halfRefundDateIneligible}
                fullRefundDateIneligible={fullRefundDateIneligible}
                signature={signature}
                setSignature={(v) => setSignature()}
                refundPolicy={refundPolicy}
                setrefundPolicy={(v) => setrefundPolicy()}
                agreeRulesAndRegulations={agreeRulesAndRegulations}
                setAgreeRulesAndRegulations={(v) => setAgreeRulesAndRegulations()}
                liability={liability}
                setLiability={(v) => setLiability()}
                offPlatformPolicy={offPlatformPolicy}
                setOffPlatformPolicy={(v) => setOffPlatformPolicy()}
            />
            <WebStripe
                clientSecret={clientSecret}
                handleSubmit={(v) => handleSubmit(v)}
                loading={loading}
                bookingRequestID={bookingRequestID}
                instantBook={instantBook}
                loadingStatus={loadingStatus}
                loadingPercentage={loadingPercentage}
                setLoadingPercentage={(v) => setLoadingPercentage(v)}
                setLoadingStatus={(v) => setLoadingStatus(v)}
                error={error}
                width={width}
                agreed={agreed}
                setError={(v) => setError(v)}
                handleError={(v) => handleError(v)}
                setloading={(v) => setloading(v)}
                mobileView={mobileView}
            />
        </Elements>
    );
};
