import { Text, View } from "react-native";
import { colors } from "../../styles/colors";
import { globalStyles } from "../../styles/styles";
import PrivacyPolicyModal from "./PrivacyPolicyModal";
import TermsConditionsModal from "./TermsConditionsModal";


const PrivacyTermsSitemapFooter = ({ mobileView = false }) => {

    return (
        <View style={[mobileView ? globalStyles.flexEnd : globalStyles.center, { marginVertical: 5, marginLeft: 20 }]}>
            <PrivacyPolicyModal />
                <Text style={{ color: colors.amplifyNeutral90, marginHorizontal: 10 }}>
                    &#x2022;
                </Text>
            <TermsConditionsModal />
        </View>
    )
}

export default PrivacyTermsSitemapFooter;
