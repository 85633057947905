import { Text, useWindowDimensions, Image, View } from "react-native";
import { Authenticator as ReactAuthenticator } from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import { colors } from "../styles/colors";
import CustomButton from "../components/CustomElements/CustomButton";
import { globalStyles } from "../styles/styles";
import { Entypo } from "@expo/vector-icons";

const Authenticator = ({ children, authenticate, authMethod }) => {
    const { height, width } = useWindowDimensions();
    const mobileView = width < 500;
    let imageWidth = mobileView ? 150 : 163.35;

    const formFields = {
        signUp: {
            name: {
                labelHidden: true,
                label: "name:",
                placeholder: "Legal Name",
                isRequired: true,
                order: 2,
            },
            phone_number: {
                labelHidden: true
            },
            email: {
                labelHidden: true
            },
            password: {
                labelHidden: true
            },
            confirm_password: {
                labelHidden: true
            }
        },
        forceNewPassword: {
            password: {
                labelHidden: false,
                placeholder: "Enter your Password:",
            },
        },
        resetPassword: {
            email: {
                labelHidden: false,
                placeholder: "Enter your email:",
            },
        },
        confirmResetPassword: {
            confirmation_code: {
                labelHidden: false,
                placeholder: "Enter your Confirmation Code:",
                label: "Confirmation Code",
                isRequired: true,
            },
            confirm_password: {
                labelHidden: false,
                placeholder: "Enter your Password Please:",
            },
        },
        setupTOTP: {
            QR: {
                totpIssuer: "test issuer",
                totpUsername: "amplify_qr_test_user",
            },
            confirmation_code: {
                labelHidden: false,
                label: "Confirmation Code",
                placeholder: "Enter your Confirmation Code:",
                isRequired: false,
            },
        },
        confirmSignIn: {
            confirmation_code: {
                labelHidden: false,
                label: "Confirmation Code",
                placeholder: "Enter your Confirmation Code:",
                isRequired: false,
            },
        },
    };

    const components = {
        Header() {
            return (
                <View style={[globalStyles.center, { backgroundColor: "#fff", paddingVertical: 10 }]}>
                    <Image
                        style={{ width: imageWidth, height: imageWidth / 3.86, marginBottom: 15 }}
                        source={require("../../assets/images/../../assets/images/hookhub-logo-orange-mini.png")}
                    />
                </View>
            );
        },
        Footer() {
            return (
                <View style={[globalStyles.centerColumn, { backgroundColor: "#fff", paddingTop: 30 }]}>
                    <CustomButton
                        width={160}
                        backgroundColor={colors.amplifyNeutral20}
                        onPress={() => authenticate()}
                    >
                        <View style={globalStyles.center}>
                            <Text>go to spaces</Text>
                            <Entypo name="chevron-thin-right" size={20} color="black" />
                        </View>
                    </CustomButton>
                    <Text style={{ marginTop: 20, marginBottom: 5 }}>
                        &copy; All Rights Reserved
                    </Text>
                </View>
            );
        },
    };

    return (
        <ReactAuthenticator
            loginMechanisms={["email"]}
            initialState={authMethod}
            formFields={formFields}
            components={components}
            // socialProviders={["google"]}
            variation="modal"
            height={mobileView ? height / 1.8 : height}
        >
            {children}
        </ReactAuthenticator>
    );
};

export default Authenticator;
