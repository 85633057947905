import { createProperty, deleteProperty, updateProperty } from "../graphql/mutations";
import { getProperty, listProperties, listPropertiesByInternalRating } from "../graphql/queries";
import { API } from "aws-amplify";
import { notifyHookhub } from "../lambdaFunctions/notifyHookhub";


export const getPropertyDetails = async (propertyID) => {
    const property = await API.graphql({
        query: getProperty,
        variables: { id: propertyID },
        authMode: "API_KEY",
    })

    return { property: property?.data?.getProperty };
};

export const listMyHostedProperties = async ({ ownerID }) => {
    let properties = await API.graphql({
        query: listProperties,
        variables: {
            filter: {
                ownerID: { eq: ownerID }
            }
        },
        authMode: "API_KEY",
    }).then((r) => {
        return r.data.listProperties;
    }).catch((e) => {
        console.log("e in here >>  ", e)
        return false;
    })
    return properties;
};

export const listPropertiesByInternalRatingQuery = async ({ filterData, spaceFilterData }) => {
    let properties = await API.graphql({
        query: listPropertiesByInternalRating,
        variables: {
            type: "property",
            filter: filterData,
            filterSpace: spaceFilterData
        },
        authMode: "API_KEY",
    }).then((r) => {
        return r.data.propertyByInternalRating?.items;
    }).catch((e) => {
        console.log("e in here >>  ", e)
        return false;
    })
    return properties;
};

export const deletePropertyMutation = async (propertyID) => {
    try {
        let response = await API.graphql({
            query: deleteProperty,
            variables: {
                input: {
                    id: propertyID
                }
            },
            authMode: "AMAZON_COGNITO_USER_POOLS",
        })
        return response?.data?.deleteProperty
    } catch {
        return false
    }
};

export const deactivatePropertyMutation = async (propertyID) => {
    try {
        let response = await API.graphql({
            query: updateProperty,
            variables: {
                input: {
                    id: propertyID,
                    active: false
                }
            },
            authMode: "AMAZON_COGNITO_USER_POOLS",
        })
        return response?.data?.updateProperty
    } catch {
        return false
    }
};

export const reactivatePropertyMutation = async (propertyID) => {
    try {
        let response = await API.graphql({
            query: updateProperty,
            variables: {
                input: {
                    id: propertyID,
                    active: true
                }
            },
            authMode: "AMAZON_COGNITO_USER_POOLS",
        })
        return response?.data?.updateProperty
    } catch {
        return false
    }
};

export const createPropertyMutation = async ({ data, userEmail }) => {
    const property = await API.graphql({
        query: createProperty,
        variables: { input: data },
        authMode: "AMAZON_COGNITO_USER_POOLS",
    }).then((r) => {
        return r.data.createProperty
    }).catch((e) => {
        console.log("e in create property >>  ", e)
        let subject = `ERROR creating PROPERTY`
        let body = `ERROR: ${e.errors[0].message}`
        notifyHookhub({ subject, body, userID: data.ownerID, userEmail: userEmail })
        return false;
    })
    return property;
};

export const updatePropertyMutation = async ({ data }) => {
    const property = await API.graphql({
        query: updateProperty,
        variables: { input: data },
        authMode: "AMAZON_COGNITO_USER_POOLS",
    }).then((r) => {
        return r.data.updateProperty
    }).catch((e) => {
        console.log("e in update property >>  ", e)
        return false;
    })
    return property;
};