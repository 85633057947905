import { View } from "react-native";
import { colors } from "../../styles/colors"


const OrangeUnderlineHeader = ({
}) => {

    return (
        <View style={{ borderTopColor: colors.orange30, width: 100, borderTopWidth: 3.5 }} />
    )
}

export default OrangeUnderlineHeader;
