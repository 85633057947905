import React from 'react';
import { Pressable, Text, StyleSheet, View } from 'react-native';

const CloseButtonUnderlined = ({ onPress, marginTop=20, padding=10 }) => {
    return (
        <View style={{ padding: padding, marginTop: marginTop}}>
            <Pressable onPress={() => onPress()}><Text style={[styles.closeButtonText]}>close</Text></Pressable>
        </View>
    );
};

export default CloseButtonUnderlined;

const styles = StyleSheet.create({
    closeButtonText: {
        textDecorationLine: "underline",
        fontSize: 17
    }
});