import React from "react";
import { useWindowDimensions, View, Text, Image } from "react-native";
import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
  FontAwesome5,
  FontAwesome,
} from "@expo/vector-icons";
import Flex from "../CustomElements/Flex"
import Header from "../CustomElements/Header"
import FixedHeightCard from "../CustomElements/FixedHeightCard";
import { useSelector } from "react-redux";
import { colors } from "../../styles/colors";
import { globalStyles } from "../../styles/styles";

const Amenity = ({ amenity, icon }) => {
  const { mobileView } = useSelector(state => state.currentUser);
  return (
    <FixedHeightCard
      marginTop={8}
      marginRight={4}
      marginLeft={4}
      padding={2}
      width={90}
      height={90}
      backgroundColor={colors.white}
      alignItems="center"
      textAlign="center"
      justifyContent="center"
      border={colors.amplifyNeutral20}
    >

      <View style={[globalStyles.centerColumn, {width: 90, height:90}]}>
        <View style={globalStyles.center}>{icon}</View>
        <Text style={{ textAlign: "center", marginTop: 5 }}>{amenity}</Text>
      </View>
    </FixedHeightCard>
  );
};

const SpaceAmenities = ({ space }) => {
  const { width } = useWindowDimensions();
  const {
    water_hookup,
    electric_hookup,
    sewage_hookup,
    easy_entry,
    shower_available,
    wifi_available,
    laundry_available,
    pool_available,
    hottub_available,
    indoor_area_available,
    outdoor_area_available,
    trash_available,
    restroom_available,
    food_nearby,
    restaurant_nearby,
    water_accessible,
    pets_allowed,
    outdoor_fires
  } = space;
  const amenitiesPresent = (
    water_hookup ||
    electric_hookup ||
    sewage_hookup ||
    easy_entry ||
    shower_available ||
    wifi_available ||
    laundry_available ||
    pool_available ||
    hottub_available ||
    indoor_area_available ||
    outdoor_area_available ||
    trash_available ||
    restroom_available ||
    food_nearby ||
    restaurant_nearby ||
    water_accessible ||
    pets_allowed ||
    outdoor_fires
  );
  const { mobileView } = useSelector(state => state.currentUser);

  return (
    amenitiesPresent && (
      <>
        <Header level={1} marginTop={5} padding={0} color={colors.amplifyNeutral90} text="Amenities available:"></Header>
        <Flex
          alignItems={mobileView ? "center" : "flex-start"}
          alignContent={mobileView ? "center" : "flex-start"}
          justifyContent={mobileView ? "center" : "flex-start"}
          wrap="wrap"
          marginBottom={15}
          width={mobileView ? width / 1.1 : width / 2.1}
        >
          {water_hookup && (
            <Amenity
              amenity="Water Hookup"
              icon={
                <Image
                  style={{ width: 25, height: 25 }}
                  source={require("../../../assets/icons/water.svg")}
                />
              }
            />
          )}
          {electric_hookup && (
            <Amenity
              amenity="Electric Hookup"
              icon={
                <Image
                  style={{ width: 25, height: 25 }}
                  source={require("../../../assets/icons/electricity.svg")}
                />
              }
            />
          )}
          {sewage_hookup && (
            <Amenity
              amenity="Sewage Hookup"
              icon={
                <Image
                  style={{ width: 25, height: 25 }}
                  source={require("../../../assets/icons/sewage.svg")}
                />
              }
            />
          )}
          {easy_entry && (
            <Amenity
              amenity="Pull through Parking"
              icon={
                <MaterialCommunityIcons
                  name="steering"
                  size={25}
                  color={colors.lightBlue}
                />
              }
            />
          )}
          {shower_available && (
            <Amenity
              amenity="Shower Available"
              icon={<FontAwesome5 name="shower" size={23} color={colors.lightBlue} />}
            />
          )}
          {restroom_available && (
            <Amenity
              amenity="Restroom Available"
              icon={<FontAwesome5 name="restroom" size={23} color={colors.lightBlue} />}
            />
          )}
          {water_accessible && (
            <Amenity
              amenity="Water Accessible"
              icon={<MaterialCommunityIcons name="water-pump" size={23} color={colors.lightBlue} />}
            />
          )}
          {food_nearby && (
            <Amenity
              amenity="Food / Beverages Nearby"
              icon={<MaterialIcons name="fastfood" size={23} color={colors.lightBlue} />}
            />
          )}
          {restaurant_nearby && (
            <Amenity
              amenity="Restaurant Nearby"
              icon={<MaterialIcons name="restaurant" size={23} color={colors.lightBlue} />}
            />
          )}
          {wifi_available && (
            <Amenity
              amenity="WiFi"
              icon={<Ionicons name="wifi" size={25} color={colors.lightBlue} />}
            />
          )}
          {laundry_available && (
            <Amenity
              amenity="Laundry facilities"
              icon={<MaterialIcons name="local-laundry-service" size={25} color={colors.lightBlue} />}
            />
          )}
          {pool_available && (
            <Amenity
              amenity="Pool"
              icon={<MaterialIcons name="pool" size={mobileView ? 24 : 38} color={colors.lightBlue} />}
            />
          )}
          {hottub_available && (
            <Amenity
              amenity="Jacuzzi"
              icon={
                <FontAwesome5 name="hot-tub" size={25} color={colors.lightBlue} />
              }
            />
          )}
          {indoor_area_available && (
            <Amenity
              amenity="Indoor area"
              icon={
                <FontAwesome5
                  name="warehouse"
                  size={25}
                  color={colors.lightBlue}
                />
              }
            />
          )}
          {outdoor_area_available && (
            <Amenity
              amenity="Outdoor area"
              icon={<MaterialCommunityIcons name="table-picnic" size={25} color={colors.lightBlue} />}
            />
          )}
          {trash_available && (
            <Amenity
              amenity="Trash disposal"
              icon={<FontAwesome name="trash-o" size={25} color={colors.lightBlue} />}
            />
          )}
          {pets_allowed && (
            <Amenity
              amenity="Pets allowed"
              icon={
                <Image
                  style={{ width: 23, height: 23 }}
                  source={require("../../../assets/icons/pets-allowed.svg")}
                />
              }
            />
          )}
          {outdoor_fires && (
            <Amenity
              amenity="Fires allowed"
              icon={<MaterialCommunityIcons name="campfire" size={25} color={colors.lightBlue} />}
            />
          )}
        </Flex>
      </>
    )
  );
};

export default SpaceAmenities;
