import { StyleSheet, Text, View, useWindowDimensions } from "react-native";
import { colors } from "../../styles/colors";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useSelector } from "react-redux";
import Card from "../CustomElements/Card";
import { globalStyles } from "../../styles/styles";

const VerifyNeededForPropertyApprovalCard = ({ action }) => {
    const { mobileView } = useSelector(state => state.currentUser);
    const {width} = useWindowDimensions();

    return (
        <Card width={mobileView ? width / 1.7 : 250}>
            <View style={globalStyles.centerColumn}>
                <View style={[globalStyles.center, { margin: 10 }]} >
                    <MaterialCommunityIcons name="police-badge-outline" size={24} color={colors.amplifyNeutral80} />
                    <Text style={{ marginLeft: 5, fontWeight: "550" }}>Identity Verification</Text>
                </View>
                <Text style={[styles.nextStepsIDVerifyText, { marginTop: 15 }]}>Identity Verification needs to be completed for your Property to be active.</Text>
                {action}
            </View>
        </Card>
    );
};

const styles = StyleSheet.create({
    nextStepsIDVerifyText: {
        textAlign: "center",
        marginHorizontal: 5
    }
});

export default VerifyNeededForPropertyApprovalCard;
