import Flex from "../CustomElements/Flex"
import { useNavigation } from "@react-navigation/native";
import { AntDesign, FontAwesome6, Ionicons } from "@expo/vector-icons";
import CustomButton from "../CustomElements/CustomButton";
import { Image, Pressable, Text, View, useWindowDimensions } from "react-native";
import { colors } from "../../styles/colors"
import { useSelector } from "react-redux";
import { globalStyles } from "../../styles/styles";
import CustomDivider from "../CustomDivider";
import TextBody from "../CustomElements/TextBody";
import Card from "../CustomElements/Card";

const WebChartRow = ({ header = null, text = null, competitorCheckmark = false, width }) => {
    return (

        <View style={[globalStyles.centerColumn, { marginBottom: 20 }]}>
            <View style={[globalStyles.center, { width: width / 2.1 }]}>
                <View style={[globalStyles.flexStartColumn, { marginHorizontal: "10%", width: width / 2.7 }]}>
                    <Text style={{ fontWeight: "bold", marginBottom: 10, fontSize: 16 }}>{header}</Text>
                    <Text style={{}}>{text}</Text>
                </View>
                <>
                    <View style={[globalStyles.flexStartColumn, { marginHorizontal: "10%" }]}>
                        <Ionicons name="checkmark-sharp" size={40} color={colors.orange80} />
                    </View>
                    <View style={[globalStyles.flexStartColumn, { marginHorizontal: "10%" }]}>
                        {competitorCheckmark ? (
                            <Ionicons name="checkmark-sharp" size={28} color={"black"} />
                        ) : (
                            <AntDesign name="close" size={24} color="black" />
                        )}
                    </View>
                </>
            </View>
        </View >
    )
}

const MobileChartRow = ({ header = null, text = null, competitorCheckmark = false, width }) => {
    return (

        <View style={[globalStyles.centerColumn]}>
            <View style={[globalStyles.centerColumn, { width: width / 1.15 }]}>
                <View style={[globalStyles.spaceBetween, { marginHorizontal: "10%", width: width / 1.3 }]}>
                    <Text style={{ fontWeight: "500", marginBottom: 2, fontSize: 16, width: "70%" }}>{header}</Text>
                    <View style={[globalStyles.center, { width: "30%" }]}>
                        <View style={[globalStyles.flexStartColumn, { marginHorizontal: "1%" }]}>
                            <Ionicons name="checkmark-sharp" size={27} color={colors.orange80} />
                        </View>
                        <View style={[globalStyles.flexStartColumn, { marginHorizontal: "1%" }]}>
                            {competitorCheckmark ? (
                                <Ionicons name="checkmark-sharp" size={25} color={"black"} />
                            ) : (
                                <AntDesign name="close" size={24} color="black" />
                            )}
                        </View>
                    </View>
                </View>
                <Text style={{ margin: 7 }}>{text}</Text>
            </View>
        </View >
    )
}

const ChartRow = ({ header = null, text = null, competitorCheckmark = false, width, mobileView }) => {
    return (
        <>
            {mobileView ? (
                <MobileChartRow
                    header={header}
                    text={text}
                    width={width}
                    mobileView={mobileView}
                    competitorCheckmark={competitorCheckmark}
                />
            ) : (
                <WebChartRow
                    header={header}
                    text={text}
                    width={width}
                    mobileView={mobileView}
                    competitorCheckmark={competitorCheckmark}
                />
            )}
        </>
    )
}


const ComparisonToCompetitorsChart = ({ hostView = true }) => {
    const { mobileView } = useSelector(state => state.currentUser);
    const { width } = useWindowDimensions();

    return (
        <View style={globalStyles.centerColumn}>
            <View style={globalStyles.center}>
                {mobileView ? (
                    <Image
                        style={{ width: 60, height: 60, marginRight: 5 }}
                        source={require("../../../assets/images/favicon.png")}
                    />
                ) : (

                    <Image
                        style={{ width: 163.35, height: 42.35, marginRight: 5 }}
                        source={require("../../../assets/images/hookhub-logo-orange-mini.png")}
                    />
                )}
                <TextBody style={{fontSize: 19}}>vs. competitors</TextBody>
            </View>
            <Card width={mobileView ? width / 1.15 : width / 1.3}>
                <View style={{ marginTop: mobileView ? 10 : 20 }}>
                    {hostView && (
                        <>
                            <ChartRow
                                header="Renter identity verification"
                                text="Hookhub has a few methods in place: 1) On your space, you will define your rules and regulations and the renter must agree with them prior to making a booking request. If the renter is in violation of any of your rules, you are able to keep the payment and cancel the booking. 2) The payment system we use requires the renter to verify their identity if anything seems suspect. 3) The renter has to upload their picture and details before booking and you will be able to review their details prior to accepting or denying the request (if you choose not to allow 'instant book')."
                                width={width}
                                mobileView={mobileView}
                            />
                            <CustomDivider width={mobileView ? width / 1.36 : width / 1.5} marginVertical={mobileView ? 20 : 30} />
                        </>
                    )}
                    <ChartRow
                        header="Vacation parking"
                        text="Ability to rent out and book short-term, vacation stays."
                        width={width}
                        mobileView={mobileView}
                        competitorCheckmark={true}
                    />
                    <CustomDivider width={mobileView ? width / 1.36 : width / 1.5} marginVertical={mobileView ? 20 : 30} />
                    <ChartRow
                        header="Long-term parking"
                        text="Option to rent out and book monthly and yearly stays. Rates are lower per night on the longer stays."
                        width={width}
                        mobileView={mobileView}
                    />
                    <CustomDivider width={mobileView ? width / 1.36 : width / 1.5} marginVertical={mobileView ? 20 : 30} />
                    <ChartRow
                        header="Storage parking"
                        text="Option to rent out and book storage parking."
                        width={width}
                        mobileView={mobileView}
                    />
                    <CustomDivider width={mobileView ? width / 1.36 : width / 1.5} marginVertical={mobileView ? 20 : 30} />
                    <ChartRow
                        header="Easy book and payout through platform"
                        text="The majority of our competitors are a source for finding and contacting RV parking locations. Hookhub is one of the select few that allows for everything to flow through the app- making the process simple and secure."
                        width={width}
                        mobileView={mobileView}
                    />
                </View>
            </Card>
        </View>
    )
}

export default ComparisonToCompetitorsChart;
