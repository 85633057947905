import React, { useState, useEffect } from "react";
import { Image, Pressable, StyleSheet, Text, useWindowDimensions } from "react-native";
import HostOnboarding from "../../components/host/HostOnboarding";
import SelectPhotos from "../../components/space/SelectPhotos";
import { setAdditionalSpacesAlteredData, setImageStatus } from "../../redux/actions/HostOnboardingActions";
import { uploadSaveImage, uploadSaveImages } from "../../utils/uploadSaveImages";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../components/CustomElements/CustomButton";
import Header from "../../components/CustomElements/Header";
import { View } from "react-native";
import { Fontisto, Ionicons } from "@expo/vector-icons";
import { colors } from "../../styles/colors";
import Card from "../../components/CustomElements/Card";
import FixedHeightCard from "../../components/CustomElements/FixedHeightCard";
import { UUID } from "../../utils/UUID";
import getEnvVars from '../../../environment';
import { pickImage } from "../../utils/pickImage";
import { LoadingComponent } from "../../components/LoadingComponent";
const { cloudfrontURL } = getEnvVars();


const AdditionalSpaceImagePreview = ({ imageKey, mobileView, spaceTitle, updateImage, savedImage = null  }) => {
  const [image, setImage] = useState(savedImage ? `${cloudfrontURL}${savedImage}` : '');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false)
  const { width } = useWindowDimensions();

  const selectImage = async () => {
    setLoading(true)
    const imageValue = `${imageKey}${UUID()}.jpg`;
    let formattedImage = await pickImage(false);
    if (formattedImage) {
      const response = await fetch(formattedImage.uri);
      const blob = await response.blob();
      setImage(formattedImage.uri)
      updateImage(imageKey, imageValue, blob);
      setLoading(false)
    } else {
      setLoading(false)
    }
  };

  return (
    <Pressable onPress={selectImage} style={styles.multPhotoContainer}>
      <Header text={`space: ${spaceTitle}`} color={colors.amplifyNeutral80} />
      {loading ? (<LoadingComponent />) : (
        <>
          {!!image ? (
            <Card width={!!mobileView ? width / 1.6 : 240} marginBottom={13}>
              <Image
                source={{ uri: image }}
                padding={12}
                style={{ width: !!mobileView ? width / 1.77 : 240, height: !!mobileView ? width / 1.75 : 240 }}
                onError={() => setError(true)}
              />
            </Card>
          ) : (
            <FixedHeightCard
              padding={mobileView ? 11 : 70}
              marginBottom={13}
              marginLeft={5}
              marginRight={5}
              width={mobileView ? width / 1.6 : 240}
              height={mobileView ? width / 1.6 : 240}
              alignItems="center"
              justifyContent="center"
              alignContent="center"
              alignSelf="center"
            >
              <View
                height={mobileView ? width / 1.6 : 240}
                justifyContent="center"
                alignContent="center"
                alignSelf="center"
                alignItems="center"
              >
                <Fontisto name="photograph" size={80} color="grey" />
              </View>
            </FixedHeightCard>
          )}
        </>
      )}
    </Pressable>
  );
};

function PhotoUploadScreen() {
  const dispatch = useDispatch();
  const { mobileView } = useSelector(state => state.currentUser);
  const { width } = useWindowDimensions();
  const { imageUploadError, additionalSpacesBoolean, additionalSpacesAlteredData, newProperty } = useSelector(state => state.hostOnboarding)
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageIDs, setImageIDs] = useState(useSelector(state => state.hostOnboarding.imageIDs) || {});
  const [additionalSpacesData, setAdditionalSpacesData] = useState(additionalSpacesAlteredData);
  const [imagePreviews, setLocalImagePreviews] = useState({});
  const [additionalImagesBoolean, setadditionalImagesBoolean] = useState(false);
  const [uploadImages, setUploadImages] = useState({});

  const uploadTo = async () => {
    setLoading(true);
    const uploadImageStatus = await uploadSaveImages(uploadImages)
    if (uploadImageStatus) {
      dispatch(setImageStatus({
        imageUploadError: false,
        imageIDs: imageIDs,
        imagesFinishedUploading: true
      }))
      setLoading(false)
    } else {
      dispatch(setImageStatus({
        imageUploadError: true,
        imageIDs: {},
        imagesFinishedUploading: false
      }))
      setLoading(false)
    }
  };

  const updateImagesArr = (index, imageID, imageValue, blob) => {
    setError(false)
    const imagesToUpload = { ...uploadImages };
    const previewImages = { ...imagePreviews };
    const imageIds = { ...imageIDs };
    imagesToUpload[index] = {
      imageID: imageID,
      blob: blob
    }
    previewImages[index] = imageValue;
    imageIds[index] = imageID;
    setImageIDs(imageIds);
    setUploadImages(imagesToUpload);
    setLocalImagePreviews(previewImages);
    setLoading(false);
  };

  const updateImage = async (spaceKey, imageValue, blob) => {
    setError(false);
    let updateImageData = { ...additionalSpacesData }
    updateImageData[spaceKey]['images'] = [imageValue]
    let imageData = {
      imageID: imageValue,
      blob: blob
    }
    setAdditionalSpacesData(updateImageData)
    await uploadSaveImage(imageData)
    dispatch(setAdditionalSpacesAlteredData({
      additionalSpacesAlteredData: updateImageData
    }))
    // setIndividualSpacePhotosLoading(false);
  };

  useEffect(() => {
    if (newProperty) {
      !!imageUploadError && setError(true);
      typeof imageIDs !== "object" && dispatch(setImageStatus({
        imageUploadError: false,
        imageIDs: {}
      }))
    }
  }, []);

  useEffect(() => {
    if (newProperty) {
      (Object.keys(imagePreviews).length >= 4 || Object.keys(imageIDs).length >= 4) ? setDisabled(false) : setDisabled(true);
    }
  }, [imagePreviews]);

  return (
    <HostOnboarding
      heading="Let's add some photos!"
      navigateF={newProperty ? "AddDescription" : "SetDimensions"}
      nextButtonText="Next"
      navigateB={newProperty ? "LocationConfirmation" : "SetDistances"}
      disabledNextButton={disabled}
      onClickNext={() => uploadTo()}
      subtext={"Images are what will get you bookings. Be sure to set your first image as a clear shot of the parking space available."}
      required={"At least 4 images"}
    >
      <Text style={{ textAlign: "center", fontSize: 19, fontWeight: "bold", marginTop: 20 }}>Bonus! We will come out and help you get great photos.</Text>
      <Text style={{ textAlign: "center", fontSize: 18, marginBottom: 40 }}>Dont hesitate to reach out and we will send one of our teammembers to help you take photos and help you with getting your space set up- at no cost!</Text>
      {newProperty && (
        <>
          {additionalSpacesBoolean && <Header color={colors.amplifyNeutral80} text="Property images" />}
          <SelectPhotos
            images={imageIDs}
            imagePreviews={imagePreviews}
            setImages={(i, x, y, z) => updateImagesArr(i, x, y, z)}
            loading={loading}
            setLoading={(v) => setLoading(v)}
            imageUploadError={imageUploadError}
          />
        </>
      )}
      {additionalSpacesBoolean && (
        <>
          <Header color={colors.amplifyNeutral80} textAlign="center" text="Would you like to add an image of each space?" marginTop={newProperty ? 50 : 0} marginBottom={newProperty ? 10 : 20} />
          <View>
            <CustomButton
              height={mobileView ? 100 : 60}
              textAlign="flex-start"
              alignItems="flex-start"
              width={mobileView ? width / 1.14 : 300}
              marginBottom={10}
              padding={5}
              backgroundColor={!additionalImagesBoolean ? colors.vibrantBlue : colors.amplifyNeutral20}
              border={!additionalImagesBoolean ? colors.amplifyNeutral80 : "#4c4e52"}
              onPress={() => setadditionalImagesBoolean(false)}
            >
              <View style={{ flexDirection: "row", width: mobileView ? width / 1.3 : 260 }}>
                <Ionicons name={!additionalImagesBoolean ? "radio-button-on-sharp" : "radio-button-off-sharp"} size={24} color={colors.amplifyNeutral80} />
                <Text style={{ fontSize: mobileView ? 18 : 20, textAlign: "left", marginLeft: 4 }}>Nope, just property images works for me.</Text>
              </View>
            </CustomButton>
            <CustomButton
              height={mobileView ? 100 : 60}
              textAlign="flex-start"
              alignItems="flex-start"
              width={mobileView ? width / 1.14 : 300}
              marginBottom={10}
              padding={5}
              backgroundColor={additionalImagesBoolean ? colors.vibrantBlue : colors.amplifyNeutral20}
              border={additionalImagesBoolean ? colors.amplifyNeutral80 : "#4c4e52"}
              onPress={() => setadditionalImagesBoolean(true)}
            >
              <View style={{ flexDirection: "row", width: mobileView ? width / 1.3 : 260 }}>
                <Ionicons name={additionalImagesBoolean ? "radio-button-on-sharp" : "radio-button-off-sharp"} size={24} color={colors.amplifyNeutral80} />
                <Text style={{ fontSize: mobileView ? 18 : 20, textAlign: "left", marginLeft: 4 }}>(OPTIONAL) Yes, lets add an image of each space!</Text>
              </View>
            </CustomButton>
          </View>
          {additionalImagesBoolean && (
            <>
              {
                Object.keys(additionalSpacesData).map((key, i) => (
                  <AdditionalSpaceImagePreview
                    imageKey={key}
                    key={i}
                    spaceTitle={additionalSpacesData[key]?.title}
                    savedImage={additionalSpacesData[key]?.image}
                    mobileView={mobileView}
                    updateImage={(x, y, z) => updateImage(x, y, z)}
                    // setLoading={(v) => setIndividualSpacePhotosLoading(v)}
                    // loading={individualSpacePhotosLoading}
                  />
                ))
              }
            </>
          )}
        </>
      )}
    </HostOnboarding>
  );
}

const styles = StyleSheet.create({
  multPhotoContainer: {
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default PhotoUploadScreen;
