import React from "react";
import {
    StyleSheet,
    Text,
    Pressable,
    View,
    useWindowDimensions
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import Modal from "react-native-modal";
import { AntDesign, FontAwesome5 } from "@expo/vector-icons";
import Flex from "../CustomElements/Flex"
import { useSelector } from "react-redux";
import Header from "../CustomElements/Header";
import { globalStyles } from "../../styles/styles";
import CustomButton from "../CustomElements/CustomButton";
import { colors } from "../../styles/colors";
import { ScrollView } from "react-native-gesture-handler";

export const MyRigNeededModal = ({
    modalVisible,
    setModalVisible,
}) => {
    const navigation = useNavigation();
    const { height } = useWindowDimensions();
    const { mobileView } = useSelector(state => state.currentUser);

    const onClose = () => {
        setModalVisible();
    };

    const navigateToMyProfile = () => {
        setModalVisible();
        navigation.navigate('account', {
            screen: 'options',
            params: {
                screen: 'profile'
            }
        });
    }

    return (
        <Modal
            coverScreen={true}
            isVisible={modalVisible}
            animationInTiming={600}
            style={{ margin: 0 }}
            onBackdropPress={onClose}
            onSwipeComplete={onClose}
            deviceHeight={height}
        >
            <ScrollView>
            <View style={styles.centeredView}>
                <View style={[styles.modalView, { padding: !!mobileView ? 15 : 35 }]}>
                    <Flex justifyContent="flex-start">
                        <Pressable onPress={onClose}>
                            <AntDesign name="closecircleo" size={!!mobileView ? 19 : 24} color="#616A6B" />
                        </Pressable>
                    </Flex>
                    <View style={globalStyles.centerColumn}>
                        <FontAwesome5
                            name="exclamation-circle"
                            size={55}
                            color="#f2b877"
                        />
                        <Header text="Your Rig image is required to book!" textAlign="center" marginTop={15} marginBottom={20} />
                        <Text style={styles.subText}>
                            You must provide an image of your RV, Trailer, (or whatever your 'rig' is!) before requesting to book a space.
                            Click the button below, then select 'Edit My Rig'.
                        </Text>
                        <Text style={styles.subText}>
                            If you have any issues updating your Rig, you may need to re-try on a Desktop. Please reach out if you need any help via the Help Screen.
                        </Text>
                        <CustomButton
                            onPress={() => navigateToMyProfile()}
                            width={150}
                            marginTop={20}
                            backgroundColor={colors.orange50}
                        >
                            <Text style={styles.forwardButtonText}>Add My Rig</Text>
                        </CustomButton>
                    </View>
                </View>
            </View>
            </ScrollView>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modalView: {
        margin: 13,
        backgroundColor: "white",
        borderRadius: 20,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    forwardButtonText: {
        color: "#fff",
        fontSize: 17,
    },
    subText: {
        fontSize: 16,
        textAlign: "center"
    },
    modalText: {
        marginBottom: 15,
        textAlign: "center",
    },
    logoImage: {
        justifyContent: "center",
    },
    miniLogoImage: {
        justifyContent: "flex-start",
        width: 60,
        height: 60,
    },
});
