const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';

export const colors = {
  light: {
    text: '#000',
    background: '#fff',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
  },
  dark: {
    text: '#fff',
    background: '#000',
    tint: tintColorDark,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
  },
  orange80: "#d69045",
  orange90: "#85522a",
  orange60: "#dd5a02",
  orange50: "#e88b35",
  orange40: "#ffa320",
  orange30: "#FBB53A",
  orange20: "#feecc7",
  orange10: "#fff8eb",

  medBlue: "#065d8c",
  lightBlue: "#5f8aa1",
  vibrantBlue: "#78b2d6",
  brightBlue: "#48a8db",
  amplifyBlue20: "#6b9dc2",

  grey10: "#f3f3f3",
  grey20: "#e3e3e3",
  grey30: "#c7c7c7",
  grey80: "333333",
  black: '#000000',
  white: '#fff',
  amplifyNeutral80: "#4c4e52",
  amplifyNeutral20: "#f2f2f2",
  amplifyNeutral40: "#d1d1d1",
  amplifyNeutral60: "#969595",
  amplifyNeutral90: "#2b2b2b",

  red70: "#942d0c",
};