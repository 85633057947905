import React, { useState } from 'react';
import { StyleSheet, TextInput, Pressable, View, Alert } from 'react-native';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { AntDesign, MaterialCommunityIcons } from '@expo/vector-icons';
import { globalStyles } from '../../styles/styles';
import { colors } from '../../styles/colors';

const LocationSearch = ({ setCoordinates, clearLocation, mobileView, width, maxWidth=null }) => {
  const [addressText, setAddressText] = useState('');

  const handleChange = address => {
    setAddressText(address);
  };

  const handleSelect = (x) => {
    setAddressText(x)
    geocodeByAddress(x)
      .then(results => getLatLng(results[0]))
      .then(latLng => setCoordinates(latLng))
      .catch(error => console.error('Error', error));
  };

  const handleClear = () => {
    setAddressText('');
    setCoordinates({});
    clearLocation();
  };

  return (
    <PlacesAutocomplete
      value={addressText}
      placeholder="search..."
      onChange={(v) => handleChange(v)}
      onSelect={(v) => handleSelect(v)}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <View style={globalStyles.flexStart}>
          <View style={[globalStyles.centerColumn]}>
            <View style={[styles.searchButton, { height: 40 }]}>
              <MaterialCommunityIcons name="map-marker-radius" size={20} />
              <TextInput
                style={[styles.inputField, { width: width, maxWidth: maxWidth ? maxWidth : width, height: mobileView ? 37 : 38 }]}
                {...getInputProps({
                  placeholder: 'Looking for an RV space?',
                  placeHolderTextColor: colors.amplifyNeutral20,
                })}
              />
            </View>

            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, i) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer', width: width, maxWidth: maxWidth ? maxWidth : width }
                  : { backgroundColor: '#ffffff', cursor: 'pointer', width: width, maxWidth: maxWidth ? maxWidth : width };
                return (
                  <div key={i}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </View>
          {/* {!mobileView ? (
            <View style={{ marginTop: 8, marginLeft: 8 }}>
              <Pressable onPress={() => handleClear()}>
                <AntDesign name="closecircleo" size={25} color={colors.amplifyNeutral80} />
              </Pressable>
            </View>
          ) : (<></>)} */}
        </View>
      )}
    </PlacesAutocomplete>
  );
}

const styles = StyleSheet.create({
  inputField: {
    alignItems: "center",
    backgroundColor: "#fff",
    elevation: 1,
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: 5,
  },
  searchButton: {
    paddingHorizontal: 10,
    alignItems: "center",
    backgroundColor: "#fff",
    elevation: 1,
    flexDirection: "row",
    justifyContent: "center",
    borderColor: "#d6d6d6",
    borderRadius: 8,
    borderWidth: 1,
  },
  searchButtonInput: {
    paddingHorizontal: 10,
    alignItems: "center",
    backgroundColor: "#fff",
    elevation: 1,
    flexDirection: "row",
    height: 45,
    justifyContent: "center",
    borderColor: "#d6d6d6",
    borderRadius: 30,
    shadowColor: "#cccccc",
    borderWidth: 1,
    borderBottomWidth: 2,
    shadowOffset: {
      width: -2,
      height: 4,
    },
    shadowOpacity: 0.45,
    shadowRadius: 4,
  },
  searchButtonText: {
    color: "#000",
  },
});

export default LocationSearch;
