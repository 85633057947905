import React from "react";
import { FontAwesome } from '@expo/vector-icons';
import { View } from "react-native";
import { colors } from "../../styles/colors";

const Star = ({ val, numCheck }) => {
    return (
        <>
            {val >= numCheck ? (
                <>
                    {val >= numCheck + .5 ? (
                        <FontAwesome name="star" margin={.1} padding={.1} size={24} color={colors.orange50} />
                    ) : (
                        <FontAwesome name="star-half-empty" size={24} color={colors.orange50} />
                    )}
                </>
            ) : (
                <FontAwesome name="star-o" size={24} color="grey" />
            )}
        </>
    )
}

const StarRatingDisplay = ({ stars }) => {

    return (
        <View style={{ flexDirection: 'row', marginTop:10 }}>
            <Star val={stars} numCheck={.5} />
            <Star val={stars} numCheck={1.5} />
            <Star val={stars} numCheck={2.5} />
            <Star val={stars} numCheck={3.5} />
            <Star val={stars} numCheck={4.5} />
        </View>
    )
};

export default StarRatingDisplay;