import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, useWindowDimensions } from "react-native";
import HostOnboarding from "../../components/host/HostOnboarding";
import { useDispatch, useSelector } from 'react-redux';
import { setAdditionalSpacesAlteredData, setSpaceDimensions } from "../../redux/actions/HostOnboardingActions";
import SetDimensions from "../../components/space/SetDimensions";
import Card from "../../components/CustomElements/Card";
import Flex from "../../components/CustomElements/Flex";
import CustomButton from "../../components/CustomElements/CustomButton";
import { colors } from "../../styles/colors";
import { Ionicons } from "@expo/vector-icons";


function SetDimensionsScreen() {
  const dispatch = useDispatch();
  const {
    additionalSpacesBoolean,
    numberAdditionalSpaces,
    additionalSpacesAlteredData,
    newProperty
  } = useSelector(state => state.hostOnboarding);
  const windowWidth = useWindowDimensions().width;
  const { mobileView } = useSelector(state => state.currentUser);
  const [width, setwidth] = useState(useSelector(state => state.hostOnboarding.width));
  const [length, setlength] = useState(useSelector(state => state.hostOnboarding.length));
  const [additionalSpacesdimensions, setAdditionalSpacesdimensions] = useState({});
  const [dimensionsDiffer, setdimensionsDiffer] = useState(false);

  const setDimensions = () => {
    if (additionalSpacesBoolean) {
      if (dimensionsDiffer) {
        dispatch(setAdditionalSpacesAlteredData({
          additionalSpacesAlteredData: additionalSpacesdimensions
        }))
      } else {
        let setMyValues = { ...additionalSpacesdimensions };
        Object.keys(setMyValues).map((key) => {
          setMyValues[key]["width"] = width;
          setMyValues[key]["length"] = length;
        })
        dispatch(setAdditionalSpacesAlteredData({
          additionalSpacesAlteredData: setMyValues
        }))
      }
    } else {
      dispatch(setSpaceDimensions({
        length: length,
        width: width,
      }));
    }
  };

  const updateDimensions = (i, type, val) => {
    let spacesObj = { ...additionalSpacesdimensions };
    spacesObj[i][type] = val;
    setAdditionalSpacesdimensions(spacesObj)
  }

  useEffect(() => {
    numberAdditionalSpaces && (
      setAdditionalSpacesdimensions(additionalSpacesAlteredData)
    )
  }, []);

  return (
    <HostOnboarding
      heading="What are the Space Dimensions?"
      navigateF={newProperty ? "SetPrice" : "Confirmation"}
      nextButtonText="Next"
      navigateB={newProperty ? "AddDescription" : "PhotoUpload"}
      onClickNext={() => setDimensions()}
      subtext="set the maximum amount available"
    >
      {additionalSpacesBoolean ? (
        <>
          <View style={{marginBottom: 25}}>
            <CustomButton
              height={70}
              textAlign="flex-start"
              alignItems="flex-start"
              width={mobileView ? windowWidth / 1.15 : 500}
              marginBottom={5}
              backgroundColor={!dimensionsDiffer ? colors.vibrantBlue : colors.amplifyNeutral20}
              border={!dimensionsDiffer ? colors.amplifyNeutral80 : "#4c4e52"}
              onPress={() => setdimensionsDiffer(!dimensionsDiffer)}
            >
              <View style={{ flexDirection: "row", paddingLeft: 10, width: mobileView ? windowWidth / 1.3 : 480 }}>
                <Ionicons name={!dimensionsDiffer ? "radio-button-on-sharp" : "radio-button-off-sharp"} size={24} color={colors.amplifyNeutral80} />
                <Text style={{ fontSize: mobileView ? 17 : 20, textAlign: "left", marginLeft: 4 }}>Dimensions of spaces are relatively the same</Text>
              </View>
            </CustomButton>
            <CustomButton
              height={mobileView ? 52 : 45}
              textAlign="flex-start"
              alignItems="flex-start"
              width={mobileView ? windowWidth / 1.15 : 500}
              marginBottom={5}
              backgroundColor={dimensionsDiffer ? colors.vibrantBlue : colors.amplifyNeutral20}
              border={dimensionsDiffer ? colors.amplifyNeutral80 : "#4c4e52"}
              onPress={() => setdimensionsDiffer(!dimensionsDiffer)}
            >
              <View style={{ flexDirection: "row", paddingLeft: 10, width: mobileView ? windowWidth / 1.3 : 480 }}>
                <Ionicons name={dimensionsDiffer ? "radio-button-on-sharp" : "radio-button-off-sharp"} size={24} color={colors.amplifyNeutral80} />
                <Text style={{ fontSize: mobileView ? 17 : 20, textAlign: "left", marginLeft: 4 }}>Nope, dimensions vary</Text>
              </View>
            </CustomButton>
          </View>
          {dimensionsDiffer ? (
            <>
              {
                Object.keys(additionalSpacesdimensions).map((key) => (
                  <Flex
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    width={mobileView ? windowWidth / 1.7 : 500}
                    key={key}
                  >
                    <SetDimensions
                      additionalSpacesBoolean={additionalSpacesBoolean}
                      spaceTitle={additionalSpacesdimensions[key].title}
                      width={additionalSpacesdimensions[key].width || width}
                      length={additionalSpacesdimensions[key].length || length}
                      setlength={(v) => updateDimensions(key, 'length', v)}
                      setwidth={(v) => updateDimensions(key, 'width', v)}
                    />
                  </Flex>
                ))
              }
            </>
          ) : (
            <SetDimensions
              width={width}
              length={length}
              setlength={(v) => setlength(v)}
              setwidth={(v) => setwidth(v)}
            />
          )}
        </>
      ) : (
        <SetDimensions
          width={width}
          length={length}
          setlength={(v) => setlength(v)}
          setwidth={(v) => setwidth(v)}
        />
      )}
    </HostOnboarding>
  )
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: "#808080",
    padding: 70
  },
  textBox: {
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 50
  },
  text: {
    color: "white",
    fontSize: 33,
    fontWeight: "bold",
    textAlign: "center",
  },
  buttonSection: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  backButtonText: {
    color: "#343434",
    fontSize: 20,
    fontWeight: "bold",
  },
  backButton: {
    alignItems: 'center',
    borderBottomWidth: 2,
    justifyContent: 'center',
    alignSelf: 'center',
    height: 40,
    marginTop: 25,
    marginLeft: 25,
  },
  forwardButtonText: {
    color: "#616A6B",
    fontSize: 20,
  },
  forwardButton: {
    borderColor: "#616A6B",
    borderRadius: 10,
    width: 100,
    borderWidth: 1,
    backgroundColor: "#fff",
    alignItems: 'center',
    borderBottomWidth: 2,
    justifyContent: 'center',
    alignSelf: 'center',
    height: 40,
    marginTop: 25,
    marginLeft: 25,
  },
  disabledButtonText: {
    color: "#99A3A4",
    fontSize: 20,
  },
  disabledButton: {
    borderColor: "#616A6B",
    borderRadius: 10,
    width: 100,
    borderWidth: 1,
    backgroundColor: "#616A6B",
    alignItems: 'center',
    borderBottomWidth: 2,
    justifyContent: 'center',
    alignSelf: 'center',
    height: 40,
    marginTop: 25,
    marginLeft: 25,
  },
});

export default SetDimensionsScreen;
